import React, { Component } from 'react';
import Droppable from 'droppable';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import axios from 'axios';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
/**
 * source_id
 * attachmentsource_integrator
 * attachmenttype_id
 * attachmentsource_id
 */
class FileUpload extends Component {
    constructor(props){
        super(props);
        this.droppable = null;
        this.id = this.props.id ? this.props.id : 'file_uploader_canvas';
        this.cancelTokenSource = axios.CancelToken.source();
        this.state = {
            totalFiles:0,
            uploadingFiles:0,
            percentage:0,
            isLoading:false
        }
    }
    cancelUpload = () => {
        this.cancelTokenSource.cancel('Upload canceled by user');
        this.cancelTokenSource = axios.CancelToken.source()
        this.cancelTokenSource.token.reason = null;
    };
    uploadFiles(files){
        if(!this.props.source_id){
            Helper.alert('Source id not found','error');
            return;
        }
        if(!this.props.attachmenttype_id){
            Helper.alert('Type Id not found','error');
            return;
        }
        let that = this;
        that.setState({
            isLoading:true,
            totalFiles:files.length,
            uploadingFiles:1
        })
        let loaderController = () => {
            let currentFileCount =  that.state.uploadingFiles + 1;
            that.setState({
                isLoading:currentFileCount > that.state.totalFiles ? false : true,
                uploadingFiles: currentFileCount,
                percentage:0
            })
        }
        files.forEach(file => {
            let fileSize =file.size >=1 ?  (( (file.size) / 1024 ) / 1024) : 0;
            const ProgressConfig = {
                onUploadProgress: progressEvent => {
                    if (this.cancelTokenSource.token.reason) {
                      this.cancelTokenSource.cancel('Upload canceled by user');
                    } else{
                    let PercentageUpload  = (progressEvent.loaded / progressEvent.total ) * 100 ;
                    that.setState({
                        percentage:PercentageUpload
                    })
                }
                },
                cancelToken: this.cancelTokenSource.token,
            }
            let fileData = {
                    source_id:that.props.source_id,
                    attachmenttype_id: parseInt(that.props.attachmenttype_id) ,
                    filename: file.name,
                    filetype:file.type,
                    filesizeinMB: fileSize,
                    //file:reader.result
            }
            let api = Api;
            api.setUserToken();
            //comment for future use
            // if(that.props.attachmentsource_integrator && !that.props.attachmentsource_id){
            //     let fileDataWithIntegrator = {
            //         source_id: that.props.source_id,
            //         attachmentsource_integrator: that.props.attachmentsource_integrator,
            //         attachment:{
            //             attachmenttype_id: parseInt(that.props.attachmenttype_id),
            //             filesizeinMB: fileSize
            //         }
            //     }
            //     let finalFormData = new FormData();
            //     finalFormData.append("file",  file);
            //     finalFormData.append("data",  JSON.stringify(fileDataWithIntegrator));
            //     api.axios().post(Settings.apiUrl + '/attachmentsource',finalFormData,ProgressConfig).then(function(res){
            //         loaderController();
            //         if(res.data.status ==='success'){
            //             that.onFileUpload(fileData);
            //             Helper.alert(res.data.message);
            //         }else{
            //             Helper.alert(res.data.message,'error');
            //         }
            //     }).catch(error=> {
            //         loaderController();
            //         getPopupMessageBasedOnStatus(error);
            //     })
            // }else{
                const type = this.props.activeKey === 'internal' ? 0 : 1;
                fileData.attachmentsource_id = that.props.attachmentsource_id;
                let fileDataWithIntegrator = {
                    source_id: that.props.source_id,
                    attachmentsource_integrator:that.props.attachmentsource_integrator,
                    attachment_for:that.props.userType===0?that.props.document_type?that.props.userType:type:that.props.userType,
                    attachmenttype_id: parseInt(that.props.attachmenttype_id),
                    filesizeinMB: fileSize
                }
                let finalFormData = new FormData();
                finalFormData.append("file",  file);
                finalFormData.append("data",  JSON.stringify(fileDataWithIntegrator));


                api.axios().post(Settings.apiUrl + '/attachment',finalFormData,ProgressConfig).then(function(res){
                    loaderController();
                    if(res.data.status ==='success'){
                        that.onFileUpload(fileData);
                        Helper.alert(res.data.message);
                    }else{
                        Helper.alert(res.data.message,'error');
                    }
                }).catch(error=> {
                    if (axios.isCancel(error)) {
                        loaderController();
                        Helper.alert('Request canceled by user','error');
                    } else {
                        loaderController();
                        getPopupMessageBasedOnStatus(error);
                    }
                })
            // }
            /*
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = function () {
                

            };
            reader.onerror = function (error) {
                loaderController();
                console.log('Error: ', error);
            };
            */
           
        });
    }
    componentDidMount(){
        if(!this.droppable){
            this.droppable = new Droppable({
                element: document.querySelector('#'+this.id)
            })
            let that = this;
            this.droppable.onFilesDropped((files) => {
                that.uploadFiles(files)
            });
        }
    }
    onFileUpload(file){
        if(this.props.onFileUpload && typeof this.props.onFileUpload ==='function'){
            this.props.onFileUpload(file)
        }
    }
    
    onTemplateDownloadClick(id){
        if(!id){
            return;
        }
        let api = Api
        api.setUserToken()
        api
          .axios()
          .get(Settings.loginUrl + `/get_attachmenttype_doc/${id}`,{ responseType: 'blob' })
          .then(async function (res) {
            if (res.status === 200) {
              const response = res
              const contentDisposition = response.headers['content-disposition']
                let fileName = 'dummy.jpg'; 
                if (contentDisposition) {
                  const fileNameMatch = contentDisposition.match(/filename\*?=(?:UTF-8'')?([^;]+)/);
                  if (fileNameMatch?.[1]) {
                    fileName = decodeURIComponent(fileNameMatch[1].trim());
                  } 
                }
              const fileBlob = new Blob([response.data], {
                type: response.headers['content-type'],
              })
              const downloadLink = document.createElement('a')
              downloadLink.href = URL.createObjectURL(fileBlob)
              downloadLink.download = fileName
    
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            }
          })
          .catch((res) => {
            getPopupMessageBasedOnStatus(res) 
          })
    }
    displayLoader(){
        if(!this.state.isLoading){
            return <></>
        }
        let percentageUpload = this.state.percentage;
        return(
            <div className="uploader_loader">
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={percentageUpload} aria-valuemin="0" aria-valuemax="100" style={{width:`${percentageUpload}%`}}>{Math.round(percentageUpload)}%</div>
                </div>
                <p className='upload_st_label' style={{display:'none'}}>Uploading {this.state.uploadingFiles} / {this.state.totalFiles}</p>
            </div>
        )
    }
    render() {
        return (
            <>
            <div className='file_uploader'>
                {
                    this.displayLoader()
                }
                <div className='file_uploader_canvas' id={this.id}>
                    <img src="/images/icons/file-upload.svg" className='file_uploader_icon' alt="Upload File" />
                    <div className='uploader_help_text'>
                        <h3>Select a file or drag and drop here</h3>
                        <p>JPG, PNG or PDF, file size no more than 10MB</p>
                        
                    </div>
                </div>
            </div>
            <div className='d-flex gap-2 align-items-center justify-content-end'>
              {this.props?.attachmenttype_id && this?.props?.isTemplate && <button  className='d-flex align-items-center p-0 border-0 bg-transparent justify-content-end' onClick={() => {this.onTemplateDownloadClick(this?.props?.attachmenttype_id)}}><img src="/images/icons/print.svg" alt="download_icon" height={"18px"} /><p className='p-0 m-0'>Download Template</p></button>}
              <a onClick={this.cancelUpload} className='d-flex align-items-right justify-content-end' style={{cursor:"pointer",textDecoration:"none"}}>Cancel</a>
            </div>
            </>
        );
    }
}

export default FileUpload;