import React, {useState, useEffect, Fragment, useRef} from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../inc/Button';
import Api from '../../../inc/Api';
import Treeview from '../../Treeview/Treeview';
import '../User Container/UserContainer.css';
import Collapse from '../../inc/Collapse';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper';
import LinkBtn from '../../inc/LinkBtn';
import NavigationHeder from '../../Navigations/NavigationHeder';
import { connect, useSelector, useDispatch } from 'react-redux';
import Delete from '../../../assets/delete.svg';
import $ from 'jquery';
import Plus from '../../../assets/add-new.svg';
import { gridIdActions } from '../../../store/grid-id';
import Popup from '../../Popup/Popup';
import DropDownInput from '../../Forms/Dropdown';
import SimpleLoading from '../../Loading/SimpleLoading';
import Input from '../../Forms/Input';
import FormValidator from '../../../inc/FormValidator';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import Checkbox from '../../Forms/Checkbox';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import { areObjectsDifferent, checkIfArrayIsEmpty, filterByReference, getPopupMessageBasedOnStatus } from '../../../inc/Validation';

import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

// Lynkaz- User SYSTEM -

// Variables Declaration for keeping the system's state presistent --  
let defaultUserObj = null; 
let rolesGridSelected = [];
let defaultUserRoles = [];
let currentSearchParam = '';

// Component  Initialization ---
const EditUsers = (props) => {
  // getting id from url --
  const {id} = useParams();
  const employeeAllData = useRef([]);
  // dispatching function using redux hook
  const dispatch = useDispatch()
  // Roles Array data --
  const [rolesStructure, setRolesStructure] = useState([])
  // loading state
  const [loading, setLoading] = useState(true)
  const [loadRoles, setLoadRoles] = useState(true) 
  // getting users populated in state -
  const [users, setUsers] = useState({})
  // dropdown state data ---
  const [languages, setLanguages] = useState([])  
  // grid data thrugh Redux -
  const showRolesGrid = useSelector(state => state.gridIdFlow.rolesListShow)
  // unique id for identifying the record inside the popup grid --
  const [ID, setID] = useState(null) 
  const [employees, setEmployees] = useState([]) 

  const wrapperDiv = useRef();
  const [height, setHeight] = useState(0);

  //API Reduction Calls 
    let languagesCalled = false
    let usersCalled = false
    let rolesCalled = false
    let employeeCalled = false 
  //End

  // Component Renders / loads --

  useEffect(() => {
    const timer = setTimeout(() => {  
      get_languages_list() 
      load_users()  
      getEmployeeList() 
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => { 
    load_roles() 
  }, [id])
 
  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  // -----

  // Validation system initialization ----
  let validator; 
  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'user_name', displayName:Helper.getLabel(props.language,'user_name','User Name'),types:['Required']}, 
      {name:'first_name', displayName:Helper.getLabel(props.language,' first_name','First Name'),types:['Required']},  
      {name:'last_name', displayName:Helper.getLabel(props.language,'last_name','Last Name'),types:['Required']},
      // {name:'language_id', displayName:Helper.getLabel(props.language,'language','Language'),types:['Required']},
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()

  // ----

  // API Calls for dropdown data ---
  
  const load_users = () => { 
    setLoading(true)
    let UserID = id ? id : null
    if(usersCalled || !UserID){
      return;
    }
    let api = Api
    usersCalled = true
    api.setUserToken()  
    api.axios().get(Settings.apiUrl+`/get_user/${id}`,{}).then(function(res){ 
      if(res.data.status==='success'){
        defaultUserObj = res.data.data   
        setUsers(res.data.data)
        setLoading(false)
      }
    }).catch((res) => { 
      setLoading(false) 
      getPopupMessageBasedOnStatus(res)
    })  
  }
 
  const load_roles = () => {
    if(rolesCalled || !id){
      return;
    }
    let api = Api
    rolesCalled = true
    setLoadRoles(true)
    api.setUserToken()  
    api.axios().get(Settings.apiBaseUrl+`/get_user_roles/${id}`,{}).then(function(res){ 
      if(res.data.status==='success'){
        let ROLES_STRUCTURE = []
        res.data.data.forEach(element => {
          ROLES_STRUCTURE.push({
            id: element.role_id, 
            title: element.name,
            showChild:false,
            items:[]
          })
        })
        setLoadRoles(false)
        setRolesStructure(ROLES_STRUCTURE)  
        defaultUserRoles = res.data.data
      }
    }).catch((res) => { 
      setLoadRoles(false)
    })  
  }

  const getEmployeeList = () => {
    if(employeeCalled) {
      return
    }
    let api = Api
    employeeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/employee_dropdown?status=true',{}).then(function(res){
      if(res.data.status==='success'){   
        let Employee_API_LIST = []
        res.data.data.forEach(item => {  
          Employee_API_LIST.push({
           value: item.employee_id,
           label: `${item.displayname}`
         })
        })
        setEmployees(Employee_API_LIST)
      }
    }).catch((res) => { 
      console.log(res)
    })
  }
 
  const get_languages_list = () => {
    if(languagesCalled){
      return;
    }
    let api = Api
    languagesCalled = true
    api.setUserToken()
    api.axios().get(Settings.loginUrl+'/language',{}).then(function(res){
      if(res.data.status==='success'){   
        let Languages_API_LIST = []
        res.data.data.forEach(item => {  
          Languages_API_LIST.push({
           value: item.language_id,
           label: item.language
         })
        })
        setLanguages(Languages_API_LIST)
      } 
    }).catch((res) => { 
      console.log(res)
    })
  }

  // ---

  // mapping functions,

  const mapEmployeeValue = (obj) => { 
    return {  id: obj.employee_id , text: `${obj.displayname}`}
  }

  //--
  
  // Dropdown input change handlers --

  const onChangeUser = (event) => {
    let User = event.target.value
    setUsers((prevState) => {
      return {...prevState, user_name: User}
    })
  }

  const onChangefName = (event) => {
    let FirstName = event.target.value
    setUsers((prevState) => {
      return {...prevState, first_name: FirstName}
    })
  }
 
  const onChangeNetwork = (event) => {
    let NetworkDomain = event.target.value
    setUsers((prevState) => {
      return {...prevState, network_domain: NetworkDomain}
    })
  }

  const onChangeMName = (event) => {
    let MiddleName = event.target.value
    setUsers((prevState) => {
      return {...prevState, middle_name: MiddleName}
    })
  }

  const onChangelName = (event) => {
    let LastName = event.target.value
    setUsers((prevState) => {
      return {...prevState, last_name: LastName}
    })
  }

  const EmployeeHandler = (event) => {
    let emp = event.target.value
    setUsers((prevState) => {
      return {...prevState, employee_id: emp}
    })
  }

  const LanguageHandler = (event) => {
    let lang = event.target.value
    setUsers((prevState) => {
      return {
        ...prevState,
        language_id: lang
      }
    })
  }
 
  const onChangeEnable = (name, value) => { 
    if(value == "on"){
      setUsers((prevState) => {
        return {...prevState, [name]: true}
      })
    }else {
      setUsers((prevState) => {
        return {...prevState, [name]: false}
      })
    } 
  }

  // popup closing handlers --
 
  const CloseHandler = (event) => { 
    dispatch(gridIdActions.rolesGridShow({payload: false})) 
    $('html').removeClass('rs_popup_open');
  }

  //--- 

  // grid things -- 

  const Columns_Headings_Roles = [
    {field:'name', headerName: 'Role Name'},
    {field:'description', headerName: 'Role Description'}, 
  ]
 
  const RowSelection = (data) => { 
    let Updated_array = []
    let currentNode = data.api.getSelectedRows(); 
    if(currentNode.length <=0 || !currentNode[0].role_id){
      $('.rs_setvalue_btn').addClass('ctl-dissable');
      $('.visible_on_select_item').addClass('ctl-dissable');
    }else{
      $('.rs_setvalue_btn').removeClass('ctl-dissable');
      $('.visible_on_select_item').removeClass('ctl-dissable');
    } 
    if(!checkIfArrayIsEmpty(currentNode)){ 
      currentNode.forEach((element) => {
        Updated_array.push({
          "role_id": element.role_id,
          "user_id": parseInt(id)
        })
      })
      rolesGridSelected = Updated_array
      return;
    } 
    rolesGridSelected = [] 
  }

  const AssignRoles = () => {  
    if(id){ 
      dispatch(gridIdActions.rolesGridShow({payload: true}))
    }else {
      Helper.alert('Please Select A User To Assign Roles', 'error')
    }
  }

  const rolesHandler = (itemId) => {
    setID(itemId.id)
  }

  const DeleteAssignedRoles = () => {
    let api = Api
    api.setUserToken()
    if(ID){
      api.axios().delete(Settings.apiBaseUrl+`/delete_user_role/${id}/${ID}`,{}).then(function(res){
        if(res.data.status==='success'){  
          Helper.alert('User Role Deleted Successfully')
          load_roles() 
        }
      }).catch((res) => { 
        
      getPopupMessageBasedOnStatus(res)
        load_roles() 
      }) 
    }else{ 
      Helper.alert('Please Select Any Role To Delete!', 'error')
      load_roles() 
    }
  }

  const Callbuttons = () => {
    return <Button title='Select' className='rs_setvalue_btn select_role_btn visible_on_select_item ctl-dissable' onClick={SelectedRolesList} />;
  }

  const handleFetchData = (response) => {
    let newArr = filterByReference(response?.data?.data, defaultUserRoles);
    return {
      rowData: newArr
      ?.filter(val => val.role_id !== "63" && 
        (val.name.includes("Lynkaz -") || 
        val.name === "System User" || 
        val.name === "Workflow User" || 
        val.name === "Lynkaz Demo Role") 
      )
      ?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: newArr
      ?.filter(val => val.role_id !== "63" && 
        (val.name.includes("Lynkaz -") || 
        val.name === "System User" || 
        val.name === "Workflow User" || 
        val.name === "Lynkaz Demo Role")
      )?.length
    };
  }

  // ----

  // User related Components / Modules ----

  const UsersModule = () => {
    return (
      <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
        <Collapse
          title={Helper.getLabel(props.language, 11217, "General")}
          open={true}
        >
          <section className="users-fields">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="row users-fields-form gy-0 mt-2 mb-2">
                    <div className="col-lg-3 col-md-3">
                      <Input
                        disable={true}
                        label={Helper.getLabel(
                          props.language,
                          "user",
                          "User email"
                        )}
                        maxLength={80}
                        type="text"
                        disabled 
                        required={true}
                        onChange={onChangeUser}
                        value={users.user_name}
                      />
                    </div>

                    <div className="col-lg-3 col-md-3">
                      <Input
                        label={Helper.getLabel(
                          props.language,
                          "network_domain_USER_SETUP",
                          "Network domain"
                        )}
                        maxLength={255} 
                        type="text" 
                        onChange={onChangeNetwork}
                        value={users.network_domain}
                      />
                    </div>

                    <div className="col-lg-3 col-md-3">
                      <Input
                        label={Helper.getLabel(
                          props.language,
                          "first_name_USER_SETUP",
                          "First name"
                        )}
                        type="text" 
                        maxLength={50} 
                        required={true}
                        onChange={onChangefName}
                        value={users.first_name}
                      />
                    </div>

                    <div className="col-lg-3 col-md-3">
                      <Input
                        label={Helper.getLabel(
                          props.language,
                          "middle_name_USER_SETUP",
                          "Middle name"
                        )}
                        maxLength={50} 
                        type="text" 
                        onChange={onChangeMName}
                        value={users.middle_name}
                      />
                    </div>
                  </div>

                  <div className="row users-fields-form gy-0 mb-2">
                    <div className="col-lg-3 col-md-3">
                      <Input
                        label={Helper.getLabel(
                          props.language,
                          "last_name_USER_SETUP",
                          "Last name"
                        )}
                        type="text" 
                        required={true}
                        maxLength={50} 
                        onChange={onChangelName}
                        value={users.last_name}
                      />
                    </div>

                    <div className="col-lg-3 col-md-3 mt-auto"> 
                      <DropDownInput
                        apiUrl={Settings.apiOrgUrl + "/employee_dropdown?status=true"}
                        currentSearchTerm={currentSearchParam}
                        mapFunction={mapEmployeeValue}
                        allDataRef={employeeAllData}
                        reRenderRequired={true}
                        options = { 
                          users?.employee_id ? 
                            [{ value: users?.employee_id, label: users?.employee_name ?? employeeAllData?.current?.find(item => parseInt(item.employee_id) === parseInt(users?.employee_id))?.displayname }] 
                          : 
                          employees
                        }
                        label={Helper.getLabel(
                          props.language,
                          "employee_USER_SETUP",
                          "Employee"
                        )}
                        placeHolder={`${Helper.getLabel(
                          props.language,
                          'please_select',
                          'Select employee',
                          )}`
                        } 
                        pagination={true} 
                        onChange={EmployeeHandler}
                        value={users?.employee_id}
                        id="employee_id"
                        name='employee_id'
                      />
                    </div>

                    <div className="col-lg-3 col-md-3 mt-auto">
                      <DropDownInput
                        label={Helper.getLabel(
                          props.language,
                          "default_language_USER_SETUP",
                          "Default language"
                        )}
                        onChange={LanguageHandler}
                        options={languages}
                        reRenderRequired={true}
                        isDisable={true}
                        required={true}
                        value={languages?.find(val => val.label == "English")?.value || null}
                        id="language_id"
                      />
                    </div>

                    <div className="col-lg-3 col-md-3 mt-auto">
                      <Input
                        label={Helper.getLabel(
                          props.language,
                          "user_type",
                          "User type"
                        )}
                        type="text"     
                        disable={true}
                        value={users.user_type}
                      />
                    </div>

                    <div className="col-lg-2 col-md-2 mt-2 d-flex gap-3">
                      <Checkbox
                        type="checkbox"
                        className="me-4"
                        name="enable"
                        onChange={onChangeEnable}
                        isChecked={users.enable}
                        label={Helper.getLabel(
                          props.language,
                          "active",
                          "Active"
                        )}
                      />
                       <Checkbox type='checkbox' name="is_buyer" hasError={validator.hasError('is_buyer')} className='me-4' onChange={onChangeEnable} isChecked={users.is_buyer} label={Helper.getLabel(props.language,'is_buyer',"is Buyer")} /> 
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Collapse>
        <div className="mt-4">{UsersRolesGridModule()}</div>
        {PopupGridWithRoles()}
      </div>
    );
  }

  const UsersRolesGridModule = () => {
    return (
      <Collapse title='Users Roles' open={true}>
        <section className='user-roles-section'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 gy-2'>
                <div className='roles-buttons d-flex justify-content-start border-bottom'>
                  <button type='button' className='add-user-role' onClick={AssignRoles}><span className='me-1'><img src={Plus} className='img-fluid mb-1 roles-icons' /></span> {Helper.getLabel(props.language, 'assign_role_USER_SETUP', "Assign Role")}</button>
                  <button type='button' className='delete-role' onClick={DeleteAssignedRoles}><span className='me-1'><img src={Delete} className='img-fluid mb-1 roles-icons' /></span> {Helper.getLabel(props.language, 'delete_role_USER_SETUP', "Delete Role")}</button>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='user-treeview-hierarchy ps-4 pt-2'>
                  {loadRoles ? <SimpleLoading /> : <Treeview items={rolesStructure} onClick={rolesHandler} />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Collapse>
    )
  }

  const PopupGridWithRoles = () => {
    return (
      <Fragment>
        {showRolesGrid && 
          <div className='search_grid_height'> 
            <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
              <div className='rs_users_grid'>
                <AgGridNew
                  apiUrl={Settings.apiBaseUrl+'/role'}  
                  pagination={false}
                  columnDefs={Columns_Headings_Roles}
                  fetchData={handleFetchData}
                  hideAddBtn={true}
                  hideDeleteBtn={true}
                  height={500}
                  afterDeleteBtns={<Callbuttons />}
                  onSelectionChanged={RowSelection}
                  gridId={"rs_popup_open"}
                />
              </div>
            </Popup>
          </div>
        }
      </Fragment>
    )
  }

  // -----

  // API Execution - Saving User Changes

  const SelectedRolesList = () => {   
    let api = Api
    api.setUserToken() 
    if(!checkIfArrayIsEmpty(rolesGridSelected)){
      api.axios().post(Settings.apiBaseUrl+'/assign_role', rolesGridSelected).then(function(res){
        if(res.data.status==='success'){  
          load_roles() 
          CloseHandler()
          Helper.alert('User Roles Updated Successfully!')
          rolesGridSelected = []
        }
      }).catch((res) => { 
        load_roles() 
        
      getPopupMessageBasedOnStatus(res)
      }) 
    }
  }

  const SaveHandler = (e) => {
    if(checkIfArrayIsEmpty(defaultUserRoles)){ 
      Helper.alert('Assign atleast basic role to this user!', 'error') 
      return; 
    }

    if(!areObjectsDifferent(defaultUserObj, users)) {
      return;
    }

    let newData = {
      user_name: users.user_name,
      first_name: users.first_name,
      last_name: users.last_name,
      language_id: users.language_id
    }

    let validData = {
      ...newData
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    } 

    let api = Api
    api.setUserToken()   
    api.axios().put(Settings.apiUrl+`/user/${id}`, {
      "first_name": users.first_name !== "" ? users.first_name : null,
      "last_name": users.last_name !== "" ? users.last_name : null,
      "middle_name": users.middle_name !== "" ? users.middle_name : null,
      "user_name":users.user_name !== "" ? users.user_name : null,
      "enable": users.enable,
      "is_buyer":users.is_buyer,
      "SID":null,
      "network_domain":users.network_domain !== "" ? users.network_domain : null,
      "network_alias":null,
      "employee_id": users?.employee_id ? parseInt(users?.employee_id) : "",
      "language_id": languages?.find(val => val.label == "English")?.value || null
    }).then(function(res){
      if(res.data.status==='success'){  
        load_roles()
        load_users() 
        Helper.alert('User Updated Successfully!')
      }
    }).catch((res) => { 
      load_roles()
      load_users()
      
      getPopupMessageBasedOnStatus(res)
    })
  } 

  // ----

  // SYSTEM Authentication - Security - Accessibility --
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.administrationForms.users.users_edit_screen)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  }
  //--

  return ( 
    <Fragment> 
      { loading ? <OverlayLoader /> : null }
      <div className='container-fluid'> 
        <div ref={wrapperDiv}> 
          <NavigationHeder hideMoreBtn={true} title={`<span style="color:#000;">${Helper.getLabel(props.language, 'user', "User")} #</span>  ${users.first_name}`}>
            <LinkBtn isActive={false} to={-1} title={Helper.getLabel(props.language, 'close', "Close")} className='black-btn-style' />
            <Button isDisable={!security.canEdit(frontendIds.administrationForms.users.users_edit_screen_save_button)}  isActive={false} onClick={SaveHandler} title={Helper.getLabel(props.language, 'save', "Save")} className="rfq_save_btn" />
          </NavigationHeder> 
        </div>
 
        { UsersModule()} 
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.administrationForms.users.users_edit_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditUsers, SecurityOptions))))