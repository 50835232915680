import DatepickerFunctions from '../../../inc/DatepickerHelper'
import Helper from '../../../inc/Helper'
import { getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator, purchaseStatusValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'

const getCommonColumns = ({ language, type, purchaseStatus, documentStatus }) => {
  return [
    {
      field: "document_number",
      headerName: Helper.getLabel(
        language,
        "document_number",
        "Document number"
      ),
      minWidth:200,
      editable: false,
      cellRenderer: gridLinkValueFormat,
    },
    {
      field: "vendor_code",
      headerName: Helper.getLabel(language, "vendor_code", "Vendor code"),
      editable: false,
      cellRenderer: gridLinkValueFormat,
    }, 
    ...(type !== "purchase agreement"
      ? [
          { field:'purchase_requisition_number', sortable: false, minWidth: 300, headerName:Helper.getLabel(language,'purchase_requisition_number',"Purchase requisition number"), valueFormatter: (params) => params?.value ? params?.value : '-', editable: false },
          {
            field: "rfq_document_number",
            sortable: false,
            valueFormatter: (params) => (params?.value ? params?.value : "-"),
            minWidth: 300,
            sortable: false,
            headerName: Helper.getLabel(
              language,
              "rfq_document_number",
              "RFQ document number"
            ),
            editable: false,
            minWidth: 240
          }
        ]
      : []
    ),  
    ...(type === "purchase agreement"
      ? [
          {
            field: "release_order_document_number",
            headerName: Helper.getLabel(
              language,
              "pr_document_number",
              "Release order number"
            ),
            sortable: false,
            valueFormatter: (params) => params.value || "-",
            editable: false,
            minWidth: 240,
          }
        ]
      : []
    ), 
    ...(type === "purchase agreement"
      ? [
          {
            field: "agreement_document_number",
            sortable: false,
            valueFormatter: (params) => (params?.value ? params?.value : "-"),
            minWidth: 300,
            sortable: false,
            headerName: Helper.getLabel(
              language,
              "agreement_document_number",
              "Agreement document number"
            ),
            editable: false
          }
        ]
      : []
    ),
    {
      field: "vendor_name",
      headerName: Helper.getLabel(language, "vendor_name", "Vendor name"),
      editable: false,
    },
    {
      field: "document_status",
      headerName: Helper.getLabel(
        language,
        "document_status",
        "Document status"
      ),
      editable: false,
      options: [true, false],
      cellRenderer: (params) => getStatusNameById(params.value, documentStatus),
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params) => getOptions(params, documentStatus),
        keyCreator: (params) => optionsKeyCreator(params, "id"),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: "purchase_status",
      headerName: Helper.getLabel(
        language,
        "purchase_status",
        "Purchase status"
      ),
      editable: false,
      options: [true, false],
      cellRenderer: (params) =>
        purchaseStatusValueFormat(params, purchaseStatus?.allStatus),
      filter: "agSetColumnFilter",
      filterParams: {
        values: (params) => getOptions(params, purchaseStatus?.allStatus),
        keyCreator: (params) => optionsKeyCreator(params, "id"),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: "description",
      headerName: Helper.getLabel(language, "description", "Description"),
      valueFormatter: (params) => params.value || "-",
    },
    {
      field: "document_date",
      headerName: Helper.getLabel(language, "document_date", "Document date"),
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      filter: "agDateColumnFilter",
    },
    {
      field: "createddatetime",
      headerName: Helper.getLabel(
        language,
        "creationdatetime",
        "Creation date and time"
      ),
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateTimeFromDataBase(params.value),
      editable: false,
      filter: "agDateColumnFilter",
    },
    {
      field: "accounting_date",
      headerName: Helper.getLabel(
        language,
        "accounting_date",
        "Accounting date"
      ),
      editable: false,
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      hide: true,
      filter: "agDateColumnFilter",
    },
    {
      field: "attention_information",
      headerName: Helper.getLabel(
        language,
        "attention_information",
        "Attention information"
      ),
      editable: false,
      hide: true,
    },
    {
      field: "delivery_date",
      headerName: Helper.getLabel(language, "delivery_date", "Delivery date"),
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      editable: false,
      hide: true,
    },
    {
      field: "delivery_name",
      headerName: Helper.getLabel(language, "delivery_name", "Delivery name"),
      editable: false,
      hide: true,
    },
    {
      field: "discount_amount",
      headerName: Helper.getLabel(
        language,
        "discount_amount",
        "Discount amount"
      ),
      filter: "agNumberColumnFilter",
      editable: false,
      hide: true,
    },
    {
      field: "discount_percentage",
      headerName: Helper.getLabel(
        language,
        "discount_percentage",
        "Discount percentage"
      ),
      editable: false,
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "total_amount",
      headerName: Helper.getLabel(language, "total_amount", "Total amount"),
      editable: false,
      filter: "agNumberColumnFilter",
      hide: true,
    },
  ];
}

export default getCommonColumns
