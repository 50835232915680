import React, { Component } from 'react';
import Input from '../../Forms/Input';
import DropDownInput from '../../Forms/Dropdown';
import Collapse from '../../inc/Collapse';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import Tabs from '../../Widgets/Tabs/Tabs';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import "../../Purchase Orders Internal/PO.css"
import Checkbox from '../../Forms/Checkbox';


class GeneralInformation extends Component {
    constructor(props){
        super(props); 
        this.state = {
            data:this.props.data, 
            originalData:this.props.data,
            date: new Date()
        }
    }
    componentDidMount(){
        this.setState({
            data:this.props.data,
            originalData:this.props.data
        }) 
        this.validationConfigure(); 
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState?.data?.exempted_from_tax !== this.state?.data?.exempted_from_tax) {
            this.validationConfigure(); 
        } 
    }
    validationConfigure(){
        let language = this.props.dataLoaderProps.language;
        let fieldConfig = [
            {name:'name',displayName:Helper.getLabel(language,'supplier_trade_name_en','Supplier trade name (EN)'),types:['Required'] },
            {name:'alternate_name',displayName:Helper.getLabel(language,'supplier_trade_name_ar','Supplier trade name (AR)'),types:['Required']  },
            {name:'currency_id',displayName:Helper.getLabel(language,'currency','Currency'),types:['Required']},
            {name:'paymentterm_id',displayName:Helper.getLabel(language,'payment_term','Payment Term'),types:['Required']},
            {name:'externalcode',displayName:Helper.getLabel(language,'license_number','License number'),types:['Required']}
            // {name:'dunsnumber',displayName:Helper.getLabel(language,"dunsnumber","DUNS number"),max:18}
        ]
        if(!this.state?.data?.exempted_from_tax) {
            fieldConfig.push({
               name:'taxregistration_number',displayName:Helper.getLabel(language,'taxregistration_number','Tax registration number'),types:['Required']  
            })
        }
        if(this.state.data.exempted_from_tax){
            fieldConfig.push({
                name:'reason_for_tax_exemption',displayName:Helper.getLabel(language,'reason_for_tax_exemption','Reason for tax exemption'),types:['Required']  
            })
        }
        this.validator = new FormValidator(fieldConfig,language);
    }
    onChangeHandler(e){
        let data = this.state.data;
        this.setState({
            data:{
                ...data,
                [e.target.name]:e.target.value
            }
        })
    }
    onInputCheckboxChangeHandler = (name,value) => {  
        let data = this.state.data;
        if(value === 'on'){
            this.setState({
                data:{
                    ...data,
                    taxregistration_number: '',
                    [name]:true
            }
        })
        }else {
            this.setState({
                data:{
                    ...data,
                    [name]:false
                }
            })
        }
    }
    dateChangeHandler(date,name){
        let data = this.state.data;
        this.setState({
            data:{
                ...data,
                [name]:date
            }
        })
    }

    filterAndReduceData(data, excludedFields) {
        return Object.keys(data)
            .filter(key => !excludedFields.includes(key))
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {});
    }
    
    goStep(stepNumber){ 
        let validData = this.state?.data;
        let validationObjFields = {
            name: validData?.name,
            alternate_name: validData?.alternate_name,
            currency_id: validData?.currency_id,
            paymentterm_id: validData?.paymentterm_id,
            externalcode: validData?.externalcode,
            taxregistration_number: validData?.taxregistration_number,
            reason_for_tax_exemption: validData?.reason_for_tax_exemption
        };
        if (validData?.exempted_from_tax) {
            delete validationObjFields.taxregistration_number;
        };
        if(!validData?.exempted_from_tax) {
            delete validationObjFields.reason_for_tax_exemption;
        }; 
        if (!this.validator.isValid(validationObjFields)) {
            this.validator.displayMessage(this);
            return;
        };
        let excludedFields = ['currency_id', 'paymentterm_id', 'taxpayer_id', 'taxregistration_number', 'taxgroup_id','code','exempted_from_tax','reason_for_tax_exemption'];
        let excludedSiteFields = ['addresssource_id', 'alternate_name', 'attachmentsource_id', 'category', 'code', 'contactsource_id', 'dunsnumber', 'externalcode', 'facebook', 'incorporationyear', 'instagram', 'tiktok', 'isdeleted', 'legacy_code', 'linkedin', 'name', 'notesource_id', 'onetime', 'processinstance_id', 'status', 'translationsource_id', 'twitter', 'vendor_id', 'vendor_type', 'websiteurl', 'yearestablished', 'youtube','name','brand_name'];
        let vendorData = this.filterAndReduceData(validData, excludedFields);
        let originalVendorData = this.filterAndReduceData(this.state.originalData, excludedFields);
        let sitesData = this.filterAndReduceData(validData, excludedSiteFields);
        let originalSitesData = this.filterAndReduceData(this.state.originalData,excludedSiteFields)
        if((JSON.stringify(vendorData)===JSON.stringify(originalVendorData)) && ((JSON.stringify(sitesData)===JSON.stringify(originalSitesData)))){
            this.props.goStep(stepNumber);
            this.props.updateStep(0,true)
            return;
        }
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        if(!validData.vendor_id){
            Helper.alert("Vendor Id not found!",'error');
            return;
        }
        validData = {
            ...validData,
            yearestablished:validData?.yearestablished === null ? null : DatepickerFunctions.convertDateForDataBase(new Date(validData.yearestablished)),
            incorporationyear:validData?.incorporationyear===null ? null : DatepickerFunctions.convertDateForDataBase(new Date(validData.incorporationyear)),
        }

        let siteData = {
            "vendor_sites": [
                {
                    "vendor_id": validData.vendor_id,
                    "name":validData.name,
                    "taxpayer_id": null,
                    "taxregistration_number": validData?.taxregistration_number,
                    "exclude_freight_from_discount": null,
                    "distributionsource_id": null,
                    "vendor_group_id": null,
                    "currency_id": validData.currency_id,
                    "paymentterm_id": validData.paymentterm_id,
                    "exempted_from_tax":validData.exempted_from_tax,
                    "reason_for_tax_exemption":validData.reason_for_tax_exemption,
                    "address": null,
                    "contact": null,
                    "is_default":true,
                    "taxgroup_id":null,
                    "price_include_tax":null
                }
            ]
        }

        let updateSiteData = {
            "vendor_id": validData.vendor_id,
            "name": validData.name,
            "taxpayer_id": null,
            "taxregistration_number": sitesData.taxregistration_number,
            "exclude_freight_from_discount": null,
            "distributionsource_id": null,
            "vendor_group_id": null,
            "currency_id": siteData?.vendor_sites[0]?.currency_id,
            "paymentterm_id": siteData?.vendor_sites[0]?.paymentterm_id,
            "exempted_from_tax": siteData?.vendor_sites[0]?.exempted_from_tax,
            "reason_for_tax_exemption":siteData?.vendor_sites[0]?.reason_for_tax_exemption,
            "address_source_id": null,
            "contact_source_id": null,
            "account_id":null,
            "is_default":null,
            "taxgroup_id":null,
            "price_include_tax":null
        }
        
        if (JSON.stringify(sitesData) !== JSON.stringify(originalSitesData) || validData?.vendorsite_id) {
            const updateSiteApiCall = () => {
                if(!originalSitesData?.vendorsite_id) return 
                api.axios()
                    .put(
                        Settings.apiVendorUrl + `/vendorsite/${originalSitesData.vendorsite_id}`,
                        updateSiteData
                    )
                    .then((res) => {
                        that.setState({
                            isLoading: false,
                        });
                        if (res.data.status === 'success') {
                            Helper.alert(
                                'Vendor Updated Successfully.'
                            );
                            that.props.goStep(stepNumber);
                            that.props.setVendorState(validData);
                        }
                    })
                    .catch((error) => {
                        that.setState({
                            isLoading: false,
                        });
                    });
            };
            updateSiteApiCall();
        }
      
        if(JSON.stringify(vendorData)!==JSON.stringify(originalVendorData)){

        api.axios().put(Settings.apiVendorUrl + '/vendor/'+validData.vendor_id,vendorData).then(res=>{
            that.setState({
                isLoading:false
            })
            if(res.data.status=='success'){
                Helper.alert(res.data?.message,"success")
                that.props.goStep(stepNumber)
                that.props.setVendorState(validData)
                that.props.loadVendorDetails()
            }
        }).catch(error =>{
            that.setState({
                isLoading:false
            })
        })
       }
        if(this.state.data.vendorsite_id === null || this.state.data.vendorsite_id === undefined) {
        api.axios().post(Settings.apiVendorUrl + '/vendorsite',siteData).then(res=>{
            that.setState({
                isLoading:false
            })
            if(res.data.status=='success'){
                that.props.goStep(stepNumber)
                that.props.setVendorState(siteData)
                that.props.loadVendorDetails()
            }
        }).catch(error =>{
            that.setState({
                isLoading:false
            })
            getPopupMessageBasedOnStatus(error)
        })
      }
      if(Object.keys(this.state.data).length>0){
        this.props.updateStep(0,true)
        this.props.goStep(stepNumber)
      }
    }

    render() {
        let data = this.state.data;
        let activeStep = this.props.activeStep;
        let disableCondition = data?.document_status!=="Draft";
        let currency = this.props.currencies.map(item=>{
            return{label: `${item.currency_code} - ${item.currency_name}`,value:item.currency_id}
        })
        let payment_terms = this.props.paymentTerms.map(item=>{
            return{label:item.name,value:item.paymentterm_id}
        })
        let tax_exempted_reasons = this.props.taxReasons.filter(item => item.tax_related === true) 
        .map(item => {
            return { label: item.description, value: item.reason_id };
        });
        let vendorTypesList = this.props.vendorTypes.map(item => {
            return { label: item.name, value: item.id };
        });
 
        if(this.state.isLoading){
            return  <SimpleLoading />
        }
        let sites_navs = [
            {title:Helper.getLabel(this.props.dataLoaderProps.language,'general',"General"), isActive: true},
        ];
        // let natureOfOrganizationOptions = [
        //     { value: 1, label: 'Private Limited' },
        //     { value: 2, label: 'Partnership' },
        //     { value: 3, label: 'Enterprise/Sole Proprietary' },
        //     { value: 4, label: 'Limited' },
        //     { value: 5, label: 'Others' }
        // ];
        return (
            <div className='vendor-registration-form'>
                <Collapse title="General Information" open={true} className="general_info">
                    <div className='row'>
                        <div className='col-xs-12 col-sm-3'><Input name="name" required={true} hasError={this.validator?.hasError('name')} value={data.name} onChange={this.onChangeHandler.bind(this)}  label={parseInt(data?.vendor_type)===1?Helper.getLabel(null,"organization_name","Organization name (EN)"):Helper.getLabel(null,"supplier_trade_name","Supplier trade name (EN)")} placeholder="Enter supplier trade name" disable={disableCondition} maxLength={255}/></div>
                        <div className='col-xs-12 col-sm-3'><Input name="alternate_name" required={true} hasError={this.validator?.hasError('alternate_name')} value={data.alternate_name} onChange={this.onChangeHandler.bind(this)}  label={parseInt(data?.vendor_type)===1?Helper.getLabel(null,"organization_name","Organization name (AR)"):Helper.getLabel(null,"supplier_trade_name","Supplier trade name (AR)")} placeholder="Enter supplier trade name" disable={disableCondition} maxLength={100}/></div>
                        <div className='col-xs-12 col-sm-3'><Input name="externalcode" required={true}  value={data.externalcode} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"license_number","License number")} placeholder="Enter license number " disable={disableCondition} maxLength={20}/></div>
                        <div className='col-xs-12 col-sm-3'><DropDownInput name="vendor_type" required={true} hasError={this.validator?.hasError('vendor_type')} value={data.vendor_type} options={vendorTypesList} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"type","Type")} placeHolder="Select type" disable={data?.version>=0?true:disableCondition}/></div>
                        <div className='col-xs-12 col-sm-3'><Input name="brand_name" value={data.brand_name} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"brand_name","Brand name")} placeholder="Enter brand name" disable={disableCondition} /></div>
                        {/* <div className='col-xs-12 col-sm-3'><Input name="dunsnumber" required={false}  value={data.dunsnumber} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"dunsnumber","DUNS number")} placeholder="Enter DUNS number" disable={disableCondition} inputType="number" maxLength={18}/></div> */}
                        {/* <div className='col-xs-12 col-sm-3'><Input name="legacy_code" required={false} hasError={this.validator.hasError('legacy_code')} value={data.legacy_code} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"legacy_code","Legacy code")} placeholder="Enter legacy code" disable={disableCondition} maxLength={40}/></div> */}
                        {/* <div className='col-xs-12 col-sm-3'><DropDownInput name="nature_of_organization" required={false} value={data?.nature_of_organization} options={natureOfOrganizationOptions} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"nature_of_organization","Nature of organization")} placeHolder="Select nature of organization" disable={disableCondition} reRenderRequired={true}/></div> */}
                    </div>
                    <div className='vendor'>
                    <h3 className='mb-3'>Other Information</h3>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-3'><NewInputDatePicker label={Helper.getLabel(null,'yearestablished',"Establishment date")}  name="yearestablished" timePicker={false} value={data.yearestablished} onChange={this.dateChangeHandler.bind(this)} id='year-established' disable={disableCondition} empty={true}/></div>
                        <div className='col-xs-12 col-sm-3'><NewInputDatePicker name="incorporationyear" value={data.incorporationyear} onChange={this.dateChangeHandler.bind(this)} timePicker={false}  label={Helper.getLabel(null,"licenseissuedate","License issue date")} disable={disableCondition} empty={true}/></div>
                    </div>
                    </div>
                    <div className='vendor'>
                    <h3 className='mb-3'>Online Presence</h3>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-3'><Input name="website_url" disable={disableCondition} value={data.website_url} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"website_url","Website")} placeHolder="Enter website details" /></div>
                        <div className='col-xs-12 col-sm-3'><Input name="facebook" disable={disableCondition} value={data.facebook} onChange={this.onChangeHandler.bind(this)} label={Helper.getLabel(null,"facebook","Facebook")} placeholder="Enter facebook details"/></div>
                        <div className='col-xs-12 col-sm-3'><Input name="twitter" disable={disableCondition} value={data.twitter} onChange={this.onChangeHandler.bind(this)} label={Helper.getLabel(null,"x","X")} placeholder="Enter x details" /></div>
                        <div className='col-xs-12 col-sm-3'><Input name="instagram" disable={disableCondition} value={data.instagram} onChange={this.onChangeHandler.bind(this)} label={Helper.getLabel(null,"instagram","Instagram")} placeholder="Enter instagram details" /></div>
                        <div className='col-xs-12 col-sm-3'><Input name="linkedin" disable={disableCondition} value={data.linkedin} onChange={this.onChangeHandler.bind(this)} label={Helper.getLabel(null,"linkedin","LinkedIn")} placeholder="Enter linkedin details"/></div>
                        <div className='col-xs-12 col-sm-3'><Input name="youtube" disable={disableCondition} value={data.youtube} onChange={this.onChangeHandler.bind(this)} label={Helper.getLabel(null,"youtube","Youtube")} placeholder="Enter youtube details"/></div>
                        <div className='col-xs-12 col-sm-3'><Input name="tiktok" disable={disableCondition} value={data.tiktok} onChange={this.onChangeHandler.bind(this)} label={Helper.getLabel(null,"tiktok","Tiktok")} placeholder="Enter tiktok details"/></div>
                    </div>
                    </div>
                    <div className='vendor'>
                    <h3 className='mb-3'>Vendor Details</h3>
                    <div className='row'>
                        <Tabs open={true} navs={sites_navs} disableSticky={true}  showAll={false} scrollOffset="500">
                            <div className='row'>
                               {/* <div className='col-xs-12 col-sm-3'><Input name="taxpayer_id" value={data.taxpayer_id} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"tax_payer_id","Tax payer id")} placeholder="Enter tax payer id" disable={disableCondition} maxLength={30}/></div> */}
                               <div className='col-xs-12 col-sm-3'><Input name="taxregistration_number" value={data.taxregistration_number} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"tax_registration_number","Tax registration number")} placeholder="Enter tax registration number" disable={disableCondition || data?.exempted_from_tax} isComponentRenderRequired={true} required={!data?.exempted_from_tax} maxLength={30}/></div>
                               <div className='col-xs-12 col-sm-3'><DropDownInput name="currency_id" required={true} hasError={this.validator?.hasError('currency_id')} value={data.currency_id} options={currency} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"currency","Currency")} placeHolder="Select currency" disable={disableCondition}/></div>
                               <div className='col-xs-12 col-sm-3'><DropDownInput name="paymentterm_id" required={true} hasError={this.validator?.hasError('paymentterm_id')} value={data.paymentterm_id} options={payment_terms} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"payment_term","Payment term")} placeHolder="Select payment term" disable={disableCondition}/></div>
                               <div className='col-xs-12 col-sm-3'> <Checkbox id={'exempted_from_tax'} type={'checkbox'} name={'exempted_from_tax'} onChange={this.onInputCheckboxChangeHandler.bind(this)} disableWithoutIcon={disableCondition} label={Helper.getLabel(null,"tax_exempted","Tax exempted")} isChecked={data.exempted_from_tax}/></div>
                               {data?.exempted_from_tax === true && <div className='col-xs-12 col-sm-3'><DropDownInput name="reason_for_tax_exemption" required={data.exempted_from_tax===true} hasError={this.validator?.hasError('reason_for_tax_exemption')} value={data.reason_for_tax_exemption} options={tax_exempted_reasons} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"reason_for_tax_exemption","Reason for tax exemption")} placeHolder="Select reason for tax exemption" disable={disableCondition} reRenderRequired={true}/></div>}
                               {/* <div className='col-xs-12 col-sm-3'><DropDownInput name="taxgroup_id" required={false} value={data.taxgroup_id} options={this.props.taxGroup} onChange={this.onChangeHandler.bind(this)}  label={Helper.getLabel(null,"tax_group","Tax group")} placeHolder="Select tax group" disable={disableCondition} reRenderRequired={true}/></div> */}
                            </div>
                        </Tabs>
                     
                    </div>
                    </div>
                </Collapse>
                <div className='tabs_changer'>
                    {activeStep >= 2 ? <Button className="light_gray btn_back" title="Back" onClick={e => this.goStep( activeStep - 1 )}/> : '' }
                    <Button className="blue" title="Next" onClick={e => this.goStep( activeStep + 1 )}/>
                </div>
            </div>
            
        );
    }
}

export default GeneralInformation;