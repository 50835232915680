import React, { Component } from 'react';
import Api from '../../inc/Api';
import FormValidator from '../../inc/FormValidator';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import Input from '../Forms/Input';
import RsDataLoader from '../inc/RsDataLoader';
import SimpleLoading from '../Loading/SimpleLoading';
import RsWithRouter from '../inc/RsWithRouter';
import PasswordInput from '../Forms/PasswordInput';

class AccountSecurity extends Component {
    constructor(props){
        super(props);
        this.validationConfigure();
        this.state = {
            isLoading:false,
            user:{}
        }
    }

    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'old_password',displayName:Helper.getLabel(language,'old_password','Old Password'),types:['Required']},
            {name:'new_password',displayName:Helper.getLabel(language,'new_password','New Password'),types:['Required','Password'],min:12,max:30},
            {name:'confirm_new_password',displayName:Helper.getLabel(language,'confirm_new_password','Confirm New Password'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }

    componentDidMount(){
        this.setState({
            user:{ 
                ...this.props.dataLoaderProps.auth.user
            }
        }) 
        if(this.props.setFormObj && typeof this.props.setFormObj ==='function'){
            this.props.setFormObj(this)
        }
    }

    onInputChangeHanlder(e){
        this.setState({
            user:{
                ...this.state.user,
                [e.target.name] : e.target.value
            }
        })
    }

    onSaveHandler(e){ 
        let user_password = this.state.user; 
        let validData = {
            ...user_password
        }
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage() 
            this.validator.reset() 
            return;
        }
        if(user_password.new_password !== user_password.confirm_new_password ){
            Helper.alert('Password Doesnt Match!', 'error')
            return;
        }
        // this.setState({
        //     isLoading:true
        // })
        
        let api = Api
        api.setUserToken();
        let that = this;
        let newData ={
            "old_password":user_password.old_password?.trim(),
            "new_password": user_password.new_password?.trim()
        }
        api.axios().post(Settings.apiUrl + '/change_password',newData).then(res => { 
            Helper.alert("Password Updated!")
            that.setState({
                isLoading:false,
                user:{}
            })
            if(this.props.isFirstTime){
                if(this.props.logOut && typeof this.props.logOut ==='function'){
                    this.props.logOut(this)
                    that.props.rs_router.navigate("/login")
                }
            }
        }).catch(e => {
            that.setState({
                isLoading:false
            })
            Helper.alert(e.response.data.message, 'error')
        })
    }
 
    render() {
        let language = this.props.dataLoaderProps.language;
        let user = this.state.user;
        if(this.state.isLoading){
            return <SimpleLoading />
        }
        return (
            <div className='account_security'>
                <h2>Password & Security</h2>
                <div className='row'>
                    <div className='col-xs-12 col-sm-6'><PasswordInput required={true} name="old_password" value={ user.old_password } inputType="password" label={Helper.getLabel(language,'old_password','Old Password')} onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                    <div className='col-xs-12 col-sm-6'></div>
                    <div className='col-xs-12 col-sm-6'><PasswordInput required={true} name="new_password" value={ user.new_password } inputType="password"  label={Helper.getLabel(language,'new_password','New Password')} onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                    <div className='col-xs-12 col-sm-6'><PasswordInput required={true} name="confirm_new_password" value={ user.confirm_new_password } inputType="password"  label={Helper.getLabel(language,'confirm_new_password','Confirm New Password')} onChange={  this.onInputChangeHanlder.bind(this) }/></div>
                </div>
            </div>
        );
    }
}


export default RsDataLoader(RsWithRouter(AccountSecurity)) ;