import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Alert from '../../inc/Alert';
import Button from '../../inc/Button';
import LinkBtn from '../../inc/LinkBtn';
import SimpleLoading from '../../Loading/SimpleLoading';
import AddressList from '../Address/AddressList';
import ContactAddress from '../Address/ContactAddress';
import TenantFields from '../Tenant Fields/TenantFields';
import $ from "jquery";
import NavigationHeder from '../../Navigations/NavigationHeder';
import Helper from '../../../inc/Helper';
import TextTranslations from '../../Popup/TextTranslation/TextTranslations';
import Collapse from '../../inc/Collapse';
import Form_gui_ids from '../../../inc/Form_gui_ids';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import FormValidator from '../../../inc/FormValidator';
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory';
import User from '../../../inc/Auth/User';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
class EditTenant extends Component {
    constructor(props){
        super(props);
        this.contactGridObj = null;
        this.addressGridObj = null;
        this.validationConfigure();
        this.isFirstCalled = false;
        this.state = {
            tenant:null,
            isLoading:false,
            message:null,
            
            messageType:'danger'
        }
    }
    componentDidMount(){
        
        this.loadTenant()
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'name',displayName:Helper.getLabel(language,'name','Name'),types:['Required'],max:100 },
            {name:'end_date',displayName:Helper.getLabel(language,'end_date','End date'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
      }
    loadTenant (){
        let tenantID = this.props.params.id ? this.props.params.id : null;
        if(this.isFirstCalled || !tenantID){
            return;
        }
        let that = this;
        let api = Api;
        this.isFirstCalled = true;
        that.setState({
            isLoading:true,
        }) 
        api.axios().get(Settings.apiUrl + '/tenant/'+tenantID).then(function(res){
            if(res.data.status === 'success'){
                let item = res.data.data;
                let tenantUser = item.user;
                that.setState({
                    isLoading:false,
                    tenant: {
                        ...item,
                        user_type:tenantUser.usertype,
                        tenant_name:item.name,
                        first_name:tenantUser.first_name,
                        last_name:tenantUser.last_name,
                        middle_name:tenantUser.middle_name,
                        SID:tenantUser.SID,
                        user_email:tenantUser.user_name,
                        isactive:item.isactive ? "true" : 'false',
                        enable:item.isactive ? "true" : 'false',
                    },
                    message:null,
                    messageType:'success'
                })
            } else{
                that.setState({
                    isLoading:false,
                    tenant: null,
                    message:"Tenant not found!",
                    messageType:'error'
                }) 
            } 
        }).catch((error) => { 
            that.setState({
                isLoading:false,
                tenant: null,
                message:"Tenant not found!",
                messageType:'error'
            }) 
        })
    }
    onSaveHandler(e){
        let newData = {
            name:this.state.tenant.name,
            isactive:this.state.tenant.isactive === 'true' ? true : false,
            end_date: DatepickerFunctions.convertDateForDataBase(this.state?.tenant?.end_date)
        }

        let validData = {
            ...newData
        }
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage(this);
            return;
        }

        this.setState({
            isLoading:true,
            //tenant: null,
        })
        let that = this;
        let api  = Api;
        let tenantId = this.state.tenant.tenant_Id;
        
        api.setUserToken(); 

        api.axios().put(Settings.apiUrl + '/tenant/'+ tenantId,newData).then(function(res){
            if(res.data.status ==='success'){
                Helper.alert(res.data.message) 
                that.setState({
                    isLoading:false,
                    message: res.data.message,
                    messageType:'success'
                })
            }else{
                that.setState({
                    isLoading:false,
                    message: res.data.message,
                    messageType:'danger'
                })
                Helper.alert(res.data.message,'error')
            }
            
            
        }).catch(function(enm){
            that.setState({
                isLoading:false,
                message: enm.response.data.message,
                messageType:'danger'
            })
            getPopupMessageBasedOnStatus(enm)
        })
        setTimeout(function(){
            
        },300)
    }
    onFormDataChange(data){
        this.setState({
            tenant:data
        })
    }
    onContactCreate(data){
        let updatedData = {
            ...this.state.tenant,
            contactsource_id:data.contact_source_id
        }
        if(!this.state.tenant.contactsource_id){
            this.setState({
                tenant:updatedData,
            })
        }
    }
    onAddressCreate(data){
        let updatedData = {
            ...this.state.tenant,
            addresssource_id:data.address_source_id
        }
        if(!this.state.tenant.addresssource_id){
            this.setState({
                tenant:updatedData,
            })
        }
    }
    onContactGridReady(grid){
        this.contactGridObj = grid;
    }
    displayContactAndAddress(){
        if(!this.state.tenant){
            return <></>
        }
        let user = new User(this.props.auth);
        /*
        if(user.isSupperAdmin()){
            return;
        }
        */
        return(
            <>
                <AddressList canEdit={ this.props.user?.admin_user } onAddressCreate = { this.onAddressCreate.bind(this) } addressSourc_id = {this.state.tenant.addresssource_id} address_source_integrator={this.state.tenant.tenant_Id} source_id ={1}/>
                <Collapse title={ "Contact"} open={true}>
                    <ContactAddress canEdit={ this.props.user?.admin_user } onContactCreate = {this.onContactCreate.bind(this)} contactsource_id ={this.state.tenant.contactsource_id} source_id = {1} address_id = {null} contactsource_integrator={this.state.tenant.tenant_Id} />
                </Collapse>
            </>
        )
    }
    render() {
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        let language =this.props.language; 

        if(!this.props.user?.admin_user){
            return <Alert type="danger" message={Helper.getLabel(language,'no_access','No Access')} />
        }
        let tenant = this.state.tenant ? this.state.tenant : {};
        return (
            <div className='container-fluid'>
                <NavigationHeder  backUrl="/tenants"  title={ `<span style="color:#000;">${Helper.getLabel(language,'edit_tenant',"Edit Tenant")} #</span>  ${tenant.code}` }>
                    <TextTranslations isDisable={ !this.props.user?.admin_user}  source_id="1" source_integrator = {tenant.tenant_Id} translationsource_id={tenant.translationsource_id} original_text={tenant.name}  product_number={tenant.code}/>
                    <LinkBtn isActive= {false} to="/tenants" title={Helper.getLabel(language,'Close',"Close")} className="black-btn-style" />
                    <Button isDisable={ !this.props.user?.admin_user || this.state.isLoading } isActive= {true} onClick = { this.onSaveHandler.bind(this)} title={Helper.getLabel(language,'save',"Save")} icon={'/images/icons/save-white.svg'} />
                </NavigationHeder>
                <div className='address_contact_section'>
                    {
                        this.state.tenant ? <TenantFields  validator={this.validator} isAdminEditor={true}  isNotNewForm = {true} data = { this.state.tenant} setFormData = { this.onFormDataChange.bind(this) }/> : <Alert message ={this.state.message} type={this.state.messageType ==='error' ? "danger" : 'success' } /> 
                    }
                    { this.displayContactAndAddress() }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language,
        user:state.auth.user
    }
}
 
export default   connect (mapStateToProps) ( ( EditTenant ));