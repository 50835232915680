import React, { useState,useEffect} from "react";
import $ from "jquery";
import Popup from "../../../Popup/Popup";
import SimpleLoading from "../../../Loading/SimpleLoading";
import Helper from "../../../../inc/Helper";
import LineViewComponent from "../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import CustomCheckboxCellRenderer from "../../../grid/ag/cellRender/CustomCheckboxCellRenderer";
import { getPopupMessageBasedOnStatus, objectIsEmpty } from "../../../../inc/Validation";
import TotalWidget from "../../../Widgets/TotalWidget/TotalWidget";
import { getNewValue } from "../../../grid/ag/commonFunctions/GridCommonFunctions";
import Button from "../../../inc/Button";
import OverlayLoader from "../../../PurchaseRequisition/common/OverlayLoader";

let gridObj = null

const TechnicalSelectedLinesModalPreferredColumn = ({
  rfqHeader,
  language,
  setShowSelectedLinesModal,
  vendorreplyId,
  scoreReplyHeaderId,
  type,
  evaluationData,
  getScoreSummary,
  user
}) => {
  const [linesData, setLinesData] = useState([]);
  const [vendorReplyLines, setVendorReplyLines] = useState();
  const [scoreReplyLines, setScoreReplyLines] = useState();
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isMarkLoading, setIsMarkLoading] = useState(false);
  const [key, setKey] = useState(Date.now())

  useEffect(() => {
    async function fetchData() {
      await getUserDetails();
      await getVendorReplyLines();
      await getScoreReplyLines();
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (scoreReplyLines && vendorReplyLines && userDetails) {
       mapReplyLines()
    }
   }, [scoreReplyLines, vendorReplyLines, userDetails])

  const getUserDetails = async () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + `/user_details`)
      .then(function (res) {
        if (res.data.Status === "Success") {
          setUserDetails(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const getVendorReplyLines = async () => {
    if (!vendorreplyId) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/lines/${vendorreplyId}/${Settings?.source_id?.RfqVendorReply}`)
      .then(function (res) {
        if (res.data.status === "success") {
          setVendorReplyLines(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  const getScoreReplyLines = async () => {
    if (!scoreReplyHeaderId) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/get_line_recommendations/${scoreReplyHeaderId}`,
        {}
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setScoreReplyLines(res.data.data);
        }
      })
      .catch((res) => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  const mapReplyLines = () => {
    if (!vendorReplyLines || !scoreReplyLines || !userDetails) return;


    const filteredVendorReplyLines = vendorReplyLines?.filter((vendorLine) =>
      scoreReplyLines?.some(
        (scoreLine) => scoreLine.vendorreplyline_id === vendorLine.line_id
      )
    );

    const linesDataWithPreferred = filteredVendorReplyLines?.map(
      (vendorLine) => {
        const matchingScoreLine = scoreReplyLines?.find(
          (scoreLine) => scoreLine.vendorreplyline_id === vendorLine.line_id
        );
        if(type==="recommended"){
        return {
          ...vendorLine,
          line_recommendation: matchingScoreLine?.recommended || null,
          recommendation_id:matchingScoreLine?.replylinerecommendation_id,
        };
       }
       if(type==="preferred"){
        return {
          ...vendorLine,
          preferred: matchingScoreLine?.preferred || null,
          recommendation_id:matchingScoreLine?.replylinerecommendation_id,
        };
       }
      }
    );
  
    setLinesData(linesDataWithPreferred);
    setDataLoaded(true);
  };

  const onCheckboxChanged = (value,params) => {
    const filteredScore = scoreReplyLines?.find(
      (score) =>
        score.vendorreplyline_id === params.data.line_id
    );
    let lineRecommendationId =
      filteredScore?.replylinerecommendation_id;

    if (!lineRecommendationId) return;

    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/line_recommendation/${lineRecommendationId}`,
        {
          recommended: null,
          preferred: value,
          award_to: null,
        }
      )
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert("Line recommendation updated successfully.", "success");
          setLinesData((prevLinesData) => {
            const updatedLinesData = prevLinesData?.map((line) =>
              line.recommendation_id === lineRecommendationId
                ? { ...line, preferred: value}
                : line
            );
            return updatedLinesData;
          });
          params.node.setDataValue('preferred',value)
          getScoreSummary()
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      });
  };

  const onCheckboxToggledForAll = (value) => {
    if (!linesData || linesData.length === 0) {
      Helper.alert("No lines data available to update.", "error");
      return;
    }
  
    let api = Api;
    api.setUserToken();
    setIsMarkLoading(true);
    let isSelected = gridObj?.api?.getServerSideSelectionState()
    let data = isSelected?.selectAll ? linesData : gridObj?.api?.getSelectedRows()
  
    const requests = data.map((line) => {
      const filteredScore = scoreReplyLines?.find(
        (score) =>
          score.vendorreplyline_id === line.line_id 
      );
  
      let lineRecommendationId =
        filteredScore?.replylinerecommendation_id;
      if (!lineRecommendationId) return null;
   
      return api
        .axios()
        .put(
          Settings.apiPurchaseRequisitionUrl +
            `/line_recommendation/${lineRecommendationId}`,
          {
            recommended: null,
            preferred: value,
            award_to: null,
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            return {
              success: true,
              lineId: lineRecommendationId,
              updatedValue: value,
            };
          } else {
            throw new Error("Failed to update line.");
          }
        })
        .catch((error) => {
          setIsMarkLoading(false);
          return {
            success: false,
            lineId: lineRecommendationId,
            name: line?.line_type === 1 ? line.category_name : line.line_type===2 ? line.comments?.substring(0,20) : line.item_name,
            error: error.response?.data?.message || error.message,
          };
        });
    });
  
    Promise.all(requests.filter(Boolean)) // Filter out any null entries
      .then((results) => {
        const successUpdates = results.filter((res) => res.success);
        const failedUpdates = results.filter((res) => !res.success);
        if (successUpdates.length > 0) {
          setLinesData((prevLinesData) =>
            prevLinesData?.map((line) =>
              successUpdates.some(
                (update) => update.lineId === line.recommendation_id
              )
                ? { ...line, preferred: successUpdates.find(
                     (update) => update.lineId === line.recommendation_id
                  ).updatedValue }
                : line
            )
          );
          setKey(Date.now())
          setIsMarkLoading(false);
          gridObj?.api?.deselectAll()
          Helper.alert(
            `${successUpdates.length} line(s) updated successfully.`,
            "success"
          );
          getScoreSummary()
        }
        if (failedUpdates.length > 0) {
          const errorMessages = failedUpdates
            .map((update) => `Line ${update.name}: ${update.error}`)
            .join("\n");
          Helper.alert(
            `Failed to update ${failedUpdates.length} line(s):\n${errorMessages}`,
            "error"
          );
        }
      });
  };
  
  const markSelectedLinesPreferred = () => {
    let isSelected = gridObj?.api?.getServerSideSelectionState()
    if(!gridObj?.api?.getSelectedRows().length && !isSelected?.selectAll){
      Helper.alert("Please select atleast one line.","error")
      return
    }
    Helper.confirmMe(() => {
      onCheckboxToggledForAll(true);
    }, "This action will mark the selected items as preferred.", null, "Are you sure you want to mark all selected items as preferred?", 580);
  }

  const unmarkSelectedLinesPreferred = () => {
    let isSelected = gridObj?.api?.getServerSideSelectionState()
    if(!gridObj?.api?.getSelectedRows().length && !isSelected?.selectAll){
      Helper.alert("Please select atleast one line.","error")
      return
    }
    Helper.confirmMe(() => {
      onCheckboxToggledForAll(false);
    }, "This action will unmark the selected items as preferred.", null, "Are you sure you want to unmark all selected items as preferred?", 600);
  }

  let lineAdditionalColumns = []
  if(type==="preferred"){
    lineAdditionalColumns.push(
      {
        field: "preferred",
        minWidth: 160,
        headerName: Helper.getLabel(language, "preferred", "Preferred"),
        cellClass:'rfq-col-additional',
        cellRenderer: CustomCheckboxCellRenderer,
        cellRendererParams:{
          onChange:onCheckboxChanged,
          disabled:(evaluationData?.submitted || objectIsEmpty(evaluationData)) ? true : rfqHeader?.rfq_status !== 6 || rfqHeader?.onhold || rfqHeader?.rfq_status===2
        },
        editable: false,
      },
    )
  }

  if(type==="recommended"){
    lineAdditionalColumns.push(
      {
        field: "line_recommendation",
        minWidth: 160,
        headerName: Helper.getLabel(language, "recommended", "Recommended"),
        cellRenderer: CustomCheckboxCellRenderer,
        cellRendererParams:{
          disabled:true
        },
        editable: false,
      },
    )
  }

  const taxTransactionViewCell = (params) => {
    if(params.data.line_id){
        return <div className="rs_inactive_label">View tax</div>;
    }
    return <></>;
  }

  let Columns_Header = [
    {
      field:"line_number",
      headerName:Helper.getLabel(language,'line_number',"Line number"), 
      editable:false,
      minWidth:160,
    },
    {
      field:"item_name",
      headerName:Helper.getLabel(language,'item_name',"Item name"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"item_code",
      headerName:Helper.getLabel(language,'item_code',"Item code"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"category_name",
      headerName:Helper.getLabel(language,'category',"Category"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    }, 
    {
      field:"comments",
      headerName:Helper.getLabel(language,'comemnts',"Comments"), 
      editable:false,
      minWidth:220,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field:"ordered_qty",
      headerName:Helper.getLabel(language,'quantity',"Quantity"), 
      editable:false,
      minWidth:180,
    },
    {
      field:"required_qty",
      headerName:Helper.getLabel(language,'purposed_quantity',"Purposed quantity"), 
      editable:false,
      minWidth:180,
    },
    {
      field:"unit_name",
      headerName:Helper.getLabel(language,'Unit Name',"Unit name"), 
      editable:false,
      minWidth:180,
    },
  ]

  let commercialValues = [{
    field:"unit_price",
    headerName:Helper.getLabel(language,'unit_price',"Unit price"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"discount_amount",
    headerName:Helper.getLabel(language,'discount_amount',"Discount amount"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"discount_percentage",
    headerName:Helper.getLabel(language,'discount_percentage',"Discount percentage"), 
    editable:false,
    minWidth:200,
  },
  {
    field:"line_amount",
    headerName:Helper.getLabel(language,'line_amount',"Line amount"), 
    editable:false,
    minWidth:180,
  },
  {
    field:"itemgroup_name",
    headerName:Helper.getLabel(language,'item_group',"Item group"), 
    editable:false,
    minWidth:180,
  },
  {
    field:'view_tax_transaction', 
    minWidth: 150, 
    headerName:Helper.getLabel(language,'tax',"Tax"), 
    editable: false,
    cellRenderer:taxTransactionViewCell
  }
]
Columns_Header = [...Columns_Header]; 
Columns_Header.splice(1, 0, ...lineAdditionalColumns); 

if(type==='recommended'){
  if(user?.is_buyer){
    Columns_Header = Columns_Header.concat(commercialValues)
  } else if (rfqHeader?.allow_view_commericals_in_technical_evaluation && !user?.is_buyer) {
    Columns_Header = Columns_Header.concat(commercialValues)
  } else{
    Columns_Header = [...Columns_Header];
    Columns_Header.splice(1, 0, ...lineAdditionalColumns);
  }
}else{
  Columns_Header = Columns_Header.concat(commercialValues)
}

 const hideTabs = () => {
  if(rfqHeader?.target_document === 1 && user?.is_buyer){
    return false
  }else if(rfqHeader?.target_document === 1 && !user?.is_buyer && rfqHeader?.allow_view_commericals_in_technical_evaluation){
    return false
  }else{
    return true
  }
}


let dataSource = {
  getRows: async function (params) {
    if(linesData?.length!==0){
      gridObj?.api?.hideOverlay()
    }
    params?.success({
      rowData: linesData?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: linesData?.length
    })
  },
};

const handleLineViewGridReady = (params) => {
  gridObj = params
  if(params){ 
    setTimeout(()=>{ 
      params?.api?.setGridOption('serverSideDatasource', dataSource);
    }, 200)
  };
};

const onPopupCloseHandler = () => {
  $("html").removeClass("rs_popup_open");
  setShowSelectedLinesModal(false);
}

  return (
    <>
    <Popup
      autoOpen={true}
      width="1200px"
      onClose={onPopupCloseHandler}
    >
      <>
        <div className="d-flex justify-content-between gap-3 mb-3">
          <h5 className="create_rfq_modal__title">
           {type==='recommended'? Helper.getLabel(
              language,
              "recommended_lines",
              "Recommended Lines")
            :Helper.getLabel(
              language,
              "select_lines_preferred_to_vendor",
              "Select Lines Preferred to Vendor")}
          </h5>
          <div className="d-flex gap-2">
          <Button title={Helper.getLabel(language, 'save_and_close', 'Save & Close')} className='blue-btn-style' onClick={onPopupCloseHandler} />
          <div className="technical-total">
              <TotalWidget source_id={Settings.source_id.RfqVendorReply}
            integrator={vendorreplyId} isDisable={type==='preferred'?false:!rfqHeader?.allow_view_commericals_in_technical_evaluation && !user?.is_buyer}/>
          </div>
          </div>
        </div>
        {loading || !dataLoaded ? (
          <SimpleLoading />
        ) : (
          <div className="rfq-line-view line_scoring_module">
          <LineViewComponent
            defaultValues={{ requester_id: rfqHeader?.requester_id }}
            header={Columns_Header}
            key={key}
            source_id={Settings?.source_id?.RfqVendorReply}
            line_source={null}
            isEditable={false}
            header_id={null}
            purchase_status={null}
            document_status={rfqHeader?.rfq_status}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            requester_id={rfqHeader?.requester_id}
            orderer_id={rfqHeader?.order_id}
            data={linesData}
            setOrderStatusProps={null}
            warehouse_id={null}
            taxgroup_item={null}
            taxgroup_id={null}
            entity_id={null}
            site_id={null}
            lineRecommended={true}
            hideExtraColumn={true}
            hideDistribution={false}
            hideBillingRule={true}
            hideSetup={true}
            rowType="multiple"
            hideDeleteBtn={true}
            hideGeneral={true}
            hideAdditionalInformationTab={true}
            onLineGridReady={handleLineViewGridReady}
            afterDeleteButtonOfLines={type==="preferred" && <div className="d-flex gap-2 px-3"><Button title={Helper.getLabel(language,'mark','Mark')} className="p-0" onClick={markSelectedLinesPreferred} isDisable={(evaluationData?.submitted || objectIsEmpty(evaluationData)) ? true : rfqHeader?.rfq_status !== 6 || rfqHeader?.onhold || rfqHeader?.rfq_status===2} /><Button title={Helper.getLabel(language,'unmark','Unmark')} onClick={unmarkSelectedLinesPreferred} isDisable={(evaluationData?.submitted || objectIsEmpty(evaluationData)) ? true : rfqHeader?.rfq_status !== 6 || rfqHeader?.onhold || rfqHeader?.rfq_status===2}></Button></div>}
          />
          </div>
        )}
      </>
      {/* )} */}
    </Popup>
    {isMarkLoading && <OverlayLoader isPopupInTab={true} isLogoCenter={true} />}
    </>
  );
};

export default TechnicalSelectedLinesModalPreferredColumn;
