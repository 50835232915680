import React, { Component } from "react";
import Cart from "../../components/PurchaseRequisition/Cart";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import RsWithRouter from "../../components/inc/RsWithRouter";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import Helper from "../../inc/Helper";

// "/purchase-requisition/addrequisitiondetails"
class ShoppingCartScreen extends Component {
  constructor(props) {
    super(props)
    this.handleNextBtn = this.handleNextBtn.bind(this)
    this.handleBackBtn = this.handleBackBtn.bind(this)
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  async checkPolicyHandler() {
    let api = Api;
    api.setUserToken();
 
    try {
      const res = await api
        .axios()
        .get(
          Settings.apiPurchaseRequisitionUrl +
            `/check_policies/${this.getSearchParam('pr_view_id')}`
        );
 
      if (res.data.status === 'success') {
        Helper.alert(res.data.message, 'success'); 
      }
    } catch (error) {
      // getPopupMessageBasedOnStatus(error);
    }
  }

  handleNextBtn(){
    this.checkPolicyHandler()
    let current_user = this.props.rs_router?.location?.state?.isCurrentUser
    if(current_user){
      this.props.rs_router.navigate(`/purchase-requisition/summary?pr_view_id=${this.getSearchParam('pr_view_id')}`,{state:{isCurrentUser:current_user}});
    }else{
      this.props.rs_router.navigate(`/purchase-requisition/summary?pr_view_id=${this.getSearchParam('pr_view_id')}`);
    }
  }

  handleBackBtn(){
    this.props.rs_router.navigate(-1)
  }

  render() {
    return (
      <ShopLayout
        backUrl={this?.props?.rs_router?.location?.state?.isCurrentUser !== null && this?.props?.rs_router?.location?.state?.isCurrentUser !== undefined ? `/purchase-requisition-created-by-me?user_id=${this?.props?.rs_router?.location?.state?.isCurrentUser}`:"/purchase-requisition"}
        title="Shop > Add Requisition Details > Cart"
        hideMoreBtn={true}
        nextBtnHandler={this.handleNextBtn}
        handleBackBtn={this.handleBackBtn}
        isContinueShopping={true}
        currentDocumentStatus={0}
        prId={this.getSearchParam('pr_view_id')}
      >
        <Cart showNext={true} currentDocumentStatus={0} viewLineDistribution={true} />
      </ShopLayout>
    );
  }
}


export default (RsWithRouter(ShoppingCartScreen))
