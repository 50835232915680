import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import Popup from "../../../Popup/Popup";
import Helper from "../../../../inc/Helper";
import Input from "../../../Forms/Input";
import Collapse from "../../../inc/Collapse";
import Dropdown from "../../../Forms/Dropdown";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import { Form } from "react-bootstrap";
import Button from "../../../inc/Button";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import NewInputDatePicker from "../../../Forms/NewInputDatePicker";
import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import { getPopupMessageBasedOnStatus, objectIsEmpty } from "../../../../inc/Validation";
import FormValidator from "../../../../inc/FormValidator";
import axios from "axios";
import OverlayLoader from "../../../PurchaseRequisition/common/OverlayLoader"


let cancelTokenSource = axios.CancelToken.source();
const CreateRFQModal = ({
  language,
  setShowCreateRFQModal,
  employee,
  entity,
  sites,
  warehouse,
  quotationType,
  type,
  auth,
  currentRFQType,
  rfqTypes
}) => {
  const [selectedEntity, setSelectedEntity] = useState();
  const [rfqHeader, setRfqHeader] = useState({});
  const [expiry_date, setExpiry_date] = useState(null);
  const [description, setDescription] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [entityValue, setEntityValue] = useState();
  const [saveBtnApi, setSaveBtnApi] = useState(false);
  const [rfqType,setRfqType]=useState(currentRFQType ?? 0)
  const navigate = useNavigate();
 
  const api = Api;

  let entityRefData = useRef()
  let siteRefData = useRef()
  let warehouseRefData = useRef()

  const [entityOptions, setEntityOptions] = useState(entity.map((item) => {
    return {
      value: item?.entity_id,
      label: item?.name,
    }
  }))
  const [siteOptions, setSiteOptions] = useState(sites.map((item) => {
    return {
      value: item?.site_id,
      label: item?.name,
    }
  }))
  const [warehouseOptions, setWarehouseOptions] = useState(warehouse.map((item) => {
    return {
      value: item?.warehouse_id,
      label: item?.name,
    }
  }))
  const [selectedEntityOptions, setSelectedEntityOptions] = useState(auth?.user?.Employee?.Entity_id?auth?.user?.Employee?.Entity_id:null);
  const [selectedSiteOptions, setSelectedSiteOptions] = useState(auth?.user?.Employee?.Site_id?auth?.user?.Employee?.Site_id:null);
  const [selectedWarehouseOptions, setSelectedWarehouseOptions] = useState(auth?.user?.Employee?.Warehouse_id?auth?.user?.Employee?.Warehouse_id:null);
  const [siteAdditionalParams,setSiteAdditionalParams]=useState({})
  const [warehouseAdditionalParams,setWarehouseAdditionalParams]=useState({})
  const [numberSequence,setNumberSequence]=useState({})

  let validator 

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'title', displayName:Helper.getLabel(language,'title','Title'),types:['Required']},  
      {name:'entity_id', displayName:Helper.getLabel(language,'entity_id','Entity id'),types:['Required']},
      {name:'site_id', displayName:Helper.getLabel(language,'site_id','Site id'),types:['Required']},
      {name:'warehouse_id', displayName:Helper.getLabel(language,'warehouse_id','Warehouse id'),types:['Required']},
      {name:'expiry_datetime', displayName:Helper.getLabel(language,'expiry_datetime','Expiry date and time'),types:['Required']},
      // {name:'security_level', displayName:Helper.getLabel(language,'security_level','Security Level'),types:['Required'], max: 80},
      {name:'quotation_type', displayName:Helper.getLabel(language,'quotation_type','Quotation Type'),types:['Number Required']},
    ]
    if(numberSequence?.ns_type==="manual"){
      fieldConfig?.push({name:'document_number', displayName:Helper.getLabel(language,'document_no','Document number'),types:['Required']},  )
    }
    if(rfqType===2){
      fieldConfig.push({name:'rfq_type', displayName:Helper.getLabel(language,'request_for_quotation_type','Request for quotation type'),types:['Required']})
    }
    if(!validator) {
      validator = new FormValidator(fieldConfig,language)
    }
  }

  validationConfigure()


  const getProcurementSettingsForEntity = (entity_id) => {
    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        `${Settings.apiProcurementSettingsUrl}/procurementsetting_by_entity/${entity_id}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          const data = res?.data.data;
          const expiryDays = data?.default_quoation_expiry_days || 0;
          const expiryTime = data?.default_quoation_expiry_times || null;
          const expiryDateTime = getExpiryDateTime(expiryDays, expiryTime);
          setExpiry_date(expiryDateTime);
          setRfqHeader({
            ...rfqHeader,
            entity_id: entity_id,
            expiry_datetime: getExpiryDateTime(expiryDays,expiryTime),
            // security_level:0,
            quotation_type:
              data?.default_quoation_type === 0
                ? "0"
                : data?.default_quoation_type,
          });
        }
      })
      .catch((error) => {
        const newDate = new Date()
        setExpiry_date(newDate) 
      });
  }

 
  // it's a entity function, here we apply multiple filter based on condition...
  const handleEntityChange = (e) => {
    setSelectedEntityOptions(e.target.value)
    if (e.target.value) {
      setSiteAdditionalParams({})
      setWarehouseAdditionalParams({})
      setSelectedSiteOptions(null)
      setSelectedWarehouseOptions(null)
      setSiteAdditionalParams({
        entity_id:e.target.value
      })
      getNumberSequenceData(e.target.value)
      setWarehouseAdditionalParams({
        entity_id:e.target.value
      })
    
      getProcurementSettingsForEntity(e.target.value)

    } else {
      setSiteAdditionalParams({})
      setWarehouseAdditionalParams({})
    }
  }
  // it's a site function, here we apply multiple filter based on condition...
  const handleSiteChange = (e) => {
    setSelectedSiteOptions(e.target.value)
    if (e.target.value) {
      const siteData = siteRefData?.current?.find((item)=>item.site_id === parseInt(e.target.value))
      setSiteAdditionalParams({
        entity_id:siteData?.entity_id
      })
      setWarehouseAdditionalParams({})
      setWarehouseAdditionalParams({
        site_id:e.target.value
      })
      setSelectedEntityOptions(siteData?.entity_id)
      setSelectedWarehouseOptions(siteData?.warehouse_id)
      getProcurementSettingsForEntity(siteData?.entity_id)
    } else {
      let resSite = sites.filter((item) => item?.entity_id == selectedEntityOptions);
      let resWerehouse = warehouse.filter((item) => item?.entity_id == selectedEntityOptions);
      setSiteOptions(resSite.map((item) => {
        return {
          value: item?.site_id,
          label: item?.name,
        }
      }));
      setWarehouseOptions(resWerehouse.map((item) => {
        return {
          value: item?.warehouse_id,
          label: item?.name,
        }
      }));
      setSelectedWarehouseOptions(null);
    }
  }
  // it's a site function, here we apply multiple filter based on condition...
  const handleWarehouseChange = (e) => {
    setSelectedWarehouseOptions(e.target.value)
    if (e.target.value) {
      setSiteAdditionalParams({})
      setWarehouseAdditionalParams({})
      const warehouseData = warehouseRefData?.current?.find((item)=>item.warehouse_id === parseInt(e.target.value))
      setSelectedSiteOptions(warehouseData?.site_id)
      setSelectedEntityOptions(warehouseData?.entity_id)
      getProcurementSettingsForEntity(warehouseData?.entity_id)
    }
  }

  const getUserDetails = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/user_details")
      .then((res) => {
        setSelectedEntity(res.data.data?.Employee?.Entity_id);
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const initData = () => {
    api.setUserToken();
    getUserDetails();
  };

  useEffect(() => {
    initData();
    getProcurementSettingsForEntity(auth?.user?.Employee?.Entity_id)
  }, []);

  const mapEntityItems = (item) => {
    return {
        id:item.entity_id,
        text:item.name
    }
  }

  const mapSiteItems = (item) => {
    return {
        id:item.site_id,
        text:item.name
    }
  }

  const mapWarehouseItems = (item) => {
    return {
        id:item.warehouse_id,
        text:item.name
    }
  }

  const getExpiryDateTime = (expiryDays, expiryTime) => {
    const today = new Date();
    const expiryDate = new Date(today.setDate(today.getDate() + expiryDays));
  
    if (expiryTime && expiryTime !== 'None') {
      const [hours, minutes] = expiryTime.split(":");
      expiryDate.setHours(Number(hours), Number(minutes), 0, 0);
    } else if(expiryTime === 'None') {
      expiryDate.setHours(0, 0, 0, 0); 
    }
  
    return expiryDate;
  };       
  const executePostApiCallforRFQ = async (payload) => {
    try {
      const api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl + "/rfq_header", payload, { cancelToken: cancelTokenSource.token });
  
      if (res.data.status === "success") {
        Helper.alert(res?.data?.message, "success");     
        setShowCreateRFQModal(false);
        navigate(`/request-for-quotation/rfqdetailform?rfq_view_id=${res?.data?.header_id}`,{state:rfqType===2?{isPrevPath:true,rfqType:rfqType}:null});
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
   
  const handleSubmit = () => {
    let descriptionAfterTrim = description.trim();
      const payLoad = {
        ...rfqHeader,
        document_number:numberSequence?.ns_type==="continuous"?null:documentNumber,
        entity_id: parseInt(selectedEntityOptions),
        quotation_type: parseInt(rfqHeader.quotation_type),
        expiry_datetime:
          expiry_date===null || expiry_date===undefined ? null : DatepickerFunctions.convertDateTimeForDataBase(expiry_date),
        site_id: parseInt(selectedSiteOptions),
        warehouse_id: parseInt(selectedWarehouseOptions),
        title: descriptionAfterTrim,
        rfq_type: rfqType ?? 0, // 1 - RFI, 0 - RFQ
      };
      let validData = {
        ...payLoad
      }

      if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
      };

      Helper.createDebouncedAPIFunction([
        async () => await executePostApiCallforRFQ(payLoad)
      ], setSaveBtnApi, cancelTokenSource)();
  };

  const getNumberSequenceData = async (entity_id) => {
    try{
      let api = Api
      api.setUserToken();
      const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/${entity_id?entity_id:rfqHeader?.entity_id}/${rfqType===2?Settings.source_id.auction:Settings.source_id.request_for_quotation}`)
      if (numberSequenceRecord?.status===200) {
        setNumberSequence(numberSequenceRecord?.data)
      }
    }catch(error){
      setNumberSequence({})
    }
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleDocumentChange = (e) => {
    setDocumentNumber(e.target.value);
  };

  const handleRFQTypeChange = (e) => {
    setRfqType(e.target.value)
  }

  const mainTitle = (type) => {
    if (type === "RFI") {
      return Helper.getLabel(
        language,
        "create_requestion_for_informations",
        "Create Requestion for Informations"
      );
    } else if (type === "Vendor Reply") {
      return Helper.getLabel(
        language,
        "create_rfq_vendor_reply",
        "Create Request for Quotations - Vendor reply"
      );
    } else {
      return Helper.getLabel(
        language,
        "create_request_for_quotation",
        "Create Request for Quotation"
      );
    }
  };
  return (
    <>
    {saveBtnApi ? <OverlayLoader /> : null}
    <Popup
      autoOpen={true}
      width="900px"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setShowCreateRFQModal(false);
      }}>
      <>
        <h5 className="create_rfq_modal__title">{mainTitle(type)}</h5>

        <Collapse
          className="mt-4 mb-4 "
          open={true}
          title={Helper.getLabel(
            language,
            "enter_the_following_information",
            "Enter the following information"
          )}
        >
           
          <Form className="addVendorModalForm" style={{ gap: "20px" }}>
            <Input
              label={`${Helper.getLabel(
                language,
                "document_number",
                "Document number"
              )}`}
              disable={objectIsEmpty(numberSequence) || numberSequence?.ns_type==='continuous'}
              onChange={handleDocumentChange}
              inputType="text"
              value={documentNumber}
              required={numberSequence?.ns_type==="manual"}
            />
            <Input
              label={`${Helper.getLabel(
                language,
                "title",
                "Title"
              )}`}
              onChange={handleDescriptionChange}
              inputType="text"
              value={description}
              required={true}
              maxLength={255}
            />

            <Dropdown
              label={`${Helper.getLabel(
                language,
                "request_for_quotation_type",
                "Request for quotation type"
              )}`}
              options={rfqTypes || []}
              placeHolder={`${Helper.getLabel(
                language,
                "select_request_for_quotation_type",
                "Select request for quotation type"
              )}`}
              id="select_rfq_type"
              name="rfq_type"
              reRenderRequired={true}
              onChange={(e) => handleRFQTypeChange(e)}
              value={rfqType}
              required={true}
              isDisable={true}
            />

            <Dropdown
              label={`${Helper.getLabel(
                language,
                "entity",
                "Entity"
              )}`}
              options={entityOptions || []}
              apiUrl={Settings.loginUrl + "/get_entities"} 
              pagination={true} 
              mapFunction={mapEntityItems} 
              allDataRef={entityRefData}  
              placeHolder={`${Helper.getLabel(
                language,
                "select_entity",
                "Select entity"
              )}`}
              id="select_entity_id"
              reRenderRequired={true}
              onChange={(e) => handleEntityChange(e)}
              value={selectedEntityOptions}
              required={true}
            />

            <Dropdown
              label={`${Helper.getLabel(language, "site", "Site")}`}
              options={siteOptions ? siteOptions : []}
              placeHolder={`${Helper.getLabel(
                language,
                "select_site",
                "Select site"
              )}`}
              onChange={(e) => handleSiteChange(e)}
              value={selectedSiteOptions}
              id="site"
              reRenderRequired={true}
              apiUrl={Settings.loginUrl + "/get_site"} 
              pagination={true} 
              mapFunction={mapSiteItems} 
              allDataRef={siteRefData}  
              additionalParams={siteAdditionalParams}
              required={true}
            />

            <Dropdown
              label={`${Helper.getLabel(language, "warehouse", "Warehouse")}`}
              options={warehouseOptions || []}
              placeHolder={`${Helper.getLabel(
                language,
                "select_warehouse",
                "Select warehouse" 
              )}`}
              apiUrl={Settings.loginUrl + "/get_warehouse"} 
              pagination={true} 
              mapFunction={mapWarehouseItems} 
              allDataRef={warehouseRefData}  
              reRenderRequired={true}
              onChange={(e) => handleWarehouseChange(e)}
              additionalParams={warehouseAdditionalParams}
              value={selectedWarehouseOptions}
              id="warehouse"
              required={true}
            />

            {quotationType.length !== 0 && rfqType !== 2 && (
              <Dropdown
                label={`${Helper.getLabel(
                  language,
                  "quotation_type",
                  "Quotation type"
                )}`}
                reRenderRequired={true}
                required={true}
                options={quotationType.map((item) => {
                  return {
                    value: item?.id,
                    label: item?.name,
                  };
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  "select_quotation_type",
                  "Select quotation type"
                )}`}
                onChange={(e) => {
                  setRfqHeader({
                    ...rfqHeader,
                    quotation_type: e.target.value,
                  });
                }}
                value={rfqHeader?.quotation_type}
                id="quotation_type"
              />
            )}
            {/* {rfqHeader?.quotation_type != 0 && rfqHeader?.quotation_type != null ?
              <Dropdown
                label={`${Helper.getLabel(
                  language,
                  "unsealed_by",
                  "Unsealed by"
                )}`}
                reRenderRequired={true}
                options={employee.map((item) => {
                  return {
                    value: item?.employee_id,
                    label: item?.display_name,
                  };
                })}
                placeHolder={`${Helper.getLabel(
                  language,
                  "please_select",
                  "Please Select"
                )}`}
                onChange={(e) => {

                  setUnsealed(e.target.value);
                }}
                id="unsealed_by"
              />
              :
              null

            } */}
            {/* {rfqHeader?.quotation_type != 0 && rfqHeader?.quotation_type != null ?
              <NewInputDatePicker
                label={`${Helper.getLabel(
                  language,
                  "unsealed_date_and_time",
                  "Unsealed date and time"
                )}`}
                id="unsealed_date_time"
                name="unsealed_date_time"
                onChange={(date) => {
                  setUnsealed_datetime(date);
                }}
                timePicker={true}
                minDate={expiry_date || new Date(Date.now())}
                value={unsealed_datetime ? (unsealed_datetime < expiry_date ? expiry_date : unsealed_datetime) : expiry_date}
              />
              :
              null
            } */}
            {/* <Dropdown
              label={`${Helper.getLabel(
                language,
                "security_level",
                "Security level"
              )}`}
              options={securityLevel.map((item) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              })}
              reRenderRequired={true}
              required={true}
              placeHolder={`${Helper.getLabel(
                language,
                "select_security_level",
                "Select security level"
              )}`}
              onChange={(e) => {
                setRfqHeader({
                  ...rfqHeader,
                  security_level: e.target.value,
                });
              }}
              value={rfqHeader?.security_level}
              id="security_level"
            /> */}
            {rfqType === 2 ? <></> : <NewInputDatePicker
              label={`${Helper.getLabel(
                language,
                "expiry_date_and_time",
                "Expiry date and time"
              )}`}
              id="expiry_datetime"
              name="expiry_datetime"
              required={true}
              empty={true}
              onChange={(date) => {
                setExpiry_date(date);
              }}
              timePicker={true}
              value={expiry_date || rfqHeader?.expiry_datetime}
            />}
          </Form> 
        </Collapse>
        <div
            className="addDocumentNumber-body-buttons"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              isActive={false}
              className='black-btn-style'
              title={Helper.getLabel(language, "close", "Close")}
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                setShowCreateRFQModal(false);
              }}
            />  
            <Button
              className="solid_btn"
              isActive={true}
              isDisable={saveBtnApi}
              title={Helper.getLabel(language, "create", "Create")} 
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                handleSubmit();
              }}
            />  
        </div>
      </>
    </Popup>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(CreateRFQModal);