import React, { useState, useEffect, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MasterComonent from '../../../Backend/MasterComonent'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import WorkConfirmationApiCalls from '../CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import WorkConfirmationTabData from './WorkConfirmationTabData'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import WorkFlowCommonHeader from '../../../Header/WorkFlowCommonHeader'
import WorkFlowCommonHeaderAPICalls from '../../../Header/WorkflowCommonHeaderAPICalls'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import SimpleLoading from '../../../Loading/SimpleLoading'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Alert from '../../../inc/Alert'

const WorkConfirmationDetails = (props) => {
  const location = useLocation()
  const workConfirmationApiCalls = new WorkConfirmationApiCalls()

  const [workConfirmationDetails, setWorkConfirmationDetails] = useState({})
  const [linesData, setLinesData] = useState([])
  const [lineIds, setLineIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [documentStatuses, setDocumentStatuses] = useState([])
  const [documentStatus,setDocumentStatus]=useState('')

  // Workflow States
  const [workflowActionHistory, setWorkflowActionHistory] = useState([])
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({})

  //function to get params
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const shipment_id = getSearchParam('shipment_id');
  const workflow_instance_id = getSearchParam('instance_id');

  const navigate = useNavigate()

  const getAllLines = () => {
    if (!shipment_id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/get_sh_lines/${shipment_id}`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let updatedData = []
          for (let obj of res.data.data) {
            getLineItemDetails(obj.line_id)
              .then((data) => {
                obj.items = data
                updatedData.push(obj)
              })
              .catch((error) => {
                obj.items = []
                updatedData.push(obj)
              })
          }
          setTimeout(() => {
            setLinesData(updatedData)
            setLoading(false)
          }, 1000)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const getWorkFlowCalls = async () => {
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();
    const trigger = await workflowCalls.getWorkFlowTriggers();
    const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name == "Update")?.id;
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.work_confirmation, props?.auth?.user?.tenant_id, mode);
    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props?.auth?.user?.tenant_id, Settings.source_id.work_confirmation, shipment_id);
 
    setWorkflowExist(workflowExist);
    setWorkflowEligible(eligibleWorkflow);

    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.work_confirmation, shipment_id);
    setWorkflowActionHistory(workflowActionHistory);
  }

  const getLineItemDetails = (line_id) => {
    return new Promise((resolve, reject) => {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .get(
          Settings.apiPurchaseOrderUrl +
            `/lines/${line_id}/${Settings.source_id.work_confirmation}`,
          {},
        )
        .then((res) => {
          if (res.data.status === 'success') {
            resolve(res.data.data)
          } else {
            reject(new Error('API call failed'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const getAllAsyncData = async () => {
    // getAllLines()
    setLoading(true)
    const shipment_header = await workConfirmationApiCalls.getAllShipmentHeaderById(
      shipment_id,
    )
    const lineId = await workConfirmationApiCalls.getAllHeaderLines(
      shipment_header?.purchaseorder_id,
      Settings.source_id.purchase_order,
    )
    const documentStatuses = await workConfirmationApiCalls.getAllShDocumentStatus()
    if (lineId) setLineIds(lineId)
    if (shipment_header) {
      setWorkConfirmationDetails(shipment_header) 
      setLoading(false)
    }
    if(documentStatuses) setDocumentStatuses(documentStatuses)
  }

  const handleEditButton = () => {
    navigate(`/work-confirmation/edit#${shipment_id}`,{state:{prevPath:"/details"}})
  }
 
  useEffect(() => {
    if(!props?.auth?.user) return;
    getWorkFlowCalls()
  }, [props?.auth?.user])

  useEffect(() => {
    if (!shipment_id) return
    getAllAsyncData()
  }, [shipment_id])

  useEffect(() => {
    if (workConfirmationDetails && workConfirmationDetails.document_status !== undefined) {
      const document_status = documentStatuses?.find(
        (option) => option.id === workConfirmationDetails.document_status
      );
      setDocumentStatus(document_status?.name);
    }
  }, [workConfirmationDetails, documentStatus]);

  const handleStatusChangeOfDocument = async () => {
    let shipmentHeaderPayload = { status: 0 };

    setLoading(true);
    let api = Api
    api.setUserToken()
    try {
      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseOrderUrl +
            `/sh_header/${shipment_id}`,
          shipmentHeaderPayload,
        )  
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success');
        getAllAsyncData();
        setLoading(false);
      }
    } catch(res) {
      getPopupMessageBasedOnStatus(res)
      setLoading(false);
    }
  }

  let security = props?.security;
  let commonUserDocumentAccess = getSearchParam('common_user_access') === 'true' && location?.state?.prevRoutePath === '/dashboard' ? true : false;
  let isBtnDisableFromSecurity = !security?.canEdit(Gui_id_list.receiving.work_confirmation.work_confirmation_main_details_screen_button) && props?.user?.usertype === 0;
  let WCFormVisible = security?.canView(Gui_id_list.receiving.work_confirmation.work_confirmation_main_details_screen) || commonUserDocumentAccess;

  if(!WCFormVisible && props?.user?.usertype === 0){ 
    return (
      <MasterComonent>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </MasterComonent>
    )
  };
  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'work-confirmation-internal-screen external-detail-screens responsive-screen-style invoice-screen'
          : 'request_for_quotation_detail_screen external-detail-screens external-theme responsive-screen-style invoice-screen'
      }
    >
      <MasterComonent> 
        <NavigationHeder
          backUrl={-1}
          title={
            !(location?.state !== null && location?.state?.type==="Contract")
              ? `<span style="color:#757575;">${Helper.getLabel(
                  props.language,
                  'work_confirmation_list',
                  'Work Confirmation List'
                )} > </span> <span style="color:#313638;"> Work Confirmation Details # </span> ${
                 workConfirmationDetails?.receiving_reference_number
                }`
              : `<span style="color:#757575;">${Helper.getLabel(
                  props.language,
                  'contract_order_list',
                  `Contract Orders List`
                )} ></span> <span style="color:#313638">Contract Order Details : ${location?.state?.document_number || workConfirmationDetails?.purchaseorder_id} > Work Confirmation List > </span> # ${
                  workConfirmationDetails?.receiving_reference_number
                }`}
          hideMoreBtn={true}
        >
           <Button
              onClick={()=>{navigate(-1)}}
              title={Helper.getLabel(props.language, 'close', 'Close')}
              className="black-btn-style"
           />
          {workflowExist?.instance_details?.last_instance_status?.[4] === 'Rejected' && workConfirmationDetails?.document_status === 3 && (
            <Button
              onClick={handleStatusChangeOfDocument}
              className="blue-btn-style"
              title={Helper.getLabel(props.language, 'edit', 'Edit')}
              isDisable={isBtnDisableFromSecurity}
            />
          )}
          {workConfirmationDetails.document_status === 0 && (
            <Button
              onClick={handleEditButton}
              title={Helper.getLabel(props.language, 'edit', 'Edit')}
              className="blue-btn-style"
              isDisable={isBtnDisableFromSecurity}
            />
          )}

        <TotalWidget source_id={Settings.source_id.work_confirmation} integrator={workConfirmationDetails?.shipment_id} ></TotalWidget>
          <Button
            title={Helper.getLabel(
              props.language,
              'invoices',
              'Invoices',
            )}
           onClick={()=>{navigate(`/invoice-list?receiving_id=${workConfirmationDetails?.shipment_id}`)}}
           className="brown-btn-style"
          />
        </NavigationHeder>
       {loading ? <SimpleLoading />  : <>
        {workflowExist?.instance_exist && workflowEligible && documentStatus != "Posted" && !loading &&
          <div className='work_confirmation_workflow'>
            <WorkFlowCommonHeader
              instance_task_id={workflow_instance_id}
              workflowInstanceDetails={workflowExist?.instance_details}
              module_id={!workflow_instance_id ? Settings.source_id.work_confirmation : null}
              source_id={Settings.source_id.work_confirmation}
              source_item_id={shipment_id}
              SubmitWorkflowObjDetails={workflowExist?.workflow_name}
              module_item_id={!workflow_instance_id ? shipment_id : null}
              workflowInstanceExist={workflowExist?.instance_exist}
              workflowEligible={workflowEligible}
              workflowName={'Work Confirmation Workflow'}
              reloadFunction={()=>{
                getWorkFlowCalls()
                getAllAsyncData() 
              }}
            />
          </div>
        }

        <div className="tabs_default internal-tabs">
          <WorkConfirmationTabData
            workflowActionHistory={workflowActionHistory}
            shipment_id={shipment_id}
            language={props.language}
            workConfirmationData={workConfirmationDetails}
            linesData={linesData}
            lineIdOptions={lineIds}
            isLoading={loading}
            userType={props.user?.usertype}
            document_status={documentStatus}
          />
        </div>
        </>}
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}
  
const SecurityOptions = {
  gui_id: Gui_id_list.receiving.work_confirmation.work_confirmation_main_details_screen
};

export default connect(mapStateToProps) (ApplySecurityRoles(WorkConfirmationDetails, SecurityOptions))