import React, { useState } from 'react';

const TaskActionDropdown = ({onChangeHandler, applyCustomStyles=false, defaultPlaceholderLabel='Type', APIData}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(defaultPlaceholderLabel); 

    const toggleDropdown = () => { setIsOpen(!isOpen); };

    const getDropdownItemClass = (value) => {
        switch (value) {
            case 'Draft':
                return <h2 className='recall-item'> <span>{value}</span></h2>;
            case 'Published':
                return <h2 className='complete-item'> <span>{value}</span></h2>;
            case 'Canceled':
                return <h2 className='cancel-item'> <span>{value}</span></h2>;
            case 'Awarded':
                return <h2 className='reject-item'> <span>{value}</span></h2>;
            case 'OnHold':
                return <h2 className='in-process-item'> <span>{value}</span></h2>;
            default:
                return <h2 className='default-item'> <span>{value}</span></h2>;
        }
    }

    const getDropdownItemStatusClass = (value) => {
        switch (value) {
            case 'Request for Quotation line Distribution':
                return <h2 className='rfq-line-distribution-item'> <span>{value}</span></h2>;
            case 'RFQ Technical Evaluation':
                return <h2 className='rfq-techincal-evaluation-item'> <span>{value}</span></h2>;
            case 'RFQ Commercial Evaluation':
                return <h2 className='rfq-financial-evaluation-item'> <span>{value}</span></h2>;
            case 'RFQ Awarding':
                return <h2 className='rfq-awarding-item'> <span>{value}</span></h2>;
            case 'Request for Quotation Line':
                return <h2 className='rfq-line-item'> <span>{value}</span></h2>;
            default:
                return <h2 className='default-item'> <span>{value}</span></h2>;
        }
    }

    const handleOptionClick = (label, value) => {
        onChangeHandler(value)
        setSelectedItem(label);
        toggleDropdown();
    };

    return (
        <div className="custom_dropdown_task_actions_grid" style={{...!applyCustomStyles ? { position: 'relative' } : {}}}>
            <button className="dropdown-toggle" onClick={toggleDropdown}> 
                { applyCustomStyles ? getDropdownItemStatusClass(selectedItem) : getDropdownItemClass(selectedItem) }
                <img className={`img-fluid ms-2 ${isOpen ? "rotate-up" : ""}`} src={'/images/icons/chevron-down-blue.svg'} alt="Chevron" />
            </button>
            {isOpen && (
                <div className={applyCustomStyles ? 'dropdown_menu_action_history' : 'dropdown_menu_task_actions_grid'}>
                    {APIData.map((option) => {
                        return( 
                            <button
                                key={option.value}
                                type="button"
                                className="dropdown-item" 
                                onClick={() => handleOptionClick(option.label, option.value)} 
                            >
                                {option.label}
                            </button>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default TaskActionDropdown;
