import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MasterComonent from "../../components/Backend/MasterComonent";
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import Helper from "../../inc/Helper";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";
import Gui_id_list from "../../inc/Gui_id_list";
import Alert from "../../components/inc/Alert";
import MasterComponentWraper from "../../components/Backend/MasterComponentWraper";

const ReceiveScreen = ({ language, auth, security }) => {
  
  if (!security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_view)) { 
    return ( 
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    );
  }

  const canViewGRNAll = security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_goods_receiving_all);
  const canViewGRNOpen = security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_goods_receiving_open);
  const canViewInvoicesGRNCreatedByMe = security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_goods_receiving_created_by_me); 
 
  const canViewWCAll = security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_work_confirmation_all);
  const canViewWCOpen = security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_work_confirmation_open);
  const canViewWCCreatedByMe = security?.canView(Gui_id_list.screens.receiving_screen.main_receiving_screen_work_confirmation_created_by_me); 
  
  return (
    <div className="modules_screen_lynkaz"> 
      <InnerScreen innerTitle="Receiving">  
          {(canViewGRNAll || canViewGRNOpen || canViewInvoicesGRNCreatedByMe) && (
            <div className="col-lg-3 col-md-4 col-6 gy-4">
              <div className="inner-page-header border-bottom">
                <h2>
                  {Helper.getLabel(language, "goods_receiving", "Goods Receiving")}
                </h2>
              </div>
              <div className="inner-page-routes">
                {canViewGRNAll && (
                  <Link to="/goods-receiving-list">
                    {Helper.getLabel(language, "goods_receiving_all", "Goods Receiving - All")}
                  </Link>
                )}
                {canViewGRNOpen && (
                  <Link to={`/goods-receiving-list?status=0`}>
                    {Helper.getLabel(language, "goods_receiving_open", "Goods Receiving - Open")}
                  </Link>
                )}
                {canViewInvoicesGRNCreatedByMe && (
                  <Link to={`/goods-receiving-list?createby=${auth.user?.user_id}`}>
                    {Helper.getLabel(language, "goods_receiving_created_by_me", "Goods Receiving - Created by Me")}
                  </Link>
                )}
              </div>
            </div>
          )}

          {(canViewWCAll || canViewWCOpen || canViewWCCreatedByMe) && (
            <div className="col-lg-3 col-md-4 col-6 gy-4">
              <div className="inner-page-header border-bottom">
                <h2>
                  {Helper.getLabel(language, 'work_confirmations', 'Work Confirmations')}
                </h2>
              </div>
              <div className="inner-page-routes">
                {canViewWCAll && (
                  <Link to='/work-confirmation-list'>
                    {Helper.getLabel(language, 'work_confirmation_all', 'Work Confirmations - All')}
                  </Link>
                )}
                {canViewWCOpen && (
                  <Link to={`/work-confirmation-list?status=0`}>
                    {Helper.getLabel(language, 'work_confirmation_open', 'Work Confirmations - Open')}
                  </Link>
                )}
                {canViewWCCreatedByMe && (
                  <Link to={`/work-confirmation-list?createby=${auth.user?.user_id}`}>
                    {Helper.getLabel(language, 'work_confirmation_created_by_me', 'Work Confirmations - Created by Me')}
                  </Link>
                )}
              </div>
            </div>
          )} 
      </InnerScreen> 
    </div>
  );
};
 
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.screens.receiving_screen.main_receiving_screen_view
};

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(ReceiveScreen, SecurityOptions), { masterClassName: 'no_padding_class' })));