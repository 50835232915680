import React, {useState} from 'react';
import '../../../Forms/Toggle.css';
import { calculateTotalScoringPercentages, disableTextAreaInputFields } from '../../../../inc/Validation';

const TogglerSwitchApplicable = ({ ArrayRFQMasterScoring, out_of_total_score, out_of_total_score_technical, out_of_total_score_financial, onToggleValueChange, RFQVendors, disableToggle, title, name, value }) => {
  const [checked, setChecked] = useState(value);
   
  const handleToggle = () => {
    if(disableToggle) return;
     
    disableTextAreaInputFields(ArrayRFQMasterScoring); // sending array, function calculating the fields required for disabled
    calculateTotalScoringPercentages(RFQVendors, out_of_total_score, out_of_total_score_technical, out_of_total_score_financial);
    setChecked(!checked);
    onToggleValueChange(!checked);
  }
  return (
    <div className={`toggle-container`}>
      <span>{title}</span>
      <label className="toggle-switch">
        <input
          type="checkbox"
          disabled={disableToggle}
          name={name}
          checked={checked}
          onChange={handleToggle}
        />
        <span className="toggle-slider"></span>
      </label> 
    </div> 
  )
}

export default TogglerSwitchApplicable;