import React, { Component } from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { getMimeTypeFromExtension } from '../../../inc/Validation';

class FileListItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAction:false,
            isLoading:false,
            isDeleted:false,
        }
    }
    fileDeleteHandler(e){
        let file = this.props.file;
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoading:true
        })
        api.axios().delete(Settings.apiUrl + '/attachment/'+file.attachment_id).then(function(res){
            if(res.data.status ==='success'){
                Helper.alert(res.data.message)
                if (that.props.onFileDeleted) {
                    that.props.onFileDeleted(file.attachment_id);
                }
                that.setState({
                    isDeleted:true,
                    isLoading:false
                })
            }else{
                that.setState({
                    isLoading:false
                })
                Helper.alert(res.data.message,'error')
            }
            
        })
    }
    getIconUrl (){
        let file = this.props.file;
        let icon = '/images/icons/folder.svg';
        let fileType = file.filetype.toLowerCase();
        if( fileType === 'application/pdf'){
            icon = '/images/icons/pdf.svg';
        }
        else if(fileType.search('image') >=0){
            icon = '/images/icons/picture.svg';
        }
        
        return icon;
    }
    downloadFile(e){
        let file = this.props.file;
        let api = Api;
        api.setUserToken();
      
        // api.axios().get(Settings.apiUrl + '/get_attachment/'+file.attachment_id).then(function(res){
        //     if(res.data.status ==='success'){
        //         const fileUrl = res.data.data.file_url
        //         const fileName = res.data.data.file_name
        //         const link = document.createElement('a')
        //         link.href = fileUrl
        //         link.download = fileName
        //         document.body.appendChild(link)
        //         link.click()
        //         document.body.removeChild(link)
        //     }
        // })
        api.axios().get(Settings.apiUrl + `/get_attachment/${file.attachment_id}`, { responseType: 'blob' })
        .then(async function (res) {
            if (res.status === 200) {
                const response = res;
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'dummy.jpg'; 
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename\*?=(?:UTF-8'')?([^;]+)/);
                    if (fileNameMatch?.[1]) {
                      fileName = decodeURIComponent(fileNameMatch[1].trim());
                    } 
                }
                const fileBlob = new Blob([response.data], {
                    type: response.headers['content-type'],
                });

                // Create a download link and trigger the download
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(fileBlob);
                downloadLink.download = fileName;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        })
        .catch(function (error) {
            console.error('Error downloading file:', error);
        })
        
    }

    async previewFile(e){   
        let file = this.props.file;
        let api = Api;
        api.setUserToken();
        try {
            const response = await api.axios().get(`${Settings.apiUrl}/get_attachment/${file.attachment_id}`, {
              responseType: 'arraybuffer', 
            });

            const mimeType = getMimeTypeFromExtension(file.filetype)

            const blob = new Blob([response.data], { type: mimeType }); 
            const fileURL = URL.createObjectURL(blob);
        
            window.open(fileURL, '_blank');
          } catch (error) {
            console.error('Error fetching the file:', error);
          }
    }
    render() {
        let icon = this.getIconUrl ();
        let file = this.props.file;
        
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        if(this.state.isDeleted){
            return <></>
        }
        return (
            <div className='file_list_item'>
                <img src= {icon} alt="file type"  className='file_type_icon'  onClick={ e => this.downloadFile(e)} onKeyDown={()=>{}}/>
                <div className='file_details'>
                 {this.props.condition?null: 
                //  <div className= {this.state.showAction ? 'file_actions show' : 'file_actions'}>
                //         <div className='file_action_controller' onClick={ e=> { this.setState({ showAction:!this.state.showAction })} }>...</div>
                //          <div className='file_actions_option'>
                //             <span onClick={ e => this.fileDeleteHandler(e) }>Delete</span>
                //         </div>
                //     </div>
                   <img src="/images/icons/delete.svg" alt="delete icon" onClick={ e => {Helper.confirmMe(this.fileDeleteHandler.bind(this), Helper.getLabel(null, 'attachment_del', `This file cannot be recovered once deleted.`))}} className='common-attachment-delete-style' onKeyDown={()=>{}}/>
                    }
                    {/* e => this.fileDeleteHandler(e) */}
                    <div className='d-flex gap-2 align-items-center'>
                      <h3 onClick={ e => this.downloadFile(e)} onKeyDown={()=>{}}>{file.filename}</h3>
                      <FontAwesomeIcon icon={faEye} onClick={e=>this.previewFile(e)} className="attachment-preview-eye-icon" />
                    </div>
                    <h6 className='subtitle-attachment'>{file.attachmenttype_name}</h6>
                    <div className='d-flex align-items-center gap-1'>
                      <p>{Number.parseFloat(file.filesize).toFixed(3)} Mb</p> . <p>Uploaded on: {DatepickerFunctions.convertDateTimeFromDataBase(file.createddatetime)}</p>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default FileListItem;