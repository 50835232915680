import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import InnerScreen from '../../components/Inner Screens/InnerScreen';
import Helper from '../../inc/Helper';
import Alert from '../../components/inc/Alert';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../components/Backend/MasterComponentWraper';

const MasterScreen = ({language, security}) => {

  if(!security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_view)){ 
    return ( 
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    )
  };
 
  const canViewVendorsApproved = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_approved);
  const canViewVendorsNotCompleted = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_joined_but_not_completed);
  const canViewVendorsUnderReview = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_under_review);
  const canViewVendorsDeleted = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_deleted);
  const canViewVendorsAll = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_all);
  const canViewVendorsInvitationList = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_invitation_list);
  const canViewVendorsGroup = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_group);
  const canViewVendorsBusinessClassification = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_business_class);
  const canViewVendorsReason = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_reason);
  const canViewVendorsDesignation = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_designations);
  const canViewVendorsContactTypes = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_contact_types);
  const canViewVendorsAnnouncements = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_vendor_announcements);
 
  const canViewItems = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_items);
  const canViewDimensions = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_dimensions);
  const canViewSize = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_size);
  const canViewColor = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_color);
  const canViewConfiguration = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_configuration);
  const canViewStyle = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_style);
  const canViewBrand = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_screen_brand);
 
  const canViewTeams = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_teams);
  const canViewTeamsDynamic = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_teams_dynamic); 
 
  const canViewScoringTemplates = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_scoring_templates);
  const canViewTermsConditionsTemplates = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_terms_conditions_templates);
  const canViewComplianceTemplates = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_compliance_templates); 
 
  const canViewEmployee = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_employee);
  const canViewEmployeePosition = security?.canView(Gui_id_list.screens.master_data_screen.main_master_data_employee_position); 

  return (
    <div className='modules_screen_lynkaz'> 
      <InnerScreen innerTitle='Master Data'>  
          {(canViewVendorsApproved || canViewVendorsNotCompleted || canViewVendorsUnderReview || canViewVendorsDeleted || canViewVendorsAll || canViewVendorsInvitationList || canViewVendorsGroup || canViewVendorsBusinessClassification || canViewVendorsReason || canViewVendorsDesignation || canViewVendorsAnnouncements || canViewVendorsContactTypes) && <div className='col-lg-3 col-md-4 col-6 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'vendor', 'Vendors')}</h2>
            </div>
            <div className='inner-page-routes'>
              {canViewVendorsApproved && <Link to='/vendors?status=1'>{Helper.getLabel(language, 'vendors_registration_approved', 'Vendors - Approved')}</Link>}
              {canViewVendorsNotCompleted && <Link to='/vendors?status=2'>{Helper.getLabel(language, 'vendors_not_completed_registration', 'Vendors - Joined but not completed registration')}</Link>}
              {canViewVendorsUnderReview && <Link to='/vendors?document_status=2'>{Helper.getLabel(language, 'vendors_under_review', 'Vendors - Under review')}</Link>}
              {canViewVendorsDeleted && <Link to='/vendors?deleted=true'>{Helper.getLabel(language, 'vendors_deleted', 'Vendors - Deleted')}</Link>}
              {canViewVendorsAll && <Link to='/vendors'>{Helper.getLabel(language, 'vendor_all', 'Vendors - All')}</Link>}
              {canViewVendorsInvitationList && <Link to='/vendors-invitation-list'>{Helper.getLabel(language, 'vendors_invitation_list', 'Vendors - Invitation list')}</Link>}
              {canViewVendorsGroup && <Link to='/vendors-group'>{Helper.getLabel(language, 'vendors_group', 'Vendors - Group')}</Link>}
              {canViewVendorsBusinessClassification && <Link to='/vendors-business-classification'>{Helper.getLabel(language, 'vendors_business_classification', 'Vendors - Business classification')}</Link>}
              {canViewVendorsReason && <Link to='/vendors-reason'>{Helper.getLabel(language, 'vendors_reason', 'Vendors - Reason')}</Link>}
              {canViewVendorsDesignation && <Link to='/designations'>{Helper.getLabel(language, 'vendor_designations', 'Vendors - Designations')}</Link>}
              {canViewVendorsContactTypes && <Link to='/contact-types'>{Helper.getLabel(language, 'vendor_contact_types', 'Vendors - Contact types')}</Link>}
              {canViewVendorsAnnouncements && <Link to='/vendors-announcements'>{Helper.getLabel(language, 'vendor_announcements', 'Vendors - Announcements')}</Link>}
            </div>
          </div>}

          {(canViewItems || canViewDimensions || canViewSize || canViewColor || canViewConfiguration || canViewStyle || canViewBrand) && <div className='col-lg-3 col-md-4 col-6 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'item', 'Item')}</h2>
            </div>
            <div className='inner-page-routes'>
              {canViewItems && <Link to='/items'>{Helper.getLabel(language, 'items', 'Items')}</Link>}
              {canViewDimensions && <Link to='/dimensions'>{Helper.getLabel(language, 'dimensions', 'Dimensions')}</Link>}
              {canViewSize && <Link to='/size'>{Helper.getLabel(language, 'size', 'Size')}</Link>}
              {canViewColor && <Link to='/color'>{Helper.getLabel(language, 'color', 'Color')}</Link>}
              {canViewConfiguration && <Link to='/configuration'>{Helper.getLabel(language, 'configuration', 'Configuration')}</Link>}
              {canViewStyle && <Link to='/style'>{Helper.getLabel(language, 'style', 'Style')}</Link>}
              {canViewBrand && <Link to='/brand'>{Helper.getLabel(language, 'brand', 'Brand')}</Link>}  
            </div>
          </div>}

          {(canViewTeams || canViewTeamsDynamic) && <div className='col-lg-3 col-md-4 col-6 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'team', 'Team')}</h2>
            </div>
            <div className='inner-page-routes'>
              {canViewTeams && <Link to='/team-header'>{Helper.getLabel(language, 'teams', 'Teams')}</Link>} 
              {canViewTeamsDynamic && <Link to='/team-header?dynamic_team_header=true'>{Helper.getLabel(language, 'teams-dynamic', 'Dynamic - Teams')}</Link>} 
            </div>
          </div> }

          {(canViewScoringTemplates || canViewTermsConditionsTemplates || canViewComplianceTemplates) && <div className='col-lg-3 col-md-4 col-6 gy-4'>
            <div className='inner-page-header border-bottom'>
              <h2>{Helper.getLabel(language, 'request_for_quotations', 'Request for Quotations')}</h2>
            </div>
            <div className='inner-page-routes'>
              {canViewScoringTemplates && <Link to='/scoring-template'>{Helper.getLabel(language, 'scoring_templates', 'Scoring templates')}</Link>} 
              {canViewTermsConditionsTemplates && <Link to='/terms-conditions-templates'>{Helper.getLabel(language, 'terms_conditions_templates', 'Terms & condition templates')}</Link>} 
              {canViewComplianceTemplates && <Link to='/compliance-templates/listings'>{Helper.getLabel(language, 'compliances_templates', 'Compliance templates')}</Link>} 
            </div>
          </div> }
  
          {(canViewEmployee || canViewEmployeePosition) &&
            (<div className='col-lg-3 col-md-4 col-6 gy-4'>
              <div className='inner-page-header border-bottom'>
                <h2>{Helper.getLabel(language, 'employee', 'Employee')}</h2>
              </div>
              <div className='inner-page-routes'>
                {canViewEmployee && <Link to='/employee'>{Helper.getLabel(language, 'employee', 'Employee')}</Link>} 
                {canViewEmployeePosition && <Link to='/position'>{Helper.getLabel(language, 'employee_position', 'Employee Position')}</Link>} 
              </div>
            </div>
          )}
      </InnerScreen> 
    </div>
  );
}
 
const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.screens.master_data_screen.main_master_data_screen_view
};

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(MasterScreen, SecurityOptions), { masterClassName: 'no_padding_class' })));