// import React, { Component, version } from 'react';
// import { connect } from 'react-redux';
// import ActionTypes from '../../../inc/ActionsTypes';
// import Api from '../../../inc/Api';
// import Helper from '../../../inc/Helper';
// import Settings from '../../../inc/Settings';
// import AgGrid from '../../grid/ag/ag-grid';
// import AgGridMemo from '../../grid/ag/AgGridMemo';
// import SimpleLoading from '../../Loading/SimpleLoading';
// import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
// /**
//  * contactsource_id : Contact source id
//  * contactsource_integrator : id such as tenant id
//  * source_id : source_id // Tenant , Site etc source id
//  * address_id : id of existing address
//  * dataExportable
//  * canEdit : true/false
//  */
// class ContactAddress extends Component {
//     constructor(props){
//         super(props);
//         this.isCalledCantactType = false;
//         this.gridObj = null;
//         this.forceRender = 1;
//         this.state = {
//             contactType: [],
//             dataExportable: this.props.dataExportable ? true : false,
//             contacts:this.props.data ? this.props.data : [],
//             contactsource_id: this.props.contactsource_id ? this.props.contactsource_id  : null,
//             source_id: this.props.source_id ? this.props.source_id  : null,
//             contactsource_integrator: this.props.contactsource_integrator ? this.props.contactsource_integrator  : null,
//             address_id: this.props.address_id ? this.props.address_id  : null,
//             isLoading:false,
//             isFirstItem:false
//         }
//     }
//     componentDidMount(){
//         this.loadContacts()
//         this.loadContactsTypes()
//     }
//     componentDidUpdate(prevProps, prevState, snapshot){
//         if(this.props.contactsource_id && this.props.contactsource_id  != this.state.contactsource_id){
//             let that = this;
//             this.setState({
//                 contactsource_id: this.props.contactsource_id
//             },function () {
//                 that.loadContacts();
//             })
//         }
//         if(this.props.address_id && this.props.address_id  != this.state.address_id){
//             let that = this;
//             this.setState({
//                 address_id: this.props.address_id
//             },function () {
//                 that.loadContacts();
//             })
//         }
//     }
//     loadContactsTypes (){
//         if(this.isCalledCantactType){
//             return;
//         }
//         if(this.props.contactTypes.types.lenght >=1){
//             return;
//         }
//         this.isCalledCantactType = true;
//         let api  = Api;
//         api.setUserToken();
//         let that = this;
//         api.axios().get(Settings.apiUrl + '/enum/contacttype').then(function(res){
//             if(res.data.status ==='success'){
//                 let allTypes = res.data.data.reverse();
//                 that.forceRender++;
//                 that.props.setContactTypes(allTypes.map(itemType => { return  { label:itemType.name, value: itemType.id }}))
//             }
            
//         })
//     }
    
//     hasErrors(){
//         let gridData = Helper.getDataFromGrid(this.gridObj);
//         if(gridData.length >=1){
//             let hasPrimary = false;
//             gridData.forEach(item => {
//                 if(item.is_primary =='yes'){
//                     hasPrimary = true
//                 }
//             })
//             if(!hasPrimary){
//                 Helper.alert(Helper.getLabel(null,15236899,"Please select a primary contact"),'error');
//                 return true;
//             }
//         }
//         return false;
//     }

//     onSaveClick(contactItems){
//         if(this.hasErrors()){
            
//             return;
//         }
//         let that = this;
//         let newContacts = [];
//         contactItems.forEach(contactItem => {
//             if(!contactItem.contact_id){
//                 newContacts.push(contactItem)
//             }else{
//                 that.updateExistingContact(contactItem)
//             }
//         });
//         if(newContacts.length >=1){
//            this.createNewContact(newContacts)
//         }
//     }
//     createNewContact(contacts){
//         if(this.state.dataExportable){
//             return;
//         }
//         let that = this;
//         that.setState({
//             isLoading:true
//         })
//         if(!that.state.source_id){
//             Helper.alert("Source not found",'error');
//         }
//         let api = Api;
//         api.setUserToken();
//         let newContacts = [];
//         contacts.forEach(contactItem => {
//             newContacts.push(
//                 {
//                     address_id: that.state.address_id,
//                     type: contactItem.type,
//                     description: contactItem.description,
//                     contact:contactItem.contact,
//                     extension: Helper.getNullableValue( contactItem.extension),
//                     isprimary:contactItem.is_primary && contactItem.is_primary === "yes" ? true : false,
//                     isactive:contactItem.is_active && contactItem.is_active === "yes" ? true : false,
//                     mobile:contactItem.mobile && contactItem.mobile === "yes" ? true : false,
//                 }
//             )
//         })
//         let newData ={
//             contact:{
//                 contactsource_id: that.state.contactsource_id,
//                 source_id: that.state.contactsource_id ? null : that.state.source_id,
//                 contactsource_integrator: that.state.contactsource_id ? null : that.state.contactsource_integrator,
//                 contacts:newContacts
//             }
//         }
        
//         if(newData.contactsource_id && parseInt(newData.contactsource_id) >= 1){
//             newData.source_id = null;
//             newData.contactsource_integrator = null;
//         }
//         api.axios().post(Settings.apiUrl + '/add_contact',newData).then(function(res){
//             if(res.data.Status ==='Success'){
//                 Helper.alert(res.data.Message)
//                 /*
//                 if(res.data.contact_source_id){
//                     that.state.contactsource_id = res.data.contact_source_id;
//                 }
//                 */
//                 if(that.props.onContactCreate && typeof that.props.onContactCreate ==='function'){
//                     that.props.onContactCreate(res.data);
//                 }

//                 if(that.props.onContactLoad && typeof that.props.onContactLoad ==='function'){
//                     that.props.onContactLoad(res.data);
//                 }
//             }else{
//                 Helper.alert(res.data.Message,'error')
//             }
//             that.setState({
//                 isLoading:false
//             })
//             that.loadContacts();
            
//         }).catch( (errors) => {
//             getPopupMessageBasedOnStatus(errors)
//             that.setState({
//                 isLoading:false
//             })
//         })
//     }
//     updateExistingContact(contactItem){
//         let that = this;
//         that.setState({
//             isLoading:true
//         })
//         if(!that.state.source_id){
//             Helper.alert("Source not found",'error');
//         }
//         let api = Api;
//         api.setUserToken();
//         let existingData ={
//             type:contactItem.type,
//             description:contactItem.description,
//             contact:contactItem.contact,
//             extension:contactItem.extension,
//             isprimary:contactItem.is_primary && contactItem.is_primary === "yes" ? true : false,
//             isactive:contactItem.is_active && contactItem.is_active === "yes" ? true : false,
//             mobile:contactItem.mobile && contactItem.mobile === "yes" ? true : false,
//         }
//         api.axios().put(Settings.apiUrl + '/contact/'+contactItem.contact_id,existingData).then(function(res){
//             if(res.data.status ==='success'){
//                 Helper.alert(res.data.message)
//             }else{
//                 Helper.alert(res.data.message,'error')
//             }
//             that.setState({
//                 isLoading:false
//             })
//             that.loadContacts();
//         }).catch( errors => {
//             getPopupMessageBasedOnStatus(errors)
//             that.setState({
//                 isLoading:false
//             })
//         })
//     }
//     getFormatedData(){
//         let output = {}
//         return output;
//     }
//     loadContacts(){
//         let contactsource_id = this.state.contactsource_id;
//         if(!contactsource_id){
//             return;
//         }
//         let that = this;
//         let api = Api;
//         api.setUserToken();
//         this.setState({
//             isLoading:true
//         })
//         api.axios().get(Settings.apiUrl+'/get_contact/'+contactsource_id,{}).then(function(res){
//             if(res.data.Status ==='Success'){
//                 let RsContacts = res.data.data.contact.reverse();
//                 let allContacts = RsContacts.map( (contact) => {
//                     contact.is_active = contact.is_active ? 'yes' : 'no';
//                     contact.is_primary = contact.is_primary ? 'yes' : 'no';
//                     contact.mobile = contact.mobile ? 'yes' : 'no';
//                     return contact;
//                 })
//                 that.forceRender++; 
//                 that.setState({
//                     contacts: allContacts,
//                     contactsource_id: res.data.data.contactsource_id,
//                     source_id: res.data.data.source_id,
//                     isLoading:false
//                 })
//                 if(that.props.onContactLoad && typeof that.props.onContactLoad ==='function'){
//                     that.props.onContactLoad(RsContacts);
//                 }
//             }
            
//         }).catch( errors => {
//             //getPopupMessageBasedOnStatus(errors)
//             that.setState({
//                 isLoading:false
//             })
//         })
//     }
//     onContactDeleteHanlder(data){ 
//         let api = Api;
//         api.setUserToken();
//         let that =this;
//         let totalReqeustCount = 0;
//         data.forEach(contact => {
            
//             if(contact.contact_id){
//                 api.axios().delete(Settings.apiUrl + '/contact/'+contact.contact_id,{}).then(function(res){
//                     totalReqeustCount++;
//                     if(res.data.status === 'success'){
//                         if(totalReqeustCount >= data.length){
//                             Helper.alert(res.data.message )
//                             that.loadContacts();
//                         }
//                     }else{
//                         Helper.alert(res.data.message,"error" )
//                     }
//                 }).catch(error => {
//                     totalReqeustCount++;
//                     that.loadContacts();
//                     Helper.alert(error.response.data.message,"error" )
//                 })
//             }else{
//                 totalReqeustCount++;
//             }
//         })
//     }
//     onContactGridReady(event){
//         this.gridObj = event;
//         if(this.props.onGridReady && typeof this.props.onGridReady ==='function'){
//             this.props.onGridReady({
//                 ...event,
//                 contactComponent:this
//             })
//         }
//     }
//     canCreateEditDelete(){ 
//         if( typeof this.props.disableRecords != "undefined" && this.props.disableRecords === true){
//             return false;
//         }
//         if(typeof this.props.canEdit === "undefined"){
//             return true;
//         }
//         return (this.props.canEdit && this.props.canEdit === true) ? true : false;
//     }
//     isViewOnly(){
//         return !this.canCreateEditDelete();
//     }
//     onRowValueChanged(rowObject){
//         let rowData = rowObject.data;
        
//         if(rowData.is_primary && rowData.is_primary ==='yes') {
//             this.setState({
//                 isLoading:true
//             })
//             let newData = [];
//             rowObject.api.forEachNode( gridItem => {
//                 let newDataItem =gridItem.data;
//                 if( (gridItem.data.contact_id &&  gridItem.data.contact_id == rowObject.data.contact_id ) || (gridItem.data.gridRowId && gridItem.data.gridRowId == rowObject.data.gridRowId ) ){
//                     newDataItem.is_primary = 'yes';
//                 }else{
//                     newDataItem.description = newDataItem.description;
//                     newDataItem.is_primary = 'no';
//                 }
//                 newData.push(newDataItem)
//             })
//             this.setState({
//                 isLoading:false
//             },function(){
//                 rowObject.api.setRowData(newData)
//             })
//         }
//     }
//     getContactValidationRules(gridNode){
//         let rules = {
//             types:['Required'],
//             displayName:Helper.getLabel(null,11234,'Contact Number'),
//             min:1, max:80
//         }
//         let nodeData = gridNode.data;
//         if(nodeData.type == 1){ // phone
//             rules.types = ['Required','Phone'];
//             rules.max = 15;
//             rules.min = 10;
//         }else if(nodeData.type == 2){ // email
//             rules.displayName = Helper.getLabel(null,11234,'Contact');
//             rules.types = ['Required','Email'];
//         }
//         else if(nodeData.type == 3){ // url
//             //rules.types = ['Required','Url'];
//         }
//         else if(nodeData.type == 4){ // Telex

//         }
//         else if(nodeData.type == 5){ // Fax

//         }
//         if(nodeData.mobile =='yes'){
//             rules.types = ['Required','Phone'];
//         }
//         return rules;
//     }
//     getIsPrimaryDefaultValue(params){
//         let totalItems = 0;
//         if(this.gridObj){
//             this.gridObj.api.forEachNode(node => {
//                 totalItems++;
//             })
//         }
//         return totalItems>= 1 ? '' : 'yes';
//     }
//     render() {
//         if(this.state.isLoading){
//             return <SimpleLoading />
//         }
//         let language = this.props.language;
//         const gridValidationRules ={
//             description: {name:'description',displayName:Helper.getLabel(language,1188,'Description'),types:['Required'], min:1, max:80},
//             type: {name:'type',displayName:Helper.getLabel(language,1189,'Type'),types:['Required']},
//             extension: {max:80},
//             contact: this.getContactValidationRules.bind(this) 
//         }
//         //console.log(this.props.contactTypes.types);
//         //console.log(this.state.contacts);
//         let ctTypesList = this.props.contactTypes.types;
//         let Columns_Headings = [
//             {field:'description', headerName: Helper.getLabel(language,1188,'Description') , validationRule: gridValidationRules.description },
//             { field:'type', headerName: Helper.getLabel(language,1189,'Type') , inputType : 'dropdown' , options:ctTypesList, validationRule: gridValidationRules.type } ,
//             {field:'contact', headerName: Helper.getLabel(language,11234,'Contact number / Address'), width: 260 , validationRule: gridValidationRules.contact },  
//             {field:'extension', headerName: Helper.getLabel(language,11235,'Extension'), validationRule: gridValidationRules.extension},
//             {field:'is_primary', headerName: Helper.getLabel(language,11230,'Primary'), inputType:'checkbox', defaultValue: this.getIsPrimaryDefaultValue.bind(this)},
//             {field:'is_active', headerName: Helper.getLabel(language,11231,'Active'), inputType:'checkbox', defaultValue:'yes' } ,
//             {field:'mobile', headerName: Helper.getLabel(language,11232,'Mobile') , inputType:'checkbox' } 
//         ]
//         let contactGridId = this.props.id ? this.props.id : 'rs_contact_grid';
//         let contactList = this.state.contacts;
//         return (
//             <div className='contact_address'>
//                 <AgGridMemo forceRender={this.forceRender}  id={contactGridId} onRowValueChanged ={ this.onRowValueChanged.bind(this) } readOnlyGrid={!this.canCreateEditDelete()} allBtnHide={this.props.allBtnHide} dissableCheckBox = {!this.canCreateEditDelete()} viewOnly={ this.isViewOnly()} onGridReady={ this.onContactGridReady.bind(this)} onDelete = { this.onContactDeleteHanlder.bind(this)} data={ contactList }   header={Columns_Headings} onSave={this.onSaveClick.bind(this)} />
//             </div>
//         );
//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         auth:state.auth,
//         contactTypes:state.contactTypes,
//         language:state.language,
//     }
// }
// const mapDispatchToProps = (dispatch) =>{
//     return {
//         setContactTypes: (types) => { dispatch({type:ActionTypes.SET_ALL_CONTACT_TYPE,payload:types})}
//     }
// }
   
// export default connect (mapStateToProps,mapDispatchToProps) ( ContactAddress );



import React, { useCallback, useEffect, useRef, useState } from 'react';
import AgGridNew from '../../grid/ag/ag-grid-new';
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux';
import ActionTypes from '../../../inc/ActionsTypes';
import Settings from '../../../inc/Settings';
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import Api from '../../../inc/Api';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';

// let gridObj = null


const ContactAddress = (props) => {
  let contactId = props.id ? props.id : 'rs_contact_grid'
  let language = props.language
  let ctTypesList = props.contactTypes.types;
  let isCalledCantactType= false
  let AddBtn;
  let transaction

  const gridObj = useRef()

  const [contacts,setContacts]=useState({
    contactSource_id : props.contactsource_id ? props.contactsource_id  : null,
    source_id: props.source_id ? props.source_id  : null,
    contactsource_integrator: props.contactsource_integrator ? props.contactsource_integrator  : null,
    address_id: props.address_id ? props.address_id  : null,
  })
  const [rerenderGrid, setRerenderGrid] = useState(false);

  const loadContactsTypes = () => {
    if(isCalledCantactType){
        return;
    }
    if(props.contactTypes.types.lenght >=1){
        return;
    }
    isCalledCantactType = true;
       let api  = Api;
       api.setUserToken();
       api.axios().get(Settings.apiUrl + '/enum/contacttype').then(function(res){
         if(res.data.status ==='success'){
            let allTypes = res.data.data.reverse();
                props.setContactTypes(allTypes.map(itemType => { return  { label:itemType.name, value: itemType.id }}))
            }
        })
    }

  useEffect(() => {
    if (props.contactsource_id && props.contactsource_id !== contacts.contactSource_id) {
      setContacts(prevState => ({
        ...prevState,
        contactSource_id: props.contactsource_id
      }));
    }
  }, [props.contactsource_id]);

  useEffect(()=>{
    loadContactsTypes()
  },[])

  const onContactDeleteHanlder  = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl + '/contact/' + element?.data?.contact_id)
      const rowNode = gridObj?.current?.api?.getRowNode(element?.data?.contact_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.current?.api?.applyServerSideTransaction(transaction)
      }, 300)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])


  const conditionForOverlay = (response) => {
    return response?.data?.data?.contact?.length === 0
  }

  const onContactGridReady = (params) => {
    gridObj.current = params;
    if(props.onGridReady && typeof props.onGridReady ==='function'){
       props.onGridReady({
           ...params,
            contactComponent:params,
        })
        }
    }

  const fetchData = (response) => {
    return {
      rowData:response.data?.data?.contact?.length === 0 ? [] : response.data?.data?.contact?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}` 
      })),
      rowCount: response.data?.data?.contact?.length === 0  ? 0 : response.data?.data?.contact?.length,
    }
  }

   const canCreateEditDelete = () => {
     if (typeof props.disableRecords != 'undefined' && props.disableRecords === true ) {
       return false
     }
     if (typeof props.canEdit === 'undefined') {
       return true
     }
     return props.canEdit && props.canEdit === true 
   }


   let newAddItems = {is_active:true}

    const onBeforeAddRowClick = (params) => {
      if(params?.rowRenderer?.lastRenderedRow<0){
        newAddItems.is_primary=true
      }else{
        newAddItems.is_primary=false
      }
    }

   const isViewOnly = () => {
        return !canCreateEditDelete();
    }

    const handleAddBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const handleRowValueChanged = (event) => {
      let contactItem = event?.data
      let api = Api
      if (event?.data?.contact_id) {
        let existingData = {
          type: contactItem.type,
          description: contactItem.description,
          contact: contactItem.contact,
          extension: contactItem.extension,
          isprimary:
            contactItem?.is_primary,
          isactive:
            contactItem?.is_active,
          mobile:
            contactItem?.mobile,
        }
        api
          .axios()
          .put(
            Settings.apiUrl + '/contact/' + contactItem.contact_id,
            existingData
          )
          .then(function (res) {
            if (res.data.status === 'success') {
              Helper.alert(res.data.message)
            } else {
              Helper.alert(res.data.message, 'error')
            }
            gridObj?.current?.api?.refreshServerSide({purge:true})
            gridObj?.current?.api?.deselectAll()

          })
          .catch((errors) => {
            getPopupMessageBasedOnStatus(errors)
          })
      }else{
        if (!contacts.source_id) {
          Helper.alert('Source not found', 'error')
        }
        let api = Api
        api.setUserToken()
        let newContacts = []
          newContacts.push({
            address_id: contacts?.address_id,
            type: parseInt(contactItem.type),
            description: contactItem.description,
            contact: parseInt(contactItem.type)===2?contactItem.contact?.trim():contactItem.contact,
            extension: Helper.getNullableValue(contactItem.extension),
            isprimary:
              (contactItem?.is_primary) ? true : false,
            isactive:
              (contactItem?.is_active) ? true : false,
            mobile:
              (contactItem?.mobile) ? true : false,
          })
        let newData = {
          contact: {
            contactsource_id: contacts.contactSource_id,
            source_id: contacts.contactSource_id
              ? null
              : contacts.source_id,
            contactsource_integrator: contacts.contactSource_id
              ? null
              : contacts.contactsource_integrator,
            contacts: newContacts,
          },
        }

        if (
          newData.contactsource_id &&
          parseInt(newData.contactsource_id) >= 1
        ) {
          newData.source_id = null
          newData.contactsource_integrator = null
        }
        api
          .axios()
          .post(Settings.apiUrl + '/add_contact', newData)
          .then(function (res) {
            if (res.data.Status === 'Success') {
              Helper.alert(res.data.Message)
              if(contacts?.contactSource_id===null){
                setContacts({
                 ...contacts,
                 contactSource_id:res?.data?.contact_source_id,
                })
                if (
                    props.onContactCreate &&
                    typeof props.onContactCreate === 'function'
                  ) {
                    setRerenderGrid(prevState => !prevState);
                    props.onContactCreate(res.data)
                  }
              }

              setTimeout(()=>{
                gridObj?.current?.api?.refreshServerSide({purge:true})
                gridObj?.current?.api?.deselectAll()
              },200)

              setTimeout(() => {
                if (AddBtn) {
                  gridObj?.current?.api?.applyServerSideTransaction(transaction);
                }
              }, 1000);

              if (
                props.onContactLoad &&
                typeof props.onContactLoad === 'function'
              ) {
                props.onContactLoad(res.data)
              }
            } else {
              Helper.alert(res.data.Message, 'error')
            }
          })
      }
    }
 
  //Columns 
  let Columns_Headings = [
    {field:'description', headerName: Helper.getLabel(language,1188,'Description')},
    {field:'type', headerName: Helper.getLabel(language,1189,'Type') , cellEditor:SimpleDropdown, cellEditorParams:{values:ctTypesList}, valueFormatter:(params)=>getLabelFromDropdown(params),filter:'agSetColumnFilter',
    filterParams: {
      values: (params) => getOptions(params, ctTypesList),
      keyCreator: (params) => optionsKeyCreator(params, 'value'),
      valueFormatter: params => params.value.label,
    }} ,
    {field:'contact', headerName: Helper.getLabel(language,11234,'Contact details'), width: 260},  
    {field:'extension', headerName: Helper.getLabel(language,11235,'Extension'),},
    {field:'is_primary', headerName: Helper.getLabel(language,11230,'Primary'), cellRenderer:"agCheckboxCellRenderer", cellRendererParams:{disabled:true}, cellEditor:"agCheckboxCellEditor",filter: 'agSetColumnFilter', filterParams: { values: ['true', 'false'] },},
    {field:'is_active', headerName: Helper.getLabel(language,11231,'Active'),cellRenderer:"agCheckboxCellRenderer", cellRendererParams:{disabled:true}, cellEditor:"agCheckboxCellEditor", defaultValue:true, filter: 'agSetColumnFilter', filterParams: { values: [true, false] },} ,
    {field:'mobile', headerName: Helper.getLabel(language,11232,'Mobile') , cellRenderer:"agCheckboxCellRenderer", cellRendererParams:{disabled:true}, cellEditor:"agCheckboxCellEditor" ,  valueGetter:(params)=> { 
        return params.data.mobile ? params.data?.mobile : false
      },   filter: 'agSetColumnFilter', filterParams: { values: [true, false] },} 
  ]

  Columns_Headings = Columns_Headings.map(column => ({ ...column, editable: !isViewOnly() }));

  return (
    <div>
      <AgGridNew
        gridId={contactId}
        key={rerenderGrid ? 'rerender' : 'no-rerender'} 
        columnDefs={Columns_Headings}
        apiUrl={Settings.apiUrl+'/get_contact/'+contacts?.contactSource_id}
        fetchData={fetchData}
        onGridReady={onContactGridReady}
        allBtnHide={props.allBtnHide}
        isAddBtnDisable={isViewOnly()}
        isDeleteBtnDisable={isViewOnly()}
        uniqueField={'contact_id'}
        handleDeleteSelectedRows={onContactDeleteHanlder}
        handleAddButton={handleAddBtn}
        newAddItems={newAddItems}
        onRowValueChanged={!props.address_id && props.type==="address" ? null : handleRowValueChanged}
        returnApiCondition={!contacts?.contactSource_id}
        conditionForOverlay={conditionForOverlay}
        onBeforeRowAddBtnClick={onBeforeAddRowClick}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        contactTypes:state.contactTypes,
        language:state.language,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        setContactTypes: (types) => { dispatch({type:ActionTypes.SET_ALL_CONTACT_TYPE,payload:types})}
    }
}
   
export default connect (mapStateToProps,mapDispatchToProps) ( ContactAddress );
