import React, { Component } from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { connect } from 'react-redux';
import { detectLanguageOfText, getPopupMessageBasedOnStatus } from '../../../inc/Validation';

class NoteItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAction:false,
            isDeleting:false,
            noteText:this.props.note.note,
            isEditing:false,
            isPosting:false
        }
    }
    deleteNote(){
        let note = this.props.note;
        let that = this;
        let api = Api;
        api.setUserToken();
        that.setState({
            isDeleted:false,
            isDeleting:true
        })
        api.axios().delete(Settings.apiUrl + '/note/'+note.note_id).then(function(res){
            if(res.data.status==='success'){
                Helper.alert(res.data.message);
                that.setState({
                    isDeleted:true,
                    isDeleting:false
                })
            }else{
                Helper.alert(res.data.message,'error');
                that.setState({
                    isDeleted:false,
                    isDeleting:false
                }) 
            }
            
        }).catch( error => {
            console.log(error);
            that.setState({
                isDeleting:false
            })
        })
    }
    enableEditable(e){
        this.setState({
            showAction:false,
            isEditing:true
        })
    }
    onChangeNote(e){
        this.setState({
            noteText:e.target.value
        })
    }
    onUpdateHandler(e){
        let note = this.props.note;
        this.setState({
            isPosting:true
        })
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().put(Settings.apiUrl + '/note/'+note.note_id,{ note: this.state.noteText}).then(function(res){
            that.setState({
                isPosting:false,
                isEditing:false
            })
            if(res.data.status==='success'){
                Helper.alert(res.data.message)
            }
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })
    }
    onKeyPessHandler(e){
        if(e.keyCode ===27){
            this.setState({
                isPosting:false,
                noteText:this.props.note.note,
                isEditing:false
            })
        }
    }
    getProfileImage(note){
        const imageData = note.image?.slice(2,-1)
        const imageUrl = `data:image/${note?.image};base64,${imageData}`;
        
        return note.image ? imageUrl : '/images/blank-profile.jpg';
    }
    render() {
        let note = this.props.note;
        if(this.state.isDeleted){
            return <></>
        }
        return (
            <div className='note_item'>
                <img src={this.getProfileImage(note)} className='author_thumbnail' alt="" />
                <div className='note_item_content'>
                    {note.createdby_id === this.props.user.user_id && 
                        <div className= {this.state.showAction ? 'note_ations show' : 'note_ations'}>
                            <div className='note_action_controller' onClick={ e=> { this.setState({ showAction:!this.state.showAction })} }></div>
                            
                            <div className='note_actions_option'>
                                
                                {
                                    this.state.isDeleting ? <SimpleLoading width="30px" /> : <><span onClick={ e => this.enableEditable(e)}>Edit</span><span onClick={ e => this.deleteNote()}>Delete</span></>
                                }
                                
                            </div>
                        </div>
                    }
                    <h5 className='author'>{note.createdby}</h5>
                    <span className='date'>{ DatepickerFunctions.convertDateTimeFromDataBase(note.createddate) }</span>
                    <div className='note_text'>
                            {
                                !this.state.isEditing ? 
                                <pre style={detectLanguageOfText(this.state.noteText) === 'english' ? { direction: 'ltr' } : { direction: 'rtl' }}>{this.state.noteText}</pre> :
                                <div className='note_form'>
                                    <textarea onKeyDown={ e => this.onKeyPessHandler(e)}  value={this.state.noteText ? this.state.noteText : ''} onChange={e=>this.onChangeNote(e) } type="text" placeholder='Write a new note'/>
                                    <button onClick={ e => this.onUpdateHandler(e)}>{this.state.isPosting ? <SimpleLoading width="15px"/> : <img src="/images/icons/save-white.svg" alt="Submit" /> }</button>
                                </div>
                                
                            }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { 
        language:state.language,
        user:state.auth.user,
    }
}

export default connect(mapStateToProps) (NoteItem)