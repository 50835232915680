import DatepickerFunctions from "../../../inc/DatepickerHelper"
import Helper from "../../../inc/Helper"
import { commonThousandSeparator } from "../../../inc/Validation"
import SimpleDropdown from "../../grid/ag/cellEditor/SimpleDropdown"
import NumberCellRenderer from "../../grid/ag/cellRender/NumberCellRenderer"
import { getBillingRuleDescription, getLabelFromDropdown } from "../../grid/ag/commonFunctions/GridCommonFunctions"

const getInvoiceSummaryColumns = ({language}) => {
    return [
        {
            field: 'item_name',
            headerName: Helper.getLabel(
              language,
              'item_name',
              'Item name',
            ),
            minWidth: 220,
            editable: false,
            valueFormatter:(params)=>params.value || "-"
        },
        {
          field: 'received_qty',
          headerName: Helper.getLabel(
          language,
          'invoice_percentage',
          'Invoice percentage / quantity',
          ),
          cellRenderer:NumberCellRenderer,
          minWidth: 160,
          editable:false,
        },
        {
          field: 'discount_amount',
          headerName: Helper.getLabel(
            language,
            'discount_amount',
            'Discount amount',
          ),
          cellRenderer:NumberCellRenderer,
          minWidth: 160,
          editable: false,
        },
        {
          field: 'discount_percentage',
          headerName: Helper.getLabel(
            language,
            'discount_percentage',
            'Discount percentage',
          ),
          minWidth: 160,
          editable:false,
          cellRenderer:NumberCellRenderer,
        },
        {
          field: 'line_amount',
          headerName: Helper.getLabel(
            language,
            'line_amount',
            'Line amount',
          ),
          minWidth: 160,
          cellRenderer:NumberCellRenderer,
          editable: false,
        }    
      ]   
}

const getMatchProductColumns1 = ({language}) => {
    return [
        {
          field: 'line_number',
          headerName: Helper.getLabel(language, 'line_number', 'Line Number'),
          editable:false,
          minWidth:180,
        },
        {
          field: 'item_name',
          headerName: Helper.getLabel(language, 'item_name', 'Item Name'),
          editable:false,
          minWidth:180,
        },
        {
          field: 'unit_name',
          headerName: Helper.getLabel(language, 'unit', 'Unit'),
          editable:false,
          minWidth:180,
        },
        {
          field: 'unit_price',
          headerName: Helper.getLabel(language, 'unit_price', 'Unit Price'),
          editable:false,
          cellRenderer:NumberCellRenderer,
          minWidth:180,
        },
        {
          field: 'receiving_reference_number',
          headerName: Helper.getLabel(language, 'document_no', 'Receiving No.'),
          editable:false,
          minWidth:200,
        },
        {
          field: 'ordered_qty',
          headerName: Helper.getLabel(language, 'total_qty', 'Total Qty'),
          editable:false,
          cellRenderer:NumberCellRenderer,
          minWidth:180,
        },
        {
          field: 'matched_qty',
          headerName: Helper.getLabel(language, 'matched_qty', 'Matched Qty'),
          minWidth:180,
          editable:false,
          cellRenderer:NumberCellRenderer,
        },
        {
          field: 'received_qty',
          headerName: Helper.getLabel(language, 'invoice_qty', 'Invoice Qty'),
          minWidth:180,
          cellRenderer:NumberCellRenderer,
          editable:false,
        },
      ]
}

const getMatchProdutSiteColumns = ({language,invoice_header,getQtyToMatchEditable}) => {
    return [
        {
          field: 'receiving_reference_number',
          headerName: Helper.getLabel(language, 'receiving_no', 'Receiving No.'),
          editable:false,
          minWidth:200,
          filter:false,
          valueFormatter:(params)=>params.value || "-"
        },
        {
          field: 'received_qty',
          headerName: Helper.getLabel(language, 'matched_qty', 'Qty to Match'),
          minWidth:180,
          cellRenderer:NumberCellRenderer,
          filter:false,
          editable:false,
        },
        {
          field: 'matched_qty',
          headerName: Helper.getLabel(language, 'qty_to_match', 'Matched Qty'),
          minWidth:180,
          cellRenderer:NumberCellRenderer,
          filter:false,
          editable:(params)=>getQtyToMatchEditable(params)
        },
        {
          field: 'remaining_qty_for_invoice',
          headerName: Helper.getLabel(language, 'remaining_invoice_qty', 'Remaining Invoice Qty'),
          minWidth:180,
          cellRenderer:NumberCellRenderer,
          filter:false,
          editable:invoice_header?.purchaseorder_type===1
        },
      ]
}

const getInvoiceLineGridColumns = ({language,lineIdOptions,lineData,shipmentHeader,gridType}) => {
   return [
    {
      field: 'line_number',
      headerName: Helper.getLabel(
        language,
        'line_number',
        'Line number',
      ),
      minWidth: 200,
      editable: false,
    },
    {
      field: 'item_name',
      headerName: Helper.getLabel(language, 'source_line', 'Source line'),
      cellClass: 'rs_grid_simple_link rfq-link',
      minWidth: 240,
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values:lineIdOptions?.map((item) => {
            return {
              value: item.line_id,
              label: item.name,
            }
        }),
      },
      valueFormatter:(params)=>getLabelFromDropdown(params),
      editable: false,
    },
    {
      field: 'billingrule_id',
      headerName: Helper.getLabel(
        language,
        'billing_rule_line',
        'Billing rule line',
      ),
      minWidth: 280,
      inputId: 'billingrule_id',
      cellRenderer: (params)=>getBillingRuleDescription(params,lineData),
      editable: false,
    },
    {
      field: 'ordered_qty',
      headerName: Helper.getLabel(
        language,
        'order_qty',
        'Order percentage / quantity',
      ),
      filter:'agNumberColumnFilter',
      cellRenderer:NumberCellRenderer,
      minWidth: 220,
      editable: false,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},
    },
    {
      field: 'remaining_qty',
      headerName: Helper.getLabel(
        language,
        'remaining_qty',
        'Remaining percentage / quantity',
      ),
      minWidth: 280,
      cellRenderer:NumberCellRenderer,
      filter:'agNumberColumnFilter',
      valueFormatter:(params)=>{return params.data.received_qty>params.data.remaining_qty?"-":params.data?.remaining_qty - params.data?.received_qty},
      editable: false,
    },
    {
      field: 'received_qty',
      headerName: Helper.getLabel(
        language,
        'invoice_percentage',
        'Invoice percentage / quantity',
      ),
      minWidth: 280,
      filter:'agNumberColumnFilter',
      cellRenderer:NumberCellRenderer,
      editable: (params) =>
        params.node.data.billingrule?.item_detail_required !== true &&
        shipmentHeader?.document_status !== 1 &&
        gridType !== 'summary',
    },
    {
      field: 'line_amount',
      headerName: Helper.getLabel(
        language,
        'line_amount',
        'Line amount',
      ),
      cellRenderer:NumberCellRenderer,
      minWidth: 280,
      editable: false,
    }    
  ]
}

const getExistingInvoicesColumns = ({language}) => {
    return [
        {
          field: 'reference_number',
          headerName: Helper.getLabel(language, 'invoice_number', 'Invoice Number'),
          editable: false,
        },
        {
          field: 'document_number',
          headerName: Helper.getLabel(
            language,
            'source_document_number',
            'Source Document Number'
          ),
          editable: false,
        },
        {
          field: 'document_date',
          headerName: Helper.getLabel(language, 'document_date', 'Document Date'),
          editable: false,
          valueFormatter: (params) =>
            DatepickerFunctions.convertDateFromDataBase(params.value),
        },
        {
          field: 'document_status',
          headerName: Helper.getLabel(
            language,
            'document_status',
            'Document Status'
          ),
          editable: false,
          valueFormatter:(params)=>params.value===0?"Draft":"-"
        },
      ]
}

export {getInvoiceSummaryColumns,getMatchProductColumns1,getMatchProdutSiteColumns,getInvoiceLineGridColumns,getExistingInvoicesColumns}