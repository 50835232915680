import React, { Component } from 'react';
import $ from 'jquery';
import Helper from '../../inc/Helper';
import SimpleLoading from '../Loading/SimpleLoading';
import { checkIfArrayIsEmpty } from '../../inc/Validation';
class TreeviewItem extends Component {
    constructor(props){
        super(props);
        this.inSelected = '/images/icons/square-plus-blue.svg';
        this.iconBlank = '/images/icons/square-blank.svg';
        this.iconMinus = '/images/icons/square-with-minus.svg';
        this.iconPlus = '/images/icons/square-with-plus.svg';
        this.isAjaxLaod = this.props.isAjax ? true : false;
        this.state = {
            ajaxItems:[],
            isShowChild: this.props.item.showChild ? this.props.item.showChild :  false
        }
    }
    componentDidMount() {
            if (this.props.items && this.props.items.length === 1) {
            this.onIconClick(null, this.props.item, (newChildData) => {
                    this.setState({
                    isLoading: false,
                    isShowChild:true,
                    ajaxItems: newChildData
                });
            });
        }
    }
    setAjaxData(data){
        this.setState({
            isLoading:false,
            ajaxItems:data
        })
    }
    onClickItem(e,item){
        let that = this;
        if(that.props.onClick && typeof that.props.onClick ==='function'){
            let isExpanded = item.items.length <=0 ? true : that.state.isShowChild;
            let isLastChild = item.items.length <=0 ? true : false;
            that.props.onClick(item,{
                isExpanded:isExpanded,
                isLastChild: isLastChild
            })
        }
        $(e.target).closest('.rs_tree_view_section').find('.rs_treeview_controller').removeClass('active');
        $(e.target).closest('.rs_treeview_controller').addClass('active');
    }
    
    onIconClick(e,item){
        let that = this;
        this.setState({
            isShowChild:!this.state.isShowChild
        },function(){
            if(that.props.onIconClick && typeof that.props.onIconClick ==='function'){
                let isExpanded = item.items.length <=0 ? true : that.state.isShowChild;
                let isLastChild = item.items.length <=0 ? true : false;
                if(isExpanded){
                    if(that.isAjaxLaod){
                        that.setState({
                            isLoading:true
                        })
                    }
                }
                
                that.props.onIconClick(item,{
                    isExpanded:isExpanded,
                    isLastChild: isLastChild,
                    setData:(newChildData) => {that.setAjaxData(newChildData)}
                })
            }
        })
    }
    displayChildren(item){
        let items = !this.isAjaxLaod && item.items ? item.items :  this.state.ajaxItems;
        if(!item.items){
            return <></>
        }
        if(items.length>=1){
            return (
                <ul>
                    {items.map( (itemInner,key) => { return <TreeviewItem {...this.props} onIconClick={this.props.onIconClick} onClick ={this.props.onClick} item={itemInner} key={key} />} )}
                </ul>
                )
        }
        return <></>
    }
    getIconUrl = (item) => {
        if(item.items){
            if(item.items.length >= 1 && !this.state.isShowChild){
                return this.iconPlus; 
            }
            if(item.items.length >= 1 && this.state.isShowChild){
                return this.iconMinus; 
            }
        }
        
        return null;
    } 
    render() {
        let item = this.props.item;
        return (
            <li>
                <div className='rs_treeview_controller' >
                    { this.getIconUrl(item) !== null && <img className='icon_no_select' onClick={ e => this.onIconClick(e,item)} src={  this.getIconUrl(item) } alt={item.title}/> }
                    { !checkIfArrayIsEmpty(item.items) && <img className='icon_select' onClick={ e => this.onIconClick(e,item)} src={ this.inSelected } alt={item.title}/> }
                    { this.getIconUrl(item) === null && checkIfArrayIsEmpty(item.items) ? <span className='last-treeview-title-style' onClick={ e => { this.onClickItem(e,item)}}>{`${item.title}`}</span> : <span onClick={ e => { this.onClickItem(e,item)}}>{item.title}</span> }
                </div>
                {this.state.isLoading ? <div style={{width:'50px'}}><SimpleLoading/></div> :''}
                { this.state.isShowChild ? this.displayChildren(item) : ""}
            </li>
        );
    }
}

export default TreeviewItem;