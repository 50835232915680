import React, { Fragment, useCallback } from 'react';
import '../Email.css';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'; 
import Alert from '../../inc/Alert';
import ButtonNew from '../../inc/ButtonNew'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'; 
import AgGridNew from '../../grid/ag/ag-grid-new';
import { gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';

let gridApi = null;

const EmailArchive = (props) => { 
  const navigate = useNavigate();
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'name') { 
      navigate(`/edit-email-templates/${event.data.emaildetail_id}`)
    }
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delEmail_ID = element?.data?.emaildetail_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/emaildetail/${delEmail_ID}`)
      const rowNode = gridApi?.getRowNode(delEmail_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi.applyServerSideTransaction(transaction)
      }, 200)
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
   
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.email_template.email_template)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }
 
  const gridColHeaders = [
    {field:'name', headerName:'Name', editable: false, cellRenderer: (params) => gridLinkValueFormat(params), minWidth:220},
    {field:'subject', filter: 'agTextColumnFilter', headerName:'Subject of E-mail', editable: false, minWidth:220},
    {field:'mail_body', filter: false, sortable: false, headerName:'Body of E-mail', editable: false,minWidth:300},
  ];

  return ( 
    <div className='emails_template_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title='Email Templates'>
          <ButtonNew isDisable={!security.canCreate(frontendIds.setupForms.email_template.email_template_main_create_button)} isActive= {true} to="/create-email-template" title='New' />
        </NavigationHeder>

        <AgGridNew
          apiUrl={Settings.apiUrl+'/emaildetails'} 
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.email_template.email_template_main_delete_button)}
          pagination={false}
          columnDefs={gridColHeaders} 
          hideAddBtn={true}
          height={500}
          uniqueField={'emaildetail_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={(params) => gridApi = params.api} 
          gridId={"rs_email_template_list"}
        />
      </div>
    </div> 
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.email_template.email_template
} 

export default connect(mapStateToProps)(MasterComponentWraper( (ApplySecurityRoles(EmailArchive, SecurityOptions))))