import React from "react";
import InnerScreen from "../../components/Inner Screens/InnerScreen";
import { Link } from "react-router-dom";
import MasterComonent from "../../components/Backend/MasterComonent";
import Gui_id_list from "../../inc/Gui_id_list";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";
import Alert from "../../components/inc/Alert";
import MasterComponentWraper from "../../components/Backend/MasterComponentWraper";

const AdminScreen = ({ security }) => {
  if (!security?.canView(Gui_id_list.screens.administration_screen.main_administration_screen_view)) { 
    return ( 
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    );
  }

  const canViewSystemParameters = security?.canView(Gui_id_list.screens.administration_screen.main_administration_screen_system_params);
  const canViewOrganizationConfig = security?.canView(Gui_id_list.screens.administration_screen.main_administration_screen_org_config);

  const canViewHistoryLogs = security?.canView(Gui_id_list.screens.administration_screen.main_administration_screen_history_logs);

  return (
    <div className="modules_screen_lynkaz"> 
      <InnerScreen innerTitle="Administration">
        {(canViewSystemParameters || canViewOrganizationConfig) && (
          <div className="col-lg-3 col-md-4 col-6 gy-4">
            <div className="inner-page-header border-bottom">
              <h2>Setup</h2>
            </div>
            <div className="inner-page-routes">
              {canViewSystemParameters && (
                <Link to="/system-parameters">System Parameters</Link>
              )}
              {canViewOrganizationConfig && (
                <Link to="/organisation-configuration">Organization Configuration</Link>
              )}
            </div>
          </div>
        )}
        
        {canViewHistoryLogs && (
          <div className="col-lg-3 col-md-4 col-6 gy-4">
            <div className="inner-page-header border-bottom">
              <h2>Audit History</h2>
            </div>
            <div className="inner-page-routes">
              <Link to="/history-logs">History Logs</Link>
            </div>
          </div>
        )}
      </InnerScreen> 
    </div>
  );
};

const SecurityOptions = {
  gui_id: Gui_id_list.screens.administration_screen.main_administration_screen_view
};

export default (MasterComponentWraper(ApplySecurityRoles(AdminScreen, SecurityOptions), { masterClassName: 'no_padding_class' }));