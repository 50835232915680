import React from 'react'
import Popup from '../../../Popup/Popup'
import Helper from '../../../../inc/Helper'
import $ from 'jquery'
import Button from '../../../inc/Button'
import Settings from '../../../../inc/Settings'
import AgGridNew from '../../../grid/ag/ag-grid-new'

const RFQAuctionInnerPopup = ({ handleCloseModal, language, rfq_id }) => {
  const Columns_Headings = [
    {
      field: 'vendor_name',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'name', 'Name'),
      editable: false,
    },
    {
      field: 'bid_amount',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'amount', 'Amount'),
      editable: false,
      valueFormatter:(params)=>{return params?.data?.bid_amount != null && !isNaN(params?.data?.bid_amount) ? Helper.addThousandSeparator(Number(params?.data?.bid_amount).toFixed(2)) : params?.data?.bid_amount}
    },
    {
      field: 'rank',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'rank', 'Rank'),
      editable: false,
      valueFormatter:(params)=>params?.value?params.value:"-"
    },
  ]
  return (
    <>
      <Popup
        autoOpen={true}
        width='900px'
        onClose={() => {
          $('html').removeClass('rs_popup_open')
          handleCloseModal()
        }}
      >
        <>
          <div className='d-flex justify-content-between align-items-start'>
            <div>
              <h5 className='create_rfq_modal__title'>{Helper.getLabel(language, 'competitor_bidding_details', 'Competitor Bidding Details')}</h5>
            </div>
            <div className='addDocumentNumber-body-buttons justify-content-end gap-3'>
              <Button
                isActive={false}
                title={Helper.getLabel(language, 'close', 'Close')}
                className='black-btn-style'
                onClick={() => {
                  $('html').removeClass('rs_popup_open')
                  handleCloseModal()
                }}
              />
            </div>
          </div>

          <div className="mt-4">
            <div>
              <AgGridNew gridId={'competitor-bidding-details'} hideAddBtn={true} hideDeleteBtn={true} columnDefs={Columns_Headings} apiUrl={`${Settings.apiPurchaseRequisitionUrl}/view_auction_bid/${rfq_id}`} />
            </div>
          </div>
        </>
      </Popup>
    </>
  )
}

export default RFQAuctionInnerPopup
