import React, { Component } from 'react';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
/**
 * integrator
 * source_id
 */
class TotalWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            isShowTotalWidget:false,
            isLoading:false,
            total:null
        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    loadTotal(){
        let api = Api;
        api.setUserToken();
        let that = this;
        let url = this.props.isVersion ? Settings.apiPurchaseOrderUrl + `/versions_total/${this.props.source_id}/${this.props.integrator}/${this.props.version}` : Settings.apiPurchaseOrderUrl+`/total/${this.props.source_id}/${this.props.integrator}`
        this.setState({
            isShowTotalWidget: !this.state.isShowTotalWidget,
            isLoading:true,
            total:null
        })
        api.axios().get(url).then(res => {
            that.setState({
                isLoading:false,
                total:res.data.data
            })
        })
        
    }
    onCloseClick(){
        this.setState({
            isShowTotalWidget:false
        })
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target) && this.state.isShowTotalWidget) {
            this.onCloseClick();
        }
    }

    popupTotal(){
        let total = this.state.total;
        if(this.state.isLoading){
            return <div className='total_popup_widget'><SimpleLoading/></div>
        }
        return(
            <div className='total_popup_widget' ref={this.wrapperRef}>
                <h2>{Helper.getLabel(null,'total','Total')} <span onClick={ this.onCloseClick.bind(this)} style={{backgroundImage:'url("/images/icons/close-2.svg")'}}></span></h2>
                <ul>
                    <li><span>{Helper.getLabel(null,'line_amount','Line amount')}</span><span>{ Helper.formateCurrency(total?.line_amount)}</span></li>
                    <li><span>{Helper.getLabel(null,'line_discount','Line discount')}</span><span>{Helper.formateCurrency(total?.line_discount)}</span></li>
                    <li><span>{Helper.getLabel(null,'miscellaneous_charges','Miscellaneous charges')}</span><span>{Helper.formateCurrency(total?.miscellaneous_charges)}</span></li>
                    <li className='devider'></li>
                    <li><span>{Helper.getLabel(null,'subtotal','Subtotal')}</span><span>{Helper.formateCurrency(total?.subtotal)}</span></li>
                    <li className='devider'></li> 
                    <li><span>{Helper.getLabel(null,'Tax','Tax')}</span><span>{Helper.formateCurrency(total?.tax)}</span></li>
                    <li><span>{Helper.getLabel(null,'discount','Discount')}</span><span>{Helper.formateCurrency(total?.discount)}</span></li>
                    <li><span>{Helper.getLabel(null,'round_off','Round off')}</span><span>{Helper.formateCurrency(total?.round_off)}</span></li>
                </ul>
                <div className='tw_footer'><span>{Helper.getLabel(null,'total','Total')}</span><span>{Helper.formateCurrency(total?.total)}</span></div>
            </div>
        )
    }
    render() {
        return (
            <div className='total_popup_widget_wraper'>
                <Button title="Total" className="green-btn-style" icon="" onClick={ this.loadTotal.bind(this) } isDisable={this.props.isDisable}/>
                {
                    this.state.isShowTotalWidget ? this.popupTotal() : ""
                }
            </div>
        );
    }
}

export default TotalWidget;