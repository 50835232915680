import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MasterComponentWraper from '../Backend/MasterComponentWraper' 
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Helper from '../../inc/Helper'
import NavigationHeder from '../Navigations/NavigationHeder'
import SimpleDropdown from '../grid/ag/cellEditor/SimpleDropdown'
import { getLabelFromDropdown, getOptions, optionsKeyCreator } from '../grid/ag/commonFunctions/GridCommonFunctions'
import AgGridNew from '../grid/ag/ag-grid-new'
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../inc/Gui_id_list'
import Alert from '../inc/Alert'

/* validation -> Event is mandatory field */

let gridApi = null;
let AddBtn;
let transaction;

const NotificationEventsArchieve = ({security}) => {  
  const [events, setEvents] = useState([])
  const [emailTemplates, setEmailTemplates] = useState([])
  const [teams,setTeams]=useState([])

  let eventsCalled = false
  let emailTemplatesCalled = false

  useEffect(() => { 
    getEvents()
    getTeamsforEmail()
    getEmailTemplates()
  }, [])

  const getEvents = () => {
    if(eventsCalled) return;

    let api = Api
    eventsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/events`).then(function(res){
      if(res.data.status==="success"){
        setEvents(res?.data?.data?.map(element => {
          return {
            value: element.event_id,
            label: element.event_name
          }
        }))
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getTeamsforEmail = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/teamheader?dynamic_team_header=false`).then(function(res){
      if(res.data.status==="success"){
        setTeams(res?.data?.data?.map(element => {
          return {
            value: element.teamheader_id,
            label: element.name
          }
        }))
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getEmailTemplates = () => {
    if(emailTemplatesCalled) return;

    let api = Api
    emailTemplatesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/emaildetails').then(function(res){
      if(res.data.status==="success"){  
        setEmailTemplates(res?.data?.data?.map(element => {
          return {
            value: element.emaildetail_id,
            label: element.name ?? element.subject
          }
        })) 
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res)  
    })
  }
 
  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.rec_id) {
      updateNotificationsEvents(event)
    } else {
      postNotificationsEvents(event)
    }
  }

  const updateNotificationsEvents = async (event) => {
    try {
      let payload = {
        trigger_push_notification: event?.data?.trigger_push_notification ? true : false,
        trigger_email: event?.data?.trigger_email ? true : false,
        emailtemplate_id: event?.data?.emailtemplate_id ? parseInt(event?.data?.emailtemplate_id) : "",
        cc_members: event?.data?.cc_members ? parseInt(event?.data?.cc_members) : ""
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl + `/triggernotification/${event?.data?.rec_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const postNotificationsEvents = async (event) => {
    try {
      let payload = {
        event_id: parseInt(event?.data?.event_id),
        trigger_push_notification: event?.data?.trigger_push_notification ? true : false,
        trigger_email: event?.data?.trigger_email ? true : false,
        emailtemplate_id: event?.data?.emailtemplate_id ? parseInt(event?.data?.emailtemplate_id) : null,
        cc_members: event?.data?.cc_members ? parseInt(event?.data?.cc_members) : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl + '/triggernotification', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }
   
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_notifications_id = element?.data?.rec_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl + `/triggernotification/${delete_notifications_id}`)
      const rowNode = gridApi?.getRowNode(delete_notifications_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  /* Grid Column Headers */
  
  const gridColumnHeaders = [ 
    { 
      field: 'event_id', 
      headerName: 'Event*',
      editable: (params)=> !params?.data?.rec_id,
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180, 
      cellEditorParams: { values: events },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, events),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
    { 
      field: 'trigger_push_notification',
      filter: 'agSetColumnFilter',
      filterParams: { 
        values: [true, false], 
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'true' : 'false' 
      },
      cellRenderer: 'agCheckboxCellRenderer', 
      cellEditor: 'agCheckboxCellEditor', 
      cellRendererParams: { disabled: true },
      editable: true,
      minWidth: 300,
      valueGetter: (params) => {
        if(!params?.data?.trigger_push_notification || !params?.data){
          return false
        }
        else {
          return params.data?.trigger_push_notification
        }
      },
      headerName: 'Trigger push notification'
    },  
    { 
      field: 'emailtemplate_id', 
      headerName: 'E-mail templates',
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180, 
      cellEditorParams: { values: emailTemplates },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, emailTemplates),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
    { 
      field: 'trigger_email',
      filter: 'agSetColumnFilter',
      filterParams: { 
        values: [true, false], 
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'true' : 'false' 
      },
      cellRenderer: 'agCheckboxCellRenderer', 
      cellEditor: 'agCheckboxCellEditor', 
      cellRendererParams: { disabled: true },
      editable: true,
      minWidth: 300,
      valueGetter: (params) => {
        if(!params?.data?.trigger_email || !params?.data){
          return false
        }
        else {
          return params.data?.trigger_email
        }
      },
      headerName: 'Trigger e-mail'
    },
    { 
      field: 'cc_members', 
      headerName: 'Email cc members',
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180, 
      cellEditorParams: { values: teams },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, teams),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    },
  ];

  if(!security.canView(Gui_id_list.setupForms.notification_events.notification_events_main)){ 
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className='emails_template_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/setup' title='Notifications' hideMoreBtn={true} />

        <AgGridNew
          apiUrl={Settings.apiUrl + '/triggernotification'}
          pagination={false}
          columnDefs={gridColumnHeaders}
          hideAddBtn={!security.canCreate(Gui_id_list.setupForms.notification_events.notification_events_main_create_button)}
          hideDeleteBtn={!security.canDelete(Gui_id_list.setupForms.notification_events.notification_events_main_delete_button)}
          onRowValueChanged={onRowValueChanged}
          handleDeleteSelectedRows={DeleteHandler}
          height={500}
          onGridReady={(params) => gridApi = params?.api}
          handleAddButton={handleBtn}
          uniqueField={`rec_id`}
          gridId={'rs_notification_event_list'}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
 
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.notification_events.notification_events_main
};

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(NotificationEventsArchieve, SecurityOptions))));  