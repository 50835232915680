import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import Collapse from '../../../inc/Collapse'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Alert from '../../../inc/Alert'
import WorkFlowCommonHeaderAPICalls from '../../../Header/WorkflowCommonHeaderAPICalls'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import WorkFlowCommonHeader from '../../../Header/WorkFlowCommonHeader'
import WorkFlowOffCanvas from '../../../Header/WorkFlowOffCanvas'
import Api from '../../../../inc/Api'

//for render tabs data
const GoodsReceivingData = (props) => {
  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const innerTabs = [
    {
      title: Helper.getLabel(
        props.language,
        'general_information',
        'General Information'
      ),
      isActive: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        'additional_information',
        'Additional Information'
      ),
    },
  ]

  let generalData = [
    {
      id: 1,
      title: 'Document number',
      subtitle: props.goodsReceivingDetailsData?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle: props.document_status,
      className:props.document_status.toLowerCase()==='posted'?`status-style success-style`:`status-style draft-style`
    },
    {
      id: 3,
      title: 'Vendor code',
      subtitle: props.goodsReceivingDetailsData?.vendor_code,
    },
    {
      id: 4,
      title: 'Goods receipt number',
      subtitle: props.goodsReceivingDetailsData?.receiving_reference_number,
    },
    {
      id: 5,
      title: 'Vendor name',
      subtitle: props.goodsReceivingDetailsData?.vendor_name,
    },
    {
      id: 6,
      title: 'Accounting date',
      subtitle: Helper.formateDate(
        props.goodsReceivingDetailsData?.accounting_date
      ),
    },
    {
      id: 7,
      title: 'Vendor site',
      subtitle: props.goodsReceivingDetailsData?.vendorsite_name,
    },
    {
      id: 8,
      title: 'Document date',
      subtitle: Helper.formateDate(
        props.goodsReceivingDetailsData?.document_date
      ),
    }
  ]

  if(props?.usertype===1){
    generalData = generalData?.filter((item)=>item.title !== 'Accounting date')
  }

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <div className='parent_sidebar_cn'>
        <MasterSidebar>
          <div style={{ background: '#fff' }}>
            <NoteAttachmentHistory
              openCanvas={props?.setShowCanvas}
              openCurrentAssignees={props?.setShowCurrentAssignees}
              notesource_integrator={props?.shipment_id}
              hideHistory={props?.usertype==1? true : !props?.workflowActionHistory?.history?.length}
              historyArray={props?.workflowActionHistory} 
              disableAttachments={props?.disableAttachments}
              attachmentsource_integrator={props?.shipment_id}
              source_id={Settings.source_id.goods_receiving}
              height={props?.height}
              condition={props?.goodsReceivingDetailsData?.document_status===2}
            />
          </div>
        </MasterSidebar>

        {props?.showCanvas &&
          <WorkFlowOffCanvas
            isOpen={props?.showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={props?.setShowCanvas}
            source_id={Settings.source_id.goods_receiving}
            source_item_id={props?.shipment_id}
          />
        }

        {props?.showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={props?.showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={props?.setShowCurrentAssignees}
            source_id={Settings.source_id.goods_receiving}
            source_item_id={props?.shipment_id}
          />
        }
      </div>
    )
  }

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset="300"
      disableSticky={true}
      addComponentRight={addComponentRight}
    >
      <div className='common_scrollbar_styles' style={{ height: `${props?.height}px`, overflowX: 'hidden', overflowY: 'auto'}}>
        <div className="bidding_tab mt-3">
          <div className="mt-1 header-tab-rfq">
            <Tabs
              navs={innerTabs}
              disableSticky={true}
              scrollOffset="300"
              showAll={false}
            >
              <div className="mt-3 ps-3 pe-3">
                <div className="external-general-information">
                  <div className="purchase-order-inner-details general-details-responsive">
                    {generalData.map((item) => (
                      <div
                        className="row justify-content-between"
                        key={item.id}
                      >
                        <div className="col-sm-6">
                          <span className="purchase-order-external-title">
                            {item.title} :
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.goodsReceivingDetailsData} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
              </div>  
            </Tabs>
          </div>
        </div>
        <div className="mt-3 external-read-only-grid line-grid-btns-common">
          <LineViewComponent
            defaultValues={{ requester_id: null }}
            source_id={Settings.source_id.goods_receiving}
            line_source={
              Settings.source_id.goods_receiving
            }
            isEditable={false}
            viewType="shipment"
            shipmentType="good_receiving"
            userType={props.usertype}
            canShipmentEdit={false}
            header_id={props?.shipment_id}
            purchase_status={null}
            document_status={null}
            delivery_date={null}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={false}
            hideGeneral={false}
            hideSetup={true}
            hideBillingRule={true}
            alternate_line={false}
            lineGridTitle={Helper.getLabel(props.language, 'lines', 'Lines')}
            hideDistributionInnerTabs={true}
            hideAddBtn={true}
            hideDeleteBtn={true}
            hideMoreBtns={true}
          />
        </div>
      </div>
    </Tabs>
  )
}

const GoodsReceivingDetails = (props) => {
  const location = useLocation(); 
  const goodsReceivingApiCalls = new WorkConfirmationApiCalls()

  const [loading, setLoading] = useState(false);
  const [goodsReceivingDetails, setGoodsReceivingDetails] = useState({})
  const [documentStatuses, setDocumentStatuses] = useState([])
  const [documentStatus,setDocumentStatus]=useState('')

  const wrapperDiv = useRef(null);
  const [height, setHeight] = useState(0);

  const [workflowActionHistory, setWorkflowActionHistory] = useState([])
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({})
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)

  //for fetch params from route
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  
  const shipment_id = getSearchParam('shipment_id')
  const purchase_order_id = getSearchParam('purchaseorder_id')
  const workflow_instance_id = getSearchParam('instance_id')

  const navigate = useNavigate()
  
  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window); 
  }, [wrapperDiv?.current?.clientHeight, loading]);

  useEffect(() => {
    if (!shipment_id) return;
    getShipmentHeaderById();
  }, [])

  useEffect(() => { 
    if(!props?.auth?.user || !shipment_id) return;
    getWorkFlowCalls();
  }, [props?.auth?.user])
 
  useEffect(() => {
    if (goodsReceivingDetails && goodsReceivingDetails.document_status !== undefined) {
      const document_status = documentStatuses?.find(
        (option) => option.id === goodsReceivingDetails.document_status
      );
      setDocumentStatus(document_status?.name);
    }
  }, [goodsReceivingDetails, documentStatus]);

  const getShipmentHeaderById = async () => {
    setLoading(true);
    const shipment_header =
      await goodsReceivingApiCalls.getAllShipmentHeaderById(shipment_id)
    const documentStatuses = await goodsReceivingApiCalls.getAllShDocumentStatus()
    if (shipment_header) setGoodsReceivingDetails(shipment_header)
    if(documentStatuses) setDocumentStatuses(documentStatuses)
    setLoading(false);
  }

  const getWorkFlowCalls = async () => {   
    let source_item_id = shipment_id;
 
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();

    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.goods_receiving, source_item_id);
      
    const trigger = await workflowCalls.getWorkFlowTriggers();
    const mode = !checkIfArrayIsEmpty(trigger) && trigger.find(id => id.name == "Update")?.id;

    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.goods_receiving, props?.auth?.user?.tenant_id, mode);
    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props?.auth?.user?.tenant_id, Settings.source_id.goods_receiving, source_item_id);
    
    if(workflowActionHistory){ setWorkflowActionHistory(workflowActionHistory) }
    if(workflowExist) { setWorkflowExist(workflowExist) }
    if(eligibleWorkflow) { setWorkflowEligible(eligibleWorkflow) } 
  }

  const documentName = location?.state?.type==='release order' ? 'Release' : 'Purchase'

  const handleEditButton = () => {
    navigate(`/goods-receiving/edit/${shipment_id}`,{state:{prevPath:"/details"}})
  }

  const handleStatusChangeOfDocument = async () => { 
    let document_status_id = documentStatuses?.find(item => item.name == "Draft")?.id;

    setLoading(true);
    let api = Api;
    api.setUserToken();
    try {
      const res = await api.axios().put(`${Settings.apiPurchaseOrderUrl}/sh_header/${shipment_id}`, {
        status: document_status_id
      }); 
      if(res?.data?.status == "success"){
        Helper.alert(res?.data?.message);
        getShipmentHeaderById();
        setLoading(false);
      } 
    } catch (error) {
      getPopupMessageBasedOnStatus(error); 
      setLoading(false);
    }
  }

  let security = props?.security;
  let commonUserDocumentAccess = getSearchParam('common_user_access') === 'true' && location?.state?.prevRoutePath === '/dashboard' ? true : false;
  let isBtnDisableFromSecurity = !security?.canEdit(Gui_id_list.receiving.goods_receiving.goods_receiving_main_details_screen_button) && props?.auth?.user?.usertype === 0 && !commonUserDocumentAccess;
  let GRNFormVisible = security?.canView(Gui_id_list.receiving.goods_receiving.goods_receiving_main_details_screen) || commonUserDocumentAccess;
  let WorkflowBarVisible = workflowExist?.instance_exist && workflowEligible && documentStatus != "Approved";

  if(!GRNFormVisible && props?.auth?.user?.usertype === 0){ 
    return (
      <MasterComonent>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
      </MasterComonent>
    )
  };
  return (
    <div className={props.auth?.user?.usertype === 0
      ? 'work-confirmation-internal-screen external-detail-screens responsive-screen-style'
      : 'request_for_quotation_detail_screen external-detail-screens external-theme responsive-screen-style'}>
      <MasterComonent>
        <div className='scrollBarBlinkControl'> 
          <div ref={wrapperDiv}>
            <NavigationHeder
              // backUrl="/goods-receiving-list"
              hideMoreBtn={true}
              title={
                !purchase_order_id
                  ? `<span style="color:#757575;">${Helper.getLabel(
                      props.language,
                      'good_receiving_details',
                      'Goods Receiving List'
                    )} > </span> <span style="color:#313638;"> Goods Receiving Details # </span> ${
                      goodsReceivingDetails?.receiving_reference_number
                    }`
                  : `<span style="color:#757575;">${Helper.getLabel(
                      props.language,
                      'purchase_order_list',
                      `${documentName} Orders List`
                    )} ></span> <span style="color:#313638">${documentName} Order Details : ${location?.state?.document_number || purchase_order_id} > Goods Receiving List > </span>  Goods Receiving Details : ${
                      goodsReceivingDetails?.receiving_reference_number
                    }`
              }
            >
              <Button
                title={Helper.getLabel(
                  props.language,
                  'close',
                  'Close'
                )}
                className='black-btn-style'
                onClick={()=>{navigate(-1)}}
              />
              {workflowExist?.instance_details?.last_instance_status?.[4] === 'Rejected' && goodsReceivingDetails?.document_status === 3 && (
                <Button
                  onClick={handleStatusChangeOfDocument}
                  className="blue-btn-style"
                  title={Helper.getLabel(props.language, 'edit', 'Edit')}
                  isDisable={isBtnDisableFromSecurity || loading}
                />
              )}
              {goodsReceivingDetails?.document_status === 0 && (
                <Button
                  onClick={handleEditButton}
                  className="blue-btn-style"
                  title={Helper.getLabel(props.language, 'edit', 'Edit')}
                  isDisable={isBtnDisableFromSecurity}
                />
              )}
              <TotalWidget
                source_id={goodsReceivingDetails?.source_id}
                integrator={goodsReceivingDetails?.shipment_id}
              ></TotalWidget>
              <Button
                title={Helper.getLabel(
                  props.language,
                  'invoices',
                  'Invoices'
                )}
                onClick={()=>{navigate(`/invoice-list?receiving_id=${goodsReceivingDetails?.shipment_id}`)}}
                className='brown-btn-style'
              />
            </NavigationHeder>
          </div>

          { WorkflowBarVisible && !loading &&
            <div className='goods_receiving_workflow'>
              <WorkFlowCommonHeader
                instance_task_id={workflow_instance_id}
                workflowInstanceDetails={workflowExist?.instance_details}
                module_id={!workflow_instance_id ? Settings.source_id.goods_receiving : null}
                source_id={Settings.source_id.goods_receiving}
                source_item_id={shipment_id}
                SubmitWorkflowObjDetails={workflowExist?.workflow_name}
                module_item_id={!workflow_instance_id ? shipment_id : null}
                workflowInstanceExist={workflowExist?.instance_exist}
                workflowEligible={workflowEligible}
                workflowName={'Goods Receiving Workflow'}
                reloadFunction={() => {
                  getWorkFlowCalls();
                  getShipmentHeaderById();
                }}
              />
            </div>
          }

          <div className='tabs_default internal-tabs'>
            {
              <GoodsReceivingData
                height={WorkflowBarVisible ? (height - 105) : (height - 5)}
                language={props.language}
                shipment_id={shipment_id}
                document_status={documentStatus}
                goodsReceivingDetailsData={goodsReceivingDetails}
                setGoodsReceivingDetailsData={setGoodsReceivingDetails}
                usertype={props.auth?.user?.usertype}
                setShowCanvas={setShowCanvas}
                disableAttachments={isBtnDisableFromSecurity}
                showCanvas={showCanvas}
                workflowActionHistory={workflowActionHistory}
                setShowCurrentAssignees={setShowCurrentAssignees}
                showCurrentAssignees={showCurrentAssignees}
              />
            }
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.receiving.goods_receiving.goods_receiving_main_details_screen
};

export default connect(mapStateToProps) (ApplySecurityRoles(GoodsReceivingDetails, SecurityOptions))