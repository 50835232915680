import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../../inc/ActionsTypes';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Checkbox from '../../Forms/Checkbox';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import Popup from '../../Popup/Popup';
import ContactAddress from './ContactAddress';
import $ from 'jquery';
import RsDataLoader from '../../inc/RsDataLoader';
import FormValidator from '../../../inc/FormValidator';
import Collapse from '../../inc/Collapse';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import OverlayCommon from '../../RequestForQuotation/RFQDetailForm/HeaderView/OverlayCommon';
class AddressFormPopup extends Component {
    constructor(props){
        super(props);
        this.contactGrid = null;
        this.state = {
            dataExportable: this.props.dataExportable,
            address: this.props.address ? this.props.address : {},
            isLoading:true,
            selectedState:0,
            source_id: this.props.source_id ? this.props.source_id : null,
            addressSourc_id: this.props.addressSourc_id ? this.props.addressSourc_id : null,
            addresssourceintegrator: this.props.addresssourceintegrator ? this.props.addresssourceintegrator : null,
            contactsource_integrator: this.props.address ? this.props.address.address_id : null,
            selectedCountry:0,
            selectedCity: this.props.address ? parseInt(this.props.address?.city_id) : null,
            disableAllFields: this.props.disableFields ? this.props.disableFields : false
        }
        const language = this.props.language;
        let fieldConfig = [
            {name:'description',displayName:Helper.getLabel(language,'description','Description'),types:['Required']},
            {name:'purpose',displayName:Helper.getLabel(language,'purpose','Purpose'),types:['Required']},
            {name:'country_id',displayName:Helper.getLabel(language,'country_name','Country name'),types:['Required']},
            // {name:'city_id',displayName:Helper.getLabel(language,'city_name','City name'),types:['Required']},
            // {name:'state_id',displayName:Helper.getLabel(language,'state_name','State name'),types:['Required']},
            {name:'addressline_1',displayName:Helper.getLabel(language,'address_line','Address line 1'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig);
    }
    
    componentDidMount(){
        if(this.isForcePrimary()){
            this.setState(prevState => ({
                address: {
                    ...prevState.address,
                    is_primary: true
                }
            }));            
        }
        this.loadAddress();
        this.loadCountry();
        this.loadCityByCountryState(); 
    }
    isForcePrimary(){
        let isForcePrimary = false;
        if(this.props.adsGrid){
            let gridData = Helper.getDataFromGrid(this.props.adsGrid);
            if(gridData.length <=0 || this.props?.selectedNode?.node?.data?.gridId && gridData.length == 1 || !gridData){
                isForcePrimary= true;
            }
        }
        return isForcePrimary;
    }

    loadCountry (){
        if(this.props.locations.country.length <=0){
            let api = Api;
            let that = this;
            api.axios().get(Settings.apiUrl + '/country',{}).then(function(res){
                if(res.data.Status==="Success"){
                    that.props.setCountry(res.data.Message)
                    that.setExistingLocaitonToDropdown(res.data.Message);
                }
            })
        }else{
            this.setExistingLocaitonToDropdown(this.props.locations.country)
        }
    }
    setExistingLocaitonToDropdown(allCountry){
        let address = this.state.address;
        if(address.address_id){
           let selected_countryObj =  Helper.getCountryFromName( allCountry,address.country_name);
            this.loadStateByCountry(selected_countryObj.country_id)  
            this.setState({ 
                selectedCountry:selected_countryObj.country_id
            });
            document.getElementById('country_name').value = selected_countryObj.country_id;
        }
    }
    loadStateByCountry(countryId){
        let api = Api;
        let that = this;
        api.axios().get(Settings.apiUrl + '/state/'+countryId).then(function(res){
            if(res.data.Status==="Success"){
                that.props.setState(res.data.Message)
            }
        })
    }
    loadCityByCountryState(countryId = null,StateId = null){
        if(!countryId){
            let address = this.state.address;
            if(address.state_id && address.country_id){
                countryId = address.country_id;
                StateId = address.state_id;
            }
        }
        let api = Api;
        let that = this;
        api.axios().get(Settings.apiUrl + '/city/'+countryId+ '/'+StateId,{}).then(function(res){
            if(res.data.Status==="Success"){
                that.props.setCity(res.data.Message)
            }
        })
    }
    
    loadAddress(){
        this.setState({
            isLoading:false
        })
    }
    getData(){
        let addresssource_id = this.state.addressSourc_id;
        let source_id = this.state.source_id;
        let addresssource_integrator = this.state.addresssourceintegrator;
        if(addresssource_id){
            source_id = null;
        }
        
        let stateAddress = this.state.address;
        let data = {
            addresssource_id: addresssource_id,
            addresssource_integrator: addresssource_integrator,
            source_id       :source_id,
            description : stateAddress.description ? stateAddress.description : '',
            purpose     : document.getElementById('purpose').value,
            isprimary   :stateAddress.is_primary ? stateAddress.is_primary : false,
            isprivate   :stateAddress.is_private ? stateAddress.is_private : false,
            addressline1    :stateAddress.addressline_1 ? stateAddress.addressline_1 : '',
            addressline2    :stateAddress.addressline_2 ? stateAddress.addressline_2 : '',
            country_id  : document.getElementById('country_name').value,
            state_id    : document.getElementById('state_code').value,
            city_id : document.getElementById('city_code').value,
            latitude    : stateAddress.latitude ? stateAddress.latitude : null,
            longitude   : stateAddress.longitude ? stateAddress.longitude : null
            
        }
        return data;
    }
    getContactsListFromGrid(){
        let contactsList = [];
        if(this.contactGrid){
            this.contactGrid.api.forEachNode(contactNode => contactsList.push(contactNode.data));
        }
        return contactsList;
    }
    setDataAddressListComponentForExport(addressListGrid){

        // contact data
        let contactsList =  this.getContactsListFromGrid();
        //address details

        let newData = this.getData();
        let countryName = '';
        this.props.locations.country.map(cntry => {
            if(cntry.country_id == newData.country_id){
                countryName = cntry.country_short_name;
            }
        })
        let tempAddressObj = {
                ...newData,
                ContactSource_id: null,
                addressLine1:newData.addressline1,
                addressLine2: newData.addressline2,
                address_id: null,
                city_code: newData.city_id,
                country_name: countryName,
                description: newData.description,
                is_primary:newData.isprimary,
                is_private: newData.isprivate,
                latitude: newData.latitude,
                longitude: newData.longitude,
                purpose: newData.purpose,
                state_code: newData.state_id,
                contacts:contactsList,
                gridRowId: Date.now()
        }
        
        let allData = [];
        if(this.props.selectedNode){            
            addressListGrid.api.forEachNode(
                node => {
                    if(this.props.selectedNode.node.data.gridId === node.data.gridId){
                        allData.push(tempAddressObj)
                    }else{
                        if(tempAddressObj.is_primary){
                            allData.push({
                                ...node.data,
                                is_primary:false
                            })
                        }else{
                            allData.push(node.data)
                        }
                        console.log('');
                    }
                }
            );
        }else{
            addressListGrid.api.forEachNode(node => {
                if(tempAddressObj.is_primary){
                    allData.push({
                        ...node.data,
                        is_primary:false,
                    })
                }else{
                    allData.push(node.data)
                }
            });
            allData.push(tempAddressObj)
        }
        return allData;
        
    }
    onSaveHandler(e){
        let data = this.getData();
        let that = this;
        let validData = {
            ...data,
            addressline_1: data.addressline1,
            addressline_2: data.addressline2
        }
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage(this);
            return;
        }
        // if(this.contactGrid.contactComponent.hasErrors()){
        //     return;
        // }
        if(this.state.dataExportable){
            this.props.setGridData(this.setDataAddressListComponentForExport.bind(this))
            that.onPopupCloseHandler();
            return;
        }

        let api = Api;
        api.setUserToken();
        $('.rs_grid_btn_save').trigger('click');
        if(this.state.address.address_id){
            this.updateAddress(e);
            return;
        }
        // create new one;
        this.setState({
            isLoading:true
        })
        // set contast for new address
        let contactsList = this.getContactsListFromGrid().map( item => {return {
            ...item,
            isprimary : item.is_primary,
            isactive: item.is_active,
            mobile: item.mobile
        } });
        if(contactsList.length >=1){
            data.contact = {
                contacts:contactsList
            }
        }
        if(data.addresssource_id && parseInt(data.addresssource_id) >=1){
            data.addresssource_integrator = null;
            data.source_id = null;
        }
        data = {
            ...data,
            state_id: data.state_id==='' ? null : data.state_id,
            city_id: data.city_id === '' ? null : data.city_id
        }
        api.axios().post(Settings.apiUrl + '/add_address',data).then(function(res){
            if(res.data.status ==="Success"){
                Helper.alert(res.data.message)
                // close popup
                that.onPopupCloseHandler();
                setTimeout(()=>{
                    that.props.addressGridObj?.api?.refreshServerSide({purge:true})
                    that.props.addressGridObj?.api?.deselectAll()
                },300)
                if(that.props.onAddressCreate && typeof that.props.onAddressCreate ==='function'){
                    that.props.onAddressCreate(res.data)
                    setTimeout(()=>{
                        that.props.addressGridObj?.api?.refreshServerSide({purge:true})
                        that.props.addressGridObj?.api?.deselectAll()
                    },1000)
                }
            }else{
                Helper.alert(res.data.message,'error')
            }
            that.setState({
                isLoading:false
            })
        }).catch( (error) => {
            if(error.response){
                getPopupMessageBasedOnStatus(error);
            }
            that.setState({
                isLoading:false
            })
        })
    }
    updateAddress(e){
        let address_id = this.state.address.address_id;
        if(!address_id){
            Helper.alert("Address not found",'error');
            return;
        }
        let data = this.getData();
        let validData = {
            ...data,
            addressline_1: data.addressline1,
            addressline_2: data.addressline2
        }
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage(this);
            return;
        }
        let that = this;
        let api = Api;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        api.axios().put(Settings.apiUrl + '/address/'+address_id,data).then(function(res){
            if(res.data.status ==="success"){
                Helper.alert(res.data.message)
                that.props.addressGridObj?.api?.refreshServerSide({purge:true})
                that.props.addressGridObj?.api?.deselectAll()
                // close popup
                that.onPopupCloseHandler();
            }else{
                Helper.alert(res.data.message,'error')
            }
            that.setState({
                isLoading:false
            })
        }).catch( (error) => {
            if(error.response){
                getPopupMessageBasedOnStatus(error);
            }
            that.setState({
                isLoading:false
            })
        })
    }
    getFormatedCountry (){
        let countryList = this.props.locations.country.map( (item) => {
            return {
                value: item.country_id,
                label: item.country_short_name
            }
        })
        return countryList;
    }
    onCountryChangeHandler(e){
        this.setState({
            selectedCountry:e.target.value,
            selectedState:null,
            selectedCity:"",
        })
        this.props.setCity([])
        this.loadStateByCountry(e.target.value)
    }
    onStateChangeHandler(e){
        this.setState({
            selectedState:e.target.value,
            selectedCity:""
        })
        this.loadCityByCountryState( this.state.selectedCountry, e.target.value)
    }
    onAddressInputChanges(e){
        let oldAddress = this.state.address;
        this.setState({
            address: {
                ...oldAddress,
                [e.target.name] : e.target.value
            }
        })
    }
    onCheckboxChangeHandler(name,value){
        let oldAddress = this.state.address;
        this.setState({
            address: {
                ...oldAddress,
                [name] :value === "on"
            }
        })
    }
    // googleMapChange(data){
    //     let address = this.state.address;
    //     this.setState({
    //         address:{
    //             ...address,
    //             latitude: data.latitude,
    //             longitude: data.longitude,
    //         }
    //     })
    // }
    onPopupCloseHandler(){
        if(this.props.onPopupClose){
            this.props.onPopupClose(this.state.address);
        }
        $('html').removeClass('rs_popup_open');
    }
    onContactGridReady(event){
        this.contactGrid = event;
    }
    onContactCreate(data){
        if(!this.state.address.ContactSource_id){
            let api  = Api;
            let address_id = this.state.address.address_id;
            api.setUserToken();
            let newData ={
                contactsource_id:data.contact_source_id,
                description:null,
                purpose:null,
                isprimary:null,
                isprivate:null,
                addressline1:null,
                addressline2:null,
                country_id:null,
                state_id:null,
                city_id:null,
                latitude: null,
                longitude: null
            }
            api.axios().put(Settings.apiUrl + '/address/'+ address_id,newData).then(function(res){})
        }
    }
    displayContactGrid(){
        let language = this.props.language;
        if(this.state.address.address_id){
            return (
                <Collapse title={<>{Helper.getLabel(language,'contact_information','Contact Information')}{this.props.isContactTooltipNeeded && <OverlayCommon toolTipText={this.props.contactToolTipText} style={{marginLeft:"0.2rem",marginTop:"-0.1rem"}} />}</> } open={true}>
                    <ContactAddress disableRecords={this.state.disableAllFields} onContactCreate = {this.onContactCreate.bind(this)} canEdit={this.canCreateEditDelete()} onGridReady={ this.onContactGridReady.bind(this)} contactsource_id = {this.state.address.ContactSource_id } source_id = { Settings.source_id.address } address_id = {this.state.address.address_id } contactsource_integrator = { this.state.contactsource_integrator} type="address"/> 
                </Collapse>
            )
        }else{
            let contactsData = this.state.address.contacts ? this.state.address.contacts : []
            return (
                <Collapse title={<>{Helper.getLabel(language,'contact_information','Contact Information')}{this.props.isContactTooltipNeeded && <OverlayCommon toolTipText={this.props.contactToolTipText} style={{marginLeft:"0.2rem",marginTop:"-0.1rem"}} />}</>} open={true} className="contact-info-grid">
                    <ContactAddress disableRecords={this.state.disableAllFields} canEdit={this.canCreateEditDelete()} data={contactsData} onGridReady={ this.onContactGridReady.bind(this)} dataExportable={true} contactsource_id ={null} source_id ={Settings.source_id.address } address_id = {null} type="address"/>
                </Collapse>
            )
        }
    }
    canCreateEditDelete(){
        if(typeof this.props.canEdit === "undefined"){
            return true;
        }
        return (this.props.canEdit && this.props.canEdit === true);
    }
    isViewOnly(){
        return !this.canCreateEditDelete();
    }
    render() {
        let language = this.props.language;
        let countryList = this.getFormatedCountry();
        let stateList = this.props.locations.state.map( (item) => { return { value: item.state_id, label: item.name }});
        let cityList = this.props.locations.city.map( (item) => { return { value: item.city_id, label: item.city_name }});
        let adsPurposList = this.props.dataLoaderProps.purpose.purpose.map( item => {
            return {
                value: item.id,
                label: item.name
            }
        });
        
        let address = this.state.address;
        let purpose = address.purpose ? parseInt(address.purpose) : address.purpose;
        let countryId = address.country_id ? parseInt(address.country_id) : address.country_name;
        let selected_state_obj =  Helper.getStateFromName( this.props.locations.state,this.state.address.state_code);
        // let stateId = (this.state.dataExportable) ? parseInt(this.state.address.state_code ) : selected_state_obj.state_id; 
        let stateId = (this.state.address.state_id) ? parseInt(this.state.address.state_id ) : this.state.selectedState
        // let cityId = address.city_id ? parseInt(address.city_id) : Helper.getCityFromName(this.props.locations.city,address.city_code).city_id;
        let cityId = this.state.selectedCity
        let isPrimary = (this.state.address.is_primary === true);
        let isPrivate = (this.state.address.is_private === true);
        if(this.isForcePrimary()){
            isPrimary = true;
        }  
        return (
            <Popup onClose={ this.onPopupCloseHandler.bind(this)} autoOpen={true} width="1150px">  
                <div className='d-flex justify-content-end  mb-4 '>
                    {this.state.isLoading ? <SimpleLoading /> : <Button className='blue-btn-style' isDisable={this.isViewOnly() || this.state.disableAllFields} title={Helper.getLabel(language,'save_and_close','Save & Close')} onClick={ this.onSaveHandler.bind(this)} /> }
                </div>
                <div className='row mb-4'>
                    <div className='col-xs-12 col-sm-3 d-none'>
                        <Input required={true} disable={true}  name="address_id" label={Helper.getLabel(language,'address_id','Address Id')} value= {this.state.address.address_id}/>
                    </div>
                    <div className='col-xs-12 col-sm-3'>
                        <Input disable={this.state.disableAllFields} required={true} name="description" hasError={this.validator.hasError('description')} label={Helper.getLabel(language,'address_description','Address description')} value= {this.state.address.description} onChange = { e => this.onAddressInputChanges(e) } maxLength={100}/>
                    </div>
                    <div className='col-xs-12 col-sm-3'>
                        <DropDownInput isDisable={this.state.disableAllFields} required={true} id="purpose"  hasError={this.validator.hasError('purpose')}  options={adsPurposList} name="purpose" label={Helper.getLabel(language,'purpose','Purpose')} value= {purpose} placeHolder={Helper.getLabel(language,'select_purpose','Select purpose')}/>
                    </div>
                </div>
                <Collapse title={ Helper.getLabel(language,'address_details' , 'Address Details') } open={true}>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-3'>
                            <DropDownInput required={true} isDisable={this.state.disableAllFields} onChange = { this.onCountryChangeHandler.bind(this) } options={countryList} name="country_name" hasError={this.validator.hasError('country_id')}  id="country_name" value={ countryId } label={Helper.getLabel(language,'country_or_origin','Country / Origin')} placeHolder={Helper.getLabel(language,'select_country_origin','Select country / origin')} />
                        </div>
                        <div className='col-xs-12 col-sm-3'>
                            <DropDownInput required={false} isDisable={this.state.disableAllFields} options={stateList} onChange = { this.onStateChangeHandler.bind(this) }  name="state_code" id="state_code"  hasError={this.validator.hasError('state_id')}   value= {stateId} label={Helper.getLabel(language,'state','State')} reRenderRequired={true} placeHolder={Helper.getLabel(language,'select_state','Select state')}/>
                        </div>
                        <div className='col-xs-12 col-sm-3'>
                            <DropDownInput required={false} isDisable={this.state.disableAllFields} options={cityList}  id="city_code"  name="city_code"  hasError={this.validator.hasError('city_id')}  value= {cityId} label={ Helper.getLabel(language,'city' , 'City') } reRenderRequired={true} placeHolder={Helper.getLabel(language,'select_city','Select city')}/>
                        </div>
                        <div className='col-xs-12 col-sm-3'> </div>
                        <div className='col-xs-12 col-sm-3'>
                            <Input disable={this.state.disableAllFields} required={true}  hasError={this.validator.hasError('addressline_1')}  name="addressline_1" value= {this.state.address.addressline_1} label={ Helper.getLabel(language,'address_line_1' , 'Address line 1') } onChange = { e => this.onAddressInputChanges(e) } maxLength={100}/>
                        </div>
                        <div className='col-xs-12 col-sm-3'>
                            <Input disable={this.state.disableAllFields} name="addressline_2" value= {this.state.address.addressline_2}  label={ Helper.getLabel(language,'address_line_2' , 'Address line 2') } onChange = { e => this.onAddressInputChanges(e) } maxLength={100} />
                        </div>
                        <div className='col-xs-12 col-sm-3'>
                            <div className="row">
                                <div className='col-xs-12 col-sm-6'>
                                    <Checkbox disableWithoutIcon={this.isForcePrimary() || this.state.disableAllFields}  name="is_primary" isChecked = {isPrimary}  label={ Helper.getLabel(language,'primary' , 'Primary') } onChange = {  this.onCheckboxChangeHandler.bind(this) }/>
                                </div>
                                <div className='col-xs-12 col-sm-6'>
                                    <Checkbox disableWithoutIcon={this.state.disableAllFields} name="is_private" isChecked = {isPrivate} label={ Helper.getLabel(language,'private' , 'Private') } onChange = {  this.onCheckboxChangeHandler.bind(this) } />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Collapse>
                {
                    this.displayContactGrid()
                }
                {/* <Collapse title={ Helper.getLabel(language,'general_information' , 'General Information') } open={true}>
                    <GoogleMap latitude={this.state.address.latitude}  longitude={this.state.address.longitude}  onChange = { this.googleMapChange.bind(this) }/>
                </Collapse> */}
                

            </Popup>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language,
        locations:state.locations
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCountry: (countryList) => { dispatch({type:ActionTypes.SET_COUNTRY, payload:countryList})},
        setState: (states) => { dispatch({type:ActionTypes.SET_STATE, payload:states})},
        setCity: (cityList) => { dispatch({type:ActionTypes.SET_CITY, payload:cityList})}
    }
}
const loadData = {
    purpose:true
}
export default connect (mapStateToProps,mapDispatchToProps) ( RsDataLoader(AddressFormPopup,loadData) );