import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import store from '../../store'
class GridSegmentDistributionDetails {
    init(params) {
        this.params = params;
        this.eGui = document.createElement('div');
        this.eGui.innerHTML = 'Loading..';
        this.segmentList = store.getState().segments.segments;//firstHeaderObj.segmentList;
        this.segmentValueList = store.getState().segmentsValues.segmentsValues;
        this.loadDistributionDetails();
    }
    
    loadDistributionDetails(){
        let details = [];
        let distributedData = this.params.data.segmentcombination_value_details ? this.params.data.segmentcombination_value_details?.sort((a, b) => a.segment_sequence - b.segment_sequence) : [];
        distributedData.forEach( (dataItem,key) =>{
            let tempSegment = this.getSegmentFromId(dataItem.segment_id);
            let segmentValue = this.getSegmentValueFromId(dataItem.segment_value_id);
            let itemDetails = {
                segment:tempSegment.name,
                value:segmentValue.code,
                details:segmentValue.name,
            }
            details.push(itemDetails);
        } )
        
        this.eGui.innerHTML = this.getInnerHtml(details)
    }
    getSegmentFromId(segmentId){
        let output = {}
        this.segmentList.forEach( segment => {
            if(segment.segment_id  == segmentId){
                output = segment;
            }
        })
        return output;
    }
    getSegmentValueFromId(segmentValueId){
        let output = {}
        this.segmentValueList.forEach( segmentValue => {
            if(segmentValue.segmentvalue_id  == segmentValueId){
                output = segmentValue;
            }
        })
        return output;
    }
    getInnerHtml(detailsArray){
        let detailsBody = '';
        detailsArray.forEach( item => {
            detailsBody+= `<tr class="rs_item_row"><td>${item.segment}</td><td>${item.value}</td><td>${item.details}</td></tr>`;
        });
        return(
            `<table class="table rs_distribution_details_table">
                <thead>
                    <tr>
                        <th scope="col">Segments</th>
                        <th scope="col">Value</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                   ${detailsBody}
                </tbody>
            </table>
            `
        )
    }
    getGui() {
        return this.eGui;
    }

    refresh(params) {
        return false;
    }
}
export default GridSegmentDistributionDetails;