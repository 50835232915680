import React, { useEffect, useState, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import ButtonNew from '../../../inc/ButtonNew'
import SimpleLoading from '../../../Loading/SimpleLoading'
import RFQCommonApiCalls from '../../RFQCommonApiCalls'
import CreateRFQModal from './CreateRFQModal'
import Settings from '../../../../inc/Settings'
import Api from '../../../../inc/Api'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import getRFQColumnsHeadings from './RFQColumnHeadings'
import { customComparator, customDateComparator, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Alert from '../../../inc/Alert'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import { getBidStatus } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import RFQAuctionPopup from '../../../External Screens/RFQ Vendor Reply/RFQList/RFQAuctionPopup'

let gridApi = null

const RFQHeaderList = (props) => {
  const urlParams = new URLSearchParams(useLocation().search) 

  const rfq_status = urlParams?.get('status')
  const vendor_reply = urlParams?.get('vendor_reply') === 'true'

  const commonApiCalls = new RFQCommonApiCalls()
  const navigate = useNavigate()
  const [rfqStatusList, setRfqStatusList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showCreateRFQModal, setShowCreateRFQModal] = useState(false)
  // START:: Modal Data
  const [employee, setEmployee] = useState([])
  const [entity, setEntity] = useState([])
  const [sites, setSites] = useState([])
  const [warehouse, setWarehouse] = useState([])
  // const [securityLevel, setSecurityLevel] = useState([])
  const [quotationType, setQuotationType] = useState([])
  const [rfqTypes,setRfqTypes]=useState([])
  const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 })
  const [showAuctionModal, setShowAuctionModal] = useState(false)
  const [auctionData,setAuctionData]=useState([])
  // END:: Modal Data
  //Pagination States

  const api = Api

  const handleGridReady = (params) => {
    gridApi = params.api
  }

  const getQuotationType = () => {
    api
      .axios()
      .get(Settings.loginUrl + '/enum/RQ_type')
      .then((res) => {
        setQuotationType(res.data.data)
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getRFQTypes = () => {
    api
      .axios()
      .get(Settings.loginUrl + '/enum/RFQ_HeaderType')
      .then((res) => {
        let data = res.data?.data?.filter((item)=>item.value !== 1)
        let dropdownData = data?.map((item)=>{return {
           value:item.id,
           label:item.name
        }})
        setRfqTypes(dropdownData)
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }


  const getAllEmployee = () => {
    api
      .axios()
      .get(`${Settings.apiOrgUrl}/employee`)
      .then((res) => {
        if (res.data.status === 'success') {
          setEmployee(res.data?.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getAllEntity = () => {
    api
      .axios()
      .get(Settings.loginUrl + '/get_entities')
      .then((res) => {
        if (res.data.status === 'success') {
          setEntity(res.data?.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getSites = async () => {
    const res = await commonApiCalls.getAllSites()
    setSites(res)
  }

  const getWarehouse = async () => {
    const res = await commonApiCalls.getAllWarehouses()
    setWarehouse(res)
  }

  const getAllAuctionDetails= (rfq_id) => {
    api
      .axios()
      .get(`${Settings.apiPurchaseRequisitionUrl}/view_auction_bid/${rfq_id}`)
      .then((res) => {
        if (res.data.status === 'success') {
          setAuctionData(res.data?.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  {/* comment for future */}
  // const getSecurityLevel = () => {
  //   api
  //     .axios()
  //     .get(Settings.loginUrl + '/enum/RQ_securitylevel')
  //     .then((res) => {
  //       setSecurityLevel(res.data.data)
  //     })
  //     .catch((error) => {
  //       Helper.alert(error?.response?.data?.message, 'error')
  //     })
  // }

  const initModalData = () => {
    api.setUserToken()
    getSites()
    getWarehouse()
    // getSecurityLevel()
    getQuotationType()
    getRFQTypes()
    getAllEntity()
    getAllEmployee()
  }

  const cellHandler = (event) => {
    if(event.colDef.field==='document_number'){
    navigate(
      '/request-for-quotation/rfqdetailform?rfq_view_id=' +
        event?.data?.RFQ_id +
        '&return=rfqheaderlist',{state:props?.rfqType===2?{isPrevPath:true,rfqType:props.rfqType}:parseInt(rfq_status)===0?{status:rfq_status}:vendor_reply?{vendor_reply:true}:null}
    )
    }
    if (event.colDef.field === 'vendor_details' && event?.data?.rfq_type===2) {
      setShowAuctionModal(true)
      let scrollY = window.pageYOffset || document.documentElement.scrollTop
      let mouseY = event.event.clientY
      let absoluteMouseY = mouseY + scrollY
      let mouseX = event.event.clientX
      let absoluteMouseX = window.innerWidth - mouseX - 30
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX })
      getAllAuctionDetails(event?.data?.RFQ_id)
    }
  }

  const getAsyncData = async () => {
    try {
      const rfqStatusData = await commonApiCalls.getAllRfqStatus()
      if (rfqStatusData) {
        setIsLoading(false) 
        setRfqStatusList(rfqStatusData)}
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
      setIsLoading(false)
    }
  }


  const createNewRFQ = () => {
    setShowCreateRFQModal(true)
  }

  const closeAuctionModal = () => {
    setShowAuctionModal(false)
  }

  useEffect(() => {
    initModalData()
  }, [])
 
  useEffect(() => {
    getAsyncData()
  }, [])
 
  let columns = getRFQColumnsHeadings({
    language: props.language,
    customComparator: customComparator,
    rfqStatusList: rfqStatusList, 
    customDateComparator: customDateComparator,
  });

  if(vendor_reply){
    columns = columns.filter((item)=>item.headerName !== Helper.getLabel(props.language, "purchase_requisition_number", "Purchase Requisition number"))
    const newColumns = [
      {
         field: 'vendor_code',
         headerName: Helper.getLabel(props.language, 'vendor_code', 'Vendor code'),
         editable: false,
         minWidth: 150,
      },
    ];
    columns.splice(1, 0, ...newColumns);
  }

  if(props?.rfqType===2){
    columns = columns?.filter((item)=>item.headerName !== Helper.getLabel(props.language,'expiry_datetime', 'Expiry date and time' ))
     columns?.push({
      field:'vendor_details',
      headerName:Helper.getLabel(props.label,"vendor_details","Competitor bidding details"),
      editable:false,
      minWidth:210,
      cellRenderer: (params) => getBidStatus(false),
    })
  }

  let security = props?.security; 
  if(!security.canView(Gui_id_list.procure.request_for_quotation.request_for_quotation)){
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className='items_archive'>
      <div className='container-fluid'>
        <NavigationHeder
          backUrl='/procure'
          title={Helper.getLabel(
            props.language,
            `request_for_quotations_all`,
            `Request for Quotations - ${props?.title ? props?.title : rfq_status ? quotationType?.find((val) => val.id == rfq_status)?.name || 'All' : vendor_reply ? 'Vendor Reply' : 'All'}`
          )}
          hideMoreBtn={true}
        >
          <ButtonNew isDisable={!security.canCreate(Gui_id_list.procure.request_for_quotation.request_for_quotation_main_create_button)} onClick={createNewRFQ} />
        </NavigationHeder>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <div className='mb-5'>
            <AgGridNew
              apiUrl={Settings.apiPurchaseRequisitionUrl + (vendor_reply === true ? '/RFvendorreplyheader' : '/rfq_header')}
              additionalParams={rfq_status ? `&rfq_status=${rfq_status}` : `&rfq_type=${props.rfqType ?? 0}`}
              filterAdditionalParams={rfq_status ? `&rfq_status=${rfq_status}` : `&rfq_type=${props.rfqType ?? 0}`}
              pagination={true}
              isDisabledCheckbox={true}
              columnDefs={columns}
              onGridReady={handleGridReady}
              onCellClicked={cellHandler}
              hideDeleteBtn={true}
              hideAddBtn={true}
              height={500}
              gridId={'rfq-internal-list'}
            />
          </div>
        )}
      </div>

      {showCreateRFQModal && (
        <CreateRFQModal
          employee={employee}
          entity={entity}
          sites={sites}
          warehouse={warehouse}
          // securityLevel={securityLevel}
          quotationType={quotationType}
          language={props.language}
          setShowCreateRFQModal={setShowCreateRFQModal}
          currentRFQType={props.rfqType}
          rfqTypes={rfqTypes}
        />
      )}
      {showAuctionModal && (
        <RFQAuctionPopup
          closeModal={closeAuctionModal}
          data={auctionData}
          language={props.language}
          style={{
            top: popUpPosition.top,
            right: popUpPosition.right,
            width:'500px'
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.procure.request_for_quotation.request_for_quotation
}

export default connect(mapStateToProps) (((ApplySecurityRoles(RFQHeaderList, SecurityOptions))));