import { useEffect} from 'react';
import './TeamEvaluation.css';
import { calculateTotalScoringPercentages } from '../../../../inc/Validation';
import TechnicalEvaluationCommonCalls from './TechnicalEvaluationCommonCalls';

/* 

brief: This SliderRange is used for Scoring of respective vendors

props:-

  range_id : this id should always be unique, otherwise the component will do issues,

*/

let timeoutDeplayForAPIExecution;
const RangeSlider = ({state, dataName, range_id, out_of_total_score, out_of_total_score_technical, out_of_total_score_financial, currentComments, name, disableField, allVendorsList, scorecriterialine_id,sliderValue,onSliderChange}) => {

  useEffect(() => {
    const range = document.querySelector(`.${range_id}`);
    const rangeV = range.parentNode.querySelector('.range-value');

    const updateValue = () => {
      const newValue = Number((range.value - range.min) * 100 / (range.max - range.min));
      const newPosition = 46 - (newValue * 1);

      rangeV.innerHTML = `<span>${range.value}</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
    };

    updateValue();
    range.addEventListener('input', updateValue);

    return () => {
      range.removeEventListener('input', updateValue);
    };
  }, []);
  
  const handleChange = (newValue) => { 
    calculateTotalScoringPercentages(allVendorsList, out_of_total_score, out_of_total_score_technical, out_of_total_score_financial);
    onSliderChange(newValue)
 
    clearTimeout(timeoutDeplayForAPIExecution);
    timeoutDeplayForAPIExecution = setTimeout(() => (debouncedApiCallForRangeSliderValue(newValue)), 300);
  };

  const debouncedApiCallForRangeSliderValue = async (value) => { 
    if(!scorecriterialine_id) return;

    const technicalEvaluationCommonCall = new TechnicalEvaluationCommonCalls();
    await technicalEvaluationCommonCall.updateVendorScoringOfTechnicalEvaluation(scorecriterialine_id, { score: parseInt(value), comments: currentComments === "" || currentComments === " " ? "  " : currentComments });
  };
  
  return (
    <div className="range-wrap" name={name}>
      <span>0</span>
        <div className="range-value">
          <span>{state}</span>
        </div>
        <input disabled={disableField} data-type={dataName} name={`range_slider_scores_rfq`} className={range_id} type="range" min="0" max="10" value={sliderValue} step="1" onChange={(e) => handleChange(e.target.value) } />
      <span>10</span>
    </div>
  );
};

export default RangeSlider;