import React, { useEffect, useRef, useState } from "react";
import "./DashboardNav.css";
import PendingActions from "../Dashboard Navigation Components/PendingActions";
import MyRequest from "../Dashboard Navigation Components/MyRequest"; 
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import DashboardReq from "../Dashboard Request/DashboardReq";
import FixedNavigationHeder from "../../Navigations/FixedNavigationHeder";
import TabContentWraper from "../../Widgets/Tabs/TabContentWraper";
import DashboardInfo from "../Dashboard Information/DashboardInfo";  
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";

const DashboardNav = (props) => {
  let sectionName = "dash-board";

  const [sources, setSources] = useState([]);
  const [pendingActionsList, setPendingActionsList] = useState({});
  const [purchaseRequisitionList, setPurchaseRequisitionList] = useState({});
  const [openPRList, setOpenPRList] = useState({});
  const [workConfirmationList, setWorkConfirmationList] = useState({});
  const [vendorsList, setVendorsList] = useState({});
  const [invoicesList, setInvoicesList] = useState({});
  const [invoicesPendingList, setInvoicesPendingList] = useState({});
  const [invoicesOpenList, setInvoicesOpenList] = useState({});
  const [rfqList, setRFQList] = useState({});

  const [buyerUserRequests, setBuyerUserRequests] = useState([]);
  const [financeUserRequests, setFinanceUserRequests] = useState([]);
  const [commonUserRequests, setCommonUserRequests] = useState([]);
  const [sourcesViews, setSourcesViews] = useState([]);

  const [loadingSourceView, setLoadingSourceView] = useState(false);
  
  const [selectedSupNav, setSelectedSupNav] = useState("Header View");
  const [scrollDitection, setScrollDitection] = useState("down");

  const [height, setHeight] = useState(0);

  const myDivRef = useRef(null);

  let sourcesAPICalled = false;
  
  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef?.current?.clientHeight,scrollDitection]);

  useEffect(() => {
    getSystemVendors();
    getSystemInvoices();
    getSystemRFQs();
    getSystemOpenPR();
    getSystemOpenInvoices();
    getSystemPendingInvoices();
    getSystemSources();
  }, []);

  useEffect(() => {
    setBuyerUserRequests([
      { id: 1, module: 'Vendor Request', total_requests: vendorsList?.total_records },
      { id: 2, module: 'Urgent Request', total_requests: 0 },
      { id: 3, module: 'Open Purchase Requistion', total_requests: openPRList?.total_records },
      { id: 4, module: 'Open RFQ/RFI', total_requests: rfqList?.total_records }
    ]);
  }, [vendorsList, openPRList, rfqList]);

  useEffect(() => {
    setCommonUserRequests([
      { id: 1, module: 'My Purchase Requisitions', total_requests: purchaseRequisitionList?.total_records }
    ]); 
  }, [ purchaseRequisitionList]);

  useEffect(() => {
    setFinanceUserRequests([
      { id: 1, module: 'Pending Invoices', total_requests: invoicesPendingList?.total_records },
      { id: 2, module: 'Rejected Invoices', total_requests: 0 },
      { id: 3, module: 'Open Invoices', total_requests: invoicesOpenList?.total_records }
    ]);
  }, [invoicesList]);

  useEffect(() => { 
    updatePendingActionListings();
  }, [pendingActionsList]);

  const updatePendingActionListings = () => {
    setLoadingSourceView(true);

    if (!pendingActionsList?.task_source_count || pendingActionsList?.total_records === 0) {
      setLoadingSourceView(false);
      return;
    };
  
    const updatedPendingActionListings = pendingActionsList?.task_source_count?.map((item) => ({
      title: item?.module_id,
      numberOfRequests: item?.task_count,
      numberOfRequestsPercentage: ((item?.task_count / pendingActionsList?.total_records) * 100)?.toFixed(2)
    }));
  
    setLoadingSourceView(false);
    setSourcesViews(updatedPendingActionListings);
  };

  const getSystemVendors = () => { 
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
      if(res.data.status==="success"){   
        setVendorsList(res?.data);
      }
    }).catch((res) => {  
      setVendorsList({ total_records: 0 });
    })
  }

  const getSystemInvoices = () => {  
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+'/invoice_header',{}).then(function(res){
      if(res.data.status==="success"){   
        setInvoicesList(res?.data);
      }
    }).catch((res) => {  
      setInvoicesList({ total_records: 0 });
    })
  }

  const getSystemOpenInvoices = () => {  
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+'/invoice_header?document_status=0',{}).then(function(res){
      if(res.data.status==="success"){   
        setInvoicesOpenList(res?.data);
      }
    }).catch((res) => {  
      setInvoicesOpenList({ total_records: 0 });
    })
  }

  const getSystemPendingInvoices = () => {  
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+'/invoice_header?document_status=2',{}).then(function(res){
      if(res.data.status==="success"){   
        setInvoicesPendingList(res?.data);
      }
    }).catch((res) => {  
      setInvoicesPendingList({ total_records: 0 });
    })
  }

  const getSystemRFQs = () => {
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+'/rfq_header?purchase_status=0',{}).then(function(res){
      if(res.data.status==="success"){   
        setRFQList(res?.data);
      }
    }).catch((res) => {  
      setRFQList({ total_records: 0 });
    })
  }

  const getSystemOpenPR = () => {
    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+'/open_pr_header',{}).then(function(res){
      if(res.data.status==="success"){   
        setOpenPRList(res?.data);
      }
    }).catch((res) => {  
      setOpenPRList({ total_records: 0 });
    })
  }

  const getSystemSources = () => {
    if(sourcesAPICalled) return;
    sourcesAPICalled = true;

    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/source',{}).then(function(res){
      if(res.data.status==='success'){
        setSources(res?.data?.data
          ?.filter((item) => Settings.workflow_documents_sources.includes(item.source_id))
          ?.map((item) => {
          return {
            value: item.source_id,
            label: item.source_name
          }
        })) 
      } 
    })
  }

  let navs = [
    {
      title: Helper.getLabel(
        props.language,
        "pending_actions",
        "Pending Actions"
      ),
      data_section: "one",
      active_status: "active_tab",
    },
    {
      title: Helper.getLabel(props.language, "my_request", "My Request"),
      data_section: "two",
      active_status: "",
    },
  ];

  return (
    <div className="dashboard-nav-main scrollBarBlinkDashboard scrollBarBlinkControl ps-5 pe-5">
      <div ref={myDivRef}>
        <DashboardInfo />
        <DashboardReq 
          sources={sources}
          sourcesViews={sourcesViews} 
          buyerUserRequests={buyerUserRequests} 
          commonUserRequests={commonUserRequests} 
          financeUserRequests={financeUserRequests} 
          loadingSourceView={loadingSourceView}
          scrollDitection={scrollDitection} 
        />
        <FixedNavigationHeder
          sectionName={sectionName}
          navs={navs}
          selectedSupNav={selectedSupNav}
          scrollDitection={scrollDitection}
        />
      </div>

      <TabContentWraper
        height={height} 
        setScrollDitection={setScrollDitection}
        leftSideContent={
          <>
            <div
              className="lists_contant one"
              id={`${sectionName}Pending Actions`}
              style={{ position: "relative" }}
            >
              <PendingActions 
                sources={sources} 
                setPendingActionsList={setPendingActionsList} 
              />
            </div>
            <div 
              className="lists_contant two" 
              id={`${sectionName}My Request`}
              style={{ position: "relative" }}
            >
              <MyRequest
                setWorkConfirmationList={setWorkConfirmationList} 
                setPurchaseRequisitionList={setPurchaseRequisitionList} 
              />
            </div>
          </>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { 
    auth: state.auth, 
  };
};

export default connect(mapStateToProps)(DashboardNav);