import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { useDispatch } from 'react-redux'
import { gridIdActions } from '../../../store/grid-id'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import JoditEditor from 'jodit-react'
import {
  getLabelFromDropdown,
  getNewValue,
  getOptions,
  getStatusNameById,
  gridLinkValueFormat,
  gridValueFormatChip,
  optionsKeyCreator,
} from '../../grid/ag/commonFunctions/GridCommonFunctions'
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import Input from '../../Forms/Input'
import {
  checkIfArrayIsEmpty,
  getPopupMessageBasedOnStatus,
} from '../../../inc/Validation'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../inc/Alert'

let gridApi = null
const VendorsAnnouncement = (props) => {
  const [vendorType, setVendorType] = useState([])
  const [vendorStatus, setVendorStatus] = useState([])
  const [vendorDocumentStatus, setVendorDocumentStatus] = useState([])
  const [content, setContent] = useState(null)
  const [emailDetails, setEmailDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const allVendors = useRef([])
  const totalRecords = useRef('')
  const editor = useRef(null)

  const dispatch = useDispatch()

  let vendorTypesCalled = false
  let vendorStatusCalled = false
  let vendorDocumentStatusCalled = false

  const navigate = useNavigate()

  const resetCentralState = () => {
    dispatch(gridIdActions.VendorID({ payload: undefined }))
  }

  useEffect(() => {
    resetCentralState()
    get_vendors_types_data()
    VendorStatusList()
    VendorDocumentStatusList()
  }, [])

  const CellHandler = (event) => {
    if (event.colDef.field === 'code') {
      navigate(`/edit-vendor/${event.data.vendor_id}`)
    }
  }

  const VendorStatusList = () => {
    if (vendorStatusCalled) {
      return
    }
    let api = Api
    vendorStatusCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/enum/vendorstatus`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          setVendorStatus(res.data.data)
        }
      })
  }

  const VendorDocumentStatusList = () => {
    if (vendorDocumentStatusCalled) {
      return
    }
    let api = Api
    vendorDocumentStatusCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/enum/vendor_documentstatus`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          setVendorDocumentStatus(res?.data?.data)
        }
      })
  }

  const get_vendors_types_data = () => {
    if (vendorTypesCalled) {
      return
    }
    let api = Api
    vendorTypesCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/enum/vendortype', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let Vendor_List_API = []
          res.data.data.forEach((item) => {
            Vendor_List_API.push({
              value: item.id,
              label: item.name,
            })
          })
          setVendorType(Vendor_List_API)
        }
      })
  }

  const getVendorsData = async () => {
    const api = Api
    api.setUserToken()
    try {
      const response = await api
        .axios()
        .get(
          `${Settings.apiVendorUrl}/vendor?page_number=1&page_size=${totalRecords?.current}`,
        )
      if (response.data.status === 'success') {
        allVendors.current = response.data.data
        return true 
      } else {
        console.error('Failed to fetch vendor data: ', response.data.message)
        return false 
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error)
      return false 
    }
  }

  const onInputChangeHandler = (event) => {
    let value = event.target.value
    setEmailDetails((prevState) => {
      return { ...prevState, [event.target.name]: value }
    })
  }

  const sendAnnouncementToVendorApi = async () => {
    try {
      const isSelected = gridApi?.api?.getServerSideSelectionState()
      const selectedData = gridApi?.api?.getSelectedRows()

      let data = []
      setIsLoading(true)
      if (isSelected?.selectAll) {
        // Fetch all vendors
        const fetchSuccess = await getVendorsData()
        if (!fetchSuccess) {
          console.error(
            'Failed to fetch vendor data. Announcement call aborted.',
          )
          setIsLoading(false)
          return
        }
        data = allVendors?.current || []
      } else {
        data = selectedData || []
      }

      const payload = {
        subject: emailDetails?.subject,
        body: content,
        vendor_ids: data.map((item) => item.vendor_id),
      }

      let hasErrors = false
      const plainText = content?.replace(/<[^>]*>/g, "").trim();
      if (checkIfArrayIsEmpty(payload?.vendor_ids)) {
        Helper.alert('Please select at least one vendor.', 'error')
        hasErrors = true
      }
      if (!plainText) {
        Helper.alert('Please provide an email template.', 'error')
        hasErrors = true
      }
      if (!emailDetails?.subject) {
        Helper.alert('Please provide a subject for the email.', 'error')
        hasErrors = true
      }

      if (hasErrors) {
        setIsLoading(false)
        return
      }

      // Uncomment the API call below to make it functional
      const api = Api;
      api.setUserToken();
      await api.axios().post(`${Settings.apiVendorUrl}/announcement`, payload)
        .then((res) => {
          if (res.data.status === "success") {
            Helper.alert(res?.data?.message, "success");
            setIsLoading(false)
            gridApi?.api?.deselectAll()
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error);
          setIsLoading(false)
        });
    } catch (error) {
      console.error('An error occurred while sending the announcement:', error)
      setIsLoading(false)
    }
  }

  const sendAnnouncementToVendor = () => {
      Helper.confirmMe(() => {
      sendAnnouncementToVendorApi()
    }, "This action will automatically send an email to all the selected vendors.", null, "Do you want to trigger email to all the selected vendors?",550)
  }

  const handleFetchData = (response) => {
    allVendors.current = response?.data?.data
    totalRecords.current = response?.data?.total_records
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`,
      })),
      rowCount: response?.data?.total_records,
    }
  }

  const gridColumnHeaders = [
    {
      field: 'code',
      minWidth: 430,
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(props.language, '60020103', 'Code'),
      editable: false,
      cellRenderer: (params) => gridLinkValueFormat(params),
    },
    {
      field: 'name',
      minWidth: 430,
      filter: 'agTextColumnFilter',
      headerName: Helper.getLabel(props.language, '60030103', 'Name'),
      editable: false,
    },
    {
      field: 'users',
      sortable: false,
      minWidth: 430,
      headerName: Helper.getLabel(props.language, '60060103', 'User emails'),
      cellRenderer: (params) => gridValueFormatChip(params, false),
      editable: false,
    },
    {
      field: 'vendor_type',
      headerName: Helper.getLabel(props.language, '60040103', 'Vendor type'),
      minWidth: 430,
      cellEditorParams: {
        values: vendorType,
      },
      filter: 'agSetColumnFilter',
      valueFormatter: (params) => getLabelFromDropdown(params),
      filterParams: {
        values: (params) => getOptions(params, vendorType),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: (params) => params.value.label,
      },
      editable: false,
    },
    {
      field: 'category',
      sortable: false,
      filter: 'agTextColumnFilter',
      minWidth: 430,
      headerName: Helper.getLabel(props.language, '60050103', 'Category'),
      editable: false,
      cellRenderer: (params) => gridValueFormatChip(params, true, 'name'),
    },
    {
      field: 'invitation_code',
      sortable: false,
      minWidth: 180,
      headerName: Helper.getLabel(
        props.language,
        '60050103',
        'Invitation code',
      ),
      editable: false,
      valueFormatter: (params) => {
        return params.value || '-'
      },
    },
    {
      field: 'document_status',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) =>
        getStatusNameById(null, vendorDocumentStatus, true, params.value),
      filterParams: {
        values: (params) => getOptions(params, vendorDocumentStatus),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
      minWidth: 430,
      headerName: Helper.getLabel(
        props.language,
        '60030104',
        'Workflow status',
      ),
      editable: false,
    },
    {
      field: 'status_name',
      filter: 'agSetColumnFilter',
      cellRenderer: (params) =>
        getStatusNameById(null, vendorStatus, true, params.value),
      filterParams: {
        values: (params) => getOptions(params, vendorStatus),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
      minWidth: 430,
      headerName: Helper.getLabel(props.language, '60030105', 'Status'),
      editable: false,
    },
  ]

  let security = props?.security; 
  if(!security?.canView(Gui_id_list.masterData.vendor_announcements.vendor_announcements_listings_main)) {
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  };

  return (
    <div className="vendors_archive">
      <div className="container-fluid">
        <NavigationHeder
          backUrl="/master-data"
          title={Helper.getLabel(
            props.language,
            '60010103',
            `Vendors - Announcements`,
          )}
          hideMoreBtn={true}
        >
          <Button
            title={Helper.getLabel(
              props.language,
              'send_message',
              'Send Email',
            )}
            className="rfq_save_btn"
            onClick={sendAnnouncementToVendor}
          ></Button>
        </NavigationHeder>

        <AgGridNew
          apiUrl={`${Settings.apiVendorUrl}/vendor`}
          pagination={true}
          columnDefs={gridColumnHeaders}
          hideAddBtn={true}
          hideDeleteBtn={true}
          height={500}
          uniqueField={'vendor_id'}
          onCellClicked={CellHandler}
          fetchData={handleFetchData}
          onGridReady={(params) => (gridApi = params)}
          gridId={`rs_vendors_announcement_listings`}
        />
        <div>
          <Collapse title="Email Template" open={true} className="mt-4">
            <div className="d-flex gap-4">
              <div className="col-lg-3 mb-4">
                <Input
                  label={Helper.getLabel(props.language, 'subject', 'Subject')}
                  type="text"
                  name="subject"
                  required={true}
                  onChange={onInputChangeHandler}
                  value={emailDetails.subject}
                />
              </div>
            </div>

            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => setContent(newContent)}
            />
          </Collapse>
        </div>
      </div>
      {isLoading && <OverlayLoader isLogoCenter={true} isPopupInTab={true}  />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    language: state.language,
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_announcements.vendor_announcements_listings_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(VendorsAnnouncement, SecurityOptions))))