import React from 'react'
import Helper from '../../../../inc/Helper'
import Input from '../../../Forms/Input'
import NewInputDatePicker from '../../../Forms/NewInputDatePicker'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import Checkbox from '../../../Forms/Checkbox'
import Dropdown from '../../../Forms/Dropdown'
import OverlayCommon from './OverlayCommon'

const GeneralAuction = ({ rfqHeader, setRfqHeader, setUpdateRFQ, updateRFQ, handleOnChange, language, rfqHeaderKeyPressData, handleInputChange, isDisabled, automaticTimeTypes, auctionTypeList, hideFieldsForVendor }) => {
  const onInputCheckboxChangeHandler = (name, value) => {
    setRfqHeader((prevState) => {
      return {
        ...prevState,
        [name]: value === 'on' ? true : false,
      }
    })
    setUpdateRFQ((prevState) => {
      return {
        ...prevState,
        [name]: value === 'on' ? true : false,
      }
    })
  }

  return (
    <div className='generalInformation-advanced'>
      <div className='generalInformation-basic-grid'>
        <Dropdown
          label={<>{Helper.getLabel(language, 'auction_modal', 'Auction model')}
          <OverlayCommon toolTipText={'Select the auction type (English Auction, Japanese Auction, or Dutch Auction) based on your requirements.'} />
          </>}
          options={auctionTypeList?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            }
          })}
          placeHolder={`${Helper.getLabel(language, 'select_auction_modal', 'Select auction model')}`}
          id='auction_type'
          name='auction_type'
          onChange={handleOnChange}
          value={rfqHeader?.auction_type}
          isDisable={isDisabled}
          required={true}
        />
        <Input
          label={
            <>
              {Helper.getLabel(language, 'lower_cap', 'Lower cap')}
              <OverlayCommon toolTipText='Enter the minimum bid value allowed for this auction.' />
            </>
          }
          value={rfqHeaderKeyPressData?.auction_bid_from_range ? rfqHeaderKeyPressData?.auction_bid_from_range : rfqHeader?.auction_bid_from_range}
          name='auction_bid_from_range'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={parseInt(rfqHeader?.auction_type)!==0 && rfqHeader?.auction_type !== null?true:isDisabled}
          inputType={'number'}
        />
        <Input
          label={
            <>
              {Helper.getLabel(language, 'upper_cap', 'Upper cap')}
              <OverlayCommon toolTipText={'Enter the maximum bid value allowed for this auction.'} />
            </>
          }
          value={rfqHeaderKeyPressData?.auction_bid_to_range ? rfqHeaderKeyPressData?.auction_bid_to_range : rfqHeader?.auction_bid_to_range}
          name='auction_bid_to_range'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={isDisabled}
          required={parseInt(rfqHeader?.auction_type)===1}
          inputType={'number'}
        />
        <NewInputDatePicker
          label={<>{Helper.getLabel(language, 'auction_start_date_time', 'Auction start date time')}<OverlayCommon toolTipText={'Specify the date and time when the auction will start.'} /></>}
          id='auction_start_date_time'
          name='auction_start_date_time'
          timePicker={true}
          onChange={(date) => {
            setUpdateRFQ({
              ...updateRFQ,
              auction_start_date_time: DatepickerFunctions.convertDateTimeForDataBase(date),
            })
            setRfqHeader({
              ...rfqHeader,
              auction_start_date_time: date,
            })
          }}
          value={rfqHeader?.auction_start_date_time}
          minDate={new Date()}
          disable={isDisabled}
          empty={true}
          required={true}
        />
        <NewInputDatePicker
          label={<>{Helper.getLabel(language, 'auction_end_date_time', 'Auction end date time')}<OverlayCommon toolTipText={'Specify the date and time when the auction will end.'} /></>}
          id='auction_end_date_time'
          name='auction_end_date_time'
          timePicker={true}
          onChange={(date) => {
            setUpdateRFQ({
              ...updateRFQ,
              auction_end_date_time: DatepickerFunctions.convertDateTimeForDataBase(date),
            })
            setRfqHeader({
              ...rfqHeader,
              auction_end_date_time: date,
            })
          }}
          value={rfqHeader?.auction_end_date_time}
          minDate={new Date()}
          disable={isDisabled}
          empty={true}
          required={true}
        />
        {parseInt(rfqHeader?.auction_type)===2 ? <></>:<Input
          label={<>{Helper.getLabel(language, 'minimum_bid_decrement_percentage', 'Minimum bid decrement percentage')}<OverlayCommon toolTipText={'Set the minimum percentage by which vendors must reduce their bid value to submit a valid bid.'} /></>}
          value={rfqHeaderKeyPressData?.minimum_bid_decrement_prct ? rfqHeaderKeyPressData?.minimum_bid_decrement_prct : rfqHeader?.minimum_bid_decrement_prct}
          name='minimum_bid_decrement_prct'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={rfqHeader?.minimum_bid_decrement_amount !== null && rfqHeader?.minimum_bid_decrement_amount !== ""?true:isDisabled}
          inputType={'number'}
        />}
         {parseInt(rfqHeader?.auction_type)===2 ? <></>:<Input
          label={<>{Helper.getLabel(language, 'minimum_bid_decrement_amount', 'Minimum bid decrement amount')}<OverlayCommon toolTipText={'Set the minimum amount by which vendors must reduce their bid value to submit a valid bid.'} /></>}
          value={rfqHeaderKeyPressData?.minimum_bid_decrement_amount ? rfqHeaderKeyPressData?.minimum_bid_decrement_amount : rfqHeader?.minimum_bid_decrement_amount}
          name='minimum_bid_decrement_amount'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={rfqHeader?.minimum_bid_decrement_prct !== null && rfqHeader?.minimum_bid_decrement_prct !== ""?true:isDisabled}
          inputType={'number'}
        />}
        {parseInt(rfqHeader?.auction_type)!==0 && rfqHeader?.auction_type !== null && <Input
          label={<>{Helper.getLabel(language, 'current_auction_bid_range', 'Current auction bid range')}<OverlayCommon toolTipText={"The current bid amount that other vendors cannot exceed."} /></>}
          value={rfqHeaderKeyPressData?.current_auction_bid_to_range ? rfqHeaderKeyPressData?.current_auction_bid_to_range : rfqHeader?.current_auction_bid_to_range}
          name='current_auction_bid_to_range'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={true}
          inputType={'number'}
        />}
        {parseInt(rfqHeader?.auction_type)===2 && <Input
          label={<>{Helper.getLabel(language, 'Accepted price', 'Accepted price')}</>}
          value={rfqHeaderKeyPressData?.auction_acceptance_price ? rfqHeaderKeyPressData?.auction_acceptance_price : rfqHeader?.auction_acceptance_price}
          name='current_auction_acceptance_price'
          onChange={handleInputChange}
          onBlur={handleOnChange}
          disable={true}
          inputType={'number'}
        />}
        {hideFieldsForVendor ? (
          <></>
        ) : (
          <>
            <Dropdown
              label={parseInt(rfqHeader?.auction_type)===2?<>{Helper.getLabel(language,'decrease_time_type','Decrease time type')}<OverlayCommon toolTipText={"Select the time unit (Days, Hours, or Minutes) for the price decrease interval."} /></>:<>{Helper.getLabel(language, 'auction_time_extensions_type', 'Automatic time extension type')} <OverlayCommon toolTipText={'Select the unit (Minutes, Hours, or Days) for automatic auction time extensions'} /></>}
              placeHolder={parseInt(rfqHeader?.auction_type)===2?Helper.getLabel(language,'select_decrease_time_type','Select decrease time type'):`${Helper.getLabel(language, 'select_time_extension_type', 'Select automatic time extension type')}`}
              options={automaticTimeTypes?.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                }
              })}
              id='automatic_time_extensions_type'
              name='automatic_time_extensions_type'
              onChange={handleOnChange}
              value={rfqHeader?.automatic_time_extensions_type}
              isDisable={isDisabled}
              reRenderRequired={true}
            />
            <Input
              label={parseInt(rfqHeader?.auction_type)===2?<>{Helper.getLabel(language,'decrease_time','Decrease time')}<OverlayCommon toolTipText={"Specify the time interval for the bid price to decrease. (e.g., 1 hour)."} /></>:<>{Helper.getLabel(language, 'auction_time_extensions', 'Automatic time extensions')} <OverlayCommon toolTipText={'Specify the time extension duration to prevent last-minute sniping during the auction.'} /></>}
              id='automatic_time_extensions'
              name={'automatic_time_extensions'}
              onChange={handleInputChange}
              onBlur={handleOnChange}
              value={rfqHeaderKeyPressData?.automatic_time_extensions ? rfqHeaderKeyPressData?.automatic_time_extensions : rfqHeader?.automatic_time_extensions}
              disable={isDisabled}
              inputType={'number'}
            />
            {parseInt(rfqHeader?.auction_type)===2 && 
              <Input
                label={<>{Helper.getLabel(language, 'price_decrease_amount', 'Price decrease amount')}<OverlayCommon toolTipText={"The amount by which the bid price decreases after each time interval."} /></>}
                value={rfqHeaderKeyPressData?.price_decrease ? rfqHeaderKeyPressData?.price_decrease : rfqHeader?.price_decrease}
                name='price_decrease'
                onChange={handleInputChange} 
                onBlur={handleOnChange}
                disable={isDisabled}
                inputType={'number'}
              />}
            <Checkbox
              id='competitor_name_visible'
              type='checkbox'
              name='competitor_name_visible'
              disableWithoutIcon={isDisabled}
              label={<>{"Show competitors' names"} <OverlayCommon toolTipText={'Enable this to display the names of competitors who have submitted bids.'} /></>}
              isChecked={rfqHeader?.competitor_name_visible}
              onChange={onInputCheckboxChangeHandler}
            />
            <Checkbox
              id='competitor_bid_visible'
              type='checkbox'
              name='competitor_bid_visible'
              disableWithoutIcon={isDisabled}
              label={<>{"Show competitors' bids"} <OverlayCommon toolTipText={'Enable this to allow vendors to see the current lowest bid without revealing bidder details.'}/></>}
              isChecked={rfqHeader?.competitor_bid_visible}
              onChange={onInputCheckboxChangeHandler}
            />
            <Checkbox
              id='bid_ranking_visibility'
              type='checkbox'
              name='bid_ranking_visibility'
              disableWithoutIcon={isDisabled}
              label={<>{'Show own ranking'} <OverlayCommon toolTipText={"Enable this to display the ranking of the vendor's own bid compared to others."} /></>}
              isChecked={rfqHeader?.bid_ranking_visibility}
              onChange={onInputCheckboxChangeHandler}
            />
            <Checkbox
              id='others_bid_ranking_visibility'
              type='checkbox'
              name='others_bid_ranking_visibility'
              disableWithoutIcon={isDisabled}
              label={<>{"Show other competitors' rankings"} <OverlayCommon toolTipText={"Enable this to display the ranking of other competitors' bids."} /></>}
              isChecked={rfqHeader?.others_bid_ranking_visibility}
              onChange={onInputCheckboxChangeHandler}
            />
            <Checkbox
              id='competitors_bid_visibility'
              type='checkbox'
              name='competitors_bid_visibility'
              disableWithoutIcon={isDisabled}
              label={<>{"Show competitors' full bid details"} <OverlayCommon toolTipText={'Enable this to display the bid amounts, name, and rank submitted by competitors.'}/></>}
              isChecked={rfqHeader?.competitors_bid_visibility}
              onChange={onInputCheckboxChangeHandler}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default GeneralAuction
