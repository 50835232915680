import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const OverlayCommon = ({toolTipText,customStyle}) => {
  const baseStyle = {
    cursor: 'pointer',
  };

  const defaultStyle = {
    marginLeft: '0.5rem',
    marginRight: '-1.4rem',
  };

  return (
    <OverlayTrigger
      placement='top'
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip style={{ zIndex: 100003, position: 'fixed', top: '10px', left: '10px' }}>{toolTipText}</Tooltip>}
    >
      <img src='/images/icons/info-circle-logo.svg' alt='info-blue-logo' style={{ ...baseStyle, ...(customStyle || defaultStyle) }} />
    </OverlayTrigger>
  )
}

export default OverlayCommon
