import Helper from "../../../inc/Helper"
import Settings from "../../../inc/Settings"
import { commonThousandSeparator } from "../../../inc/Validation"
import AdvancedDropdown from "../../grid/ag/cellEditor/AdvancedDropdown"
import NumberCellEditor from "../../grid/ag/cellEditor/NumberCellEditor"
import SimpleDropdown from "../../grid/ag/cellEditor/SimpleDropdown"
import NumberCellRenderer from "../../grid/ag/cellRender/NumberCellRenderer"
import { getLabelFromDropdown } from "../../grid/ag/commonFunctions/GridCommonFunctions"

const getWorkConfirmationHeader = ({language,lineIdOptions}) => {
    return [
      {
        field: 'po_line_id',
        headerName: Helper.getLabel(language, 'source_line', 'Source line'),
        cellClass: 'rs_grid_simple_link rfq-link',
        minWidth: 200,
        headerTooltip:'Select the contract line related to the work being confirmed.',
        cellRenderer: 'agGroupCellRenderer',
        cellEditor:SimpleDropdown,
        editable: false,
        cellEditorParams:{
          values:lineIdOptions?.map((item) => {
            return {
              value: item.line_id,
              label: item.name,
            }
          }),
        },
        valueFormatter:(params)=>getLabelFromDropdown(params),
      },
      {
        field: 'billingrule.description',
        headerName: Helper.getLabel(
          language,
          'billing_rule_line',
          'Billing rule line',
        ),
        minWidth: 280,
        headerTooltip:'Choose the billing rule that applies to the selected contract line.',
        editable: false,
      },
      {
        field: 'ordered_qty',
        headerName: Helper.getLabel(language, 'order_qty', 'Order percentage / quantity'),
        minWidth: 220,
        editable: false,
        cellRenderer:NumberCellRenderer,
        headerTooltip:'Displays the percentage or quantity specified in the billing rule for this contract line.',
        filter:'agNumberColumnFilter'
      },
      {
        field: 'amount',
        headerName: Helper.getLabel(
          language,
          'order_line_amount',
          'Order line amount',
        ),
        minWidth: 220,
        cellRenderer:NumberCellRenderer,
        editable: false,
        valueFormatter:(params)=>params?.data?.billingrule?.amount,
        headerTooltip:'Shows the monetary value associated with the billing rule line."',
        filter:'agNumberColumnFilter',
      },
      {
        field: 'unit_price',
        headerName: Helper.getLabel(
          language,
          'unit_price',
          'Unit price',
        ),
        minWidth: 220,
        cellRenderer:NumberCellRenderer,
        editable: false,
        headerTooltip:'Displays the unit price specified for the selected contract line.',
        valueFormatter:(params)=>params?.data?.billingrule?.unit_price || 0,
        filter:'agNumberColumnFilter'
      },
      {
        field: 'received_qty',
        headerName: Helper.getLabel(
          language,
          'completion_percentage',
          'Completion percentage / quantity',
        ),
        cellRenderer:NumberCellRenderer,
        minWidth: 280, 
        valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},
        headerTooltip:'Indicates the percentage or quantity of work already completed for the selected contract line.',
        filter:'agNumberColumnFilter',
        editable: false,
      },
      {
        field: 'line_amount',
        headerName: Helper.getLabel(language, 'line_amount', 'Line amount'),
        minWidth: 150,
        cellEditor:NumberCellEditor,
        cellRenderer:NumberCellRenderer,
        filter:'agNumberColumnFilter',
        headerTooltip:'Shows the calculated amount based on the completion percentage or quantity entered by the vendor.',
        editable: false,
      },
      {
        field: 'remaining_qty',
        headerName: Helper.getLabel(
          language,
          'remaining_qty',
          'Remaining percentage / quantity',
        ),
        minWidth: 220,
        editable: false,
        cellRenderer:NumberCellRenderer,
        headerTooltip:'Displays the remaining percentage or quantity of work after the current completion entry.',
        filter:'agNumberColumnFilter'
      },
      {
        field:'discount_amount',
        minWidth:200,
        hide:true,
        headerName: Helper.getLabel(
          language,
          'discount_amount',
          'Discount amount',
        ),
        filter:'agNumberColumnFilter',
        editable:false
      },
      {
        field:'discount_percentage',
        minWidth:200,
        hide:true,
        headerName: Helper.getLabel(
          language,
          'discount_percentage',
          'Discount percentage',
        ),
        editable:false,
        filter:'agNumberColumnFilter',
      },
      {
        field:'invoice_qty_calculated',
        minWidth:200,
        hide:true,
        headerName: Helper.getLabel(
          language,
          'invoice_qty_calculated',
          'Invoice qty calculated',
        ),
        editable:false,
        filter:'agNumberColumnFilter',
      },
    ]  
}

const getWorkConfirmationLineColumns = ({language,lineIdOptions,shipmentHeader,getBillingRuleDescription,type,AddOREditButton,handleOnChangeDropdown,billingRuleOption,onCompletionQtyChange, onLineAmountChange}) => {
  return [
    {
      field: 'po_line_id',
      headerName: Helper.getLabel(language, 'source_line', 'Source line'),
      cellClass: 'rs_grid_simple_link rfq-link',
      minWidth: 200,
      headerTooltip:'Select the contract line related to the work being confirmed.',
      cellRenderer: 'agGroupCellRenderer',
      cellEditor:SimpleDropdown,
      cellEditorParams:{
        values:lineIdOptions?.map((item) => {
          return {
            value: item.line_id,
            label: item.name,
          }})
      },
      inputId: 'po_line_id',
      editable: (params) =>
        shipmentHeader?.document_status === 1 || params.data?.line_id
          ? false
          : true,
      valueFormatter:(params)=>getLabelFromDropdown(params),
    },
    {
      field: 'billingrule_id',
      headerName: Helper.getLabel(
        language,
        'billing_rule_line',
        'Billing rule line',
      ),
      minWidth: 280,
      inputId: 'billingrule_id',
      cellDataType:false,
      cellRenderer: getBillingRuleDescription,
      headerTooltip:'Choose the billing rule that applies to the selected contract line.',
      cellEditor: AdvancedDropdown,
      placeHolder: 'Please Select',
      editable: (params) =>
        shipmentHeader?.document_status === 1 || params.data?.line_id
          ? false
          : true,
      cellEditorParams: (params) => {
        return  {
          lynSettings: {
            dependentSelector: ['po_line_id', 'source_id'],
            apiUrl: Settings.apiPurchaseOrderUrl + '/get_billingrule',
            apiRequestMethod: 'get',
            onChange:(event)=>handleOnChangeDropdown(event,params),
            options: (apiResponse) => {
              billingRuleOption.current = apiResponse?.data?.data
              let output = apiResponse.data.data.map((item) => {
                return {
                  label: `${item.description}`,
                  value: item.billingrule_id,
                }
              })
              return output
            },
          },
        }
      }
    },
    {
      field: 'ordered_qty',
      headerName: Helper.getLabel(
        language,
        'order_qty',
        'Order percentage / quantity',
      ),
      minWidth: 220,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},
      cellRenderer:NumberCellRenderer,
      editable: false,
      headerTooltip:'Displays the percentage or quantity specified in the billing rule for this contract line.',
      filter:'agNumberColumnFilter'
    },
    {
      field: 'amount',
      headerName: Helper.getLabel(
        language,
        'order_line_amount',
        'Order line amount',
      ),
      minWidth: 220,
      editable: false,
      cellRenderer:NumberCellRenderer,
      valueFormatter:(params)=>params?.data?.billingrule?.amount,
      headerTooltip:'Shows the monetary value associated with the billing rule line."',
      filter:'agNumberColumnFilter'
    },
    {
      field: 'unit_price',
      headerName: Helper.getLabel(
        language,
        'unit_price',
        'Unit price',
      ),
      minWidth: 220,
      editable: false,
      cellRenderer:NumberCellRenderer,
      headerTooltip:'Displays the unit price specified for the selected contract line.',
      valueFormatter:(params)=>params?.data?.billingrule?.unit_price,
      filter:'agNumberColumnFilter'
    },
    {
      field: 'received_qty',
      headerName: type==="contract type invoice"?Helper.getLabel(
        language,
        'invoice_percentage',
        'Invoice percentage / quantity',
      ):Helper.getLabel(
        language,
        'completion_percentage',
        'Completion percentage / quantity',
      ),
      minWidth: 280,
      filter:'agNumberColumnFilter',
      positive:true,
      // minValue:0,
      cellEditor:NumberCellEditor,
      cellRenderer:NumberCellRenderer,
      onChange:onCompletionQtyChange,
      specialCharAllowed:false,
      // defaultValue:0,
      maxValue:(oldValue,params)=>{return params?.data?.remaining_qty},
      headerTooltip:'Indicates the percentage or quantity of work already completed for the selected contract line.',
      editable: (params) =>
         {return params.node.data.billingrule?.item_detail_required !== true && params?.data?.billingrule?.type !== 2 &&
        shipmentHeader?.document_status !== 1},
    },
    {
      field: 'line_amount',
      headerName: Helper.getLabel(language, 'line_amount', 'Line amount'),
      minWidth: 150,
      cellEditor:NumberCellEditor,
      cellRenderer:NumberCellRenderer,
      onChange:onLineAmountChange,
      filter:'agNumberColumnFilter',
      headerTooltip:'Shows the calculated amount based on the completion percentage or quantity entered by the vendor.',
      editable: (params)=>{return params?.data?.billingrule?.type !== 0 && params?.data?.billingrule?.type !== 2 && shipmentHeader?.document_status !== 1 },
    },
    {
      field:'discount_amount',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'discount_amount',
        'Discount amount',
      ),
      filter:'agNumberColumnFilter',
      editable:false
    },
    {
      field:'discount_percentage',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'discount_percentage',
        'Discount percentage',
      ),
      filter:'agNumberColumnFilter',
      editable:false
    },
    {
      field:'invoice_qty_calculated',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        language,
        'invoice_qty_calculated',
        'Invoice qty calculated',
      ),
      editable:false
    },
    {
      field: 'remaining_qty',
      headerName: Helper.getLabel(
        language,
        'remaining_qty',
        'Remaining percentage / quantity',
      ),
      minWidth: 220,
      editable: false,
      cellRenderer:NumberCellRenderer,
      headerTooltip:'Displays the remaining percentage or quantity of work after the current completion entry.',
      filter:'agNumberColumnFilter'
    },
    {
      field: 'line-details',
      headerName: '',
      minWidth: 160,
      cellRenderer: AddOREditButton,
      editable: false,
    },
  ]
}

export {getWorkConfirmationHeader,getWorkConfirmationLineColumns}