import React, { Component } from 'react';
import PoVersion from '../../components/Purchase Orders Internal/PoVersion/PoVersion';
import Helper from '../../inc/Helper';

class PoVersionPage extends Component {
    render() {
        return (
            <PoVersion
                title = {Helper.getLabel(null,'purchase_order_version','Purchase Order Version')}
                backUrl = {'/purchase-order/edit'}
                hideBillingRule={true}
            />
        );
    }
}

export default PoVersionPage;