import React, { useEffect } from 'react'
import AccountSecurity from '../../components/MyAccount/AccountSecurity'
import NavigationHeder from '../../components/Navigations/NavigationHeder'
import Button from '../../components/inc/Button'
import Header from '../../components/Header/Header'
import Api from '../../inc/Api'
import ActionTypes from '../../inc/ActionsTypes'
import { connect } from 'react-redux'
import Settings from '../../inc/Settings'

let formComponentObj = null

const ChangePassword = (props) => {
  useEffect(()=>{
    getUserDetails()
  },[])
  const getUserDetails = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/user_details')
      .then(function ({ data }) {
        if (data && data.Status === 'Success') {
          props.setUser(data.data)
        } else {
          props.logOut()
        }
      })
      .catch((error) => {
        props.logOut()
      })
  }
  const onSavBtnClick = (e) => {
    if (formComponentObj) {
      formComponentObj.onSaveHandler()
    }
  }
  const setFormComponentObj = (frmObj) => {
    formComponentObj = frmObj
  }

  return (
    <div className={'register-vendor-master-form app_master_area'}>
      <div className='sidebar_contact_wrapper'>
        <div className='contents_with_header'>
          <Header showHamburger={true} type='password' />
          <div className='rs_master_canvas_wraper'>
            <div className='rs_master_canvas px-0'>
              <div className='register-vendor-master-form change-password-screen rs_vertical_tabs my_account_page'>
                <div className='join_to_vendor bg-white change-password-main'>
                  <NavigationHeder title={'Change Password'} hideBackButton={true} hideMoreBtn={true}>
                    <Button title={'Save'} className='rfq_save_btn' onClick={onSavBtnClick}></Button>
                  </NavigationHeder>
                  <div className='d-flex gap-4'>
                    <div className='rs_vertical_tab_contents mt-4' style={{ width: '30%' }}>
                      <div>
                        <p className='mb-2 fw-bolder'>Password Policy</p>
                        <p>To ensure your account's security, your password must meet the following criteria:</p>
                        <div>
                          <ul className='ps-4'>
                            <li style={{ listStyle: 'disc' }}>Password should be atleast 12 alphanumeric characters.</li>
                            <li style={{ listStyle: 'disc' }}>Must contain atleast one Uppercase letter (A-Z).</li>
                            <li style={{ listStyle: 'disc' }}>Must contain atleast one special character (e.g., @, #, $, %, &).</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='mt-4 rs_vertical_tab_contents' style={{ width: '70%' }}>
                      <AccountSecurity setFormObj={setFormComponentObj} isFirstTime={true} logOut={()=>{props.logOut()}} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='clearfix'></div>
            </div>
          </div>

          <div className='clearfix'></div>
        </div>
        <div className='clearfix'></div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    user:state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOut:() => { dispatch({type:ActionTypes.SET_LOGOUT}) },
    setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
  }
}
export default connect(mapStateToProps,mapDispatchToProps) (ChangePassword);
