
import { connect } from 'react-redux'; 
import './MyRequest.css';
import Helper from '../../../inc/Helper';
import CollapseWithButton from '../../inc/CollapseWithButton';
import { Fragment, useEffect, useState } from 'react';
import Tabs from '../../Widgets/Tabs/Tabs'; 
import TaskActionDropdown from './TaskActionDropdown';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading'; 
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import { useNavigate } from 'react-router-dom'; 
import RFQCommonApiCalls from '../../RequestForQuotation/RFQCommonApiCalls';
import WorkConfirmationApiCalls from '../../External Screens/Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { getNewValue, getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator, purchaseStatusValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';

const MyRequest = ({language, auth, setWorkConfirmationList, setPurchaseRequisitionList}) => {
  const navigate = useNavigate();

  const commonApiCalls = new RFQCommonApiCalls();
 
  const [documentStatus, setDocumentStatus] = useState([])
 
  const [POStatuses, setPOStatuses] = useState([])
  const [purchaseRequisitionStatus, setPurchaseRequisitionStatus] = useState([])
  const [rfqStatusList, setRfqStatusList] = useState([]);
  const [typeDropdownData, setTypeDropdownData] = useState([]);
  const [invoiceStatuses, setInvoiceStatuses] = useState([]);
 
  const [rfxStatus, setRfXStatus] = useState('Type');
  
  const [loadingRFX, setLoadingRFX] = useState(false)
  const [loadingWorkConfirmations, setLoadingWorkConfirmations] = useState(true)
  const [loadingGRN, setLoadingGRN] = useState(true)
  const [loadingInvoices, setLoadingInvoices] = useState(true)
 
  let POStatus = false
  let PurchaseReqStatus = false

  useEffect(() => {  
    getAllWorkConfirmations()
    getRFQStatuses()   
    getPurchaseOrderStatuses()
    getPurchaseRequisitionStatuses()
  }, [])

  // My Request tabs Array
  let navs = [
    {title:`${Helper.getLabel(language,'purchase_requisition','Purchase Requisition')}`,forceLoadContents: true, isActive: true},
    {title:`${Helper.getLabel(language,'rfx','RFx')}`, forceLoadContents: true},
    {title:`${Helper.getLabel(language,'purchase_order','Purchase Order')}`, forceLoadContents: true}, 
    {title:`${Helper.getLabel(language,'release_order','Release Order')}`, forceLoadContents: true},
    {title:`${Helper.getLabel(language,'contracts_order','Contract Order')}`, forceLoadContents: true},
    {title:`${Helper.getLabel(language,'goods_receiving_note','Goods Receiving Note')}`, forceLoadContents: true},
    {title:`${Helper.getLabel(language,'work_confirmation','Work Confirmation')}`, forceLoadContents: true},  
    {title:`${Helper.getLabel(language,'invoice','Invoice')}`, forceLoadContents: true},
  ];
 
  const getRFQStatuses = async () => {
    try { 
      const rfqStatusData = await commonApiCalls.getAllRfqStatus(); 
      if (rfqStatusData) {
        let Updated_Data = []
        rfqStatusData.forEach((item) => {
          Updated_Data.push({
            value: item.id,
            label: item.name
          })
        })
        setTypeDropdownData(Updated_Data);
        setRfqStatusList(rfqStatusData);
      }
    } catch (error) {
      Helper.alert(
        error?.response?.status === 500
          ? Helper.getLabel(
              this.language,
              "something_went_wrong",
              "Something went wrong"
            )
          : error?.response?.data?.message,
        "error"
      );
    }
  };

  const getAllWorkConfirmations = async () => {
    const workConfirmationApiCalls = new WorkConfirmationApiCalls() 
 
    const documentStatus = await workConfirmationApiCalls.getAllShDocumentStatus()
    const documentStatusInv = await workConfirmationApiCalls.getAllInvoiceDocumentStatus()
 
    if (documentStatus) setDocumentStatus(documentStatus)
    if (documentStatusInv) setInvoiceStatuses(documentStatusInv) 
    setLoadingWorkConfirmations(false)
    setLoadingGRN(false)
    setLoadingInvoices(false)
  };
  
  const getPurchaseOrderStatuses = () => {
    if(POStatus) return;

    let api = Api
    POStatus = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/POHeaderPurchaseStatus',{}).then(function(res){
      if(res.data.status==="success"){
        setPOStatuses(res?.data?.data) 
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getPurchaseRequisitionStatuses = () => {
    if(PurchaseReqStatus) return;

    let api = Api
    PurchaseReqStatus = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/pr_document_status',{}).then(function(res){
      if(res.data.status==="success"){
        setPurchaseRequisitionStatus(res?.data?.data) 
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }
 
  const TypeDropdownOnChangeHandler = (status) => { 
    setLoadingRFX(true) 
    setRfXStatus(status)
    setTimeout(() => {setLoadingRFX(false)}, 200)
    //comment because api call is coming from useEffect
    // getRFXHeaders(status)
  }

  // modules
  const PRModule = () => {
    const CellHandler = (event) => {
      if (event.colDef.field === "document_number") {
        navigate(
          "/purchase-requisition/summary?pr_view_id=" +
            event.data.purchaserequistion_id +
            "&return=pr"
        );
      }
    }
  
    const gridColumnHeadersPR = [
      { field: "document_number", minWidth: 280, headerName: Helper.getLabel( language, "document_number", "Document number" ), cellRenderer: (params) => gridLinkValueFormat(params), editable: false },
      { field: "target_document_number", minWidth: 280, headerName: Helper.getLabel( language, "converted_document_number", "Converted document number" ), valueFormatter: (params) => params.value ? params.value : '-' , editable: false },
      { field: "description", minWidth: 280, headerName: Helper.getLabel(language,"description", "Description" ), editable: false },
      {
        field: 'document_status',
        minWidth: 200,
        headerName: Helper.getLabel(
          language,
          'document_status',
          'Document status',
        ),
        editable: false,
        cellRenderer: (params)=> getStatusNameById(params.value, purchaseRequisitionStatus),
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, purchaseRequisitionStatus),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name
        }
      },
      {field: 'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false },
      {field: 'current_assignee', valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false },
    ]

    const handleFetchDataPR = (response) => {
      if(typeof setPurchaseRequisitionList === 'function'){
        setPurchaseRequisitionList(response?.data);
      }
      return {
        rowData: response?.data?.data?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: response?.data?.total_records
      }
    }

    const handleErrorResponse = (res) => {
      if(typeof setPurchaseRequisitionList === 'function'){
        setPurchaseRequisitionList({ total_records: 0 });
      } 
    }
    return (
      <Fragment>
        <AgGridNew
          apiUrl={Settings.apiPurchaseRequisitionUrl+`/pr_header`}
          defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
          additionalParams={`&dashboard=true`}
          filterAdditionalParams={`&dashboard=true`}
          pagination={true} 
          fetchData={handleFetchDataPR}
          onErrorFromApi={handleErrorResponse}
          columnDefs={gridColumnHeadersPR} 
          onCellClicked={CellHandler}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_pr_list'}
        />
      </Fragment>
    )
  }
  
  const RFXModule = () => {
    const cellHandler = (event) => {
      if (event?.value && event?.colDef?.field === "document_number") {
        navigate(
          "/request-for-quotation/rfqdetailform?rfq_view_id=" +
            event?.data?.RFQ_id +
            "&return=rfqheaderlist"
        );
      }
    };

    const gridColumnHeaderRFX = [
      { field: "document_number", headerName: Helper.getLabel(language, "document_number", "Document number"), cellRenderer: (params) => gridLinkValueFormat(params), editable: false, minWidth: 250 },
      {
        field: 'rfq_status',
        headerName: Helper.getLabel(language, 'rfq_status', 'RFQ status'),
        cellRenderer:(params)=> getStatusNameById(params.value, rfqStatusList),
        editable: false,
        minWidth: 150,
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, rfqStatusList),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name 
        }
      },
      { field: "publish_datetime", valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', headerName: Helper.getLabel(language, "publish_date_time", "Publish date time"), valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-", editable: false, minWidth: 250 },
      { field: "delivery_date", valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', headerName: Helper.getLabel(language, "delivery_date", "Delivery date"), valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) ?? "-", editable: false, minWidth: 250 },
      { field: "expiry_datetime", valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', headerName: Helper.getLabel(language, "expiry_datetime", "Expiry date time"), valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-", editable: false, minWidth: 250 },
      { field: 'current_stage',valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false },
      { field: 'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false },
    ];
    
    return (
      <Fragment> 
        <div className='d-flex justify-content-end'> <TaskActionDropdown APIData={typeDropdownData} onChangeHandler={TypeDropdownOnChangeHandler} /> </div>
        {loadingRFX ? (
          <div style={{ height: '500px', textAlign: 'center' }}>
            <SimpleLoading />
          </div>
          ) : 
          (
            rfxStatus === 'Type' ? (
              <div style={{ height: '500px', textAlign: 'center' }}></div>
            ) 
            : 
            (
              <AgGridNew
                apiUrl={Settings.apiPurchaseRequisitionUrl + `/rfq_header`}
                defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
                additionalParams={`&rfq_status=${rfxStatus}&dashboard=true`}
                filterAdditionalParams={`&rfq_status=${rfxStatus}&dashboard=true`}
                pagination={true}
                columnDefs={gridColumnHeaderRFX}
                onCellClicked={cellHandler}
                hideDeleteBtn={true}
                hideAddBtn={true}
                height={500}
                gridId={'rs_rfx_list'}
              />
            )
        )}
      </Fragment>
    )
  }

  const POModule = () => {
    const gridColumnHeadersPO = [
      {field:'document_number', minWidth: 280, headerName:Helper.getLabel(language,'document_number',"Document number"), editable: false,cellRenderer: (params) => gridLinkValueFormat(params) },
      {field:'vendor_code', minWidth: 300, headerName:Helper.getLabel(language,'vendor_code',"Vendor code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},       
      {field:'purchase_requisition_number', sortable: false, minWidth: 300, headerName:Helper.getLabel(language,'purchase_requisition_number',"Purchase requisition number"), valueFormatter: (params) => params?.value ? params?.value : '-', editable: false }, 
      {field:'rfq_document_number', sortable: false, valueFormatter: (params) => params?.value ? params?.value : '-', minWidth: 300, headerName:Helper.getLabel(language,'rfq_document_number',"RFQ document number"), editable: false },
      {field:'vendor_name', minWidth: 300, headerName:Helper.getLabel(language,'vendor_name',"Vendor name"), editable: false },
      {
        field: 'purchase_status',
        headerName: Helper.getLabel(language, 'purchase_status', 'Purchase status'),
        minWidth: 280,
        cellRenderer: (params)=> purchaseStatusValueFormat(params, POStatuses),
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, POStatuses),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: (params) => params.value.name,
        }
      },
      {field:'description', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 480, headerName:Helper.getLabel(language,'description', "Description"), },
      {field:'current_stage',valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false},
      {field:'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false},
      {field:'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(language,'document_date', "Document date"), },
      {field:'creationtime', valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(language,'creationdatetime',"Creation date time"), editable: false},  
      {field:'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'attention_information', editable:false, hide:true},
      {field:'delivery_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'delivery_name', editable:false, hide:true},
      {field:'discount_amount', editable:false, hide:true},
      {field:'discount_percentage', editable:false, hide:true},
      {field:'total_amount', editable:false, hide:true}
    ]

    const CellHandlerPO = (event) => {
      if(event.colDef.field === 'document_number') { 
        navigate(`/purchase-order/edit/${event.data.purchase_order_id}`)
      }
      if(event.colDef.field === 'vendor_code') { 
        navigate(`/edit-vendor/${event.data.vendor_id}`,{ state: {prevPath:'/dashboard'} })
      }
    }
    return (
      <div className='grid_wrapper'>
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl+`/PO_header`} 
          defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
          additionalParams={`&type=0&dashboard=true`}
          filterAdditionalParams={`&type=0&dashboard=true`}
          pagination={true} 
          columnDefs={gridColumnHeadersPO} 
          onCellClicked={CellHandlerPO}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_po_list'}
        />
      </div>
    )
  }
  
  const ROModule = () => {
    const gridColumnHeadersRO = [
      {field:'document_number', minWidth: 280, headerName:Helper.getLabel(language,'document_number',"Document number"), editable: false,cellRenderer: (params) => gridLinkValueFormat(params) },
      {field:'vendor_code', minWidth: 300, headerName:Helper.getLabel(language,'vendor_code',"Vendor code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},       
      {field:'purchase_requisition_number', sortable: false, valueFormatter: (params) => params?.value ? params?.value : '-', minWidth: 300, headerName:Helper.getLabel(language,'purchase_requisition_number',"Purchase requisition number"), editable: false }, 
      {field:'rfq_document_number', sortable: false, valueFormatter: (params) => params?.value ? params?.value : '-', minWidth: 300, headerName:Helper.getLabel(language,'rfq_document_number',"RFQ document number"), editable: false },
      {field:'agreement_document_number', sortable: false, valueFormatter: (params) => params?.value ? params?.value : '-', minWidth: 300, headerName:Helper.getLabel(language,'agreement_document_number',"Agreement document number"), editable: false },
      {field:'vendor_name', minWidth: 300, headerName:Helper.getLabel(language,'vendor_name',"Vendor name"), editable: false },
      {
        field: 'purchase_status',
        headerName: Helper.getLabel(language, 'purchase_status', 'Purchase status'),
        minWidth: 280,
        cellRenderer: (params)=> purchaseStatusValueFormat(params, POStatuses),
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, POStatuses),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: (params) => params.value.name,
        }
      },
      {field:'description', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 480, headerName:Helper.getLabel(language,'description', "Description"), },
      {field:'current_stage',valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false},
      {field:'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 280, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false},
      {field:'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(language,'document_date', "Document date"), },
      {field:'creationtime', valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(language,'creationdatetime',"Creation date time"), editable: false},  
      {field:'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'attention_information', editable:false, hide:true},
      {field:'delivery_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'delivery_name', editable:false, hide:true},
      {field:'discount_amount', editable:false, hide:true},
      {field:'discount_percentage', editable:false, hide:true},
      {field:'total_amount', editable:false, hide:true}
    ]

    const CellHandlerRO = (event) => {
      if(event.colDef.field === 'document_number') { 
        navigate(`/release-order/edit/${event.data.purchase_order_id}`)
      }
      if(event.colDef.field === 'vendor_code') { 
        navigate(`/edit-vendor/${event.data.vendor_id}`,{ state: {prevPath:'/dashboard'} })
      }
    };
 
    return (
      <div className='grid_wrapper'>
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl+`/PO_header`} 
          defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
          additionalParams={`&type=2&dashboard=true`}
          filterAdditionalParams={`&type=2&dashboard=true`}
          pagination={true} 
          columnDefs={gridColumnHeadersRO} 
          onCellClicked={CellHandlerRO}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_ro_list'}
        />
      </div>
    )
  }

  const ContractModule = () => {
    const gridColumnHeadersCO = [
      {field:'document_number', minWidth: 280, headerName:Helper.getLabel(language,'document_number',"Document number"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
      {field:'vendor_code', minWidth: 300, headerName:Helper.getLabel(language,'vendor_code',"Vendor code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params) },
      {field:'purchase_requisition_number', sortable: false, valueFormatter: (params) => params?.value ? params?.value : '-', minWidth: 300, headerName:Helper.getLabel(language,'purchase_requisition_number',"Purchase requisition number"), editable: false }, 
      {field:'rfq_document_number', sortable: false, valueFormatter: (params) => params?.value ? params?.value : '-', minWidth: 300, headerName:Helper.getLabel(language,'rfq_document_number',"RFQ document number"), editable: false },  
      {field:'vendor_name', minWidth: 300, headerName:Helper.getLabel(language,'vendor_name',"Vendor name"), editable: false },
      {
        field: 'purchase_status',
        headerName: Helper.getLabel(language, 'purchase_status', 'Purchase status'),
        minWidth: 280,
        cellRenderer: (params)=> purchaseStatusValueFormat(params, POStatuses),
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, POStatuses),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: (params) => params.value.name,
        }
      }, 
      {field:'description', minWidth: 480, headerName:Helper.getLabel(language,'description', "Description"), },
      {field:'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false },
      {field:'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false },
      {field:'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(language,'document_date', "Document date"), },
      {field:'creationtime', valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 280, headerName:Helper.getLabel(language,'creationdatetime',"Creation date time"), editable: false},  
      {field:'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'attention_information', editable:false, hide:true},
      {field:'delivery_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', editable:false, hide:true},
      {field:'delivery_name', editable:false, hide:true},
      {field:'discount_amount', editable:false, hide:true},
      {field:'discount_percentage', editable:false, hide:true},
      {field:'total_amount', editable:false, hide:true}
    ]
    
    const CellHandlerPO = (event) => {
      if(event.colDef.field === 'document_number') { 
        navigate(`/purchase-order/edit/${event.data.purchase_order_id}`)
      }
      if(event.colDef.field === 'vendor_code') { 
        navigate(`/edit-vendor/${event.data.vendor_id}`, {state: {prevPath:'/dashboard'}})
      }
    }

    return (
      <div className='grid_wrapper'> 
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl+`/PO_header`}
          defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
          additionalParams={`&type=1&dashboard=true`}
          filterAdditionalParams={`&type=1&dashboard=true`}
          pagination={true} 
          columnDefs={gridColumnHeadersCO} 
          onCellClicked={CellHandlerPO}
          hideDeleteBtn={true}
          hideAddBtn={true}
          height={500}
          gridId={'rs_contract_list'}
        />
      </div>
    )
  }

  const GRNModule = () => {
    const CellHandler = (event) => {
      if (event.colDef.field === 'receiving_reference_number') {
        navigate(
          `/goods-receiving-details?shipment_id=${event.data?.shipment_id}`,
        )
      }
      if (event.colDef.field === 'document_number') {
        navigate(
          `/purchase-order/edit/${event.data?.purchaseorder_id}`,
        )
      }
    }
   
    const gridColumnHeadersGRN = [
      { field: 'receiving_reference_number', headerName: Helper.getLabel(language, 'goods_receiving_no', 'Goods receiving no.'), minWidth: 220, editable: false, cellRenderer: (params) => gridLinkValueFormat(params) },
      { field: 'document_number', headerName: Helper.getLabel(language, 'source_document_no', 'Source document number'), minWidth: 240, editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
      { field: 'description', minWidth: 180, headerName: Helper.getLabel(language, 'description', 'Description'), editable: false },  
      { field: 'vendor_code', minWidth: 200, headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'), editable: false },
      { field: 'vendor_name', minWidth: 200, headerName: Helper.getLabel(language, 'name', 'Name'), editable: false },
      { field: 'vendorsite_name', headerName: Helper.getLabel(language, 'site', 'Site'), editable: false },
      {
        field: 'document_status',
        minWidth: 200,
        headerName: Helper.getLabel(
          language,
          'document_status',
          'Document status',
        ),
        editable: false,
        cellRenderer: (params) => getStatusNameById(params.value, documentStatus),
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, documentStatus),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name,
        }
      },
      { field: 'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false },
      { field: 'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 250, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false },
      { field: 'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 200, headerName: Helper.getLabel(language, 'accounting_date', 'Accounting date'), editable: false },
      { field: 'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 200, inputType: 'datepicker', headerName: Helper.getLabel(language, 'document_date', 'Document date'), editable: false },
    ];
 
    return (
      <div className='grid_wrapper'> 
        {
          loadingGRN ? <SimpleLoading /> : 
          <AgGridNew
            apiUrl={Settings.apiPurchaseOrderUrl + `/sh_headers/${Settings.sh_type.goods_receiving}`}
            defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
            additionalParams={`&dashboard=true`}
            filterAdditionalParams={`&dashboard=true`}
            pagination={true} 
            columnDefs={gridColumnHeadersGRN} 
            onCellClicked={CellHandler}
            hideDeleteBtn={true}
            hideAddBtn={true}
            height={500}
            gridId={'rs_grn_listing'}
          />
        }
      </div>
    )
  }

  const WorkConfirmationModule = () => {  
    const CellHandler = (event) => {
      if (event.colDef.field === 'receiving_reference_number') {
        navigate(
          `/work-confirmation-details?shipment_id=${event.data?.shipment_id}`,
        )
      }
      if (event.colDef.field === 'document_number') {
        navigate(
          `/contract-order/edit/${event.data?.purchaseorder_id}`,
        )
      }
    }
   
    const gridColumnHeadersWC = [
      { field: 'receiving_reference_number', headerName: Helper.getLabel(language, 'work_confirmation_no', 'Work confirmation no.'), minWidth: 220, editable: false, cellRenderer: (params) => gridLinkValueFormat(params) },
      { field: 'document_number', headerName: Helper.getLabel(language, 'source_document_no', 'Source document number'), minWidth: 240, editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
      { field: 'description', minWidth: 180, headerName: Helper.getLabel(language, 'description', 'Description'), editable: false },  
      { field: 'vendor_code', minWidth: 200, headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'), editable: false },
      { field: 'vendor_name', minWidth: 200, headerName: Helper.getLabel(language, 'name', 'Name'), editable: false },
      { field: 'vendorsite_name', headerName: Helper.getLabel(language, 'site', 'Site'), editable: false },
      {
        field: 'document_status',
        minWidth: 200,
        headerName: Helper.getLabel(
          language,
          'document_status',
          'Document status',
        ),
        editable: false,
        cellRenderer: (params) => getStatusNameById(params.value, documentStatus),
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, documentStatus),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name,
        }
      },
      { field: 'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false },
      { field: 'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 250, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false },
      { field: 'accounting_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 200, headerName: Helper.getLabel(language, 'accounting_date', 'Accounting date'), editable: false },
      { field: 'document_date', valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params?.value), filter:'agDateColumnFilter', minWidth: 200, inputType: 'datepicker', headerName: Helper.getLabel(language, 'document_date', 'Document date'), editable: false },
    ];

    const handleFetchDataWC = (response) => {
      if(typeof setWorkConfirmationList === 'function'){
        setWorkConfirmationList(response?.data);
      }
      return {
        rowData: response?.data?.data?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: response?.data?.total_records
      }
    }

    const handleErrorResponse = (res) => {
      if(typeof setWorkConfirmationList === 'function'){
        setWorkConfirmationList({ total_records: 0 });
      } 
    }
    return (
      <div className='grid_wrapper'> 
        {
          loadingWorkConfirmations ? <SimpleLoading /> : 
          <AgGridNew
            apiUrl={Settings.apiPurchaseOrderUrl + `/sh_headers/${Settings.sh_type.work_confirmation}`}
            defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
            additionalParams={`&dashboard=true`}
            filterAdditionalParams={`&dashboard=true`}
            pagination={true}
            fetchData={handleFetchDataWC}
            onErrorFromApi={handleErrorResponse}
            columnDefs={gridColumnHeadersWC} 
            onCellClicked={CellHandler}
            hideDeleteBtn={true}
            hideAddBtn={true}
            height={500}
            gridId={'rs_work_confirmation_listing'}
          />
        }
      </div>
    )
  }

  const InvoiceModule = () => {
    const gridColumnHeadersInvoice = [
      {
        field: 'reference_number',
        headerName: Helper.getLabel(language, 'invoice_no', 'Invoice no.'),
        minWidth: 220,
        editable: false,
        cellRenderer: (params) => gridLinkValueFormat(params),
      },
      {
        field: 'vendor_code',
        minWidth: 200,
        headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'),
        editable: false,
        cellRenderer:gridLinkValueFormat,
      },
      {
        field: 'document_number',
        headerName: Helper.getLabel(
          language,
          'source_document_no',
          'Source document number'
        ),
        minWidth: 200,
        editable: false
      },
      {
        field: 'vendor_name',
        minWidth: 200,
        headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
        editable: false,
      },
      {
        field: 'vendorsite_name',
        headerName: Helper.getLabel(language, 'vendor_site', 'Vendor site'),
        editable: false,
        minWidth: 180,
      }, 
      {
        field: 'description',
        minWidth: 180,
        headerName: Helper.getLabel(language, 'description', 'Description'),
        editable: false,
      }, 
      {
        field: 'document_status',
        minWidth: 200,
        headerName: Helper.getLabel(
          language,
          'document_status',
          'Document status'
        ),
        editable: false,
        cellRenderer: (params) => getStatusNameById(params.value, invoiceStatuses),
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, invoiceStatuses),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: (params) => params.value.name,
        },
      },
      { field: 'current_stage', valueFormatter: (params) => params.value ? params.value : '-' , minWidth: 280, headerName: Helper.getLabel(language,'current_stage',"Current stage"), editable: false },
      { field: 'current_assignee',valueFormatter: (params) => !checkIfArrayIsEmpty(params.value) ? params.value : '-', minWidth: 250, headerName: Helper.getLabel(language,'current_assignee',"Current assignee"), editable: false },
      {
        field: 'invoice_date',
        minWidth: 200,
        filter:'agDateColumnFilter',
        valueFormatter: (params) =>
          DatepickerFunctions.convertDateFromDataBase(params.value),
        headerName: Helper.getLabel(
          language, 'invoice_date', 'Invoice date'
        ),
        editable: false,
      },
      {
        field: 'accounting_date',
        minWidth: 200,
        filter:'agDateColumnFilter',
        valueFormatter: (params) =>
          DatepickerFunctions.convertDateFromDataBase(params.value),
        headerName: Helper.getLabel(
          language,
          'accounting_date',
          'Accounting date'
        ),
        editable: false,
      },
      {
        field: 'document_date',
        minWidth: 200,
        filter:'agDateColumnFilter',
        valueFormatter: (params) =>
          DatepickerFunctions.convertDateFromDataBase(params.value),
        headerName: Helper.getLabel(
          language,
          'document_date',
          'Document date'
        ),
        editable: false,
      },
      {
        field: 'delivery_date',
        minWidth: 200,
        hide: true,
        filter:'agDateColumnFilter',
        valueFormatter: (params) =>
          DatepickerFunctions.convertDateFromDataBase(params.value),
        headerName: Helper.getLabel(
          language,
          'delivery_date',
          'Delivery date'
        ),
        editable: false,
      },
      {
        field: 'delivery_name',
        minWidth: 200,
        hide: true,
        headerName: Helper.getLabel(
          language,
          'delivery_name',
          'Delivery name'
        ),
        editable: false,
      },
      {
        field: 'externalreference',
        minWidth: 200,
        hide: true,
        headerName: Helper.getLabel(
          language,
          'external_reference',
          'External reference'
        ),
        editable: false,
      },
      {
        field: 'PO_document_number',
        minWidth: 200,
        hide: true,
        headerName: Helper.getLabel(
          language,
          'po_document_number',
          'PO document number'
        ),
        editable: false,
      },
      {
        field: 'source_description',
        minWidth: 200,
        hide: true,
        headerName: Helper.getLabel(
          language,
          'source_description',
          'Source description'
        ),
        editable: false,
      }, 
    ]
    
    const CellHandlerInvoice = (event) => {
      if(event.colDef.field === 'reference_number') { 
        navigate(`/invoice-details/${event.data?.invoice_id}`)
      }
      if(event.colDef.field === 'vendor_code') { 
        navigate(`/edit-vendor/${event.data.vendor_id}`, {state: {prevPath:'/dashboard'}})
      }
    }

    return (
      <div className='grid_wrapper'> 
        { loadingInvoices ? <SimpleLoading /> :
          <AgGridNew
            apiUrl={Settings.apiPurchaseOrderUrl+`/invoice_header`}
            defaultAdvanceFilterObj={{ conditions:"AND", filters:[{left:"requester_id", right:auth?.user?.employee_id, operation:"equals"}] }}
            pagination={true} 
            columnDefs={gridColumnHeadersInvoice} 
            onCellClicked={CellHandlerInvoice}
            hideDeleteBtn={true}
            hideAddBtn={true}
            height={500}
            gridId={'rs_invoice_list'}
          />
        }
      </div>
    )
  }

  const BtnClick = (e) => { console.log(e) }

  return ( 
    <CollapseWithButton id='request_collapsible_bar' requestBtnClick={BtnClick} btnLabel={'My New Request'} title={Helper.getLabel(language,'request','My Request')} open={true} className="my_request_collapse mb-4 mt-4">
      <Tabs navs={navs} id='request_tabs' showAll={false} scrollOffset="300px">
        <div className='pr_module_grid'>{PRModule()}</div> 
        <div className='rfx_module_grid'>{RFXModule()}</div> 
        <div className='common_module_grid'>{POModule()}</div>
        <div className='common_module_grid'>{ROModule()}</div>
        <div className='common_module_grid'>{ContractModule()}</div> 
        <div className='common_module_grid'>{GRNModule()}</div>
        <div className='common_module_grid'>{WorkConfirmationModule()}</div>  
        <div className='common_module_grid'>{InvoiceModule()}</div>
      </Tabs>
    </CollapseWithButton>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (MyRequest);