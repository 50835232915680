import React, { useCallback, useEffect, useState } from 'react'
import RsDataLoader from '../../../inc/RsDataLoader'
import TaxTransactionPopup from './TaxTransactionPopup'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import $ from 'jquery'
import RsWithRouter from '../../../inc/RsWithRouter'
import { Tab, Tabs } from 'react-bootstrap'
import Collapse from '../../../inc/Collapse'
import RequirementTabs from './RequirementTabs'
import BillingRuleView from './BillingRuleView'
import SetupTabDetails from './SetupTabDetails'
import GeneralLineDetails from './GeneralLineDetails'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import DistributionTab from './DistributionTab'
import ItemSearchComponent from '../../../grid/ag/components/ItemSearchComponent'
import Api from '../../../../inc/Api'
import { commonThousandSeparator, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import SettingsPoDistribution from './SettingsPoDistribution'
import SiteCellRenderer from '../../../grid/ag/cellRender/SiteCellRenderer'
import AdvancedDropdown from '../../../grid/ag/cellEditor/AdvancedDropdown'
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown'
import { createRow, getLabelFromDropdown } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import WarehouseCellRenderer from '../../../grid/ag/cellRender/WarehouseCellRenderer'
import NumberCellEditor from '../../../grid/ag/cellEditor/NumberCellEditor'
import NumberCellRenderer from '../../../grid/ag/cellRender/NumberCellRenderer'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import DateEditor from "../../../grid/ag/cellEditor/CustomDatePicker"
import ItemSearchEditor from '../../../grid/ag/cellEditor/ItemSearchEditor'
import AjaxBarcodeSearch from '../../../grid/ag/cellEditor/AjaxBarcodeSearch'
import CurrencyRenderer from '../../../grid/ag/cellRender/CurrencyRenderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import PurchaseAgreementFulFillment from './PurchaseAgreementFulfillment'

let poLineGrid = null
let lineIdForTax = null
let AddBtn = false
let transaction
let totalDropdownPage = 1

const LineViewComponent = (props) => {
  const [showTransactionPopup, setShowTransactionPopup] = useState(false)
  const [employees, setEmployees] = useState([])
  const [units, setUnits] = useState([])
  const [activePOLine, setActivePOLine] = useState({})
  const [poLineTypes,setPoLineTypes]=useState([])
  const [allCategories,setAllCategories]=useState([])
  const [allItemGroups,setAllItemGroups] = useState([])
  const [taxGroup,setTaxGroup]=useState([])
  const [taxGroupItems,setTaxGroupItems]=useState([])
  const [updatedData,setUpdatedData]=useState({})
  const [allCommitmentLevels,setAllCommitmentLevels]=useState([])
  const [agreementLinesList,setAgreementLinesList]=useState([])

  let source_id = props.source_id
  let lineGridTitle = props.lineGridTitle
  let lineGridSettingsFromProps = props.lineGridSettings ? props.lineGridSettings : {}
  let newAddItems = { line_type: 0 }
  let language = null
  let defaultValues = props.defaultValues ? props.defaultValues : {};

  let unitsList = units.map((itemObj) => {
    return {
      value: itemObj.unit_id,
      label: `${itemObj.code} - ${itemObj.description}`,
    }
  })

  let employeeList = employees.map((itemObj) => {
    return {
      value: itemObj.employee_id,
      label: `${itemObj.displayname}`,
    }
  })

  let entitiesList = props.dataLoaderProps.entity.entity.map( item => {
    return {
        label:`${item.name} | ${item.code}`,
        value:item.entity_id
    }
   });

   let linestatusList = props.dataLoaderProps.lineSiteStatus.allStatus.map( (siteStatus) => {
    return ({
        label:siteStatus.name,
        value:siteStatus.id
    })
   })

   let poLineTypesList = poLineTypes.map( (itemObj) => {
    return {
        value: itemObj.id,
        label: itemObj.name
    }
   });

   let categoryOptions = allCategories.map( itemObj => {
    return {
        value: itemObj.category_id,
        label: `${itemObj.code} - ${itemObj.name}`
    }
   });

   let itemGroupList = allItemGroups.map( (itemObj) => {
    return {
        value: itemObj.itemgroup_id,
        label: itemObj.name
    }
   });

   let commitmentLevelList = allCommitmentLevels?.map((itemObj) => {
    return {
        value: itemObj.id,
        label: itemObj.name
    }
   });

  useEffect(() => {
    loadUnits()
    loadEmployee()
    loadPoLineTypes()
    loadAllCategories()
    loadAllItemGroups()
    loadTaxgroup()
    loadTaxgroupItems()
    loadCommitmentLevels()
    getAgreementLines()
  }, [])

  //enums data
  const loadUnits = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/unit`)
      .then(function (res) {
        setUnits(res.data.data)
      })
      .catch((res) => {})
  }

  const loadEmployee = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiOrgUrl + '/employee_dropdown?status=true')
      .then(function (res) {
        setEmployees(res.data.data)
      })
      .catch((res) => {})
  }

  const loadPoLineTypes = () => {
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiUrl+'/enum/po_lineType').then(function(res){
      setPoLineTypes(res.data.data)
    }).catch((res) => { 
        console.log(res.response.data.message)
    })
  }

  const loadAllCategories = (id) => {
    let api = Api;
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/child_categories`,{}).then(function(res){
    if(res.data.status==="success"){ 
        setAllCategories(res.data.data)
    }
    }).catch((res) => { 
        getPopupMessageBasedOnStatus(res)  
    })
  }

  const loadAllItemGroups = () => {
    let api = Api;
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_group`,{}).then(function(res){
    if(res.data.status==="success"){
       setAllItemGroups(res.data.data)
    }
    }).catch((res) => { 
        getPopupMessageBasedOnStatus(res)  
    })
}

const loadTaxgroup = () =>{
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
        let updatedTaxGroup = res.data.data.map( item => {
            return {
                value: item.taxgroup_id,
                label: item.name
              }
        })
        setTaxGroup(updatedTaxGroup)
    }).catch((res) => { 
        getPopupMessageBasedOnStatus(res)  
    })
}
const loadTaxgroupItems = () => {
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/taxgroupitem',{}).then(function(res){
        let updatedTaxGroupItems = res.data.data.map( item => {
            return {
                value: item.taxgroupitem_id,
                label: item.name
              }
        })
       setTaxGroupItems(updatedTaxGroupItems)
    }).catch((res) => { 
        getPopupMessageBasedOnStatus(res)  
    })
}
const loadCommitmentLevels = () => {
  if(props.formType !== "purchase agreement"){
    return
  }
  let api = Api
  api.setUserToken()
  api
    .axios()
    .get(Settings.apiUrl + `/enum/commitment_level`)
    .then(function (res) {
      setAllCommitmentLevels(res.data.data)
    })
    .catch((res) => {})
}

const getAgreementLines = async () => {
  if(props?.formType !== "release order" || !props.agreementId){
    return
  }
  let api = Api
  api.setUserToken()
   try{
     const agreementLines = await api.axios().get(Settings.apiPurchaseOrderUrl + `/lines/${props.agreementId}/${Settings.source_id.purchase_agreement}`)
     if(agreementLines?.status===200){
      let updatedAgreementLines = agreementLines?.data.data.map( item => {
        return {
            value: item.line_id,
            label: parseInt(item?.line_type)===0?item.item_name : parseInt(item?.line_type)===1?item?.category_name:`${item?.comments.substring(0,80) + "..."}`
          }
    })
       setAgreementLinesList(updatedAgreementLines)
     }
   }catch(error){}
}


const getLineTotal = (quantity,unitPrice,discount_amount,discount_percentage) => {
  let totalLineAmountBeforeDiscount = quantity * unitPrice
  let lineAmount = totalLineAmountBeforeDiscount - discount_amount
  lineAmount = lineAmount - (discount_percentage / 100) * lineAmount
  let finalLineAmount = lineAmount
  if (finalLineAmount <= 0) {
    return 0
  }
  return finalLineAmount
}

  const getLineViewAmount = (params, lineData = null) => {
    let data = params.data ? params.data : params
    let qtyData = props?.rfqExternal ? data.required_qty : data.ordered_qty
    let quantity = parseFloat(qtyData) ? parseFloat(qtyData) : 0;
    let unitPrice = parseFloat(data.unit_price) ? parseFloat(data.unit_price) : 0
    let discount_amount = parseFloat(data.discount_amount) ? parseFloat(data.discount_amount) : 0
    let discount_percentage = parseFloat(data.discount_percentage) ? parseFloat(data.discount_percentage) : 0
    if (lineData) {
      qtyData =  props?.rfqExternal ? lineData.required_qty : lineData.ordered_qty
      quantity =  parseFloat(qtyData) ? parseFloat(qtyData) : 0;
      unitPrice = parseFloat(lineData.unit_price) ? parseFloat(lineData.unit_price) : 0
      discount_amount = parseFloat(lineData.discount_amount) ? parseFloat(lineData.discount_amount) : 0
      discount_percentage = parseFloat(lineData.discount_percentage) ? parseFloat(lineData.discount_percentage) : 0
    }
    if (discount_amount > unitPrice) {
      discount_amount = unitPrice
    }
    discount_amount = discount_amount * quantity

    const finalLineAmount = getLineTotal(quantity,unitPrice,discount_amount,discount_percentage) 
    return (finalLineAmount)
  }

  const BeforeDeleteButton = () => {
    return (
      <div className={'list_view_action_btns_grid d-flex gap-3 align-items-center' + (!props.isEditable ? 'ctl-dissable' : '')}>
        <button
          className={`rs_h_controller mb-0 d-flex gap-2 align-items-center b-0 bg-transparent p-0 ${props?.disableAllBtn||!props.isEditable?"btn-disabled":""}`}
          style={{cursor:'pointer'}}
          disabled={props.disableAllBtn?true:!props.isEditable}
          onClick={(e) => {
            if (props.isEditable) {
              onCategoryAddHandler(e)
            }
          }}
        >
          <img src='/images/icons/category.svg' className='img-fluid' alt='category' />
          <span className='fw-bold'>{Helper.getLabel(null, 'add_category', 'Add Category')}</span>
        </button>
        <button
          className={`rs_h_controller mb-0 d-flex gap-2  align-items-center  b-0 bg-transparent p-0 ${props?.disableAllBtn||!props.isEditable?"btn-disabled":""}`}
          style={{cursor:'pointer'}}
          disabled={props.disableAllBtn?true:!props.isEditable}
          onClick={(e) => {
            if (props.isEditable) {
              onCommentAddHandler(e)
            }
          }}
        >
          <img src='/images/icons/comment.svg' className='img-fluid' alt='comment' />
          <span className='fw-bold'>{Helper.getLabel(null, 'add_comments', 'Add Comments')}</span>
        </button>
      </div>
    )
  }
  const AfterDeleteButton = () => {
    if (props.afterDeleteButtonOfLines) {
      return props.afterDeleteButtonOfLines
    }
  }

  const canLineViewCellEdit = (params) => {
    let line_type = params.data.line_type;
    let fieldName = params.colDef ? params.colDef.field : '';
    if(Settings.lineTypesId.item == line_type && ['comments','category_id'].includes(fieldName)){ 
        return false;
    }
    if(Settings.lineTypesId.comment == line_type && ['barcode','item_name','category_id'].includes(fieldName) ){ 
        return false;
    }
    if(Settings.lineTypesId.category == line_type && ['barcode','item_name','comments'].includes(fieldName) ){ 
        return false;
    }
    if(params?.colDef?.field === "item_name" && params?.data?.line_id){
      return false
    }
    return props.isEditable;
  }

  const onUnitPriceChangeHandler = (event,params) => {
    let discount_amount = document.getElementById('discount_amount')
    let discount_percentage = document.getElementById('discount_percentage')
    let lineData = {
      unit_price:event?.target?.value,
      discount_amount:discount_amount.value,
      discount_percentage:discount_percentage.value
    }
    if(props.rfqExternal){
      lineData={
        ...lineData,
        required_qty:document.getElementById('required_qty')?.value,
      }
    }else{
      lineData={
        ...lineData,
        ordered_qty:document.getElementById('ordered_qty')?.value,
      }
    }
    let totalLineAmount = getLineViewAmount(params,lineData);
    let editorInsAr = params.api.getCellEditorInstances();
    editorInsAr.forEach( cellEditorIns => {
        if(cellEditorIns.params && cellEditorIns.params.colDef.field == 'line_amount'){
            if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
                cellEditorIns.updateValue(totalLineAmount)
            }else{
                params.node.setDataValue('line_amount',totalLineAmount)
            }
        }
    })
    params.node.setDataValue('line_amount', totalLineAmount);
}

const valueFormateForLineView = (params) => {
    if(props.viewType == 'shipment' || props.viewType =='invoice'){
        return <div className="rs_ag_currency_renderer">{Helper.formateCurrency(params.value)}</div>
    }
    let lineData = null

    if(!params.data.line_id){
      lineData = {
        ordered_qty:document.getElementById('ordered_qty')?.value,
        unit_price:document.getElementById('unit_price')?.value,
        discount_amount:document.getElementById('discount_amount')?.value,
        discount_percentage:document.getElementById('discount_percentage')?.value
      }
    }

    let value = params.value === null || params.value === undefined ? getLineViewAmount(params,lineData) : params.value 
   
    return  <div className="rs_ag_currency_renderer">{params?.value !== null && params?.value !== undefined ? Helper.formateCurrency(value) : params?.value?.includes("*") && !params?.data?.line_id ? Helper.formateCurrency(params.value):Helper.formateCurrency(value)}</div>
}

const handleAmountChange = (event,params) => {
  let lineData = {
    unit_price:document.getElementById('unit_price')?.value,
    discount_amount:document.getElementById('discount_amount')?.value,
    discount_percentage:document.getElementById('discount_percentage')?.value
  }
  if(props.rfqExternal){
    lineData={
      ...lineData,
      required_qty:document.getElementById('required_qty')?.value,
    }
  }else{
    lineData={
      ...lineData,
      ordered_qty:document.getElementById('ordered_qty')?.value,
    }
  }
  let totalLineAmount = getLineViewAmount(params,lineData)
  params.node.setDataValue('line_amount',totalLineAmount)
}

const canEditReceivedQty = (params) => {
    if(props.viewType == 'shipment' || props.viewType == 'invoice'){
        if(props.canShipmentEdit === false){
            return false;
        }
        return true;
    }
    return false;
 }

 const isHideRemainingQty = () => {
    if(props.distributionTabCols?.includes('remaining_qty')){
        return false;
    }
    return props.viewType != 'shipment' && props.viewType != "invoice" 
}
const isHideReceivedQty = () => {
    if(props.distributionTabCols?.includes('received_qty')){
        return false;
    }
    return props.viewType != 'shipment' && props.viewType != "invoice" 
}

const getReceiviedQuantityLabel = () => {
    if(props.shipmentType && props.shipmentType =='return_order'){
        return Helper.getLabel(null,'return_quantity','Return quantity')
    }
    if(props.viewType == 'invoice'){
       return Helper.getLabel(null,'invoice_quantity','Invoice quantity')
    }
    return Helper.getLabel(null,'received_quantity',"Received quantity");
}

const getRemainingQuantityLabel = () => {
    if(props.shipmentType && props.shipmentType =='return_order'){
        return Helper.getLabel(null,'received_quantity',"Received quantity");
    }
    return Helper.getLabel(null,'remaining_quantity',"Remaining quantity")
}

const getReceivedQuanityMaxValue = (value , params) => {
    return params.data.remaining_qty;
}

const lineGridReceivedQuantityFormater = (params) => {
    if(props.shipmentType =='return_order'){
        return commonThousandSeparator(params.value);
    }
    if(params.value == 0){
        return commonThousandSeparator(params.data.remaining_qty);
    }
    return commonThousandSeparator(params.value);
}

const handleCategoryChange = (event,params) => {
  setTimeout(() => {
    let quantityCell = document.getElementById('ordered_qty'); 
    if (quantityCell) {
        quantityCell.focus();
    }
}, 100);
}

let receivingLabel = getReceiviedQuantityLabel()
let remainingLabel = getRemainingQuantityLabel()
let hideReceivedQty = isHideReceivedQty()
let hideRemainingQty = isHideRemainingQty()

let select2SettingsCategories = {
  ajax: {
    url: (params) =>{
      totalDropdownPage = params.page || 1;
      return Settings.apiUrl + '/child_categories';
    },
    processResults: function (data) {
      let isMorePageExist = ( data.total_records - ( totalDropdownPage * Settings.dropdownPageSize )) >0 ? true : false ; 
      let result = data.data.map( item => {
        return {
          id: item.category_id,
          text: item.name
        }
      })
      return {
        results:result,
        pagination: {
          more: isMorePageExist
        }
      };
    }
  }
}

  let Columns_HeadingsPart1 = [
    {field:'line_type', sortable:false, editable: false, headerName:Helper.getLabel(language,'type',"Type"),minWidth: 130,cellEditorParams:{values:poLineTypesList},cellEditor:SimpleDropdown,inputId:"rs_po_lines_type_dn",cellDataType:false, valueFormatter:(params)=>{return getLabelFromDropdown(params)}},
    {field:parseInt(props.source_id) === 10 || parseInt(props.source_id) === Settings?.source_id?.purchase_agreement ||  parseInt(props.source_id) === Settings.source_id.auction ?'linenumber':'line_number', editable: false, headerName:Helper.getLabel(language,'line_no',"Line no."), minWidth: 110},
    {field:'budget_check', headerName:Helper.getLabel(language,'budget',"Budget"), minWidth: 110, editable: false,suppressTabbing:true,hide:'true'},
  ]
  if(props.includeBudgetColumns){
    Columns_HeadingsPart1?.push({
      field: "budget_check",
      headerName: Helper.getLabel(
        language,
        "budget",
        "Budget"
      ),
      name: "budget_check",
      cellRenderer:(params)=>{return budgetCheckCellRenderer(params)},
      editable:false,
    },)
  }
  if(props.formType==="purchase agreement"){
    Columns_HeadingsPart1.push({field:'commitment_level',editable:props.isEditable,inputId:'commitmentlevel_id_selector', headerName:Helper.getLabel(language,'commitment_level',"Commitment level"), minWidth: 240,cellEditorParams:{values:commitmentLevelList},cellDataType:false,valueFormatter:(params)=>getLabelFromDropdown(params),cellEditor:SimpleDropdown,hide:'false'})
  }
  if(props.formType==="release order"){
    Columns_HeadingsPart1.push({field:'agreementline_id',editable:props.isEditable,inputId:'agreementline_id_selector', headerName:Helper.getLabel(language,'agreementline_id',"Agreement line"), minWidth: 240,cellEditorParams:{values:agreementLinesList},cellDataType:false,valueFormatter:(params)=>getLabelFromDropdown(params),cellEditor:SimpleDropdown,hide:'false'})
  }
let Columns_HeadingsPart2 = [{
        field:'item_code',
        editable: false,
        headerName:Helper.getLabel(language,'item_code',"Item code"), 
        minWidth: 180, 
        cellEditor: ItemSearchEditor,
        cellEditorParams:{
            formType:props.formType
        },
        cellEditorPopup: false 
    },
    {
        field:'item_name',
        headerName:Helper.getLabel(language,'item_name',"Item name"), 
        minWidth: 275, 
        cellEditor: ItemSearchEditor,
        cellEditorParams:{
            formType:props.formType,
            unitPriceDefault:props.unitPriceDefault,
        },
        cellClass:"item-name-cell",
        editable: canLineViewCellEdit
    },
    {field:'barcode', editable: canLineViewCellEdit, headerName:Helper.getLabel(language,'barcode',"Barcode"), minWidth: 150,cellEditor: AjaxBarcodeSearch,cellEditorPopup: false,hide:true  },
    {field:'category_id', editable: canLineViewCellEdit,inputId:'category_id_selector',headerName:Helper.getLabel(language,'category',"Category"), minWidth: 275, cellEditor:SimpleDropdown, cellEditorParams:{values:categoryOptions}, cellDataType:false, select2Settings:select2SettingsCategories, valueFormatter:(params)=> params?.data?.category_name ?? getLabelFromDropdown(params),onChange:(event,params)=>{return handleCategoryChange(event,params)}},
    {field:'comments', editable:canLineViewCellEdit, headerName:Helper.getLabel(language,'comments',"Comments") , minWidth: 350,},
    {field:'ordered_qty' ,valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}, editable: props.rfqExternal ? false :canLineViewCellEdit, onChange:handleAmountChange, headerName:Helper.getLabel(language,'quantity',"Quantity"),defaultValue:1, minWidth: 120, cellEditor:NumberCellEditor, cellRenderer:CurrencyRenderer},
]

if(props.rfqExternal){
  Columns_HeadingsPart2.push({
    field: 'required_qty',
    headerName: Helper.getLabel(props.language, 'qty_propose', 'Quantity propose'),
    minWidth: 180,
    onChange:handleAmountChange,
    cellEditor:NumberCellEditor, 
    cellRenderer:CurrencyRenderer,
    // cellRenderer: gridValueColorFormat,
    editable:props.proposedQtyEnable,
  })
}

if(props.viewType =='invoice'){
    Columns_HeadingsPart2.push({field:'receiving_reference_number', editable: false, headerName:Helper.getLabel(language,'receiving_no','Receiving no.'),minWidth: 200})
}

if(props.viewType =='shipment' || props.viewType =='invoice' ||  props.viewType =='amendment'){
    Columns_HeadingsPart2.push({field:'received_qty' ,valueFormatter:lineGridReceivedQuantityFormater, editable: false, headerName:receivingLabel, minWidth: 200,  inputType:'number',autoFocus:true})
    Columns_HeadingsPart2.push({field:'remaining_qty',  valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},  editable: false, headerName: remainingLabel,defaultValue:1, minWidth: 200,  inputType:'number',autoFocus:true})
}

let Columns_HeadingsPart3 = [
    {field:'unit', editable:canLineViewCellEdit,inputId:'unit_selector' , headerName:Helper.getLabel(language,'unit',"Unit"), minWidth: 150, cellEditor:SimpleDropdown,cellEditorParams:{values:unitsList}, cellDataType:false, valueFormatter:(params)=>getLabelFromDropdown(params)},
    {field:'unit_price',valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}, onChange:onUnitPriceChangeHandler, editable:props.disableFinancialInModule?false:canLineViewCellEdit, headerName:Helper.getLabel(language,'unitprice',"Unit price"),minWidth: 150, cellEditor:NumberCellEditor,defaultValue:0, cellRenderer:CurrencyRenderer,specialCharAllowed:false},
    {field:'discount_amount',valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}, editable:props.disableFinancialInModule?false:canLineViewCellEdit, onChange:handleAmountChange, minWidth: 200, headerName:Helper.getLabel(language,'discount_amount',"Discount amount") , cellEditor:NumberCellEditor, cellRenderer:CurrencyRenderer },
    {field:'discount_percentage',valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}, editable:props.disableFinancialInModule?false:canLineViewCellEdit, onChange:handleAmountChange, minWidth: 200, headerName:Helper.getLabel(language,'discount_percentage',"Discount percentage"),defaultValue:Helper.getOnlyNumber(defaultValues.discount_percentage),cellEditor:NumberCellEditor, cellRenderer:CurrencyRenderer},
    {field:'line_amount', minWidth: 200, headerName:Helper.getLabel(language,'lineamount',"Line amount"), editable: false,cellRenderer:(params)=>{return valueFormateForLineView(params)}, valueFormatter:(params)=>{return getLineViewAmount(params)}},
    {field:'description', editable:false, hide:true},
    
    {field:'requester_id', editable:false, hide:true},
    {field:'orderer_id', editable:false, hide:true},
    {field:'item_id', editable:false, hide:true},
    {field:'taxgroupitem_id', editable:false, hide:true},
    {field:'taxgroup_id', editable:false, hide:true},
    {field:'recoupment_rate', editable:false, hide:true},
    {field:'retainage_rate', editable:false, hide:true},
    {field:'advance_payment', editable:false, hide:true},
    {field:'advance', editable:false, hide:true},
    {field:'external_reference', editable:false, hide:true}
];

if(!props.alternate_line){
  Columns_HeadingsPart3.push({field:'itemgroup_id',editable:(params)=>{return params.data?.line_type===0?false: props.isEditable},inputId:'item_group_id_selector', headerName:Helper.getLabel(language,'item_group',"Item group"), minWidth: 150,cellEditorParams:{values:itemGroupList},cellDataType:false,valueFormatter:(params)=>getLabelFromDropdown(params),cellEditor:SimpleDropdown,hide:'false'})
}

let purchaseAgreementColumns = [
  {field:'start_date', headerName: Helper.getLabel(language, 'start_date', 'Start date'), editable: props.isEditable, cellEditor: DateEditor,cellEditorParams: { empty: true },minWidth: 250,valueFormatter: (params) => (params?.value=== null || params?.value === undefined ? null : DatepickerFunctions.convertDateFromDataBase(params.value))},
  {field:'end_date',headerName: Helper.getLabel(language, 'end_date', 'End date'), editable: props.isEditable,cellEditorParams: { empty: true },cellEditor: DateEditor,minWidth: 250,valueFormatter: (params) => ( params?.value=== null || params?.value === undefined ? null : DatepickerFunctions.convertDateFromDataBase(params.value))},
  {field:'max_is_enforced',cellEditor: 'agCheckboxCellEditor',cellRenderer: 'agCheckboxCellRenderer',cellRendererParams: { disable: true }, headerName: Helper.getLabel(language, 'maximum_release_enforced', 'Maximum release enforced'), editable: props.isEditable,  minWidth: 250, },
  {field:'min_release_amount',valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},  editable:props.isEditable, headerName:Helper.getLabel(language,'minimum_release_amount',"Minimum release amount"),minWidth: 220, cellEditor:NumberCellEditor,defaultValue:0, cellRenderer:CurrencyRenderer,specialCharAllowed:false},
  {field:'max_release_amount',valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},  editable:props.isEditable, headerName:Helper.getLabel(language,'maximum_release_amount',"Maximum release amount"),minWidth: 220, cellEditor:NumberCellEditor,defaultValue:0, cellRenderer:CurrencyRenderer,specialCharAllowed:false},
  {field:'enforce_same_category_item_release',cellEditor: 'agCheckboxCellEditor',cellRenderer: 'agCheckboxCellRenderer',cellRendererParams: { disable: true }, headerName: Helper.getLabel(language, 'enforce_same_category_for_item_release', 'Enforced same category for item release'), editable: (params)=>{return (params?.data?.line_type===0 || params?.data?.line_type===2) ? false : props.isEditable},  minWidth: 280, },   
]

if(props.formType==="purchase agreement"){
  Columns_HeadingsPart3=Columns_HeadingsPart3.concat(purchaseAgreementColumns)
}

const taxTransactionViewCell = (params) => {
  if(params.data.line_id){
      return <div className="rs_inactive_label">View tax</div>;
  }
  return <></>;
}

const getBudgetIcon = (params) => {
  if(params?.value?.toLowerCase()==="passed"){
    return faCheck
  }else if(params?.value?.toLowerCase()==="passed_with_warning"){
    return faExclamationTriangle
  }else{
    return faTimes
  }
}

const getBudgetColor = (params) => {
  if(params?.value?.toLowerCase()==="passed"){
    return "#3CBA54"
  }else if(params?.value?.toLowerCase()==="passed_with_warning"){
    return "#d83b01"
  }else{
    return "#D73535"
  }
}

const budgetCheckCellRenderer = (params) => {
  return <div className="d-flex justify-content-center align-items-center" style={{height:"41px"}}>{params.value === null || params.value === undefined ? "-" : <FontAwesomeIcon icon={getBudgetIcon(params)} color={getBudgetColor(params)} />}</div>
}

const getAdditionalHeaders = (header) =>{
  if(props.lines_attachment){
      header.push({field:'lines_attachment', minWidth: 150, headerName:Helper.getLabel(null,'lines_attachment',"Lines attachment")} )
  }
  if(props.additionalLineColumns){
      header = header.concat(props.additionalLineColumns);
  }
  return header;
}
    
if(props.viewType != 'shipment'){
    Columns_HeadingsPart3.push({field:'view_tax_transaction', sortable:false,minWidth: 150, headerName:Helper.getLabel(language,'tax',"Tax"), editable: false,cellRenderer:taxTransactionViewCell,filter:false})
}
if(props.formType==='rfq'){
    Columns_HeadingsPart3.push({field:'material_service_not_included', minWidth: 150, headerName:Helper.getLabel(language,'material_service_not_included',"Material service not included"), editable: false,hide:true},{field:'scope_of_work', minWidth: 150, headerName:Helper.getLabel(language,'scope_of_work',"Scope of work"), editable: false,hide:true},{field:'requirement_description', minWidth: 150, headerName:Helper.getLabel(language,'agreement_terms',"Agreement terms"), editable: false,hide:true})
}

  let Columns_Headings = Columns_HeadingsPart1.concat(Columns_HeadingsPart2,Columns_HeadingsPart3);

  Columns_Headings = getAdditionalHeaders(Columns_Headings)

  let distributionSettings = new SettingsPoDistribution({requester_id:props.defaultValues.requester_id});  

  let budgetCheckColumns = [
    {
      field: "budget_check",
      headerName: Helper.getLabel(
        language,
        "budget",
        "Budget"
      ),
      name: "budget_check",
      // cellRenderer: budgetCheckCellRenderer, 
      cellRendererParams: {
        innerRenderer: (params) => {
          return budgetCheckCellRenderer(params)
        },
      },
      editable:false,
    },
    {
      field: "budget_check_response",
      headerName: Helper.getLabel(
        language,
        "budget_check_error",
        "Budget check error"
      ),
      name: "budget_check_response",
      valueFormatter:(params)=>{return params.value === null || params.value===undefined ? "-" : JSON.stringify(params.value)},
      editable:false,
    },
  ]

  let distributionColumns = [

    {field:'entity_id',inputId:'entity_id',minWidth: 180,cellDataType:false,headerName:Helper.getLabel(language,'entity',"Entity"),cellEditor:SimpleDropdown,cellEditorParams:{values:entitiesList},valueFormatter:(params)=>getLabelFromDropdown(params),editable:true},
    {
        field:'site_id',minWidth: 180,cellDataType:false,headerName:Helper.getLabel(language,'site',"Site"), 
        cellRenderer : SiteCellRenderer,
        inputId:'site_id',
        cellEditor:AdvancedDropdown,
        editable:true,
        cellEditorParams:{
            lynSettings: {
                dependentSelector:['entity_id'],
                apiUrl:Settings.apiUrl +  '/get_site_entity',
                apiRequestMethod:'get',
                options: (apiResponse) => {
                    let output = apiResponse.data.data.map( item => {
                        return {
                            label: `${item.name}`,
                            value: item.site_id
                        }
                    });
                    return output;
                }
            }
        }
    },
    {
        field:'warehouse_id',minWidth: 180,cellDataType:false,headerName:Helper.getLabel(language,'warehouse',"Warehouse"),
        cellRenderer : WarehouseCellRenderer,
        inputId:'warehouse_id',
        cellEditor:AdvancedDropdown,
        editable:true,
        cellEditorParams:{
            lynSettings: {
                dependentSelector:['site_id'],
                resetDependentSelector:['entity_id'],
                apiUrl:Settings.apiUrl +  '/get_warehouse_site',
                apiRequestMethod:'get',
                options: (apiResponse) => {
                    let output = apiResponse.data.data.map( item => {
                        return {
                            label: `${item.name}`,
                            value: item.warehouse_id
                        }
                    });
                    return output;
                }
            }
        }
    },
    props.viewType === 'invoice' ? {field:'receiving_reference_number', editable: false, headerName:Helper.getLabel(language,'receiving_no','Receiving no.'),minWidth: 200}:{hide:'true'},
    {field:'ordered_qty',headerName:Helper.getLabel(language,'quantity',"Quantity"), editable:true,cellEditor:NumberCellEditor,cellRenderer:NumberCellRenderer},
    {field:'received_qty',positive:true,editable: canEditReceivedQty, hide:props.hideExtraColumn ? props.hideExtraColumn: hideReceivedQty, cellEditor:NumberCellEditor, cellRenderer:NumberCellRenderer,minWidth:200,headerName: receivingLabel, maxValue:(oldValue,params)=>{return getReceivedQuanityMaxValue(oldValue,params)}},
    {field:'remaining_qty',editable: false, hide:props.hideExtraColumn? props.hideExtraColumn:hideRemainingQty, minWidth:200,headerName:remainingLabel,   valueFormatter:(params)=>props.shipmentType==="invoice"?parseInt(params.data.received_qty)>parseInt(params.data.remaining_qty)?"-":parseInt(params.data?.remaining_qty) - parseInt(params.data?.received_qty):commonThousandSeparator(params.data?.remaining_qty)},
    
    {field:'delivery_date',headerName:Helper.getLabel(language,'delivery_date',"Delivery date"), name: "delivery_date_po_lines",defaultValue:Helper.formateDate(props.delivery_date),minWidth:180, cellEditor:DateEditor, cellEditorParams:{empty:true}, editable:true,valueFormatter:(params)=>DatepickerFunctions.convertDateFromDataBase(params.value)},
    {field:'start_date',headerName:Helper.getLabel(language,'start_date',"Start date"), cellEditor:DateEditor, cellEditorParams:{empty:true}, editable:true,valueFormatter:(params)=>DatepickerFunctions.convertDateFromDataBase(params.value)},
    {field:'end_date',headerName:Helper.getLabel(language,'end_date',"End date"), cellEditorParams:{empty:true}, cellEditor:DateEditor, editable:true,cellEditorParams:{empty:true},valueFormatter:(params)=>DatepickerFunctions.convertDateFromDataBase(params.value)},
    {field:'line_status',headerName:Helper.getLabel(language,'status',"Status"),cellEditor:SimpleDropdown , cellEditorParams:{values:linestatusList,defaultValues:1}, editable:false,valueFormatter:(params)=>getLabelFromDropdown(params)}
]

if (props.includeBudgetColumns) {
  distributionColumns.unshift(...budgetCheckColumns); 
}

  distributionSettings.setDistributionHeader(distributionColumns);
distributionSettings.setAdditionalFields([
    {
        name:'requisition_number', 
        label:Helper.getLabel(language,'purchase_requisition_number',"Purchase requisition number"),
        disable:props.formType === "rfq" ? props.prDisableCondition : false,
        inputType:'link'
    },
    {
        name:'requester_id', 
        label:Helper.getLabel(language,'requester',"Requester"),
        id:'dis_requester_id',
        inputType:'dropdown',
        options:employeeList,
        placeHolder:'Select requester'
    },
    // {
    //     name:'under_delivery_tolerance',
    //     label:Helper.getLabel(language,'under_delivery_tolerance',"Under delivery tolerence"),
    //     inputType:'checkbox',
    //     valueFormatter:(value) => { return parseInt(value) == 1 ? true: false}
    // },
    // {
    //     name:'over_delivery_tolearance',
    //     label:Helper.getLabel(language,'over_delivery_tolearance',"Over delivery tolerence"),
    //     inputType:'checkbox',
    //     valueFormatter:(value) => { return parseInt(value) == 1 ? true: false}
    // },
    // {
    //     name:'overreceipt_action',
    //     label:Helper.getLabel(language,'over_receipt_action',"Over receipt action"),
    //     inputType:'checkbox',
    //     valueFormatter:(value) => { return parseInt(value) == 1 ? true: false}
    // },
    {
        name:'received_qty', 
        label:Helper.getLabel(language,'received_qty',"Received quantity"),
        disable:true
    },{
        name:'return_qty', 
        label:Helper.getLabel(language,'return_qty',"Return quantity"),
        disable:true
    },{
        name:'canceled_qty',
        label:Helper.getLabel(language,'canceled_qty',"Canceled quantity"),
        disable:true
    },{
        name:'accepted_qty', 
        label:Helper.getLabel(language,'accepted_qty',"Accepted quantity"),
        disable:true
    },
])

  //grid settings
  let gridSettings = {
    ...lineGridSettingsFromProps,
    loadingOverlayComponent: ItemSearchComponent,
    onCellKeyDown: (params) => {
      let unit_price = 0,
        ordered_qty = 0,
        discount_amount = 0,
        discount_percentage = 0
      let gridApi = poLineGrid.api
      const editedRowNode = params.node
      const cellEditorInstances = gridApi.getCellEditorInstances({
        rowNodes: [editedRowNode],
      })
      cellEditorInstances.forEach((cellEditor) => {
        const inputElement = cellEditor.getGui().querySelector('input[type="number"]')
        if (inputElement && typeof cellEditor.params == 'object') {
          if (cellEditor.params.colDef.field == 'ordered_qty') {
            ordered_qty = inputElement.value
          }
          if (cellEditor.params.colDef.field == 'unit_price') {
            unit_price = inputElement.value
          }
          if (cellEditor.params.colDef.field == 'discount_amount') {
            discount_amount = inputElement.value
          }
          if (cellEditor.params.colDef.field == 'discount_percentage') {
            discount_percentage = inputElement.value
          }
        }
      })
      let lineAmount = getLineViewAmount(params, {
        ordered_qty,
        unit_price,
        discount_amount,
        discount_percentage,
      })
      $('.ag-cell-inline-editing')
        .next('.line_amount_' + params.rowIndex)
        .text(!lineAmount ? 0 : lineAmount)
    },
  }

  const onAddRow = (newAddItems) => {
    const rowIndex = 0
    const transactionValue = {
      addIndex: rowIndex != null ? rowIndex : undefined,
      add: [createRow(newAddItems,poLineGrid?.api,props.source_id)],
    }
    poLineGrid?.api?.applyServerSideTransaction(transactionValue)
    AddBtn=true
    transaction=transactionValue
    setTimeout(()=>{
      AddBtn=false
    },2500)
  }

  //Extra Functions
  const onCategoryAddHandler = (e) => {
    const newAddItems = {line_type:1}
    onAddRow(newAddItems)
    return
  }

  const onCommentAddHandler = (e) => {
    const newAddItems = {line_type:2}
    onAddRow(newAddItems)
  }

  const onTaxTransactionPopupClose = () => {
    lineIdForTax = null
    setShowTransactionPopup(false)
  }

 const shouldStopEditLineGridRow = (data) => {
    if (props.viewType == 'amendment' && data.line_id && !data.is_amendmentline) {
      return true
    }
    return false
  }

  const hasSelected = () => {
    if(!activePOLine){
        return false;
    }
    if(Object.keys(activePOLine).length >= 1){
        if(!activePOLine.data.line_id){
            return false
        }
        return true;
    }
    
    return false;
}

  const calculateActiveKey = () => {
    if (!props.hideDistribution) {
      return "distribution";
    }
    if (!props.hideGeneral) {
      return "general";
    }
    if (!props.hideSetup && hasSelected()) {
      return "setup";
    }
    if (!props.hideBillingRule && hasSelected()) {
      return "billingrule";
    }
    return "distribution"; 
}

const isDistributionEditable = () => {
    if(props.viewType == 'amendment'){
        return false;
    }
    return props.isEditable;
}

const isBillingRuleEditable = () => {
    if(props.viewType == 'amendment'){
        return false;
    }
    return props.isEditable;
}

  const onLineGridReady = (lineGridObj) => {
    poLineGrid = lineGridObj
    if (props.onLineGridReady && typeof props.onLineGridReady === 'function') {
      props.onLineGridReady(lineGridObj)
    }
  }

  const onCellClicked = (event) => {
    if (props.onCellClicked && typeof props.onCellClicked === 'function') {
      props.onCellClicked(event)
    }
    if (onLineCellClickHandler && typeof onLineCellClickHandler === 'function') {
      onLineCellClickHandler(event)
    }
  }

  const onLineCellClickHandler = (params) => {
    if (params.colDef.field == 'view_tax_transaction') {
      lineIdForTax = params.data.line_id
      setShowTransactionPopup(true)
    }
  }

  const onSelectionChanged = (event) => {
    let activeSelectedPOLine = event.api.getSelectedNodes()
    if (props.onLineSelectionChanged && typeof props.onLineSelectionChanged == 'function') {
      props.onLineSelectionChanged(event)
    }
    if (activeSelectedPOLine.length <= 0) {
      setActivePOLine(null)
    } else {
      setActivePOLine(activeSelectedPOLine[0])
    }
  }

  const onRowEditingStarted = (event) => {
    if (shouldStopEditLineGridRow(event.data)) {
      event.api.stopEditing()
      return
    }
    event.node.setSelected(true)
  }

  const poDeleteHandler = useCallback(async (element) => {
    let api = Api
    api.setUserToken()
    try {
      const res = await api.axios().delete(`${Settings.apiPurchaseOrderUrl + `/line/` + element?.data?.line_id + '/' + props.line_source}`)
      const rowNode = poLineGrid?.api?.getRowNode(element?.data?.line_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        poLineGrid?.api?.applyServerSideTransaction(transaction)
      }, 300)
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const addNewLines = (data,params) => {
    let api = Api
    api.setUserToken();
    if(props.viewType == 'amendment'){
        data.is_amendmentline = true;
        data.amendment_id = props.amendment_id;
        data.amendment_nature = 3;
        data.linenumber = null;
    }
    api.axios().post(Settings.apiPurchaseOrderUrl+'/lines',data).then(res => {
        Helper.alert(res.data.message,"success")
        setTimeout(()=>{
            poLineGrid?.api?.refreshServerSide({purge:false})
        },100)
        params?.node?.setDataValue('line_id',res.data?.line_id)
        setTimeout(() => {
          if (AddBtn) {
            poLineGrid?.api?.applyServerSideTransaction(transaction);
          }else{
            poLineGrid?.api?.forEachNode((node)=>{
              if(node?.data?.line_id===res?.data?.line_id){               
                node.setSelected(true)
              }
            })
          }
        },700);
        // poLineGrid?.api?.deselectAll()
    }).catch(error=>{
        getPopupMessageBasedOnStatus(error)
    })
  }

  const updateLines = (data,line_id,isNeedRefresh) => {
    if(shouldStopEditLineGridRow(data)){
        return;
    }
    if(props.lineRecommended){
        return
    }
    let api = Api
    api.setUserToken()
    let apiUrl = Settings.apiPurchaseOrderUrl+`/line/${line_id}/${props.line_source}`;
    if(props.viewType == 'shipment'){
        apiUrl = Settings.apiPurchaseOrderUrl +'/sh_linesdetail/'+data.line_id;
    }
    api.axios().put(apiUrl,data).then(res => {
      if(isNeedRefresh){
        Helper.alert(res.data.message,"success")
        setTimeout(()=>{
          poLineGrid?.api?.refreshServerSide({purge:false})
         },200)
        poLineGrid?.api?.deselectAll()
      }
        setUpdatedData({})
        setTimeout(()=>{
          const rowNode = poLineGrid?.api?.getRowNode(line_id)
          if (rowNode) {
            rowNode.setSelected(false)
          }
        },400)
    }).catch(error=>{
       setUpdatedData({})
       getPopupMessageBasedOnStatus(error)
    })
  }

  const handleAddBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    let data = event?.data
    let line_amount = Helper.formateCurrency(getLineViewAmount(event))
    let addPayload = {
      item_id:data?.item_id === "" ? null : data?.item_id,
      line_type:data?.line_type,
      unit:data?.unit===""?null:data?.unit,
      unit_price:parseFloat(data?.unit_price) || 0,
      discount_amount:parseFloat(data?.discount_amount) || 0,
      discount_percentage:parseFloat(data?.discount_percentage) || 0,
      line_amount:parseFloat(Helper.getCurrencyForDb(line_amount))||0,
      ordered_qty:parseInt(data?.ordered_qty),
      comments:data?.comments || null,
      category_id:data?.category_id || null,
      source_id:props.source_id,
      header_id:parseInt(props.header_id),
      itemgroup_id:data?.itemgroup_id===""?null:data?.itemgroup_id,
      alternate_line: props.alternate_line === true ? true : false,
    }
    let updatePayload = {
      item_id:data?.item_id,
      unit:data?.unit,
      unit_price:parseFloat(data?.unit_price) === parseFloat(0) ? 0 : parseFloat(data?.unit_price) || null,
      discount_amount:Helper.getCurrencyForDb(data?.discount_amount) || 0,
      discount_percentage:Helper.getOnlyNumber(data?.discount_percentage) || 0,
      line_amount:Helper.getCurrencyForDb(line_amount)||0,
      ordered_qty:parseInt(data?.ordered_qty),
      comments:data?.comments || null,
      category_id:data?.category_id || null,
      itemgroup_id:data?.itemgroup_id,
    }
    if(props.alternate_line){
      addPayload.vendorreplyline_id=props.vendorReplyLineId
    } 
    if(props.viewType==='rfq' || props.formType==='rfq'){
      addPayload.required_qty=data?.required_qty
      updatePayload.required_qty=data?.required_qty
    }
    if(props.formType==="purchase agreement"){
      addPayload.commitment_level=parseInt(data.commitment_level)
      addPayload.start_date = data.start_date === null || data.start_date===undefined ? null : DatepickerFunctions?.convertDateForDataBase(DatepickerFunctions?.convertDateFromDataBase(data.start_date))
      addPayload.end_date=data.end_date === null || data.end_date===undefined? null :DatepickerFunctions.convertDateForDataBase(DatepickerFunctions?.convertDateFromDataBase(data.end_date))
      addPayload.max_is_enforced=data.max_is_enforced
      addPayload.min_release_amount=parseFloat(data.min_release_amount)
      addPayload.max_release_amount=parseFloat(data.max_release_amount)
      addPayload.enforce_same_category_item_release=data.enforce_same_category_item_release
    }
    if(props.formType==="purchase agreement"){
      updatePayload.commitment_level=parseInt(data.commitment_level)
      updatePayload.start_date = data.start_date === null || data.start_date===undefined ? '' : DatepickerFunctions?.convertDateForDataBase(DatepickerFunctions?.convertDateFromDataBase(data.start_date))
      updatePayload.end_date= data.end_date === null || data.end_date===undefined? '' :DatepickerFunctions.convertDateForDataBase(DatepickerFunctions?.convertDateFromDataBase(data.end_date))
      updatePayload.max_is_enforced=data.max_is_enforced
      updatePayload.min_release_amount=parseFloat(data.min_release_amount)
      updatePayload.max_release_amount=parseFloat(data.max_release_amount)
      updatePayload.enforce_same_category_item_release=data.enforce_same_category_item_release
    }
    if(props.formType==="release order"){
      addPayload.agreement_id=parseInt(props?.agreementId)
      addPayload.agreementline_id=parseInt(data.agreementline_id)
      updatePayload.agreement_id=parseInt(props?.agreementId)
      updatePayload.agreementline_id=parseInt(data.agreementline_id)
    }
    if(data?.line_id){
        updateLines(updatePayload,data?.line_id,event,true)
    }else{
        addNewLines(addPayload)
    }
  }

  const onDistributionRowUpdated = (params) => {
    if(props.viewType == 'shipment'){
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseOrderUrl+'/sh_line/'+params.line_id).then(resLine => {
            activePOLine.setDataValue("received_qty", resLine.data.data.received_qty);
            activePOLine.setDataValue("remaining_qty", resLine.data.data.remaining_qty);
            activePOLine.setDataValue("line_amount", resLine.data.data.line_amount)
        })
    } else if(props.viewType==="invoice"){
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiPurchaseOrderUrl+'/lines/'+props.header_id + `/${props.source_id}`).then(resLine => {
            resLine.data?.data?.map((line)=>{
            if (line.line_id === params.line_id) { 
                activePOLine.setDataValue("received_qty", line.received_qty);
                activePOLine.setDataValue("remaining_qty", line.remaining_qty);
                activePOLine.setDataValue("line_amount", line.line_amount);     
              }
            })
        })
    }
 }
 let url = Settings.apiPurchaseOrderUrl+`/lines/${props.header_id}/${props.line_source}`;
 if(props.viewType =='version'){
     url = Settings.apiPurchaseOrderUrl+`/headerlines_version/${props.header_id}/${props.version}/${props.line_source}`; 
 }
 if(props.alternate_line){
     url = Settings.apiPurchaseOrderUrl + `/get_alternatelines/${props.vendorReplyLineId}`
 }
 if(props.viewType =='shipment'){
     url = Settings.apiPurchaseOrderUrl+`/get_sh_lines/${props.header_id}`; 
 }
 if(props.viewType=='amendment'){
   url = Settings.apiPurchaseOrderUrl+'/amendmentline/'+ props.amendment_id
 }

 if(props.viewType == 'alternateline version'){
  url = Settings.apiPurchaseOrderUrl + `/alternate_line_version/${props?.header_id}/${props.version}/${props.line_source}`
 }

 let isCanEdit = isDistributionEditable()

  if(props.formType==="purchase agreement"){
    newAddItems.max_is_enforced=false
    newAddItems.enforce_same_category_item_release=false
  }

  return (
    <div className='po_list_view_section line-view-grid'>
      {showTransactionPopup && lineIdForTax ? <TaxTransactionPopup onTaxTransactionPopupClose={onTaxTransactionPopupClose} line_id={lineIdForTax} source_id={source_id} integrator={props.header_id} /> : ''}
      <Collapse open={true} title={lineGridTitle} className='po_line_grid_collapse grid_save_btn_hide'>
        <AgGridNew
          addBtnDissable={!props.isEditable}
          readOnlyGrid={!props.isEditable}
          handleDeleteSelectedRows={poDeleteHandler}
          uniqueField={'line_id'}
          rowType={props.rowType ? props.rowType : 'single'}
          apiUrl={url}
          gridId={props.lineGridId ? props.lineGridId : 'po_line_grid'}
          onGridReady={onLineGridReady}
          onSelectionChanged={onSelectionChanged}
          onRowValueChanged={onRowValueChanged}
          masterSettings={gridSettings}
          newAddItems={newAddItems}
          addLabel={'Add Item'}
          height={500}
          columnDefs={props.header ? props.header : Columns_Headings}
          fetchData={props.fetchData}
          returnApiCondition={props.returnApiCondition ? props.returnApiCondition : !props.header_id}
          onRowEditingStarted={onRowEditingStarted}
          //   onRowEditingStopped={onRowEditingStopped}
          beforeDeleteBtns={props.hideMoreBtns?<></>:<BeforeDeleteButton />}
          afterDeleteBtns={<AfterDeleteButton />}
          onCellClicked={props.onCellClicked ? onCellClicked : onLineCellClickHandler}
          hideAddBtn={props.hideAddBtn}
          hideDeleteBtn={props.hideDeleteBtn}
          isAddBtnDisable={props.disableAllBtn?true:!props.isEditable}
          handleAddButton={handleAddBtn}
          isDeleteBtnDisable={props.disableAllBtn?true:!props.isEditable}
          lineNumberSource={props.source_id}
        />
      </Collapse>
      <div className='line-view-tabs'>
        <Tabs defaultActiveKey={calculateActiveKey()} className='mb-3 mt-4'>
          {!props.hideDistribution && (
            <Tab eventKey='distribution' title='Distribution' disabled={!hasSelected()}>
              {hasSelected() && (
                <DistributionTab
                  settings={distributionSettings}
                  gridId={props.distributionGridId ? props.distributionGridId : 'po_line_distribution_grid'}
                  isEditable={isCanEdit}
                  segment_distribution_source_id={Settings.source_id.PoLoLineSiteSegmentDistribution}
                  source_id={source_id}
                  distributeKey={props.viewType === 'invoice' || props.viewType === 'shipment' ? 'received_qty' : 'ordered_qty'}
                  remaining_qty={activePOLine ? Helper.getOnlyNumber(activePOLine?.data?.remaining_qty) : 0}
                  distributeValue={
                    activePOLine
                      ? props.viewType === 'invoice' || props.viewType === 'shipment'
                        ? Helper.getOnlyNumber(activePOLine?.data?.received_qty)
                        : Helper.getOnlyNumber(activePOLine?.data?.ordered_qty)
                      : 0
                  }
                  lineAmount={activePOLine ? Helper.getOnlyNumber(activePOLine?.data?.line_amount) : 0}
                  po_line_id={activePOLine ? activePOLine?.data?.line_id : null}
                  version={props.version ? props.version : null}
                  line_source={props.line_source}
                  viewType={props.viewType ? props.viewType : 'default'}
                  amendment_id={props.amendment_id ? props.amendment_id : null}
                  shipmentType={props.shipmentType ? props.shipmentType : 'default'}
                  disableDecreaseAmendment={activePOLine && activePOLine.data?.is_amendmentline ? true : false}
                  onRowValueUpdated={onDistributionRowUpdated}
                  hideDistributionInnerTabs={props.hideDistributionInnerTabs}
                  hideAddBtn={props.hideAddBtn}
                  hideDeleteBtn={props.hideDeleteBtn}
                  segmentAccountingDate={props.segmentAccountingDate}
                  userType={props.userType}
                  hideAdditionalInformationTab={props.hideAdditionalInformationTab}
                />
              )}
            </Tab>
          )}

          {!props.hideGeneral && (
            <Tab eventKey='general' title='General' disabled={!hasSelected()}>
              {hasSelected() && (
                <GeneralLineDetails
                  isEditable={props?.isGeneralTabEditable===false ? false : props.isEditable}
                  ordered={employeeList}
                  requester={employeeList}
                  activePOLine={activePOLine}
                  grid={poLineGrid}
                  updateData={updateLines}
                  viewType={props.viewType ? props.viewType : 'default'}
                  line_source={props.line_source}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  isPRLinkNeeded={props.isPRLinkNeeded}
                  isRFQLinkNeeded={props.isRFQLinkNeeded}
                />
              )}
            </Tab>
          )}

          {!props.hideSetup && (
            <Tab eventKey='setup' title='Setup' disabled={!hasSelected()}>
              {hasSelected && (
                <SetupTabDetails
                  {...props}
                  taxGroup={taxGroup}
                  taxGroupItems={taxGroupItems}
                  isEditable={props.isEditable}
                  activePOLine={activePOLine}
                  grid={poLineGrid}
                  viewType={props.viewType ? props.viewType : 'default'}
                  updateData={updateLines}
                  formType={props?.formType}
                  commitmentLevelList={commitmentLevelList}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                />
              )}
            </Tab>
          )}

          {!props.hideBillingRule && (
            <Tab eventKey='billingrule' title='Billing Rule' disabled={!hasSelected()}>
              {hasSelected && (
                <BillingRuleView
                  isEditable={isBillingRuleEditable()}
                  employeeList={employeeList}
                  ownerDefaultValue={activePOLine ? activePOLine?.data?.requester_id : 0}
                  line_id={activePOLine ? activePOLine?.data?.line_id : null}
                  item_id={activePOLine ? activePOLine?.data?.item_id : null}
                  units={unitsList}
                  grid={poLineGrid}
                  defaultQuantity={activePOLine ? activePOLine?.data?.ordered_qty : 0}
                  defaultUnitPrice={activePOLine ? activePOLine?.data?.line_amount / activePOLine?.data?.ordered_qty : 0}
                  defaultUnit={activePOLine ? activePOLine?.data?.unit : 0}
                  defaultAmount={activePOLine ? activePOLine?.data?.line_amount : 0}
                  source_id={props.source_id}
                  master_save_button_id={props.master_save_button_id}
                  line_type={activePOLine ? activePOLine?.data?.line_type : ''}
                  version={props.version ? props.version : 0}
                  viewType={props.viewType ? props.viewType : 'default'}
                  amendment_id={props.amendment_id ? props.amendment_id : null}
                  line_source={props.line_source}
                  disableDecreaseAmendment={activePOLine && activePOLine.data?.is_amendmentline ? true : false}
                  billingrulegrid_id={props.billingrulegrid_id}
                  hideAddBtn={props.hideAddBtn}
                  hideDeleteBtn={props.hideDeleteBtn}
                />
              )}
            </Tab>
          )}

          {props.formType === 'rfq' && (
            <Tab eventKey='requirements' title='Requirements' disabled={!hasSelected()}>
              {hasSelected && (
                <RequirementTabs
                  isEditable={props.source_id === Settings.source_id.RfqVendorReply && !props.alternate_line ? false : props.isEditable}
                  activePOLine={activePOLine}
                  grid={poLineGrid}
                  updateData={updateLines}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                />
              )}
            </Tab>
          )}
           {props.formType === 'purchase agreement' && (
            <Tab eventKey='balance' title='Balance' disabled={!hasSelected()}>
              {hasSelected && (
                <PurchaseAgreementFulFillment
                  isEditable={props.isEditable}
                  activePOLine={activePOLine}
                  grid={poLineGrid}
                  updateData={updateLines}
                  viewType={props.viewType ? props.viewType : 'default'}
                  line_source={props.line_source}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}   
              />
              )}
            </Tab>
          )}
        </Tabs>
      </div>
    </div>
  )
}

export default RsWithRouter(RsDataLoader(LineViewComponent, { entity: true, sites: true, warehouse: true, lineSiteStatus: true }))
