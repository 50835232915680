import React, { useEffect, useState } from 'react'
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import AgGridNew from '../../grid/ag/ag-grid-new'
import Helper from '../../../inc/Helper'
import $ from 'jquery'
import ContactPersonModal from './ContactPersonModal'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { getLabelFromDropdown, getNewValue, getOptions, gridLinkValueFormat, optionsKeyCreator, statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'

let gridObj = null

const ContactPerson = (props) => {
  const [contactPersonModal, setContactPersonModal] = useState(false)
  const [genders, setGenders] = useState([])
  const [contactTypes,setContactTypes]=useState([])
  const [designations,setDesignations]=useState([])
  const [contactPersonData,setContactPersonData]=useState({})
  let total = 0

  const getGender = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/employeegender`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setGenders(
            res?.data?.data?.map((item) => {
              return {
                value: item.id,
                label: item.name,
              }
            })
          )
        }
      })
  }

  const getContactTypes = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiVendorUrl + `/vendorcontacttype`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setContactTypes(
            res?.data?.data?.map((item) => {
              return {
                value: item.contacttype_id,
                label: item.description,
              }
            })
          )
        }
      })
  }

  const getDesignations = () => {
    if(!props.isNeedDesignations){
      return
    }
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiVendorUrl + `/vendordesignation`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setDesignations(
            res?.data?.data?.map((item) => {
              return {
                value: item.contactdesignation_id,
                label: item.description,
              }
            })
          )
        }
      })
  }

  useEffect(() => {
    getGender()
    getContactTypes()
    getDesignations()
  }, [])

  const onGridReady = (event) => {
    gridObj = event
  }

  const getTotalItems = () => {
    if (gridObj) {
      gridObj.api.forEachNode((item) => {
        total++
      })
    }
    return total
  }

  const showContactPersonModal = () => {
    setContactPersonData({})
    setContactPersonModal(true)
  }

  const onPopupClose = () => {
    setContactPersonModal(false) 
    gridObj?.api?.refreshServerSide({purge:true})
    props.userGridApi?.current?.api?.refreshServerSide({purge:true})
    $('html').removeClass('rs_popup_open')
  }

  const onCellClickHandler = (event) => {
    if (event?.colDef?.field === 'first_name') {
      setContactPersonData(event?.data)
      setContactPersonModal(true)
    }
  }

  let Columns_Headings = [
    { field: 'first_name', headerName: Helper.getLabel(null, 'first_name', 'First name'), editable: false, minWidth: 180, cellRenderer:gridLinkValueFormat },
    { field: 'middle_name', headerName: Helper.getLabel(null, 'middle_name', 'Middle name'), editable: false, minWidth: 180 },
    { field: 'last_name', headerName: Helper.getLabel(null, 'last_name', 'Last name'), editable: false, minWidth: 180 },
    { field: 'nick_name', headerName: Helper.getLabel(null, 'nick_name', 'Nick name'), editable: false, minWidth: 180 },
    { field: 'gender', headerName: Helper.getLabel(null, 'gender', 'Gender'), editable: false, minWidth: 180, cellEditor:SimpleDropdown,  cellDataType:false,cellEditorParams:{values:genders}, valueFormatter:(params)=> getLabelFromDropdown(params) , filter:'agSetColumnFilter',
    filterParams: {
        values: (params) => getOptions(params, genders),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
    }},
    {
      field: 'is_active',
      headerName: Helper.getLabel(null, 'status', 'Status'),
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: { values: [true, false],
      keyCreator: (params) => params.value, 
      valueFormatter: (params) => params.value ? 'Active' : 'Inactive'},
      cellRenderer: (params) => statusLabelValueFormat(params),
      minWidth: 180,
    },
    { field: 'job_title', headerName: Helper.getLabel(null, 'job_title', 'Job title'), editable: false, minWidth: 180 },
    {
      field: 'is_portal_access_enabled',
      headerName: Helper.getLabel(null, 'is_portal_access_enabled', 'Is portal access enabled'),
      editable: false,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      minWidth: 200,
    },
    {
      field:'signatory',
      headerName: Helper.getLabel(null, 'signatory', 'Signatory'),
      editable: false,
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      minWidth: 200,
    },
    { field: 'designation', headerName: Helper.getLabel(null, 'designation', 'Designation'), editable: false, minWidth: 180, valueFormatter:(params)=> {return params?.data?.designation?.description || "-"},filter:'agSetColumnFilter',
    filterParams: {
        values: (params) => getOptions(params, designations),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
    } },
    { field: 'contact_type', headerName: Helper.getLabel(null, 'contact_type', 'Contact type'), editable: false, minWidth: 180 , valueFormatter:(params)=> {return params?.data?.contact_type?.description || "-"}, filter:'agSetColumnFilter',
    filterParams: {
        values: (params) => getOptions(params, contactTypes),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
    }},
  ]

  if(!props.isNeedDesignations){
    Columns_Headings = Columns_Headings?.filter((item)=>{return item.headerName !== Helper.getLabel(null, 'designation', 'Designation')})
  }

  const goStep = (stepNumber) => {
    getTotalItems()
    if (total > 0) {
      props.updateStep(2, true)
    } else {
      props.updateStep(2, false)
    }
    props.goStep(stepNumber)
  }

  const handleFetchData = (response) => {
    if(props?.updateStep){
      if(response?.data?.data?.length>=1){
        props?.updateStep(2,true)
      }else{
        props?.updateStep(2,false) 
      }
    }
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`,
      })),
      rowCount: response?.data?.data?.length,
    }
  }

  let activeStep = props.activeStep
  let data = props.data
  let disableCondition = data?.document_status !== 'Draft' 
  return (
    <div className='vendor_regis_contact_page'>
      <Collapse title='Contact Person Details' open={true}>
        <AgGridNew
          onGridReady={onGridReady}
          apiUrl={Settings.apiVendorUrl + `/vendorcontact/${props?.vendor_id?props?.vendor_id:props?.data?.vendor_id}`}
          gridId={`vendor_registration_contact_person_grid`}
          hideDeleteBtn={true}
          isAddBtnDisable={props?.disableConditionVendor !== null ? props?.disableConditionVendor : disableCondition}
          isDeleteBtnDisable={props?.disableConditionVendor !== null ? props?.disableConditionVendor : disableCondition}
          columnDefs={Columns_Headings}
          fetchData={handleFetchData}
          onAddClick={showContactPersonModal}
          onCellClicked={onCellClickHandler}
        />
      </Collapse>
      {!props?.needButtons ? <></>:<div className='tabs_changer'>
        {activeStep >= 2 ? <Button className='light_gray btn_back' title='Back' onClick={(e) => goStep(activeStep - 1)} /> : ''}
        <Button className='blue' title='Next' onClick={(e) => goStep(activeStep + 1)} />
      </div>}
      {contactPersonModal && <ContactPersonModal vendor_id={props?.vendor_id?props?.vendor_id:data?.vendor_id} onPopupClose={onPopupClose} contactPersonData={contactPersonData} setContactPersonData={setContactPersonData} genders={genders} contactTypes={contactTypes} designations={designations} gridObj={gridObj} disableCondition={props?.disableConditionVendor !== null ? props?.disableConditionVendor:disableCondition} isNeedDesignations={props.isNeedDesignations} />}
    </div>
  )
}

export default ContactPerson
