import React, { Fragment, useState, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import '../Vendors.css'
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import Helper from '../../../inc/Helper';
import $ from 'jquery'
import Alert from '../../inc/Alert';
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import LinkBtn from '../../inc/LinkBtn';
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterSidebar from '../../Sidebars/MasterSidebar';
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction';
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'; 
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import AddressList from '../../Tenant/Address/AddressList';
import ContactAddress from '../../Tenant/Address/ContactAddress';
import { useNavigate, useParams, useHistory, useLocation } from 'react-router-dom';
import SimpleLoading from '../../Loading/SimpleLoading';
import { areObjectsDifferent, checkDefault, checkIfArrayIsEmpty, checkObjectProperties, getPopupMessageBasedOnStatus, removeDuplicates, toggleActiveClassNameForDefaultSite, ValidateData } from '../../../inc/Validation';
import GeneralEdit from './GeneralEdit';
import BusinessClassEdit from './BusinessClassEdit';
import UsersEdit from './UsersEdit'; 
import VendorSitesEdit from './VendorSitesEdit';
import { gridIdActions } from '../../../store/grid-id';
import VendorCategory from '../VendorCategory/VendorCategory';
import Popup from '../../Popup/Popup';
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls';
import WorkFlowCommonHeader from '../../Header/WorkFlowCommonHeader';
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder';
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'; 
import CommonAPIVendorCalls from './CommonAPIVendorCalls';
import TermsConditionsVendor from '../Vendors Create/TermsConditionsVendor';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import UploadCataloguePopUp from '../../Registrations/Vendor/UploadCataloguePopUp';
import ContactPerson from '../../Registrations/Vendor/ContactPerson';
import OverlayCommon from '../../RequestForQuotation/RFQDetailForm/HeaderView/OverlayCommon';
// Vendors System, (Edit Mode) /// --- Component  , --

// Variables Declaration, in order to utilise it everywhere --
let defaultVendorObject = null
let defaultVendorSiteObject = null
let defaultVendorSitesArray = null
let vendorName = null
let enableSubmitBtn = false;
let defaultProcurementSettingsObj = null
let masterClassType = ''
// ---

// Component / System Initialization --
const VendorsEdit = (props) => {
  // Getting Id From URL --- 
  const { id, instance_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  
  let commonUserDocumentAccess = urlParams?.get('common_user_access') && location?.state?.prevRoutePath === '/dashboard' ? true : false;

  // loading/showing states- 
  const [loading, setLoading] = useState(true)
  const [showCategories, setShowCategories] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(true)
  const [vendorSitesLoading, setVendorSitesLoading] = useState(false)

  // Component Wide -- State management
  const [vendorSites, setVendorSites] = useState([])
  const [vendorCategories, setVendorCategories] = useState([])
  const [editVendor, setEditVendor] = useState({})
  const [vendorSiteHierarchy, setVendorSiteHierarchy] = useState([])
  const [vendorCategory, setVendorCategory] = useState([])
  const [vendorStatus, setVendorStatus] = useState([])
  const [editVendorSite, setEditVendorSite] = useState({})
  const [numberSequence, setNumberSequence] = useState({}); 
  const [uploadCatalogueModal,setUploadCatalogueModal] = useState(false)
  const [scoreSummaryModal,setScoreSummaryModal]=useState(false)

  const [disableFields, setDisableFields] = useState(null)
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)

  // Getting the Respective Site Selected by the user, from the vendor sites list ..--
  const SelectedVendorSite = useSelector(state => state.gridIdFlow.vendorSiteId)
  const dispatch = useDispatch()

  //APIs Dropdowns
  const [vendorType, setVendorType] = useState([])
  const [currency, setCurrency] = useState([])

  // Workflow States
  const [workflowActionHistory, setWorkflowActionHistory] = useState([])
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({})

  const [selectedSupNav, setSelectedSupNav] = useState('Header View');
  
  const [scrollDitection, setScrollDitection] = useState('down');

  const [height, setHeight] = useState(0);

  const myDivRef = useRef(null);
  const userGridApi = useRef(null);

  let sectionName = 'vendor-edit';

  let security = props?.security;

  let isVendorFormVisible = security?.canView(Gui_id_list.masterData.vendors.vendors_edit_screen) || commonUserDocumentAccess;
  let isBtnDisableFromSecurity = !security?.canEdit(Gui_id_list.masterData.vendors.vendors_edit_screen_save_button);
 
  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef,setHeight,window)
  },[myDivRef?.current?.clientHeight,scrollDitection,loading]);

  // System Validations --

  let validator;
  let vendorSitesValidator;
  const validationConfigure = () => {
    let fieldConfigVendor = [
      // { name: 'code', displayName: Helper.getLabel(props.language, 'vendor_code', 'Vendor Code'), types: ['Required'] },
      { name: 'name', displayName: Helper.getLabel(props.language, 'name', 'Name'), types: ['Required'] },
      // { name: 'vendor_type', displayName: Helper.getLabel(props.language, 'type', 'Vendor Type'), types: ['Required'] },
      { name: 'alternate_name', displayName:Helper.getLabel(props.language,'supplier_trade_name_ar','Supplier trade name (AR)'),types:['Required'] },
      { name: 'externalcode', displayName:Helper.getLabel(props.language,'license_number','License number'),types:['Required']},
      // { name: 'dunsnumber', displayName: Helper.getLabel(props.language, 'dunsnumber', 'Duns Number') },
    ];

    let fieldConfigVendorSites = [
      { name: 'name', displayName: Helper.getLabel(props.language, 'vendorsite_name', 'Site Name'), types: ['Required'] },
      { name: 'currency_id', displayName: Helper.getLabel(props.language, 'currency_id', 'Site Currency'), types: ['Required'] },  
      { name: 'vendor_group_id', displayName: Helper.getLabel(props.language, 'vendor_group_id', 'Site Vendor Group'), types: ['Required'] },
    ];

    if (!validator) {
      validator = new FormValidator(fieldConfigVendor, props.language)
    }

    if(!vendorSitesValidator) {
      vendorSitesValidator = new FormValidator(fieldConfigVendorSites, props.language)
    }
  }
  validationConfigure()

  // ---

  // Tabs Component Labels ---

  let navs = [
    { title: Helper.getLabel(props.language, 'general', 'General'), data_section: "one", active_status: 'active_tab' },
    { title: Helper.getLabel(props.language, 'sites', 'Sites'), data_section: "two", active_status: '' },
    { title: Helper.getLabel(props.language, 'business_classification', 'Business Classification'), data_section: "three", active_status: '' },
    { title: Helper.getLabel(props.language, 'address', 'Address'), data_section: "four", active_status: '' },
    { title: Helper.getLabel(props.language, 'contact', 'Contact'), data_section: "five", active_status: '' },
    { title: Helper.getLabel(props.language, 'contact_person', 'Contact Person'), data_section: "six", active_status: '' },
    { title: Helper.getLabel(props.language, 'users', 'Users'), data_section: "seven", active_status: '' },
    { title: Helper.getLabel(props.language, 'categories', 'Categories'), data_section: "eight", active_status: '' }, 
    { title: Helper.getLabel(props.language, 'terms_conditions', 'Terms & Conditions'), data_section: "nine", active_status: '' },
  ];

  const addComponentTop = () => {
    let items = [
      { title: Helper.getLabel(props.language, 'vendor_code','Vendor Code'), subtitle: editVendor.code ? editVendor.code : '' },
      { title: Helper.getLabel(props.language, 'name', 'Name'), subtitle: editVendor.name ? editVendor.name : '' },
      { title: Helper.getLabel(props.language, 'type', 'Type'), subtitle: !ValidateData(vendorType) && editVendor.vendor_type ? (vendorType.find(id => id.value == editVendor.vendor_type) === undefined ? 'Vendor Type Not Exist!' : vendorType.find(id => id.value == editVendor.vendor_type)?.label) : '' }
    ];
    
    let vendorStatusResult = !checkIfArrayIsEmpty(vendorStatus) && vendorStatus.find(val => val.value === editVendor?.status);
    let type = vendorStatusResult
      ? vendorStatusResult.label === "Draft"
        ? 'drafted'
        : vendorStatusResult.label === "Approved"
        ? 'success'
        : ''
      : '';

    let title = vendorStatusResult ? vendorStatusResult.label : ''; 
    let tags = [{ type, title }]; 
    return (
      <div className='rfq-internal-blue'>
       <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
      </div>
    )
  }

  const addComponentRight = () => {
    return (
      <div className='parent-sidebar-cn'>
        <MasterSidebar>
          <NoteAttachmentHistory
            openCanvas={setShowCanvas}
            openCurrentAssignees={setShowCurrentAssignees}
            hideHistory={props?.user?.usertype === 1 ? true :!workflowActionHistory?.history?.length}
            historyArray={workflowActionHistory}
            disableAttachments={isBtnDisableFromSecurity}
            source_id={Settings.source_id.vendor} 
            notesource_integrator={id}
            attachmentsource_integrator={id}
            scrollDitection={scrollDitection}
            height={height}
            condition={editVendor?.document_status==='Under Review'}
          />
        </MasterSidebar>

        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={Settings.source_id.vendor}
            source_item_id={id}
          />
        }

        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={Settings.source_id.vendor}
            source_item_id={id}
          />
        }
      </div>
    )
  }

  // ----

  //API Reduction Calls
  let vendorTypeCalled = false
  let currenyListCalled = false
  let getVendorCalled = false 
  let getVendorCategoryCalled = false
  let getVendorSitesCalled = false
  let vendorStatusCalled = false
  let defaultProcurementSettingsCalled = false
  let nsApiCalled = false;
  // -- 

  // Component Renders on load --- 

  useEffect(() => {  
    getWorkFlowCalls(true);
    getVendorStatus() 
    getNumberSequenceData() 
    VendorType()
    CurrencyList()  
    getVendorsCategories()
    DefaultProcurementSettingsList()
  }, [id])

  // -----

  // Category Component -- things

  const OpenCategories = () => {
    setShowCategories(true)
  }

  const onPopupCloseHandler = () => {
    setShowCategories(false)
    $('.vn_categories_popup_close .rs_popup_close').trigger('click');
  }

  const CategoryHandler = (id) => {
    if (disableFields || isBtnDisableFromSecurity) return;

    let api = Api
    api.setUserToken()
    api.axios().delete(Settings.apiVendorUrl + `/vendor_category/${id}`, {}).then(function (res) {
      if (res.data.status === 'success') {
        Helper.alert(res.data.message)
        getVendorsCategories()
      }
    }).catch((err) => {
      getPopupMessageBasedOnStatus(err)
      getVendorsCategories()
    })
  }

  // ----

  // API CALLS ----
  const getNumberSequenceData = async () => { 
    if(nsApiCalled) return;
    nsApiCalled = true;

    try {
      let api = Api
      api.setUserToken();
      const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/None/${Settings.source_id.vendor}`)
      if (numberSequenceRecord?.status===200) { 
        setNumberSequence(numberSequenceRecord?.data)
      }
    } catch(error){
      setNumberSequence({})
    }
  };

  const getVendorStatus = () => {
    if (vendorStatusCalled) {
      return;
    }
    let api = Api
    vendorStatusCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + `/enum/vendorstatus`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.id,
            label: element.name
          })
        })
        setVendorStatus(UPDATED_STATE)  
      }
    })
  }

  const getWorkFlowCalls = async (vendorFirstLoad=false) => {
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();

    getVendor(vendorFirstLoad); 

    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.vendor, id);
   
    const trigger = await workflowCalls.getWorkFlowTriggers(); 
    const mode = !checkIfArrayIsEmpty(trigger) && trigger?.find(id => id.name == "Update")?.id

    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props?.user?.tenant_id, Settings.source_id.vendor, id);  
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.vendor, props?.user?.tenant_id, mode); 
         
    if(workflowActionHistory) setWorkflowActionHistory(workflowActionHistory);
    if(workflowExist) setWorkflowExist(workflowExist);
    if(eligibleWorkflow) setWorkflowEligible(eligibleWorkflow);    
  };
  
  // Main Vendors Object For Form
  const getVendor = (firstLoading=false) => {
    if (getVendorCalled) {
      return;
    }
    if(!firstLoading){
      setLoading(true)
    }
    let api = Api
    getVendorCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl + `/vendor/${id}`, {}).then(function (res) {
      if (res.data.status === "success") {
        setLoading(false)
        defaultVendorObject = res.data.data
        vendorName = res.data.data.name
        setEditVendor(res?.data?.data)
        setDisableFields(res?.data?.data?.document_status != "Draft"); 
        VendorSitesList();
      }
    }).catch((res) => {
      setLoading(false)
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getVendorsCategories = () => {
    if (getVendorCategoryCalled) {
      return;
    }

    setCategoriesLoading(true);
    let api = Api
    getVendorCategoryCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl + `/vendorcategories/${id}`, {}).then(function (res) {
      if (res.data.status === "success") {
        let Updated_response = res.data?.data?.map((item) => {
          return {
            name: item.category_name,
            vendorcategory_id: item.vendorcategory_id,
            category_id: item.category_id
          }
        })
        setVendorCategories(Updated_response);
        setCategoriesLoading(false);
      }
    }).catch((err) => {
      setCategoriesLoading(false);
    })
  }
 
  const DefaultProcurementSettingsList = () => {
    if(defaultProcurementSettingsCalled) return;
    let api = Api
    defaultProcurementSettingsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiProcurementSettingsUrl+`/get_default_procurmentsetting`,{}).then(function(res){
      if(res.data.status==="success"){ 
        if(res.data.data){
          defaultProcurementSettingsObj = res.data.data
        }
      }
    })
  }

  // Getting Vendor sites hierarchy data --
  
  const VendorSitesList = () => {
    if (getVendorSitesCalled && !vendorName) {
      return;
    } 
    setEditVendorSite({})
    setVendorSitesLoading(true)
    let api = Api
    getVendorSitesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl + `/get_active_vendor_sites/${id}`, {}).then(function (res) {
      if (res.data.status === "success") {
        defaultVendorSitesArray = res.data.data
        let UPDATED_STATE_DROPDOWN = []
        let SITES_HIERARCHY = []
        const sortedData = res.data.data.sort((a, b) => {
          return b.is_default - a.is_default;
        });
        sortedData?.forEach((element) => {
          UPDATED_STATE_DROPDOWN.push({
            value: element.vendorsite_id,
            label: element.name
          })
          SITES_HIERARCHY.push({
            id: element.vendorsite_id,
            title: element.name
          })
        })
        setVendorSites(UPDATED_STATE_DROPDOWN)
        setVendorSitesLoading(false)
        if (!checkIfArrayIsEmpty(SITES_HIERARCHY)) {
          setVendorSiteHierarchy((prevState) => {
            return {
              ...prevState,
              mainTitle: vendorName,
              data: []
            }
          }) 
        }
        setVendorSiteHierarchy((prevState) => {
          return {
            ...prevState,
            mainTitle: vendorName,
            data: SITES_HIERARCHY
          }
        })
        dispatch(gridIdActions.VendorID({ payload: res.data.data[0]?.vendorsite_id }))
        toggleActiveClassNameForDefaultSite() // Getting Vendor sites hierarchy data --
        if(checkIfArrayIsEmpty(res.data.data)) { 
          setDefaultProcurementSettingsValues()
        }
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)
      setVendorSitesLoading(false)
    })
  }

  // Getting data from vendor site Component -
  const getDataFromVendorSite = (data) => {
    defaultVendorSiteObject = data
  }

  const setDefaultProcurementSettingsValues = () => {
    if(defaultProcurementSettingsObj) {
      setEditVendorSite((prevState) => {
        return {
          ...prevState,
          currency_id: defaultProcurementSettingsObj.currency_id,
          vendor_group_id: defaultProcurementSettingsObj.vendorgroup_id, 
          taxgroup_id: defaultProcurementSettingsObj.taxgroup_id
        }
      })
    }
  }

  // Deleting the selected vendor's site ---
  const DeleteVendorSite = () => {
    if (!SelectedVendorSite) {
      Helper.alert('Select A Vendor Site To Delete!', 'error')
      return;
    }
    let api = Api
    api.setUserToken()
    api.axios().delete(Settings.apiVendorUrl + `/vendorsite/${SelectedVendorSite}`, {}).then(function (res) {
      if (res.data.status === "success") {
        Helper.alert(res.data.message)
        VendorSitesList()
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)
      VendorSitesList()
    })
  }
  // --- 

  const VendorType = () => {
    if (vendorTypeCalled) {
      return;
    }
    let api = Api
    vendorTypeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + `/enum/vendortype`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.id,
            label: element.name
          })
        })
        setVendorType(UPDATED_STATE)
      }
    })
  }

  const CurrencyList = () => {
    if (currenyListCalled) {
      return
    }
    let api = Api
    currenyListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl + `/currency`, {}).then(function (res) {
      if (res.data.status === "success") {
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.currency_id,
            label: element.currency_name
          })
        })
        setCurrency(UPDATED_STATE)
      }
    })
  }
 
  // Execution Of Vendor's Contact --- 
  const ContactCreateVendor = (data) => {
    if (editVendor.contactsource_id !== null) {
      return;
    }
    setEditVendor({
      ...editVendor,
      contactsource_id:data.contact_source_id
    })
  }

  // Execution Of Vendor's Address --- 
  const AddressCreateVendor = (data) => {
    if (editVendor.addresssource_id !== null) {
      return;
    }
    setEditVendor({
      ...editVendor,
      addresssource_id:data.address_source_id
    })
  }
  
  // API Executions for Update & Creation's 
  const getDocumentStatusBasedOnUserClick = (action) => {
    if(action === 'submit' || action === 'save') {
      return editVendor?.status;
    }
    if(action === 'edit') {
      return !checkIfArrayIsEmpty(vendorStatus) && vendorStatus.find(val => val.label == "Draft").value;
    }
    if(action === 'confirm') {
      return !checkIfArrayIsEmpty(vendorStatus) && vendorStatus.find(val => val.label == "Approved").value;
    }
  }

  const checkWorkflowExecution = (action) => {
    if(action === 'submit' || action === 'save') return !enableSubmitBtn ? true : false;
    return false;
  }

  const ExecuteVendorUpdateAPI = (comment, actionType) => { 
    let api = Api
    api.setUserToken() 
    if( actionType === 'edit' || actionType === 'confirm' || actionType === 'submit' ){
      setLoading(true);
      api.axios().put(Settings.apiVendorUrl + `/update_vendorstatus/${id}`, {
        "workflow_initiated": checkWorkflowExecution(actionType),
        "comment": comment == '' || comment == 'execute_api' ? null : comment,
        "status": getDocumentStatusBasedOnUserClick(actionType)
      }).then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message)
          if(actionType === 'edit') {
            Helper.onEditClickAlert('Note: This Vendor is now in Draft mode, hence, it cannot be participated, until its Approved!');
          };
          getWorkFlowCalls()
        }
      }).catch((res) => { 
        getPopupMessageBasedOnStatus(res);
        getWorkFlowCalls()
      })
      return;
    } 
    
    if(!areObjectsDifferent(defaultVendorObject, editVendor)) return;
    
    setLoading(true);
    api.axios().put(Settings.apiVendorUrl + `/vendor/${id}`, {
      "code": editVendor.code == defaultVendorObject.code ? null : editVendor.code,
      "name": editVendor.name !== "" ? editVendor.name : "",
      "alternate_name": editVendor.alternate_name !== "" ? editVendor.alternate_name : "",
      "license_issued_by": editVendor.license_issued_by !== "" ? editVendor.license_issued_by : "",
      "license_issued_by_other": editVendor.license_issued_by_other !== "" ? editVendor.license_issued_by_other : "",
      "legacy_code": editVendor.legacy_code !== "" ? editVendor.legacy_code : "",
      "brand_name": editVendor.brand_name !== "" ? editVendor.brand_name : "",
      // "vendor_type": editVendor.vendor_type !== "" ? parseInt(editVendor.vendor_type) : null,
      "onetime": editVendor.onetime == "on" || editVendor.onetime == true ? true : false,
      "dunsnumber": editVendor.dunsnumber !== "" ? (editVendor.dunsnumber) : "",
      "yearestablished": DatepickerFunctions.convertDateForDataBase(editVendor.yearestablished),
      "incorporationyear": DatepickerFunctions.convertDateForDataBase(editVendor.incorporationyear),
      "website_url": editVendor.websiteurl !== "" ? editVendor.websiteurl : "",
      "externalcode": editVendor.externalcode !== "" ? editVendor.externalcode : "",
      "linkedin": editVendor.linkedin !== "" ? editVendor.linkedin : "",
      "facebook": editVendor.facebook !== "" ? editVendor.facebook : "",
      "twitter": editVendor.twitter !== "" ? editVendor.twitter : "",
      "instagram": editVendor.instagram !== "" ? editVendor.instagram : "",
      "youtube": editVendor.youtube !== "" ? editVendor.youtube : "",
      "tiktok":editVendor.tiktok !== "" ? editVendor.tiktok : "",
      "comment": actionType === 'submit' ? comment : null,
      "status": getDocumentStatusBasedOnUserClick(actionType),
      "workflow_initiated": checkWorkflowExecution(actionType),
      "notesource_id": editVendor.notesource_id,
      "agree_on_terms_condition": editVendor.agree_on_terms_condition,
      "attachmentsource_id": editVendor.attachmentsource_id !== "" ? editVendor.attachmentsource_id : null,
      "processinstance_id": editVendor.processinstance_id !== "" ? editVendor.processinstance_id : null,
      "translationsource_id": editVendor.translationsource_id !== "" ? editVendor.translationsource_id : null,
      "addresssource_id": editVendor.addresssource_id ? editVendor.addresssource_id : null,
      "contactsource_id": editVendor.contactsource_id ? editVendor.contactsource_id : null
    }).then(function (res) {
      if (res.data.status === "success") {
        Helper.alert(res.data.message)
        getVendor()
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)
      getVendor()
    })
  }

  const ExecuteVendorSiteUpdateAPI = (actionType) => { 
    let editVendorSitesDetails = { 
      "name": editVendorSite.name,
      "currency_id": editVendorSite.currency_id,  
      "vendor_group_id": editVendorSite.vendor_group_id
    }

    let validData = {
      ...editVendorSitesDetails
    }

    // if (actionType === 'save' || actionType === 'submit') { // uncomment if needed when to execute the logic only for save, submit actions
      if (!areObjectsDifferent(defaultVendorSiteObject, editVendorSite) || !editVendorSite.vendorsite_id) {
        return;
      }
    // }
  
    // if (editVendorSite.is_default) { // comment out this is_default logic when needed
    //   Helper.alert('Default vendor site is switched!')
    // }

    if (!vendorSitesValidator.isValid(validData)) {
      vendorSitesValidator.displayMessage();
      vendorSitesValidator.reset()
      return;
    }

    if((!editVendorSite?.taxregistration_number || !editVendorSite?.hasOwnProperty('taxregistration_number') || editVendorSite.taxregistration_number === '' || editVendorSite.taxregistration_number === undefined) && editVendorSite?.exempted_from_tax === false) {
      Helper.alert('Tax registration number field is required', 'error');
      return;
    }
    
    if(editVendorSite?.exempted_from_tax === true && (!editVendorSite?.reason_for_tax_exemption || editVendorSite?.reason_for_tax_exemption === "" || !editVendorSite?.hasOwnProperty('reason_for_tax_exemption') || editVendorSite?.reason_for_tax_exemption === undefined)){
      Helper.alert('Reason for tax exemption is required','error')
      return;
    }

    setLoading(true);
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiVendorUrl + `/vendorsite/${editVendorSite.vendorsite_id}`, {
      "vendor_id": id,
      "name": editVendorSite.name !== "" ? editVendorSite.name : "", 
      "exclude_freight_from_discount": editVendorSite.exclude_freight_from_discount !== "" ? editVendorSite.exclude_freight_from_discount : null,
      "segment_combination_id": editVendorSite.segment_combination_id !== "" ? editVendorSite.segment_combination_id : null,
      "vendor_group_id": parseInt(editVendorSite.vendor_group_id),
      "exempted_from_tax": editVendorSite?.exempted_from_tax === true,
      "taxregistration_number": editVendorSite?.taxregistration_number,
      "reason_for_tax_exemption": editVendorSite?.reason_for_tax_exemption,
      "currency_id": parseInt(editVendorSite.currency_id),
      "paymentterm_id": editVendorSite.paymentterm_id ? parseInt(editVendorSite.paymentterm_id) : null,
      "address_source_id": editVendorSite.address_source_id,
      "contact_source_id": editVendorSite.contactsource_id,
      "price_include_tax": editVendorSite.price_include_tax,
      "taxgroup_id": editVendorSite.taxgroup_id ? parseInt(editVendorSite.taxgroup_id) : "",
      "is_default": editVendorSite.is_default,
      "account_id": null
    }).then(function (res) {
      if (res.data.status === "success") {
        Helper.alert(res.data.message)
        VendorSitesList()
        setEditVendorSite({})
        dispatch(gridIdActions.VendorID({ payload: null }))
        setLoading(false);
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)
      VendorSitesList()
      setEditVendorSite({})
      dispatch(gridIdActions.VendorID({ payload: null })) 
      setLoading(false);
    })
  }

  const CreateDistributions = (distribution_source_id) => {
    if(!distribution_source_id) return;

    let api = Api
    api.setUserToken()  
    api.axios().put(Settings.apiVendorUrl+`/vendorsite/${editVendorSite.vendorsite_id}`,{
      "distributionsource_id": distribution_source_id, 
    }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message);
      }
    }).catch((res) => {  
      getPopupMessageBasedOnStatus(res);
    }) 
  }

  const ExecuteVendorSiteAddAPI = () => {
    let editVendorSitesDetails = { 
      "name": editVendorSite.name,
      "currency_id": editVendorSite.currency_id, 
      "vendor_group_id": editVendorSite.vendor_group_id
    }

    let validData = {
      ...editVendorSitesDetails
    }
 
    if (editVendorSite.vendorsite_id) {
      return;
    }

    if (!checkObjectProperties(editVendorSite)) {
      return;
    }

    if (!vendorSitesValidator.isValid(validData)) {
      vendorSitesValidator.displayMessage();
      vendorSitesValidator.reset()
      return;
    }

    if (editVendorSite.is_default) {
      if (checkDefault(defaultVendorSitesArray)) {
        Helper.alert('Default vendor site is switched!');
      }
    }

    if((!editVendorSite?.taxregistration_number || !editVendorSite?.hasOwnProperty('taxregistration_number') || editVendorSite.taxregistration_number === '' || editVendorSite.taxregistration_number === undefined) && (editVendorSite?.exempted_from_tax === false || !editVendorSite?.hasOwnProperty('exempted_from_tax'))) {
      Helper.alert('Tax registration number field is required', 'error');
      return;
    }
    
    if(editVendorSite?.exempted_from_tax === true && (!editVendorSite?.reason_for_tax_exemption || editVendorSite?.reason_for_tax_exemption === "" || !editVendorSite?.hasOwnProperty('reason_for_tax_exemption') || editVendorSite?.reason_for_tax_exemption === undefined)){
      Helper.alert('Reason for tax exemption is required','error')
      return;
    }

    setLoading(true);
    let api = Api
    api.setUserToken()
    api.axios().post(Settings.apiVendorUrl + `/vendorsite`, {
      "vendor_sites": [{
        "vendor_id": parseInt(id),
        "exempted_from_tax": editVendorSite?.exempted_from_tax === true, 
        "reason_for_tax_exemption": editVendorSite?.reason_for_tax_exemption,
        "name": editVendorSite.name !== "" ? editVendorSite.name : "",
        "taxpayer_id": editVendorSite.taxpayer_id !== "" ? (editVendorSite.taxpayer_id) : "",
        "taxregistration_number": editVendorSite.taxregistration_number !== "" ? (editVendorSite.taxregistration_number) : null,
        "exclude_freight_from_discount": editVendorSite.exclude_freight_from_discount,
        "segment_combination_id": null,
        "vendor_group_id": parseInt(editVendorSite.vendor_group_id),
        "currency_id": parseInt(editVendorSite.currency_id),
        "paymentterm_id": editVendorSite.paymentterm_id ? parseInt(editVendorSite.paymentterm_id) : null,
        "address": editVendorSite.address,
        "contact": editVendorSite.contact,
        "price_include_tax": editVendorSite.price_include_tax,
        "taxgroup_id": editVendorSite.taxgroup_id,
        "is_default": editVendorSite.is_default,
        "account_id": null
      }]
    }).then(function (res) {
      if (res.data.status === "success") {
        Helper.alert(res.data.message)
        VendorSitesList()
        setEditVendorSite({})
        dispatch(gridIdActions.VendorID({ payload: null }))
        setLoading(false);
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)
      VendorSitesList()
      setEditVendorSite({})
      dispatch(gridIdActions.VendorID({ payload: null }))
      setLoading(false);
    })
  }

  // Modules / Sub-Part Component ----
 
  const ContactModuleForVendor = () => {
    return (
      <Collapse title={<>{Helper.getLabel(props.language, 'contact', 'Contact')}<OverlayCommon toolTipText={"These are the contact details associated with the vendor's main address. Include the primary phone number, email, or other contact information for official communication."} customStyle={{marginLeft:"0.2rem"}}/></>} className="mt-4 mb-4 grid_save_btn_hide contact-vendor" open={true}>
        { loading ? <div style={{height: '500px'}}> <SimpleLoading /> </div> : <ContactAddress disableRecords={disableFields || isBtnDisableFromSecurity} id='vendor_contact' onContactCreate={ContactCreateVendor} contactsource_integrator={id} address_id={null} contactsource_id={editVendor?.contactsource_id} source_id={Settings.source_id.vendor} /> }
      </Collapse>
    )
  }

  const CategoriesModuleForVendor = () => {
    return (
      <Collapse title={Helper.getLabel(props.language, 'categories', 'Categories')} className="mb-4" open={true}>
        { categoriesLoading ? <SimpleLoading /> : 
          <div className='vendor_categories_popup'>
            <div className='action_btns_categories d-flex gap-2 border-bottom pb-2'>
              <button style={{backgroundColor: `${props?.user?.usertype === 1 ? '#F7F9FB' : '#FFF'}`}} disabled={disableFields} onClick={OpenCategories} className='d-flex align-items-center justify-content-center'><span className='me-2 mb-1'><img src='/images/icons/add-new.svg' className={!disableFields ? 'img-fluid' : 'img-fluid disable-img'} /></span> <span style={{fontSize: '16px', color:'#333333'}}>Add</span></button>
            </div>

            <div className='vendor-category-wrapper common_scrollbar_styles'> 
              <div className='display_vendor_categories mt-3'>
                {vendorCategories.map((item) => {
                  return (
                    <div className="chips-list-page mb-1 mt-1 ms-2">
                      <span className='mt-5'>{item.name}</span>
                      {!disableFields && <button className='ms-2' onClick={() => CategoryHandler(item.vendorcategory_id)}>x</button>}
                    </div>
                  )
                })}
              </div>
            </div>
            {showCategories ? <div className='vn_categories_popup_close'><Popup onClose={onPopupCloseHandler} autoOpen={true} width="950px"><VendorCategory currentCategoryArray={vendorCategories?.map(val => val.category_id)} setVendorCategories={setVendorCategory} Vendorcategories={vendorCategory} setShowCategories={setShowCategories} reloadGrid={getVendorsCategories} vendor_id={id} editMode={true} /></Popup></div> : ''}
          </div>
        }
      </Collapse>
    )
  }

  // more navigation items
  const DropdownNavigationTypes = () => { 
    return (
      <ul className='actions_nav'>
        <li onClick={onClickEditListButton} className={`${( (editVendor.document_status == "Draft" || editVendor.document_status == "Under Review") || isBtnDisableFromSecurity ? 'disable' : '')}`}>{Helper.getLabel(props.language,'edit', "Edit")}</li>
        <li onClick={onClickConfirmListButton} className={`${( (editVendor.document_status == "Confirmed" || editVendor.document_status == "Under Review") || isBtnDisableFromSecurity ? 'disable' : '')}`}>{Helper.getLabel(props.language,'confirm', "Confirm")}</li>
        <li onClick={() => navigate(`/vendor-versioning/${id}`,{ state: { prevPath: location?.state?.prevRoutePath } })}>{Helper.getLabel(props.language,'version', "View Version")}</li> 
        {/* <li onClick={onClickUploadCatalogue}>{Helper.getLabel(props.language,'catalogue', "Catalogue")}</li>  */}
      </ul>
    )
  }

  // execution of api for confirm & edit buttons
  const onClickEditListButton = () => {   
    if((editVendor?.document_status == "Draft" || editVendor?.document_status == "Under Review") || isBtnDisableFromSecurity) return;
    ExecuteVendorUpdateAPI('', 'edit');
  }

  const onClickConfirmListButton = () => {
    if((editVendor?.document_status == "Confirmed" || editVendor?.document_status == "Under Review") || isBtnDisableFromSecurity) return;
 
    let editVendorDetails = {
      "code": editVendor.code,
      "name": editVendor.name,
      "alternate_name": editVendor.alternate_name,
      // "vendor_type": editVendor.vendor_type,
      "dunsnumber": editVendor.dunsnumber,
      "externalcode": editVendor.externalcode
    }

    let validData = {
      ...editVendorDetails
    }

    if (!validator.isValid(validData)) {
      validator.displayMessage();
      validator.reset()
      return;
    }
  
    if(!numberSequence?.ns_type == "continuous" && (!editVendor?.code || editVendor?.code === "")) {
      Helper.alert('Vendor code field is required', 'error');
      return;
    }

    //Execute Vendor Update API
    ExecuteVendorUpdateAPI('', 'confirm')
    //Execute Vendor Site Update API 
    ExecuteVendorSiteUpdateAPI('confirm')
    // Execute Vendor Site Create API 
    ExecuteVendorSiteAddAPI()
    // ---
  }

  //logic for upload catalogue popup
  const onClickUploadCatalogue = () => {
    setUploadCatalogueModal(true)
  }

  const onClickScoreSummary= () => {
    setScoreSummaryModal(true)
  }

  const handleUploadModalClose = () => {
    setUploadCatalogueModal(false)
    $('html').removeClass('rs_popup_open');
  }

  const handleScoreSummaryModalClose = () => {
    setScoreSummaryModal(false)
    $('html').removeClass('rs_popup_open');
  }

  // execute api for Workflow execution -
  const SubmitWorkflow = (comment) => {
    enableSubmitBtn = false

    let editVendorDetails = {
      "code": editVendor.code,
      "name": editVendor.name,
      "alternate_name": editVendor.alternate_name,
      // "vendor_type": editVendor.vendor_type,
      "dunsnumber": editVendor.dunsnumber,
      "externalcode": editVendor.externalcode
    }

    let validData = {
      ...editVendorDetails
    }

    if (!validator.isValid(validData)) {
      validator.displayMessage();
      validator.reset()
      return;
    }
 
    if(!numberSequence?.ns_type == "continuous" && (!editVendor?.code || editVendor?.code === "")) {
      Helper.alert('Vendor code field is required', 'error');
      return;
    }

    //Execute Vendor Update API
    ExecuteVendorUpdateAPI(comment, 'submit')
    //Execute Vendor Site Update API 
    ExecuteVendorSiteUpdateAPI('submit')
    // Execute Vendor Site Create API 
    ExecuteVendorSiteAddAPI()
    // --- 
  }

  // execute API for Save handler -

  const SaveHandler = () => {
    enableSubmitBtn = true

    let editVendorDetails = {
      "code": editVendor.code,
      "name": editVendor.name,
      "alternate_name": editVendor.alternate_name, 
      "dunsnumber": editVendor.dunsnumber,
      "externalcode": editVendor.externalcode, 
      // "vendor_type": editVendor.vendor_type,
      "dunsnumber": editVendor.dunsnumber
    }

    let validData = {
      ...editVendorDetails
    }

    if (!validator.isValid(validData)) {
      validator.displayMessage();
      validator.reset()
      return;
    }

    if(!numberSequence?.ns_type == "continuous" && (!editVendor?.code || editVendor?.code === "")) {
      Helper.alert('Vendor code field is required', 'error');
      return;
    }
 
    //Execute Vendor Update API
    ExecuteVendorUpdateAPI('', 'save')
    //Execute Vendor Site Update API 
    ExecuteVendorSiteUpdateAPI('save')
    // Execute Vendor Site Create API 
    ExecuteVendorSiteAddAPI()
    // --- 
  }

  const disableFieldsWithWorkflowLogics = (task_details) => {
    if(editVendor?.document_status === 'Confirmed' || props?.user?.usertype === 1) return;

    const { is_editable, action_taken } = task_details;
     
    const shouldDisable = !(
      (is_editable && (action_taken?.length === 0)) ||
      editVendor?.document_status == "Draft"
    );
    setDisableFields(shouldDisable); 
  };
 
  if (!isVendorFormVisible) {
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }
  
  return ( 
    <Fragment>
      { loading ? <OverlayLoader /> : null }
      {/* {uploadCatalogueModal && <UploadCataloguePopUp handleUploadCatalogueModalClose={handleUploadModalClose} />} */}
      <div className={`${props?.user?.usertype === 1 && 'external_vendor_profile'}`}> 
        <div className="container-fluid vn_edit_screen scrollBarBlinkControl" style={{ padding: props?.user?.usertype === 0 && '0 30px' }}>
          <div
            className={
              props?.user?.usertype === 0
                ? "container-fluid vn_edit_screen"
                : "container-fluid vn_edit_screen vendor-screen-external"
            }
          >
            <>
              <div ref={myDivRef}>
                <NavigationHeder
                  moreOptions={DropdownNavigationTypes}
                  title={  props?.user?.usertype === 0 ? `<span style="color:#000;">${Helper.getLabel( props.language, "Vendor", "Vendor" )
                  } # </span>` + editVendor.code : `<span style="color:#313638;">${Helper.getLabel( props.language, "Vendor", "Vendor" )} # </span>` + editVendor.code}
                >
                  <LinkBtn
                    isActive={false}
                    to={
                      props?.user?.usertype === 0 ? (location?.state?.prevRoutePath ? location?.state?.prevRoutePath : "/vendors") : "/dashboard"
                    }
                    className='black-btn-style'
                    title={Helper.getLabel(props.language, "close", "Close")}
                  />
                  {
                    <Button
                      isDisable={ disableFields || isBtnDisableFromSecurity}
                      isActive={false}
                      title={Helper.getLabel(props.language, "save", "Save")}
                      onClick={SaveHandler}
                      className="rfq_save_btn"
                    />
                  }
                </NavigationHeder>

                {editVendor?.profile_update === true ? null : 
                <div
                  style={{
                    display: scrollDitection == "down" ? "block" : "none",
                  }}
                >
                  { editVendor.document_status != "Confirmed" && !loading && 
                    <WorkFlowCommonHeader
                      isEditableCustomLogicsFunction={disableFieldsWithWorkflowLogics} 
                      workflowInstanceDetails={workflowExist?.instance_details}
                      submitWorkflowAction={
                        !workflowExist?.instance_exist &&
                        workflowEligible &&
                        SubmitWorkflow
                      } 
                      createdby={editVendor?.createdby}
                      instance_task_id={instance_id}
                      module_id={!instance_id ? Settings.source_id.vendor : null}
                      source_id={Settings.source_id.vendor}
                      source_item_id={id}
                      SubmitWorkflowObjDetails={workflowExist?.workflow_name}
                      module_item_id={!instance_id ? id : null}
                      workflowInstanceExist={workflowExist?.instance_exist}
                      workflowEligible={workflowEligible}
                      workflowName={"Vendors Workflow"}
                      reloadFunction={getWorkFlowCalls}
                    />
                  }
                </div>}

                <FixedNavigationHeder
                  addComponentTop={addComponentTop}
                  sectionName={sectionName}
                  navs={navs}
                  selectedSupNav={selectedSupNav}
                  scrollDitection={scrollDitection}
                /> 
              </div>
               
              <TabContentWraper
                height={height}
                setScrollDitection={setScrollDitection}
                leftSideContent={
                  <>
                    <div
                      className="lists_contant one"
                      id={`${sectionName}General`}
                      style={{ position: "relative" }}
                    >
                      <GeneralEdit
                        key={loading ? 'render' : 'rerender'}
                        disableFields={disableFields || isBtnDisableFromSecurity}
                        vendorType={vendorType}
                        editVendor={editVendor}
                        numberSequence={numberSequence}
                        setEditVendor={setEditVendor}
                      />
                    </div>
                    <div
                      className="lists_contant two"
                      id={`${sectionName}Sites`}
                      style={{ position: "relative" }}
                    >
                      <VendorSitesEdit
                        parentFormLoading={loading}
                        setDefaultProcurementSettingsValues={setDefaultProcurementSettingsValues}
                        CreateDistributions={CreateDistributions}
                        disableFields={disableFields || isBtnDisableFromSecurity}
                        getDataFromVendorSite={getDataFromVendorSite}
                        vendorSitesLoading={vendorSitesLoading}
                        vendorSites={vendorSites}
                        currency={currency}
                        DeleteVendorSite={DeleteVendorSite}
                        vendorSiteHierarchy={vendorSiteHierarchy}
                        id={id}
                        editVendorSite={editVendorSite}
                        setEditVendorSite={setEditVendorSite}
                      />
                    </div>
                    <div
                      className="lists_contant three"
                      id={`${sectionName}Business Classification`}
                      style={{ position: "relative" }}
                    >
                      <BusinessClassEdit disableFields={disableFields || isBtnDisableFromSecurity} id={id} />
                    </div>
                    <div
                      className="lists_contant four"
                      id={`${sectionName}Address`}
                      style={{ position: "relative" }}
                    >
                      { loading ? <div style={{height: '500px'}}> <SimpleLoading /> </div> : <AddressList
                        disableRecords={disableFields || isBtnDisableFromSecurity}
                        id="Vendor_address_component" 
                        onAddressCreate={AddressCreateVendor}
                        address_source_integrator={id}
                        addressSourc_id={editVendor?.addresssource_id}
                        source_id={Settings.source_id.vendor}
                        isTooltipNeeded={true}
                        toolTipText="This is the primary address of the vendor's main office. Ensure the details are accurate as they will be used for official communication and documentation."
                        isContactTooltipNeeded={true}
                        contactToolTipText="These are the contact details associated with the vendor's specific address. Use this section to add phone numbers, email addresses, or other contact information relevant to this location."
                      /> }
                    </div>
                    <div
                      className="lists_contant five"
                      id={`${sectionName}Contact`}
                      style={{ position: "relative" }}
                    >
                      { ContactModuleForVendor() }
                    </div>
                    <div
                      className="lists_contant six"
                      id={`${sectionName}Contact Person`}
                      style={{ position: "relative" }}
                    >
                     <div className='mt-2 mb-4'>
                     <ContactPerson 
                      needButtons={false}
                      vendor_id={id}
                      disableConditionVendor={disableFields || isBtnDisableFromSecurity}
                      isNeedDesignations={true}
                      userGridApi={userGridApi}
                    />
                     </div>
                    </div>
                    <div
                      className="lists_contant seven"
                      id={`${sectionName}Users`}
                      style={{ position: "relative" }}
                    >
                      <UsersEdit
                        disableFields={disableFields || isBtnDisableFromSecurity}
                        id={id}
                        currency={currency}
                        gridApi={userGridApi}
                      />
                    </div>
                    <div
                      className="lists_contant eight"
                      id={`${sectionName}Categories`}
                      style={{ position: "relative" }}
                    >
                      {CategoriesModuleForVendor()}
                    </div> 
                    <div
                      className={"lists_contant nine"}
                      id={`${sectionName}Terms & Conditions`}
                      style={{ position: "relative" }}
                    >
                      <TermsConditionsVendor
                        disableFields={disableFields || isBtnDisableFromSecurity}
                        vendor_id={id}
                        setVendor={setEditVendor}
                        masterVendor={editVendor} 
                      />
                    </div>
                  </>
                }
                rightSideContent={addComponentRight}
              />
            </> 
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.masterData.vendors.vendors_edit_screen
}

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(VendorsEdit, SecurityOptions), { masterClassName: 'no_padding_class' })))