import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import $ from 'jquery';
import Settings from '../../../inc/Settings';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation';

const VendorCategory = ({ Vendorcategories, readOnlyMode = false, currentCategoryArray = [], setVendorCategories, editMode, reloadGrid, setShowCategories, vendor_id, language }) => {
  const [vendorCategorySearch, setVendorCategorySearch] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [noResultSearchTerms, setNoResultSearchTerms] = useState(new Set());
  
  useEffect(() => {
    getCategoriesList();
  }, [currentPage]);

  useEffect(() => {
    const filtered = categoryList?.filter((item) =>
      item.label?.toLowerCase()?.includes(vendorCategorySearch?.toLowerCase())
    );
    setFilteredCategories(filtered);

    if (checkIfArrayIsEmpty(categoryList)) {
      getCategoriesList();
      return;
    }

    if (filtered?.length === 0 && vendorCategorySearch) {
      if (!noResultSearchTerms.has(vendorCategorySearch.toLowerCase())) {
        getCategoriesList(vendorCategorySearch);
      }
    }
  }, [vendorCategorySearch, categoryList]);

  const getCategoriesList = (searchQuery = null) => {
    if (categoryLoading) return;

    setCategoryLoading(true);
    Api.setUserToken();
    Api.axios()
      .get(
        `${Settings.apiUrl}/categories?page_number=${currentPage}&page_size=${pageSize}${
          searchQuery ? `&search=${searchQuery}` : ''
        }`
      )
      .then((res) => {
        if (res.data.status === 'success') {
          const fetchedCategories = res?.data?.data?.map((element) => ({
            value: element.category_id,
            label: `${element.code} - ${element.name}`,
          }));

          setCategoryList((prevList) =>
            searchQuery
              ? fetchedCategories
              : [...prevList, ...fetchedCategories.filter(
                  (newItem) => !prevList.some((oldItem) => oldItem.value === newItem.value)
                )]
          );
          setTotalPages(Math.ceil(res?.data?.total_records / pageSize));

          if (searchQuery && res?.data?.data?.length === 0) {
            setNoResultSearchTerms((prevSet) => new Set(prevSet).add(searchQuery.toLowerCase()));
            Helper.alert('No categories found for the search query', 'error');
          }
          setCategoryLoading(false);
        } 
      })
      .catch(() => {
        if (searchQuery) {
          setNoResultSearchTerms((prevSet) => new Set(prevSet).add(searchQuery.toLowerCase()));
          Helper.alert('No categories found for the search query', 'error');
        }
        setCategoryLoading(false);
      });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const SelectCategories = () => {
    let api = Api;
    api.setUserToken();
    Vendorcategories.forEach((element) => [
      api
        .axios()
        .post(Settings.apiVendorUrl + '/vendorcategory', {
          category_id: element,
          vendor_id: vendor_id,
          processinstance_id: null,
          relevance: null,
        })
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message);
            reloadGrid();
            setShowCategories(false);
            setVendorCategories([]);
            $('.vn_categories_popup_close .rs_popup_close').trigger('click');
          }
        })
        .catch((res) => {
          getPopupMessageBasedOnStatus(res);
        }),
    ]);
  };

  const CategoryHandler = useCallback(
    (value) => {
      if (Vendorcategories.includes(value)) {
        setVendorCategories(Vendorcategories.filter((item) => item !== value));
      } else {
        setVendorCategories([...Vendorcategories, value]);
      }
    },
    [Vendorcategories]
  );

  return (
    <div className="vendors_categories">
      <div className="action_button_vn_category mb-3 border-bottom pb-2">
        <div className="action_button_search_category">
          <input
            name="search_category"
            placeholder="Search"
            value={vendorCategorySearch}
            onChange={(e) => setVendorCategorySearch(e.target.value)}
          />
        </div>
        {editMode && (
          <Button
            className="rfq_save_btn"
            onClick={SelectCategories}
            title={Helper.getLabel(language, 'vn_category_select', 'Select Category')}
            isActive={false}
          />
        )}
      </div>

      {categoryLoading ? (
        <div className="vendor-category-wrapper">
          <SimpleLoading />
        </div>
      ) : (
        <>
          <div className="vendor-category-wrapper common_scrollbar_styles">
            <div className="chips-container-vn-category">
              {filteredCategories?.map((item) => (
                <div
                  key={item.value}
                  style={{ pointerEvents: readOnlyMode ? 'none' : 'auto', userSelect: 'none' }}
                  className={`chip-vn-category ${
                    Vendorcategories.includes(item.value) ||
                    Vendorcategories.some((s) => s.vendorcategory_id === item.value)
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => CategoryHandler(item.value)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>

          <div className="vendor-categories-pagination-buttons">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              {`<`}
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              {`>`}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(VendorCategory);