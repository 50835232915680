import React, { useState, useMemo, useRef, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import "../Email.css";
import NavigationHeder from "../../Navigations/NavigationHeder";
import LinkBtn from "../../inc/LinkBtn";
import Button from "../../inc/Button";
import Helper from "../../../inc/Helper";
import { connect } from "react-redux";
import DropDownInput from "../../Forms/Dropdown";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import Input from "../../Forms/Input";
import Gui_id_list from "../../../inc/Gui_id_list";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Alert from "../../inc/Alert";
import Collapse from "../../inc/Collapse";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

const EditEmail = (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editEmails, setEditEmails] = useState({
    subject: "",
    body: "",
  });

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const wrapperDiv = useRef();
  const [height, setHeight] = useState(0);

  const [fields, setFields] = useState([]);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);

  let fieldsCalled = false;
  let sourcesCalled = false;
 
  const get_emails_details = () => { 
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + `/emaildetail/${id}`, {})
      .then(function (res) {
        if (res.data.status === "success") {
          setEditEmails(res.data?.data);
          setContent(res?.data?.data?.mail_body);
          setLoading(false);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
        setLoading(false);
      });
  };

  const get_fields = (source_id) => {
    if (fieldsCalled || !source_id) {
      return;
    }
    let api = Api;
    fieldsCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + `/email_template_fields?source_id=${source_id}`)
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.field_id,
              label: element.field_name,
            });
          });
          setFields(UPDATED_STATE);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  const get_sources = () => {
    if (sourcesCalled) {
      return;
    }
    let api = Api;
    sourcesCalled = true;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiUrl + "/source")
      .then(function (res) {
        if (res.data.status === "success") {
          let UPDATED_STATE = [];
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
              value: element.source_id,
              label: element.source_name,
            });
          });
          setSources(UPDATED_STATE);
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  useEffect(() => {
    get_sources();
    get_emails_details();
  }, []);

  const SourceChangeHandler = (event) => {
    setSelectedSource(event.target.value);
    get_fields(event.target.value);
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event?.params?.data?.text;
    const currentValue = content;
    const newValue =
      currentValue.replace(/<\/?p>/g, "") +
      (currentValue.length > 0 ? " " : "") +
      `{{${selectedValue}}}`;
    setContent(newValue);
  };

  const SubjectChangeHandler = (event) => {
    let Subject = event.target.value;
    setEditEmails((prevState) => {
      return { ...prevState, subject: Subject };
    });
  };

  const NameChangeHandler = (event) => {
    let name = event.target.value;
    setEditEmails((prevState) => {
      return { ...prevState, name: name };
    });
  };

  const SaveHandler = () => {
    setLoading(true);
    if(editEmails?.name === null || editEmails?.name===undefined || editEmails?.name===""){
      Helper.alert("Name field is required","error")
      setLoading(false)
      return
    }
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(Settings.apiUrl + `/emaildetail/${id}`, {
        subject: editEmails.subject,
        body: content,
        name: editEmails.name
      })
      .then(function (res) {
        if (res.data.status === "success") {
          Helper.alert(res.data.message);
          get_emails_details();
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
        setLoading(false)
      });
  };

  let security = props.security;
  let frontendIds = Gui_id_list;
  if (
    !security.canView(
      Gui_id_list.setupForms.email_template.email_template_edit_screen
    )
  ) {
    return (
      <Fragment>
        <Alert message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />
      </Fragment>
    );
  }

  return (
    <Fragment>
      { loading ? <OverlayLoader /> : null }
      <div className="container-fluid edit-emails-section">
        <div ref={wrapperDiv}>
          <NavigationHeder
            hideMoreBtn={true}
            title={`<span style="color:#000;">${Helper.getLabel(
              props.language,
              "Edit Email Template",
              "Edit Email Template"
            )} #</span> ${editEmails?.subject}`}
          >
            <LinkBtn
              isActive={false}
              to="/email-templates"
              className='black-btn-style'
              title={Helper.getLabel(props.language, "close", "Close")}
            />
            <Button
              isDisable={
                !security.canCreate(
                  frontendIds.setupForms.email_template
                    .email_template_edit_screen_save_button
                )
              }
              className="rfq_save_btn"
              isActive={false}
              title={Helper.getLabel(props.language, "save", "Save")}
              onClick={SaveHandler}
            />
          </NavigationHeder>
        </div>

        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}> 
          <Collapse title="Email Template" open={true}>
            <div className="d-flex gap-4">
             <div className="col-lg-3 mb-4">
                <Input
                  label={Helper.getLabel(props.language, "name", "Name")}
                  type="text"
                  onChange={NameChangeHandler}
                  value={editEmails.name}
                  required={true}
                />
              </div>
              <div className="col-lg-3 mb-4">
                <Input
                  label={Helper.getLabel(props.language, "subject", "Subject")}
                  type="text"
                  onChange={SubjectChangeHandler}
                  value={editEmails?.subject}
                />
              </div>
              <div className="col-lg-3 mb-4">
                <DropDownInput
                  id="dynamic-sources"
                  label={Helper.getLabel(props.language, "sources", "Sources")}
                  options={sources}
                  onChange={SourceChangeHandler}
                />
              </div>
              {selectedSource ? (
                <div className="col-lg-3 mb-4">
                  <DropDownInput
                    id="dynamic-fields"
                    label={Helper.getLabel(props.language, "fields", "Fields")}
                    options={fields}
                    onChange={handleDropdownChange}
                    reRenderRequired={true}
                  />
                </div>
              ) : null}
            </div>

            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => {
                setContent(newContent);
              }}
            />
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};
const SecurityOptions = {
  gui_id: Gui_id_list.setupForms.email_template.email_template_edit_screen,
};

export default connect(mapStateToProps)(
  MasterComponentWraper(ApplySecurityRoles(EditEmail, SecurityOptions))
);